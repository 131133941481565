<template>
   <div class="m-4">
                    <h2>
                        بررسی صحت پرداخت
                    </h2>

                    <div class="container">
                        <div class="box p-5 m-2">
                            <div v-if="!isSuccess && !isPending" class="alert alert-danger p-5">
                                <h4>
                                    خطا در پرداخت اینترنتی
                                </h4>
                                متاسفانه پرداخت اینترنتی شما موفق نبود.
                            </div>
                            <div v-if="isSuccess && !isPending" class="alert alert-success p-5">
                                <h4>
                                    پرداخت موفق
                                </h4>
                                با تشکر پرداخت شما با موفقیت انجام شد.
                                <br/>
                                رسید دیجیتالی:
                                {{refnum}}
                                <br/>
                                مبلغ واریزی:
                                {{amount | numFormat}}
                                تومان
                            </div>
                            <div v-if="isPending" class="alert alert-info p-5">
                                <h4>
                                    بررسی پرداخت
                                </h4>
                                لطفا کمی صبر کنید.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <router-link to="/user/wallet" class="btn btn-warning history-btn">
                                بازگشت
                            </router-link>
                        </div>
                    </div>
                </div>
</template>

<script>
    import axios from 'axios';
   


    export default {
        name: 'FiatVerify',
        props: ['moduleId'],
        components: {
           
        },
        data() {
            return {
                markets: {},
                state: "",
                refnum: "",
                paidAmount: 0,
                isSuccess: true,
                isPending: true,
                amount: 0,
            }
        },
        computed: {
            irtMarkets: function () {
                return Object.values(this.markets).filter((m) => m.quote_asset_ticker === "IRT")
            },
            usdtMarkets: function () {
                return Object.values(this.markets).filter((m) => m.quote_asset_ticker === "USDT")
            },

        },
        methods: {
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },
            updateMarketList() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/market/list')
                    .then(response => {
                        this.markets = response.data;
                    })
                    .catch(() => {
                        // console.log(e)
                    });

            },
        },
        created() {
            this.$nextTick(function () {
                this.state = this.$route.query.state;
                this.refnum = this.$route.query.refnum;
                this.paidAmount = this.$route.query.amount;
                this.updateMarketList();
            });
            this.$nextTick(function () {
                if (this.refnum !== undefined && this.refnum !== "" && this.paidAmount !== undefined && this.paidAmount !== "" && this.state !== undefined && this.state === "SUCCESSFUL") {
                    this.notice('success', 'پرداخت موفق', "با تشکر پرداخت موفق بود", 3);
                    this.isSuccess = true;
                    this.amount = this.paidAmount;
                    this.isPending = false;
                } else {
                    this.isSuccess = false;
                    this.isPending = false;
                }
            });
        },

        mounted() {

        }

    }
</script>
<style lang="scss" scoped>
    .app {
        text-align: center;

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            background-color: #222;
            color: #fff;
        }

        &__title {
            display: block;
            font-size: 1.5em;
        }
    }
</style>
<style scoped>


    .history-btn {
        width: 200px;
        border-radius: 20px;
    }

    .wrapper {
        width: 100%;
    }

    #content {
        width: 100%;
        display: block;
        padding: 0;
        min-height: 100vh;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
    }

    .box {
        color: #000;
        background-color: #fff;
        border-radius: 10px;
    }

    .box .logo img {
        max-width: 100px;
    }

    @media screen and (max-width: 600px) {
        .box {
            font-size: 12px;
        }

        .box h4 {
            font-size: 18px;
        }

        .box .logo img {
            max-width: 50px;
        }
    }

</style>
