<template>
  <div id="content" class="px-4 my-5">
    <h2>
      پیام‌ها
    </h2>

    <div class="container">
      <div class="box p-2 mt-3">
        <div class="vld-parent " >
          <MessagesTable :messages="messages" />
        </div>

        <div class="pagination">
          <paginate
            :pageCount="pageCount"
            :clickHandler="pagination"
            :prevText="'قبلی'"
            :nextText="'بعدی'"
            :containerClass="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :active-class="'page-item active'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MessagesTable from "./MessagesTable";

export default {
  name: "Messages",
  title: "اعلان‌ها",

  props: {
    msg: String,
  },
  components: {
    MessagesTable,
  },
  data() {
    return {
      messages: null,
      pageCount: 0,
      assets: [],
      markets: [],
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    pagination(page) {
      this.getMessages(page);
    },
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    getMessages(page) {
      const limit = 20;

      let loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
        bars: "dots",
      });

      let url = `${process.env.VUE_APP_BACKEND_URL}/user/messages?limit=${limit}`;
      if (page !== undefined) {
        const offset = (page - 1) * limit;
        url = `${process.env.VUE_APP_BACKEND_URL}/user/messages?offset=${offset}&limit=${limit}`;
      }

      axios
        .get(url, { withCredentials: true })
        .then((response) => {
          this.messages = response.data;
          this.pageCount = Math.ceil(response.data.total / limit);
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          // console.log(e)
        });
    },
  },
  created() {
    this.checkSession();
    this.updateMarketList();
  },
  mounted() {
    this.getMessages();
  },
};
</script>

<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.wrapper {
  width: 100%;
}
.list-item-announcement{
  width: 100%;
  border-bottom: 1px solid #666;
  margin-bottom: 15px;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}
</style>
