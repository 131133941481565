<template>
  <div class="row static">
      <div class="container">
        <div class="content">
          <p><strong>کارمزد برداشت:</strong></p>

          <p>مبالغ کمتر از 50 میلیون تومان 4000 هزار تومان</p>

          <p>مبالغ بالای 50 میلیون تومان 8000 تومان</p>

          <div class="m-5"></div>

          <p><strong>تسویه به مقصد بانک آینده به شرح زیر است:</strong></p>

          <ul>
            <li>
              <p>
                تسویه به مقصد بانک آینده همه روزه به صورت آنی می باشد.
              </p>
            </li>
            <li> 
              <p>
                تسویه به مقصد سایر بانک ها طبق سیکل پایا در ایام غیر تعطیل می باشد.
              </p>
            </li>

            <li>
              <p>
                چنانچه درخواست برداشت در روزهای پنجشنبه پس از ساعت ۱۳ یا در روزهای تعطیل رسمی و یا جمعه ثبت گردد واریزی به روز کاری بعدی در اولین ساعت سیکل پایا موکول خواهد شد.
              </p>
            </li>
          </ul>

          <div class="m-5"></div>
          <p><strong> ساعت سیکل پایا:</strong></p>

          <ul>
            <li>
              <p>
                درخواست برداشت از ساعت ۲۳ الی ۹ صبح ساعت ۱۱ صبح واریز میگردد.              </p>
            </li>
            <li>
              <p>
                درخواست برداشت از ساعت ۱۰ الی ۱۲ در ساعت ۱۴ همان روز واریز میگردد.
              </p>
            </li>
            <li>
              <p>
                درخواست برداشت از ساعت ۱۳ الی ۲۲ در ساعت ۴ صبح روز بعد واریز میگردد.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>


export default {
  name: "Withdraw",
  props: {},
  components: {
   
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 20px !important;
}

table,
td {
  border: 0 !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.custom-table td {
  border: 2px solid #fff;
}

.table,
td {
  border: 1px solid #fff;
  direction: rtl;
}
.static {
  background-size: 100%;
  min-height: 500px;
}

.static .content {
  margin-top: 50px;
}
</style>
