<template>
  <div id="content">
    <div class="row no-margin">
      <div class="col-12 col-md-3 no-padding order-12 order-md-0">
        <div class="trade-boxes d-none d-md-block bg-marketState">
          <MarketStats :stats="currentMarket" />
        </div>
        <div class="p-2 trade-bg">
          <div class="row">
            <div class="col-6">آخرین معاملات</div>
            <div class="col-6 text-right">
              <span class="market-name">
                {{ currentMarket.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="trade-boxes">
          <RecentOrders :orders="recentOrders" :market="currentMarket" />
        </div>
      </div>
      <div class="col-12 col-md-6 no-padding order-0 order-md-1">
        <div
          class="col-12 ltr no-padding order-0 order-md-1"
          style="height: 400px; min-height: 400px"
        >
          <TVChartContainer
            :symbol="marketId"
            :interval="'60'"
          ></TVChartContainer>
        </div>
        <div class="row no-margin">
          <!-- buy start -->
          <div class="trade-boxes order-box col-12 col-md-6 no-padding">
            <div class="bg-success text-center padding-5">
              خرید {{ currentMarket.base_asset_name }}
            </div>
            <ul
              class="nav mb-3 mt-4 nav-fill"
              id="pills-tab-bid"
              role="tablist"
            >
              <li class="nav-item text-center p-2">
                <a
                  class="nav-link order-tab-btn active"
                  id="pills-bid-limit-tab"
                  @click="bidTabClick()"
                  data-toggle="pill"
                  href="#pills-bid-limit"
                  role="tab"
                  aria-selected="true"
                >
                  سفارش عادی
                </a>
              </li>
              <li class="nav-item text-center p-2">
                <a
                  class="nav-link order-tab-btn"
                  id="pills-bid-market-tab"
                  data-toggle="pill"
                  @click="bidTabClick()"
                  href="#pills-bid-market"
                  role="tab"
                  aria-selected="false"
                >
                  سفارش فوری
                </a>
              </li>
            </ul>
            <div class="col-12 balance-info">
              <div class="col-12 mb-2">
                موجودی ({{ currentMarket.quote_asset_ticker }}):

                <a
                  href="javascript:void(0);"
                  @click="bidBalanceClick()"
                  class="balance font-weight-bold"
                >
                  {{ balanceQuote | prec(currentMarket.quote_asset_prec + 1) }}
                </a>
              </div>
              <div class="col-12">
                بهترین پیشنهاد برای خرید:
                <a
                  href="javascript:void(0);"
                  @click="bidBestClick()"
                  class="balance font-weight-bold"
                >
                  {{ bestAsk | prec(currentMarket.price_prec) }}
                </a>
              </div>
            </div>
            <div
              class="tab-content"
              id="pills-tab-bid-Content"
              style="min-height: 330px"
            >
              <div
                class="tab-pane fade show active"
                id="pills-bid-limit"
                style="padding: 20px"
                role="tabpanel"
              >
                <form @submit="submitBidLimit">
                  <div class="form-group">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="bid-limit-price-prepend"
                          style="width: 100%"
                          >قیمت واحد ({{
                            currentMarket.quote_asset_ticker
                          }})</span
                        >
                      </div>
                      <currency-input
                        class="form-control"
                        id="bid-limit-price"
                        autocomplete="off"
                        v-model="bidLimitPrice"
                        locale="en"
                        :currency="null"
                        :value-as-integer="false"
                        :distraction-free="false"
                        :auto-decimal-mode="false"
                        :precision="{
                          min: 0,
                          max: currentMarket.price_prec + 1,
                        }"
                        :allow-negative="false"
                      ></currency-input>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="bid-limit-amount-prepend"
                          style="width: 100%"
                          >مقدار ({{ currentMarket.base_asset_ticker }})</span
                        >
                      </div>

                      <currency-input
                        class="form-control"
                        id="bid-limit-amount"
                        autocomplete="off"
                        v-model="bidLimitAmount"
                        locale="en"
                        :currency="null"
                        :value-as-integer="false"
                        :distraction-free="false"
                        :auto-decimal-mode="false"
                        :precision="{
                          min: 0,
                          max: currentMarket.amount_prec + 1,
                        }"
                        :allow-negative="false"
                      ></currency-input>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <vue-slider
                      v-model="bidLimitSliderValue"
                      @change="onBidLimitSliderChange"
                      :min="0"
                      :max="100"
                      :interval="1"
                      :marks="sliderMarks"
                      :tooltip="'always'"
                      :tooltip-placement="'bottom'"
                      :hide-label="true"
                      :tooltip-formatter="sliderTooltipFormatter"
                    ></vue-slider>
                  </div>

                  <div class="row col-12 balance-info mb-2">
                    <div class="col-12 mt-4 mb-2">
                      مبلغ کل سفارش ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        bidLimitTotalPrice
                          | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                    <div class="col-12 mb-2">
                      دریافتی شما ({{ currentMarket.base_asset_ticker }}):
                      {{
                        bidLimitReceive | prec(currentMarket.base_asset_prec)
                      }}
                    </div>
                    <div class="col-12">
                      کارمزد ({{ currentMarket.base_asset_ticker }}):
                      {{
                        bidLimitFeeAmount | prec(currentMarket.base_asset_prec)
                      }}
                    </div>
                  </div>
                  <div class="col-12 text-center mt-4">
                    <button
                      v-if="session.exists('user_info')"
                      type="submit"
                      class="cta btn btn-success"
                    >
                      خرید
                    </button>
                    <router-link v-else to="/login" class="cta btn btn-success">
                      وارد شوید
                    </router-link>
                  </div>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="pills-bid-market"
                role="tabpanel"
                style="padding: 20px; min-height: 326px"
              >
                <!-- market -->
                <form @submit="submitBidMarket">
                  <div class="form-group">
                    <div class="form-group">
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend" style="width: 42%">
                          <span
                            class="input-group-text"
                            id="bid-market-amount-prepend"
                            style="width: 100%"
                            >قیمت واحد ({{
                              currentMarket.quote_asset_ticker
                            }})</span
                          >
                        </div>

                        <input
                          disabled
                          class="form-control"
                          id="bid-market-amount"
                          autocomplete="off"
                          value="قیمت بازار"
                        />
                      </div>
                    </div>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="bid-market-total-prepend"
                          style="width: 100%"
                          >مبلغ کل معامله ({{
                            currentMarket.quote_asset_ticker
                          }})</span
                        >
                      </div>

                      <currency-input
                        class="form-control"
                        id="bid-market-total"
                        autocomplete="off"
                        v-model="bidMarketTotal"
                        locale="en"
                        :currency="null"
                        :value-as-integer="false"
                        :distraction-free="false"
                        :auto-decimal-mode="false"
                        :precision="{
                          min: 0,
                          max: currentMarket.price_prec + 1,
                        }"
                        :allow-negative="false"
                      ></currency-input>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <vue-slider
                      v-model="bidMarketSliderValue"
                      @change="onBidMarketSliderChange"
                      :min="0"
                      :max="100"
                      :interval="1"
                      :marks="sliderMarks"
                      :tooltip="'always'"
                      :tooltip-placement="'bottom'"
                      :hide-label="true"
                      :tooltip-formatter="sliderTooltipFormatter"
                    ></vue-slider>
                  </div>
                  <div class="row col-12 balance-info mb-2">
                    <div class="col-12 mt-4 mb-2">
                      مبلغ کل سفارش ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        bidMarketTotalPrice
                          | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                    <div class="col-12 mb-2">
                      دریافتی شما ({{ currentMarket.base_asset_ticker }}):
                      {{
                        bidMarketReceive | prec(currentMarket.base_asset_prec)
                      }}
                    </div>
                    <div class="col-12">
                      کارمزد ({{ currentMarket.base_asset_ticker }}):
                      {{
                        bidMarketFeeAmount | prec(currentMarket.base_asset_prec)
                      }}
                    </div>
                  </div>
                  <div class="col-12 text-center mt-4">
                    <button
                      v-if="session.exists('user_info')"
                      type="submit"
                      class="cta btn btn-success"
                    >
                      خرید
                    </button>
                    <router-link v-else to="/login" class="cta btn btn-success btn-block">
                      وارد شوید
                    </router-link>
                  </div>
                </form>
                <!-- market -->
              </div>
            </div>
          </div>
          <!-- buy end -->

          <!-- ask start --->
          <div class="trade-boxes order-box col-12 col-md-6 no-padding">
            <div class="bg-danger text-center padding-5">
              فروش {{ currentMarket.base_asset_name }}
            </div>
            <ul
              class="nav mb-3 mt-4 nav-fill"
              id="pills-tab-ask"
              role="tablist"
            >
              <li class="nav-item text-center p-2">
                <a
                  class="nav-link order-tab-btn active"
                  id="pills-ask-limit-tab"
                  @click="askTabClick()"
                  data-toggle="pill"
                  href="#pills-ask-limit"
                  role="tab"
                  aria-selected="true"
                >
                  سفارش عادی
                </a>
              </li>
              <li class="nav-item text-center p-2">
                <a
                  class="nav-link order-tab-btn"
                  id="pills-ask-market-tab"
                  data-toggle="pill"
                  @click="askTabClick()"
                  href="#pills-ask-market"
                  role="tab"
                  aria-selected="false"
                >
                  سفارش فوری
                </a>
              </li>
            </ul>
            <div class="col-12 balance-info">
              <div class="col-12 mb-2">
                موجودی ({{ currentMarket.base_asset_ticker }}):
                <a
                  href="javascript:void(0);"
                  @click="askBalanceClick()"
                  class="balance font-weight-bold"
                >
                  {{ balanceBase | prec(currentMarket.base_asset_prec + 1) }}
                </a>
              </div>
              <div class="col-12">
                بهترین پیشنهاد برای فروش:
                <a
                  href="javascript:void(0);"
                  @click="askBestClick()"
                  class="balance font-weight-bold"
                >
                  {{ bestBid | prec(currentMarket.price_prec) }}
                </a>
              </div>
            </div>
            <div
              class="tab-content"
              id="pills-tab-ask-Content"
              style="min-height: 330px"
            >
              <div
                class="tab-pane fade show active"
                id="pills-ask-limit"
                style="padding: 20px"
                role="tabpanel"
              >
                <form @submit="submitAskLimit">
                  <div class="form-group">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="ask-limit-price-prepend"
                          style="width: 100%"
                          >قیمت واحد ({{
                            currentMarket.quote_asset_ticker
                          }})</span
                        >
                      </div>
                      <currency-input
                        class="form-control"
                        id="ask-limit-price"
                        autocomplete="off"
                        v-model="askLimitPrice"
                        locale="en"
                        :currency="null"
                        :value-as-integer="false"
                        :distraction-free="false"
                        :auto-decimal-mode="false"
                        :precision="{
                          min: 0,
                          max: currentMarket.price_prec + 1,
                        }"
                        :allow-negative="false"
                      ></currency-input>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="ask-limit-amount-prepend"
                          style="width: 100%"
                          >مقدار ({{ currentMarket.base_asset_ticker }})</span
                        >
                      </div>

                      <currency-input
                        class="form-control"
                        id="ask-limit-amount"
                        autocomplete="off"
                        v-model="askLimitAmount"
                        locale="en"
                        :currency="null"
                        :value-as-integer="false"
                        :distraction-free="false"
                        :auto-decimal-mode="false"
                        :precision="{
                          min: 0,
                          max: currentMarket.amount_prec + 1,
                        }"
                        :allow-negative="false"
                      ></currency-input>
                    </div>
                  </div>

                  <div class="col-12 mb-3">
                    <vue-slider
                      v-model="askLimitSliderValue"
                      @change="onAskLimitSliderChange"
                      :min="0"
                      :max="100"
                      :interval="1"
                      :marks="sliderMarks"
                      :tooltip="'always'"
                      :tooltip-placement="'bottom'"
                      :hide-label="true"
                      :tooltip-formatter="sliderTooltipFormatter"
                    ></vue-slider>
                  </div>

                  <div class="row col-12 balance-info mb-2">
                    <div class="col-12 mt-4 mb-2">
                      مبلغ کل سفارش ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        askLimitTotalPrice
                          | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                    <div class="col-12 mb-2">
                      دریافتی شما ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        askLimitReceive | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                    <div class="col-12">
                      کارمزد ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        askLimitFeeAmount | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                  </div>
                  <div class="col-12 text-center mt-4">
                    <button
                      v-if="session.exists('user_info')"
                      type="submit"
                      class="cta btn btn-danger"
                    >
                      فروش
                    </button>
                    <router-link v-else to="/login" class="cta btn btn-danger">
                      وارد شوید
                    </router-link>
                  </div>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="pills-ask-market"
                role="tabpanel"
                style="padding: 20px"
              >
                <!-- market -->
                <form @submit="submitAskMarket">
                  <div class="form-group">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="ask-market-amount-prepend"
                          style="width: 100%"
                          >قیمت واحد ({{
                            currentMarket.quote_asset_ticker
                          }})</span
                        >
                      </div>

                      <input
                        class="form-control"
                        id="ask-market-amount"
                        autocomplete="off"
                        disabled
                        value="قیمت بازار"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend" style="width: 42%">
                        <span
                          class="input-group-text"
                          id="ask-market-total-prepend"
                          style="width: 100%"
                          >مقدار ({{ currentMarket.base_asset_ticker }})</span
                        >
                      </div>

                      <currency-input
                        class="form-control"
                        id="ask-market-amount"
                        autocomplete="off"
                        v-model="askMarketAmount"
                        locale="en"
                        :currency="null"
                        :value-as-integer="false"
                        :distraction-free="false"
                        :auto-decimal-mode="false"
                        :precision="{
                          min: 0,
                          max: currentMarket.amount_prec + 1,
                        }"
                        :allow-negative="false"
                      ></currency-input>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <vue-slider
                      v-model="askMarketSliderValue"
                      @change="onAskMarketSliderChange"
                      :min="0"
                      :max="100"
                      :interval="1"
                      :marks="sliderMarks"
                      :tooltip="'always'"
                      :tooltip-placement="'bottom'"
                      :hide-label="true"
                      :tooltip-formatter="sliderTooltipFormatter"
                    ></vue-slider>
                  </div>

                  <div class="row col-12 balance-info mb-2">
                    <div class="col-12 mt-4 mb-2">
                      مبلغ کل سفارش ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        askMarketTotalPrice
                          | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                    <div class="col-12 mb-2">
                      دریافتی شما ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        askMarketReceive | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                    <div class="col-12">
                      کارمزد ({{ currentMarket.quote_asset_ticker }}):
                      {{
                        askMarketFeeAmount
                          | prec(currentMarket.quote_asset_prec)
                      }}
                    </div>
                  </div>
                  <div class="col-12 text-center mt-4">
                    <button
                      v-if="session.exists('user_info')"
                      type="submit"
                      class="cta btn btn-danger"
                    >
                      فروش
                    </button>
                    <router-link v-else to="/login" class="cta btn btn-danger">
                      وارد شوید
                    </router-link>
                  </div>
                </form>
                <!-- market -->
              </div>
            </div>
          </div>
          <!---  ask end --->
        </div>
      </div>
      <div class="col-12 col-md-3 no-padding order-1">
        <div class="trade-boxes">
          <OrderbookAsk
            :market="currentMarket"
            :depth="orderDepth"
            :total="totalAskAmount"
            :opens="openAsks"
            @rowClick="orderbookRowClicked($event)"
          />
        </div>
        <div class="p-2">
          قیمت آخرین معامله:

          <span
            v-if="lastOrder.type === 'buy'"
            class="last-price text-danger"
            >{{ lastOrder.price | prec(currentMarket.price_prec) }}</span
          >
          <span v-else class="last-price text-success">{{
            lastOrder.price | prec(currentMarket.price_prec)
          }}</span>
        </div>
        <div class="trade-boxes">
          <OrderbookBid
            :market="currentMarket"
            :depth="orderDepth"
            :total="totalBidAmount"
            :opens="openBids"
            @rowClick="orderbookRowClicked($event)"
          />
        </div>
      </div>
    </div>
    <div v-if="session.exists('user_info')" class="row no-margin">
      <div class="col-12 p-2">سفارشات باز</div>
      <OpenOrders :open-orders="openOrders" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
// import websocket_helper from "./websocket_helper";
import TVChartContainer from "./TVChartContainer";
import OrderbookAsk from "./OrderbookAsk";
import OrderbookBid from "./OrderbookBid";
import RecentOrders from "./RecentOrders";
import MarketStats from "./MarketStats";
import OpenOrders from "./OpenOrders";
import VueSlider from "vue-slider-component";
// import 'vue-slider-component/theme/default.css'

export default {
  name: "Trade",

  title: "ترید",
  props: ["marketId", "userInfo"],
  components: {
    OpenOrders,
    MarketStats,
    RecentOrders,
    TVChartContainer,
    OrderbookAsk,
    OrderbookBid,
    VueSlider,
  },
  data() {
    return {
      pageTitle: undefined,
      metaDescription: undefined,

      sliderTooltipFormatter: "{value}%",

      bidLimitSliderValue: 0,
      bidMarketSliderValue: 0,
      askLimitSliderValue: 0,
      askMarketSliderValue: 0,

      makerFee: 0,
      takerFee: 0,

      sliderMarks: [0, 25, 50, 75, 100],
      sliderTest: 0,

      session: this.$session,

      markets: [],
      marketTicker: "",

      bidLimitAmount: null,
      bidLimitPrice: null,
      bidLimitTotalPrice: null,
      bidLimitTotalAmount: null,
      bidLimitFeeAmount: 0,
      bidLimitReceive: 0,

      bidMarketAmount: null,
      bidMarketTotal: null,
      bidMarketTotalPrice: null,
      bidMarketTotalAmount: null,
      bidMarketFeeAmount: 0,
      bidMarketReceive: 0,

      askLimitAmount: null,
      askLimitPrice: null,
      askLimitTotalPrice: null,
      askLimitTotalAmount: null,
      askLimitFeeAmount: 0,
      askLimitReceive: 0,

      askMarketAmount: null,
      askMarketTotal: null,
      askMarketTotalPrice: null,
      askMarketTotalAmount: null,
      askMarketFeeAmount: 0,
      askMarketReceive: 0,

      balanceBase: 0,
      balanceQuote: 0,

      bestBid: 0,
      bestAsk: 0,

      balances: {},

      orderDepth: {},

      totalBidAmount: 0,
      totalAskAmount: 0,

      currentMarket: {},

      lastOrder: {
        price: 0,
        amount: 0,
        type: "buy",
      },

      openOrders: [],

      recentOrders: [],

      polling: null,

      sumOfAsksAmount: 0,
      sumOfBidsAmount: 0,

      openBids: [],
      openAsks: [],
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,

      meta: [
        { name: "og:title", vmid: "og:title", content: this.pageTitle },
        {
          name: "description",
          vmid: "description",
          content: this.metaDescription,
        },
        {
          name: "og:description",
          vmid: "og:description",
          content: this.metaDescription,
        },
      ],
    };
  },
  methods: {
    bidTabClick() {
      this.bidLimitSliderValue = 0;
      this.bidMarketSliderValue = 0;
      this.bidLimitAmount = 0;
      this.bidMarketTotal = 0;
    },

    askTabClick() {
      this.askLimitSliderValue = 0;
      this.askMarketSliderValue = 0;
      this.askMarketAmount = 0;
    },

    onBidLimitSliderChange(value) {
      if (this.bidLimitPrice > 0 && this.balanceQuote > 0) {
        const calc = (this.balanceQuote * value) / 100 / this.bidLimitPrice;

        this.bidLimitAmount = this.floor(calc, this.currentMarket.amount_prec);
        return;
      }
      this.bidLimitAmount = 0;
      return;
    },

    onBidMarketSliderChange(value) {
      if (this.balanceQuote > 0) {
        this.bidMarketTotal = (this.balanceQuote * value) / 100;
        return;
      }
      this.bidMarketTotal = 0;
      return;
    },

    bidBalanceClick() {
      if (this.balanceQuote > 0) {
        if (this.bidLimitPrice > 0) {
          const calc = this.balanceQuote / this.bidLimitPrice;
          this.bidLimitAmount = this.floor(
            calc,
            this.currentMarket.amount_prec
          );
          this.bidLimitSliderValue = 100;
        }
        this.bidMarketTotal = this.balanceQuote;
        this.bidMarketSliderValue = 100;
      }
    },

    bidBestClick() {
      if (this.balanceQuote > 0) {
        if (this.bestAsk > 0) {
          this.bidLimitPrice = this.bestAsk;
        }
      }
    },

    onAskLimitSliderChange(value) {
      if (this.balanceBase > 0) {
        this.askLimitAmount = (this.balanceBase * value) / 100;
        return;
      }
      this.askLimitAmount = 0;
      return;
    },

    onAskMarketSliderChange(value) {
      if (this.balanceBase > 0) {
        this.askMarketAmount = (this.balanceBase * value) / 100;
        return;
      }
      this.askMarketAmount = 0;
      return;
    },

    askBalanceClick() {
      if (this.balanceBase > 0) {
        this.askLimitAmount = this.balanceBase;
        this.askLimitSliderValue = 100;

        this.askMarketAmount = this.balanceBase;
        this.askMarketSliderValue = 100;
      }
    },

    askBestClick() {
      if (this.balanceBase > 0) {
        if (this.bestBid > 0) {
          this.askLimitPrice = this.bestBid;
        }
      }
    },

    updateOrderbook() {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/orderbook/depth/${this.marketId}`
        )
        .then((response) => {
          this.orderDepth = response.data;
          this.totalAskAmount = this.orderDepth.asks.reduce(
            (a, b) => a + (parseFloat(b[1]) || 0),
            0
          );
          this.totalBidAmount = this.orderDepth.bids.reduce(
            (a, b) => a + (parseFloat(b[1]) || 0),
            0
          );
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateRecentTrades() {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/orderbook/recent/${this.marketId}`
        )
        .then((response) => {
          this.recentOrders = response.data;
          this.$nextTick(function() {
            if (response.data.length > 0) {
              this.lastOrder = response.data[0];
            }
          });
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateCurrentMarketStats() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/market/stats/${this.marketId}`)
        .then((response) => {
          this.currentMarket = response.data;
          this.pageTitle = `ترید ${response.data.base_asset_name}  به   ${response.data.quote_asset_name} - لیدیا پلتفرم تبادل ارزهای دیجیتال`;
          this.metaDescription = `در این صفحه لیدیا می توانید ترید ${response.data.base_asset_name} به ${response.data.quote_asset_name} را انجام دهید`;

          if (this.$session.exists("user_info")) {
            this.$nextTick(function() {
              if (
                this.balances !== undefined &&
                this.currentMarket !== undefined &&
                this.balances !== null &&
                this.currentMarket !== null &&
                this.currentMarket.quote_asset_ticker !== undefined &&
                this.currentMarket.base_asset_ticker !== undefined
              ) {
                this.balanceQuote = this.balances[
                  this.currentMarket.quote_asset_ticker
                ].available;
                this.balanceBase = this.balances[
                  this.currentMarket.base_asset_ticker
                ].available;
                this.makerFee = Math.min(
                  this.currentMarket.maker_fee,
                  this.userInfo.fee_group.maker_fee
                );
                this.takerFee = Math.min(
                  this.currentMarket.taker_fee,
                  this.userInfo.fee_group.taker_fee
                );
              }
            });
          }
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateBalances() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/balances", {
          withCredentials: true,
        })
        .then((response) => {
          this.balances = response.data.balances;
        })
        .catch(() => {
          // console.log()
        });
    },

    updateOpenOrders() {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/user/openorders/${this.marketId}`,
          { withCredentials: true }
        )
        .then((response) => {
          this.openOrders = response.data;
          this.openBids = [];
          this.openAsks = [];
          this.openOrders.records.forEach((order) => {
            if (order.side === "bid") {
              this.openBids.push(parseFloat(order.price));
            } else {
              this.openAsks.push(parseFloat(order.price));
            }
          });
        })
        .catch(() => {
          // console.log()
        });
    },

    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateSums() {
      this.sumOfAsksAmount = this.asksSum();
      this.sumOfBidsAmount = this.bidsSum();
    },

    updateAllData() {
      if (this.session.exists("user_info")) {
        //update balances
        this.updateBalances();
        //update open orders
        this.updateOpenOrders();

        //update orderbook
      }

      this.updateOrderbook();

      //update current market stats
      this.updateCurrentMarketStats();

      //update recent trades
      this.updateRecentTrades();

      //update sum of bid and asks
      this.updateSums();
    },

    pollData() {
      this.polling = setInterval(() => {
        this.updateAllData();
      }, 3000);
    },

    prec(value, prec) {
      let format = "0,0";
      if (prec > 0) {
        format = `0,0.[${"0".repeat(prec)}]`;
      }
      return numeral(value)
        .format(format)
        .replace(".", "٫");
    },

    prec2(value, prec) {
      let format = "0,0";
      if (prec > 0) {
        format = `0,0.[${"0".repeat(prec)}]`;
      }
      return numeral(value).format(format);
    },

    orderbookRowClicked(row) {
      this.askLimitPrice = row.price;
      this.askLimitAmount = row.total;
      this.bidLimitPrice = row.price;
      this.bidLimitAmount = row.total;

      this.bidLimitSliderValue = 0;
      this.bidMarketSliderValue = 0;
      this.askLimitSliderValue = 0;
      this.askMarketSliderValue = 0;
    },

    floor(number, decimalPlaces) {
      const factorOfTen = Math.pow(10, decimalPlaces);
      return Math.floor(number * factorOfTen) / factorOfTen;
    },

    asksSum() {
      if (this.orderDepth.asks !== undefined && this.orderDepth.asks !== null) {
        return this.orderDepth.asks.reduce(
          (total, obj) => parseFloat(obj[1]) + total,
          0
        );
      }
      return 0;
    },

    bidsSum() {
      if (this.orderDepth.bids !== undefined && this.orderDepth.bids !== null) {
        return this.orderDepth.bids.reduce(
          (total, obj) => parseFloat(obj[1]) + total,
          0
        );
      }
      return 0;
    },

    calculateBidAmount(balance) {
      if (this.orderDepth !== undefined) {
        let price = 0;
        let total = 0;
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          const rowTotal = parseFloat(ask[0]) * parseFloat(ask[1]);
          price = parseFloat(ask[0]);
          total += rowTotal;
          if (balance <= total) {
            return parseFloat(ask[0]);
          }

          balance = balance - rowTotal;
        }
        return price;
      }
    },

    maxTotalAvailableForBid() {
      let totalTotalPrice = 0;
      if (this.orderDepth !== undefined) {
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          const price = parseFloat(ask[0]);
          const amount = parseFloat(ask[1]);
          totalTotalPrice += price * amount;
        }
      }
      return totalTotalPrice;
    },

    maxTotalAvailableForAsk() {
      let totalAmount = 0;
      if (this.orderDepth !== undefined) {
        const bids = this.orderDepth.bids;
        for (let i = 0; i < bids.length; i++) {
          const bid = bids[i];
          const amount = parseFloat(bid[1]);
          totalAmount += amount;
        }
      }
      return totalAmount;
    },

    calculateBidTotal(totalAmount) {
      let tap = 0;
      let ta = 0;
      // let pta = 0;
      if (this.orderDepth !== undefined) {
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          const price = parseFloat(ask[0]);
          const amount = parseFloat(ask[1]);
          const rowTotal = price * amount;
          tap += rowTotal;
          ta += amount;
          if (totalAmount === tap && tap > 0) {
            return ta;
          } else if (totalAmount < tap) {
            return (totalAmount - tap) / price + ta;
          }
          // pta += amount;
        }
      }
    },

    orderAmountButtonClicked(event) {
      if (event.type === "bid") {
        const balance = this.balanceQuote * (event.percent / 100);

        const maxBidAmount = this.asksSum();
        if (maxBidAmount === 0) {
          const last = parseFloat(this.lastOrder.price);
          const amount =
            last > 0
              ? this.floor(balance / last, this.currentMarket.amount_prec)
              : 0;
          this.bidLimitPrice = last > 0 ? last : 0;
          this.bidLimitAmount = amount;
          this.bidMarketAmount = 0;
          this.bidMarketTotal = 0;
          return;
        }

        const orderAmountPrice = this.calculateBidAmount(balance);
        const amountAdj =
          event.percent === 100
            ? this.floor(
                balance / orderAmountPrice,
                this.currentMarket.amount_prec
              )
            : balance / orderAmountPrice;
        this.bidLimitPrice = orderAmountPrice;
        this.bidLimitAmount = amountAdj;
        this.bidMarketAmount =
          amountAdj > maxBidAmount ? maxBidAmount : amountAdj;
        this.bidMarketTotal =
          amountAdj > maxBidAmount
            ? orderAmountPrice * maxBidAmount
            : balance * maxBidAmount;
      } else {
        this.askLimitPrice = this.bestBid;
        this.askLimitAmount = this.balanceBase * (event.percent / 100);
        this.askMarketAmount = this.balanceBase * (event.percent / 100);
      }
    },

    routeChanged() {
      this.bidLimitAmount = null;
      this.bidLimitPrice = null;
      this.bidLimitTotalPrice = null;

      this.bidMarketAmount = null;
      this.bidMarketTotalPrice = null;

      this.askLimitAmount = null;
      this.askLimitPrice = null;
      this.askLimitTotalPrice = null;

      this.askMarketAmount = null;
      this.askMarketTotalPrice = null;

      this.bidLimitSliderValue = 0;
      this.bidMarketSliderValue = 0;
      this.askLimitSliderValue = 0;
      this.askMarketSliderValue = 0;

      this.updateAllData();
    },

    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    submitBidLimit(e) {
      e.preventDefault();
      const limitBidParams = {
        price: this.bidLimitPrice,
        amount: this.bidLimitAmount,
        side: 2,
        market: this.currentMarket.identifier,
      };
      if (this.bidLimitPrice > 0 && this.bidLimitAmount > 0) {
        if (this.bidLimitPrice > this.bestAsk) {
          this.$confirm({
            message: `قیمت پیشنهادی شما بیش از پایین‌ترین قیمت فروش است، آیا می‌خواهید ادامه دهید؟`,
            button: {
              no: "خیر",
              yes: "بله",
            },
            callback: (confirm) => {
              if (confirm) {
                this.notice(
                  "info",
                  "در حال ثبت سفارش خرید",
                  `در حال ثبت سفارش خرید`
                );

                axios
                  .post(
                    process.env.VUE_APP_BACKEND_URL + "/order/limit",
                    limitBidParams,
                    { withCredentials: true }
                  )
                  .then(() => {
                    // this.notice('success', 'ثبت موفق سفارش خرید', `سفارش خرید به میزان ${this.bidLimitAmount} و مبلغ  ${this.bidLimitPrice} با موفقیت ثبت شد `);
                    this.notice(
                      "success",
                      "ثبت موفق سفارش خرید",
                      `سفارش خرید با موفقیت ثبت شد`
                    );
                  })
                  .catch((e) => {
                    this.notice("error", "خطا", e.response.data.message, 5);
                  });
              }
            },
          });

          return;
        }

        // this.notice('info', 'در حال ثبت سفارش خرید', `سفارش خرید به میزان ${this.bidLimitAmount} و مبلغ  ${this.bidLimitPrice}`);
        this.notice("info", "در حال ثبت سفارش خرید", `در حال ثبت سفارش خرید`);

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/order/limit",
            limitBidParams,
            { withCredentials: true }
          )
          .then(() => {
            // this.notice('success', 'ثبت موفق سفارش خرید', `سفارش خرید به میزان ${this.bidLimitAmount} و مبلغ  ${this.bidLimitPrice} با موفقیت ثبت شد `);
            this.notice(
              "success",
              "ثبت موفق سفارش خرید",
              `سفارش خرید با موفقیت ثبت شد`
            );
          })
          .catch((e) => {
            this.notice("error", "خطا", e.response.data.message, 5);
          });
      }
    },

    submitBidMarket(e) {
      e.preventDefault();
      const marketBidParams = {
        amount: this.bidMarketTotal,
        side: 2,
        market: this.currentMarket.identifier,
      };
      if (this.bidMarketTotal > 0) {
        this.notice("info", "در حال ثبت سفارش  حرید...");

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/order/market",
            marketBidParams,
            { withCredentials: true }
          )
          .then(() => {
            this.notice("success", "سفارش با موفقیت ثبت شد");
          })
          .catch((e) => {
            this.notice("error", "خطا", e.response.data.message, 5);
          });
      }
    },

    submitAskLimit(e) {
      e.preventDefault();
      const limitAskParams = {
        price: this.askLimitPrice,
        amount: this.askLimitAmount,
        side: 1,
        market: this.currentMarket.identifier,
      };

      if (this.askLimitPrice > 0 && this.askLimitAmount > 0) {
        this.notice("info", "در حال ثبت سفارش فروش ...");

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/order/limit",
            limitAskParams,
            { withCredentials: true }
          )
          .then(() => {
            this.notice("success", "سفارش با موفقیت ثبت شد");
          })
          .catch((e) => {
            this.notice("error", "خطا", e.response.data.message, 5);
          });
      }
    },

    submitAskMarket(e) {
      e.preventDefault();
      const marketAskParams = {
        amount: this.askMarketAmount,
        side: 1,
        market: this.currentMarket.identifier,
      };

      if (this.askMarketAmount > 0) {
        this.notice("info", "در حال ثبت سفارش  فروش...");

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/order/market",
            marketAskParams,
            { withCredentials: true }
          )
          .then(() => {
            this.notice("success", "سفارش با موفقیت ثبت شد");
          })
          .catch((e) => {
            this.notice("error", "خطا", e.response.data.message, 5);
          });
      }
    },

    bidAmountPriceRange(amount) {
      if (this.orderDepth !== undefined) {
        if (amount > this.asksSum()) {
          return 0;
        }
        let total = 0;
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          total += parseFloat(ask[1]);
          if (amount <= total) {
            return parseFloat(ask[0]);
          }
        }
      }
    },

    bidTotalToAmount(t) {
      if (
        this.orderDepth !== undefined &&
        this.orderDepth.asks !== undefined &&
        this.orderDepth.asks.length > 0
      ) {
        const maxPrice = this.orderDepth.asks[this.orderDepth.asks.length - 1];
        if (t >= maxPrice * this.asksSum()) {
          return 0;
        }
        let total = 0;
        // let amountTotal = 0;
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          total += parseFloat(ask[0]) * parseFloat(ask[1]);
          // amountTotal += parseFloat(ask[1]) ;

          if (t <= total) {
            return parseFloat(ask[0]);
          }
        }
      }
    },
    routeHandle() {
      const marketId = this.$route.params.marketId.toUpperCase();
      const split = marketId.split("_");
      if (split.length === 1) {
        this.$router.push({
          name: "Trade",
          params: { marketId: `${split[0]}_IRT` },
        });
        this.marketId = `${split[0]}_IRT`;
      } else {
        this.$router.push({
          name: "Trade",
          params: { marketId: `${split[0]}_${split[1]}` },
        });
        this.marketId = `${split[0]}_${split[1]}`;
      }
    },
  },
  created() {
    this.routeHandle();

    //update all markets
    this.updateMarketList();

    this.updateAllData();

    this.pollData();
  },

  mounted() {
    this.sumOfAsksAmount = this.asksSum();
    this.sumOfBidsAmount = this.bidsSum();
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  watch: {
    $route: "routeChanged",
    bidLimitAmount: function(val) {
      if (val > 0 && this.bidLimitPrice > 0) {
        let fee = this.makerFee;
        if (this.bestAsk > 0) {
          if (this.bidLimitPrice >= this.bestAsk) {
            fee = this.takerFee;
          }
        }
        const totalFee = this.bidLimitAmount * fee;

        this.bidLimitTotalPrice = this.bidLimitPrice * this.bidLimitAmount;
        this.bidLimitReceive = this.bidLimitAmount - totalFee;
        this.bidLimitFeeAmount = totalFee;
      } else {
        this.bidLimitTotalPrice = 0;
        this.bidLimitReceive = 0;
        this.bidLimitFeeAmount = 0;
      }
    },

    bidLimitPrice: function(val) {
      if (val > 0 && this.bidLimitAmount > 0) {
        let fee = this.makerFee;
        if (this.bestAsk > 0) {
          if (this.bidLimitPrice >= this.bestAsk) {
            fee = this.takerFee;
          }
        }
        const totalFee = this.bidLimitAmount * fee;

        this.bidLimitTotalPrice = this.bidLimitPrice * this.bidLimitAmount;
        this.bidLimitReceive = this.bidLimitAmount - totalFee;
        this.bidLimitFeeAmount = totalFee;
      } else {
        this.bidLimitTotalPrice = 0;
        this.bidLimitReceive = 0;
        this.bidLimitFeeAmount = 0;
      }
    },

    bidMarketTotal: function(val) {
      if (val > 0) {
        const maxTotalPrice = this.maxTotalAvailableForBid();
        if (val > maxTotalPrice) {
          this.notice(
            "error",
            "مقدار درخواستی بیش از حجم بازار است",
            "مقدار به حداکثر حجم بازار اصلاح گردید",
            3
          );
          this.bidMarketTotal = maxTotalPrice;
          return;
        }
        const fee = this.takerFee;
        const totalAmount = this.calculateBidTotal(val);
        const totalFee = totalAmount * fee;

        this.bidMarketTotalPrice = this.bidMarketTotal;
        this.bidMarketReceive = totalAmount - totalFee;
        this.bidMarketFeeAmount = totalFee;
      } else {
        this.bidMarketTotal = 0;
        this.bidMarketTotalAmount = 0;
        this.bidMarketTotalPrice = 0;
        this.bidMarketReceive = 0;
        this.bidMarketFeeAmount = 0;
      }
    },

    askLimitAmount: function(val) {
      if (val > 0 && this.askLimitPrice > 0) {
        let fee = this.makerFee;
        if (this.bestBid > 0) {
          if (this.askLimitPrice <= this.bestBid) {
            fee = this.takerFee;
          }
        }
        const totalFee = this.askLimitPrice * val * fee;
        const totalPrice = val * this.askLimitPrice;

        this.askLimitTotalPrice = totalPrice;
        this.askLimitFeeAmount = totalFee;
        this.askLimitReceive = totalPrice - totalFee;
      } else {
        this.askLimitTotalPrice = 0;
        this.askLimitFeeAmount = 0;
        this.askLimitReceive = 0;
      }
    },

    askLimitPrice: function(val) {
      if (val > 0 && this.askLimitAmount > 0) {
        let fee = this.makerFee;
        if (this.bestBid > 0) {
          if (val <= this.bestBid) {
            fee = this.takerFee;
          }
        }
        const totalFee = this.askLimitAmount * val * fee;
        const totalPrice = val * this.askLimitAmount;

        this.askLimitTotalPrice = totalPrice;
        this.askLimitFeeAmount = totalFee;
        this.askLimitReceive = totalPrice - totalFee;
      } else {
        this.askLimitTotalPrice = 0;
        this.askLimitFeeAmount = 0;
        this.askLimitReceive = 0;
      }
    },

    askMarketAmount: function(val) {
      if (val > 0) {
        const maxTotalAmount = this.maxTotalAvailableForAsk();
        if (val > maxTotalAmount) {
          this.notice(
            "error",
            "مقدار درخواستی بیش از حجم بازار است",
            "مقدار به حداکثر حجم بازار اصلاح گردید",
            3
          );
          this.askMarketAmount = maxTotalAmount;
          return;
        }

        // this.askMarketTotalPrice = (val * this.bestAsk);
        this.askMarketTotalPrice = val * this.bestBid;
        this.askMarketFeeAmount = this.bestBid * val * this.takerFee;
        this.askMarketReceive =
          this.askMarketTotalPrice - this.askMarketTotalPrice * this.takerFee;
      } else {
        this.askMarketTotalPrice = 0;
      }
    },

    currentMarket: function(val) {
      this.bestAsk = val.best_ask_raw;
      this.bestBid = val.best_bid_raw;
    },
  },

  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
};
</script>
<style lang="scss">
$themeColor: #4263eb;
$tooltipColor: #000;
@import "~vue-slider-component/lib/theme/default.scss";
</style>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.balance:hover {
  font-weight: bold;
  color: #fff !important;
}

.market-name {
  font-size: 13px;
  margin-left: 10px;
}

.last-price {
  font-size: 24px;
}

.balance-info {
  font-size: 10px;
  min-height: 30px;
}

.cta {
  color: #fff !important;
  border-radius: 12px;
  width: 100%;
  font-size: 14px;
}

.order-tab-btn {
  background-color: transparent !important;
  border-radius: 12px;
  width: 110px;
  font-size: 14px;
  right: 17%;
  position: relative;
  border: 1px solid #999;
  color: #eee;
}

.order-tab-btn.active {
  background-color: #4263eb !important;
  border: 1px solid #4263eb !important;
}

.input-group {
  border: #565656 3px solid;
}

.input-group input {
  border-radius: 0 !important;
  background-color: #9d9c9b;
  border-color: #9d9c9b;
  color: #000;
}

.input-group .input-group-prepend span {
  border-radius: 0 !important;
  background-color: #565656;
  border: #565656;
  color: #fff;
  font-size: 12px;
}

.trade-boxes {
  margin: 0;
  background-color: #212b36;
  border-radius: 12px;
}

.order-box {
  border: 2px #1e1919 solid;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.wrapper {
  width: 100%;
  overflow-x: hidden;
}
.font-weight-bold:hover {
  color: #b5b5b5 !important;
}
.font-weight-bold {
  font-weight: 900 !important;
  font-size: 11px !important;
}
@media screen and (max-width: 768px) {
  #content {
    overflow-x: hidden;
  }
}
</style>
