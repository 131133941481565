<template>
  <div class="row static main">
    <div class="container">
      <section id="tabs">
        <div class="container">
          <div class="row">
            <div class="login-wrapper">
              <nav>
                <div
                  class="nav"
                  id="nav-tab"
                  role="tablist"
                  style="position: relative"
                >
                  <a
                    class="nav-item active"
                    id="nav-login-tab"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-controls="nav-login"
                    aria-selected="true"
                  >
                    404 - یافت نشد
                  </a>
                </div>
              </nav>
              <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-login"
                  role="tabpanel"
                  aria-labelledby="nav-login-tab"
                >
                  <div class="row">
                    <div class="col-12 pt-3">
                      <p>
                        صفحه مورد نظر یافت نشد.
                      </p>
                      <p>
                        ممکن است این صفحه تغییر کرده یا حذف شده باشد.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  title: "یافت نشد",
  props: {},
  components: {},
};
</script>

<style scoped>
.login-btn {
  width: 200px;
  border-radius: 20px;
}

.static {
  background: url("../assets/images/home-page-background.png") no-repeat center
    top;
  background-size: 100%;
  min-height: 500px;
}

.static .content {
  margin-top: 50px;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  width: 400px;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0.5);
}

.nav-item {
  border-bottom: 4px solid #f7a906;
  color: #f7a906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: #fff !important;
  color: #f7a906 !important;
  border: 0;
}

.input-group-append .input-group-text {
  background-color: #fff !important;
  color: #000 !important;
  border: 0;
}

.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
.main {
  max-height: 500px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
