<template>
    <div role="table" class="table-container">
        <div role="rowgroup" class="flex-table header">

            <div role="columnheader" class="flex-row">
                مقدار <sub>({{market.base_asset_ticker}})
            </sub></div>
            <div role="columnheader" class="flex-row">
                قیمت واحد <sub>({{market.quote_asset_ticker}})
            </sub></div>
            <div role="columnheader" class="flex-row">
                زمان
            </div>
        </div>
        <div class="scrollable" style="height: 426px">
            <div v-for="d in orders" :key="d.id" class="flex-table">
                <div class="flex-row pl-3">
                    {{d.amount | prec(market.base_asset_prec)}}
                </div>

                <div v-if="d.type === 'buy'" class="flex-row text-danger">{{d.price | prec(market.price_prec) }}</div>
                <div v-else class="flex-row text-success">{{d.price | prec(market.price_prec) }}</div>

                <div class="flex-row ltr">{{d.time | persianDateUnix}}</div>

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'RecentOrders',
        props: ['orders', 'market'],
    }
</script>

<style scoped>

    .clickable {
        cursor: pointer;
    }

    .text-success {
        color: #0EBF00 !important;
    }

    .text-danger {
        color: #FF4C2F !important;
    }

    .scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        scrollbar-color: rgba(0,0,0,0.3) transparent;

    }
    .scrollable::-webkit-scrollbar {
        width: 8px;
    }
    .scrollable::-webkit-scrollbar-track {
        background: transparent;
    }
    .scrollable::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.3);
        border-radius: 20px;
    }


    .table-container {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }

    .flex-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        border-left: 1px solid #444140;
        position: relative;
    }

    .bgGreen {
        background: rgba(88, 255, 60, .09);
    }

    .after {
        color: rgba(28, 29, 38, 0);
        position: absolute;
        height: 100%;
    }

    .flex-row {
        width: 33.33333%;
        text-align: right;
        display: inline-block;
        border-top: 1px solid #444140;
        padding: 6px;
        /*
                background-color: #fff;
        */
        color: #fff;
        font-size: 11px;
    }

    .range-selector {
        display: inline-block;
    }
</style>
