<template>
    <div class="row">
        <div class="col-12 p-4 ">
            <div class="row">

                <div class="col-5 logo text-center ">
                    <img v-if="stats.base_asset_logo"
                         :src="`${cdnUrl}/media/asset/logo/md/${stats.base_asset_logo}`"
                         :alt="stats.base_asset_ticker">
                    <div class="mt-1">
                        {{stats.base_asset_name}}
                    </div>
                </div>
                <div class="col-2 text-center">
                    <font-awesome-icon icon="exchange-alt" size="2x" class="white mt-2"/>
                </div>
                <div class="col-5 logo text-center">
                    <img v-if="stats.quote_asset_logo"
                         :src="`${cdnUrl}/media/asset/logo/md/${stats.quote_asset_logo}`"
                         :alt="stats.quote_asset_ticker">
                    <div class="mt-1">
                        {{stats.quote_asset_name}}
                    </div>

                </div>
                <div class="col-12 text-center">
                    {{stats.last_raw | prec(stats.price_prec)}} {{stats.quote_asset_name}}
                    <div class="mt-2">
                        <span v-if="stats.change > 0" class="text-success mt-3 ltr">
                            {{stats.change | prec(2)}}%+
                                    </span>
                        <span v-else class="text-danger mt-3 ltr">
                                    {{stats.change | prec(2)}}%
                        </span>
                    </div>

                    <hr>
                </div>

            </div>
            <h5 class="text-center text-warning">
                تغییرات ۲۴ ساعت
            </h5>
            <ul>
                <li>
                    <strong>
                    بیشترین قیمت:
                    </strong>
                    &nbsp;<span class="ml-2">
                    {{stats.high_raw | prec(stats.price_prec)}} {{stats.quote_asset_name}}
                </span>

                </li>
                <li>
                    <strong>
                        کمترین قیمت:
                    </strong>
                    &nbsp;
                    <span class="ml-2">
                    {{stats.low_raw | prec(stats.price_prec)}} {{stats.quote_asset_name}}
                    </span>

                </li>
                <li>
                    <strong>
                        حجم معاملات روز:
                    </strong>
                    &nbsp;
                    <span class="ml-2">
                    {{stats.volume_raw | prec(stats.quote_asset_prec)}} {{stats.quote_asset_name}}
                    </span>

                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'MarketStats',
        props: ['stats'],
        data() {
            return {
                backendRoot: process.env.VUE_APP_BACKEND_ROOT,
                cdnUrl: process.env.VUE_APP_CDN_URL,
            }
        },
    }
</script>

<style scoped>

    ul {
        margin-top: 10px;
    }

    ul li {
        list-style: none;
        font-size: 13px;
        line-height: 42px;

    }

    hr {
        border-bottom: 1px solid #666;
    }

    .logo img {
        max-width: 60px;
    }


</style>
