<template>
  <div>
    <section class="vh-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6 text-black">
            <router-link to="/" class="d-flex align-items-center justify-content-start mt-4">
              <img class="logo-site" src="@/assets/images/lydiaLogo.png" />
              <span class="mr-1 ml-1">لیدیا</span>
            </router-link>
            <section id="tabs">
              <div class="container">
                <div class="row gradient">
                  <div class="login-wrapper mb-4">
                    <div class="notice-text">
                <span class="notice-desc"
                >مطمئن شوید در دامنه رسمی لیدیا هستید.
                </span
                ><span class="notice-url">https://app.lydiaex.ir</span>
                    </div>
                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                      <div
                          class="tab-pane fade"
                          v-bind:class="{ 'show active': isLogin }"
                          id="nav-login"
                          role="tabpanel"
                          aria-labelledby="nav-login-tab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <form @submit="submitLogin">
                              <div class="form-outline mb-3">
                                <input
                                    type="tel"
                                    class="form-control form-control-lg latin"
                                    name="mobile"
                                    id="mobile"
                                    placeholder="شماره تلفن"
                                    dir="ltr"
                                    v-model="loginMobile"
                                />
                              </div>
                              <div class="input-group mt-3 mb-3">
                                <div class="input-group-append">
                            <span class="input-group-text">
                              <font-awesome-icon
                                  :icon="eyeIcon"
                                  class="clickable text-white"
                                  @click="switchVisibility()"
                              />
                            </span>
                                </div>
                                <input
                                    :type="passwordFieldType"
                                    class="form-control form-control-lg"
                                    name="loginPassword"
                                    id="loginPassword"
                                    placeholder="رمز عبور"
                                    v-model="loginPassword"
                                    dir="ltr"
                                />
                              </div>
                              <div class="input-group mt-3 mb-3">
                                <div class="input-group-append">
                            <span class="input-group-text" style="padding: 2px">
                              <img
                                  :src="
                                  backendRoot +
                                    'assets/captcha.png?v=' +
                                    loadTime
                                "
                                  style="
                                    border-radius: 5px !important;
                                    margin-left: 2px;
                                  "
                              />
                              <a
                                  class="btn btn-sm btn-light"
                                  @click="updateLoadTime"
                              >
                                <font-awesome-icon
                                    class="round-arrow"
                                    icon="redo"
                                /></a>
                            </span>
                                </div>
                                <input
                                    type="tel"
                                    class="form-control form-control-lg latin"
                                    name="captcha"
                                    id="captcha"
                                    placeholder="کد امنیتی"
                                    v-model="captchaCode"
                                    autocomplete="off"
                                    dir="ltr"
                                />
                              </div>
                              <div style="text-align: center;" class="col-12 text-left mt-3 mb-3">
                                <router-link to="/forgot-password">
                                  رمز عبور را فراموش کرده اید؟
                                </router-link>
                              </div>
                              <div class="text-center mt-3">
                                <button type="submit" class="btn btn-primary btn-lg btn-block">ورود</button>
                              </div>
                            </form>
                            <div class="divider d-flex align-items-center my-4">
                              <p class="text-center fw-bold mx-3 mb-0">در لیدیا حساب کاربری ندارید؟</p>
                            </div>
                                <button
                                    class="btn btn-light btn-lg btn-block"
                                    @click="handleClickTab()"
                                >
                                  ثبت نام
                                </button>
                          </div>
                        </div>
                      </div>
                      <div
                          class="tab-pane fade"
                          v-bind:class="{ 'show active': isRegister }"
                          id="nav-register"
                          role="tabpanel"
                          aria-labelledby="nav-register-tab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <form @submit="submitRegister">
                              <div class="input-group mt-3 mb-3">
                                <input
                                    type="tel"
                                    class="form-control form-control-lg latin"
                                    name="registerMobile"
                                    id="registerMobile"
                                    placeholder="شماره تلفن"
                                    dir="ltr"
                                    v-model="registerMobile"
                                />
                              </div>
                              <div class="input-group mt-3 mb-3">
                                <div class="input-group-append">
                            <span class="input-group-text">
                              <font-awesome-icon
                                  :icon="eyeIcon"
                                  class="clickable text-white"
                                  @click="switchVisibility()"
                              />
                            </span>
                                </div>
                                <input
                                    :type="passwordFieldType"
                                    class="form-control form-control-lg latin"
                                    name="registerPassword"
                                    id="registerPassword"
                                    placeholder="رمز عبور"
                                    dir="ltr"
                                    v-model="registerPassword"
                                />
                              </div>
                              <div class="input-group mt-3 mb-3">
                                <div class="input-group-append">
                            <span class="input-group-text">
                              <font-awesome-icon
                                  :icon="eyeIcon"
                                  class="clickable text-white"
                                  @click="switchVisibility()"
                              />
                            </span>
                                </div>
                                <input
                                    :type="passwordFieldType"
                                    class="form-control form-control-lg latin"
                                    name="registerPassword"
                                    id="registerConfirmPassword"
                                    placeholder="تکرار رمز عبور"
                                    dir="ltr"
                                    v-model="registerConfirmPassword"
                                />
                              </div>
                              <div class="input-group mt-3 mb-3">
                                <input
                                    type="text"
                                    class="form-control latin"
                                    name="registerRefCode"
                                    placeholder="کد دعوت (اختیاری)"
                                    dir="ltr"
                                    v-model="registerRefCode"
                                />
                              </div>
                              <div class="input-group mt-3 mb-3">
                                <div class="input-group-append">
                            <span class="input-group-text" style="padding: 2px">
                              <img
                                  :src="
                                  backendRoot +
                                    'assets/captcha.png?v=' +
                                    loadTime
                                "
                                  style="
                                    border-radius: 5px !important;
                                    margin-left: 2px;
                                  "
                              />
                              <a
                                  class="btn btn-sm btn-light"
                                  @click="updateLoadTime"
                              >
                                <font-awesome-icon icon="redo"
                                /></a>
                            </span>
                                </div>
                                <input
                                    type="tel"
                                    class="form-control form-control-lg latin"
                                    name="captcha"
                                    id="captcha2"
                                    placeholder="کد امنیتی"
                                    v-model="captchaCode"
                                    autocomplete="off"
                                    dir="ltr"
                                />
                              </div>
                              <div class="col-12 text-center mt-2">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="registerCheck"
                                />
                                <a href="https://lydiaex.com/terms&Conditions" target="_blank">
                                  <strong class="text-info">قوانین</strong>
                                </a>
                                را مطالعه کرده و می‌پذیرم
                              </div>
                              <div class="text-center mt-3">
                                <button type="submit" class="btn btn-primary btn-lg btn-block">ثبت نام</button>
                              </div>
                            </form>
                            <div class="divider d-flex align-items-center my-4">
                              <p class="text-center fw-bold mx-3 mb-0">در لیدیا حساب کاربری دارید؟</p>
                            </div>
                            <button
                                class="btn btn-light btn-lg btn-block"
                                @click="handleClickTabLogin()"
                            >
                              ورود
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div style="background-color: #00183c" class="col-sm-6 px-0 d-none d-sm-block ">
            <img src="../assets/images/auth/register.png"
                 alt="Login image" class="w-100 vh-100" style="object-fit: contain;">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { Auth } from "aws-amplify";

export default {
  name: "Login",
  title: "ورود / ثبت نام",
  props: {},

  metaInfo() {
    return {
      title: "ورود / ثبت نام",
      titleTemplate: "%s - لیدیا پلتفرم تبادل ارزهای دیجیتال",
      meta: [
        { name: "og:title", vmid: "og:title", content: "ورود / ثبت نام" },
        { name: "description", vmid: "description", content: "ورود / ثبت نام" },
        {
          name: "og:description",
          vmid: "og:description",
          content: "ورود / ثبت نام",
        },
      ],
    };
  },
  data() {
    return {
      password: "",
      passwordFieldType: "password",
      loginPassword: "",
      eyeIcon: "eye-slash",

      registerPassword: "",
      registerConfirmPassword: "",
      registerMobile: "",
      loginMobile: "",
      registerRefCode: "",
      registerCheck: "",

      isRegister: this.$route.name === "Register",
      isLogin: this.$route.name === "Login",

      captchaCode: "",
      loadTime: 0,
      backendRoot: process.env.VUE_APP_BACKEND_ROOT,
      isToastVisible: true,
    };
  },
  created() {
    if (this.$session.exists("user_info")) {
      return this.checkKycLevel(this.$session.get("user_info"));
    }

    this.registerRefCode = this.$route.query.ref_code;
    this.loadTime = Date.now();
  },
  methods: {
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },
    auth() {
      try {
        Auth.federatedSignIn({ provider: "Google" });
      } catch (error) {
        console.log("error:", error);
      }
    },

    checkKycLevel(userInfo) {
      if (userInfo.kyc_step === 0) {
        return this.$router.push({ name: "MobileVerify" });
      } else if (userInfo.kyc_step === 1) {
        return this.$router.push({ name: "MobileVerify" });
      } else {
        return this.$router.push({ name: "Profile" });
      }
    },

    updateLoadTime(e) {
      e.preventDefault();
      this.loadTime = Date.now();
      this.captchaCode = "";
    },
    setUserInfoToSession(userInfo) {
      this.$session.clear();
      try {
        this.$session.set("user_info", userInfo);
      } catch (e) {
        // console.log("catch session login page", e);
      }
    },
    resetVisibility() {
      this.eyeIcon = "eye-slash";
      this.passwordFieldType = "password";
    },
    handleClickTab() {
      this.resetVisibility();
      this.isLogin = false;
      this.isRegister = true;
    },
    handleClickTabLogin() {
      this.resetVisibility();
      this.isLogin = true;
      this.isRegister = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.eyeIcon = this.eyeIcon === "eye" ? "eye-slash" : "eye";
    },
    submitLogin(e) {
      e.preventDefault();
      const loginInfo = {
        mobile: this.loginMobile,
        password: this.loginPassword,
        captcha: this.captchaCode,
      };

      if (
        this.loginMobile !== "" &&
        this.loginPassword !== "" &&
        this.captchaCode !== ""
      ) {
        this.notice("info", "در حال ورود ...");

        axios
          .post(process.env.VUE_APP_BACKEND_URL + "/user/login", loginInfo, {
            withCredentials: true,
          })
          .then((response) => {
            const userInfo = response.data;

            if (userInfo.is_otp_active) {
              return this.$router.push({
                name: "OtpVerify",
                params: { userInfo: userInfo },
              });
            } else if (userInfo.send_sms_login == 1) {
              //this.checkKycLevel(userInfo);
              //this.setUserInfoToSession(userInfo);

              const loginInfo = {
                email: userInfo.email,
                login_code: userInfo.login_code,
              };
              axios
                .post(
                  process.env.VUE_APP_BACKEND_URL + "/user/checkSmsforLogin",
                  loginInfo,
                  { withCredentials: true }
                )
                .then(() => {
                  localStorage.setItem("email", userInfo.email);
                  // this.$session.set("user_info", userInfo);
                  return this.$router.push({
                    name: "MobileVerifyNew",
                    params: { userInfo2: userInfo },
                  });
                })
                .catch(() => {
                  console.log(e);
                });
              //return this.$router.push({name: 'MobileVerifyNew', params: {userInfo: userInfo}});
            } else {
              this.$nextTick(function() {
                return this.checkKycLevel(userInfo);
              });
              this.$nextTick(function() {
                this.setUserInfoToSession(userInfo);
              });
            }
          })
          .catch((e) => {
            if (e.response.data !== undefined) {
              const data = e.response.data;
              const errors =
                "<ul>" +
                data.errors
                  .map(function(err) {
                    return "<li>" + err + "</li>";
                  })
                  .join("") +
                "</ul>";
              this.notice("error", "خطا", errors, 10);
              return;
            }

            return this.notice(
              "error",
              "خطا",
              "ایمیل یا رمز عبور نادرست است",
              5
            );
          });
      } else {
        this.notice(
          "error",
          "خطا",
          "ایمیل  و رمز عبور و کد امنیتی را وارد نمایید",
          3
        );
      }
    },

    submitRegister(e) {
      e.preventDefault();

      const registerInfo = {
        password: this.registerPassword,
        mobile: this.registerMobile,
        ref_code: this.registerRefCode,
        captcha: this.captchaCode,
      };

      if (
        this.registerPassword !== "" &&
        this.registerMobile !== "" &&
        this.captchaCode !== ""
      ) {
        if (!this.registerCheck) {
          this.notice(
            "error",
            "خطا",
            "برای ثبت نام در سایت، شما می‌بایست قوانین را مطالعه و قبول نمایید",
            4
          );
          return;
        }
        if (this.registerPassword !== this.registerConfirmPassword) {
          this.notice("error", "خطا", "رمز عبور و تکرار آن برابر نیستند", 4);
          return;
        }

        this.notice("info", "در حال ثبت نام ...", "لطفا کمی صبر کنید", 3);

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/user/register",
            registerInfo,
            { withCredentials: true }
          )
          .then((response) => {
            const userInfo = response.data;
            this.setUserInfoToSession(userInfo);

            return this.$router.push({ name: "MobileVerify" });
          })
          .catch((e) => {
            if (e.response.data !== undefined) {
              const data = e.response.data;
              const errors =
                "<ul>" +
                data.errors
                  .map(function(err) {
                    return "<li>" + err + "</li>";
                  })
                  .join("") +
                "</ul>";
              this.notice("error", "خطا", errors, 10);
              return;
            }

            this.notice("error", "خطا", "ایمیل یا رمز عبور نادرست است");
          });
      } else {
        this.notice(
          "error",
          "خطا",
          "لطفا تمامی موارد خواسته شده را وارد نمایید",
          3
        );
      }
    },
    checkPhoneLocalStorag() {
      const checkPhone = JSON.parse(localStorage.getItem("phoneNumber"));
      if (checkPhone) {
        this.isLogin = false;
        this.isRegister = true;
      }
      this.registerMobile = checkPhone;
    },
    removeToast() {
      this.isToastVisible = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.removeToast();
    }, 10000);
    this.checkPhoneLocalStorag();
  },
  beforeDestroy() {
    return localStorage.removeItem("phoneNumber");
  },
};
</script>

<style scoped>
.latin {
  color: white;
}

.static .content {
  margin-top: 50px;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  width: 70%;
  padding: 30px;
}


.btn-light {
  background-color: transparent;
  color: white !important;
}



.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid #4c5157;
  border-radius: 15px;
}

.input-group-append .input-group-text {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #4c5157;
  border-radius: 15px;
}


.form-control {
  background-color: transparent;
  border: 1px solid #4c5157;
  letter-spacing: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
}

::placeholder {
  color: #8a8e91;
  text-align: right !important;
}


::-webkit-input-placeholder {
  text-align: center;
  font-family: IRANSans, serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  font-family: IRANSans, serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  font-family: IRANSans, serif;
}

:-ms-input-placeholder {
  text-align: center;
  font-family: IRANSans, serif;
}
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.notice-text {
  font-size: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.notice-url {
  font-weight: 700;
  color: #4263eb;
}
@media screen and (max-width: 700px) {

  .login-wrapper {
    margin-top: 5%;
    width: 100%;
  }

  ::placeholder {
    color: white;
    font-size: 13px;
  }

  .nav {
    margin-right: 0px;
  }
  .notice-text {
    font-size: 13px;
  }
}
.ToastifyMain {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 240px;
  color: #fff;
  z-index: 1;
}

.ToastifyBox {
  position: relative;
  padding: 10px;
  background-color: #302d2c;
  border-radius: 6px;
  border: 1px solid #fff;
  font-size: 14px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  animation: enterFromRight 1s ease-out;
}

.ToastifyBox img {
  top: 10px;
  right: 6px;
  cursor: pointer;
}
.ToastifyBox span {
  margin-right: 10px;
  width: 100%;
  text-justify: start;
}
/* Slide-in animation */
@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide-out animation */
.slide-leave-active {
  transition: transform 1s ease-out, opacity 1s ease-out;
}
.slide-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
