const configurationData = {
    supports_marks: false,
    supports_search: true,
    supports_group_request: false,
    supports_timescale_marks: false,
    supports_time: true,
    supported_resolutions: ["60", "180", "240", "360", "720", "1D", "2D", "3D"],
    timezone: 'Asia/Tehran'
};


const resolutionToSeconds = (r) => {
    const minutes = parseInt(r, 10);
    if (r === '1D') {
        return 1440;
    } else if (r === 'D') {
        return 4320;
    } else if (!isNaN(minutes)) {
        return minutes;
    } else {
        return 1;
    }
};

import axios from 'axios'
import websocket_helper from "./websocket_helper";

export default {
    onReady: (callback) => {
        setTimeout(() => callback(configurationData)) // callback must be called asynchronously.
    },


    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        // console.log('[resolveSymbol]: Method call', symbolName, onResolveErrorCallback, onResolveErrorCallback);
        const symbolInfo = (symbolName) => ({
            name: symbolName,
            description: symbolName.replace("_","/"),
            ticker: symbolName.split("_")[0],
            //exchange: 'Binance',
            //listed_exchange: 'Binance',
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: Math.pow(10, 2), // 	or 100
            timezone: 'Asia/Tehran',
            has_daily: true,
            has_no_volume: false,
            // has_weekly_and_monthly: true,
            currency_code: symbolName.split("_")[1],
            has_intraday: true,
            intraday_multipliers: ["60", "180", "240", "360", "720", "1D", "2D", "3D"],
            supported_resolutions: ["60", "180", "240", "360", "720", "1D", "2D", "3D"],
            volume_precision: 8,
            data_status: 'streaming',

        });
        // console.log("symbolInfo===>",symbolInfo(symbolName));
        return symbolName ? onSymbolResolvedCallback(symbolInfo(symbolName)) : onResolveErrorCallback('[resolveSymbol]: symbol not found')
    },
    // get historical data for the symbol
    getBars: async (symbolInfo, interval, from, to, onHistoryCallback, onErrorCallback) => {
        axios.get(`${process.env.VUE_APP_BACKEND_URL}/kline/history?symbol=${symbolInfo.name}&resolution=${resolutionToSeconds(interval)}&from=${from}&to=${to}`)
            .then(response => {
                onHistoryCallback(response.data, {noData: false})
            })
            .catch(e => {
                onErrorCallback('Klines data error' + e)
            })


    },
    // subscription to real-time updates
    subscribeBars: (symbolInfo, interval, onRealtimeCallback) => {
        // console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID, onResetCacheNeededCallback);
        websocket_helper.subscribeKline(symbolInfo.name, interval,onRealtimeCallback)
    },
    unsubscribeBars: (subscriberUID) => {
        websocket_helper.unsubscribeKline(subscriberUID);
    },


};
