<template>
  <div class="my-5 " id="content">
    <div class="p-3">
      <h3 class="mb-5">
        تنظیمات کاربری
      </h3>
      <div class="pt-4">
        <h5>
          تغییر رمز عبور
        </h5>

        <p>
          تغییر رمز عبور جهت ورود به حساب کاربری و برداشت ارزی و ریالی
        </p>
        <router-link class="btn btn-lg btn-secondary" to="/user/change-password">
          تغییر رمز عبور
        </router-link>
      </div>
      <div class="pt-4">
        <h5>
          تنظیمات امنیتی
        </h5>

        <p>
          تنظیم رمز عبور دو عاملی جهت ورود به حساب کاربری و برداشت تومان و ارز
        </p>
        <router-link class="btn btn-lg btn-secondary" to="/user/otp-activate">
          رمز دو عاملی
        </router-link>
      </div>
      
      <div class="pt-5">
        <h5>
          تنظیمات اطلاع رسانی
        </h5>

        <div class="row pt-2">
          <div class="col-md-3 col-8">
            ارسال پیامک تایید و عدم تایید برداشت و واریز
          </div>
          <div class="col-md-3 col-4">
            <toggle-button
              v-if="smsInfo !== null"
              @change="saveSmsPreference"
              :value="smsInfo.send_sms_confirm"
              color="#4263eb"
              :width="100"
              :height="35"
              :font-size="13"
              :tag="'send_sms_confirm'"
              :labels="{ checked: 'فعال', unchecked: 'غیر فعال' }"
            />
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-md-3 col-8">
            ارسال پیامک انجام معامله
          </div>
          <div class="col-md-3 col-4">
            <toggle-button
              v-if="smsInfo !== null"
              @change="saveSmsPreference"
              :value="smsInfo.send_sms_order"
              color="#4263eb"
              :width="100"
              :height="35"
              :font-size="13"
              :tag="'send_sms_order'"
              :labels="{ checked: 'فعال', unchecked: 'غیر فعال' }"
            />
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-md-3 col-8">
            ارسال پیامک شارژ کیف پول
          </div>
          <div class="col-md-3 col-4">
            <toggle-button
              v-if="smsInfo !== null"
              @change="saveSmsPreference"
              :value="smsInfo.send_sms_deposit"
              color="#4263eb"
              :width="100"
              :height="35"
              :font-size="13"
              :tag="'send_sms_deposit'"
              :labels="{ checked: 'فعال', unchecked: 'غیر فعال' }"
            />
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-md-3 col-8">
            ارسال پیامک ورود
          </div>
          <div class="col-md-3 col-4">
            <toggle-button
              v-if="smsInfo !== null"
              @change="saveSmsPreference"
              :value="smsInfo.send_sms_login"
              color="#4263eb"
              :width="100"
              :height="35"
              :font-size="13"
              :tag="'send_sms_login'"
              :labels="{ checked: 'فعال', unchecked: 'غیر فعال' }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Settings",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      markets: [],
      smsInfo: null,
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    getSmsPreference() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/sms-preference", {
          withCredentials: true,
        })
        .then((response) => {
          this.smsInfo = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    saveSmsPreference(e) {
      console.log(e);

      const smsPref = {
        type: e.tag,
        active: e.value,
      };

      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/user/sms-preference",
          smsPref,
          { withCredentials: true }
        )
        .then((response) => {
          this.smsInfo = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
  },
  created() {
    this.updateMarketList();
    this.checkSession();
    this.getSmsPreference();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.btn-secondary {
  width: 300px;
  background-color: #fff;
  color: #000;
}
</style>
