<template>
  <div class="row static">
    <div class="container">
      <div class="content">
        <div class="row">
          <div class="col text-center">
            <img
              :src="`${cdnUrl}/media/asset/logo/md/${asset.logo_filename}`"
              style="max-width: 75px"
            />
            <h1 class="mt-1">خرید و فروش {{ asset.name }}</h1>
            <span>
              {{ asset.ticker }}
            </span>
          </div>
        </div>

        <div class="m-5 text-center">
          &nbsp;<router-link
            :to="'/tradeview/' + asset.ticker + '_IRT'"
            class="btn btn-lg btn-warning home-btn2"
          >
            ورود به بازار حرفه‌ای
          </router-link>
        </div>

        <div class="m-5">&nbsp;</div>

        <div class="m-5">&nbsp;</div>

        <div v-if="ticker !== 'USDT'">
          <VueTradingView
            :options="{
              symbol: 'BINANCE:' + this.ticker + 'USDT',
              interval: 'D',
              height: '300px',
              width: '100%',
              style: '3',
              locale: 'en',
              toolbar_bg: '#f1f3f6',
              enable_publishing: false,
              hide_top_toolbar: true,
              save_image: false,
            }"
          />
        </div>
        <div class="col-12 mt-4 mb-4 p-3 box">
          <p v-if="asset.description !== ''" v-html="asset.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueTradingView from "vue-trading-view";

export default {
  name: "TickerInfo",
  props: ["ticker"],
  components: {
    VueTradingView,
  },
  data() {
    return {
      asset: null,
      pageTitle: undefined,
      metaDescription: undefined,
    };
  },

  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { name: "og:title", vmid: "og:title", content: this.pageTitle },
        {
          name: "description",
          vmid: "description",
          content: this.metaDescription,
        },
        {
          name: "og:description",
          vmid: "og:description",
          content: this.metaDescription,
        },
      ],
    };
  },
  methods: {
    fetchData() {
      let loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
        bars: "dots",
      });
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/asset/info/" + this.ticker)
        .then((response) => {
          this.asset = response.data;
          this.pageTitle = response.data.page_title;
          this.metaDescription = response.data.meta_description;
          //console.log(response.data);
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
    routeHandle() {
      const ticker = this.$route.params.ticker.toUpperCase();
      const split = ticker.split("_");
      this.ticker = `${split[0]}`;
      this.$router.push({
        name: "TickerInfo",
        params: { ticker: `${split[0]}` },
      });
    },
  },
  created() {
    this.routeHandle();
    this.fetchData();
  },

  computed: {
    backendRoot: function() {
      return process.env.VUE_APP_BACKEND_ROOT;
    },
    cdnUrl: function() {
      return process.env.VUE_APP_CDN_URL;
    },
  },

  watch: {
    $route: function() {
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 20px !important;
}

table,
td {
  border: 0 !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.custom-table td {
  border: 2px solid #fff;
}

.table,
td {
  border: 1px solid #fff;
  direction: rtl;
}

.static {
  background-size: 100%;
  min-height: 500px;
}

.static .content {
  margin-top: 50px;
}

.box {
  color: #fff;
  background-color: #565656;
  border-radius: 10px;
}

.home-btn2 {
  border-radius: 20px;
  border: 0;
  font-size: 17px;
  width: 220px;
  margin: 30px 10px;
}
</style>
