<template>
  <div class="flex-xycenter justify-content-md-start">
    <div class="Card position-relative">
      <!-- title -->
      <div class="title">
        معامله آسان {{ formValues.coinInfo.base_asset_name }}
      </div>
      <!-- title -->
      <div class="header w-100 mt-1 mb-1" ref="calcMain">
        <!-- headerTab -->
        <div class="col-9 headerTab">
          <span
            :class="fiatType.name === 'IRT' && 'activeHeader'"
            @click="handleFiat('IRT')"
            >تومان IRT</span
          ><span
            :class="fiatType.name === 'USDT' && 'activeHeader'"
            @click="handleFiat('USDT')"
            >تتر USDT</span
          >
        </div>
        <!-- headerTab -->

        <!-- icon-coin-fiat -->
        <div class="position-relative iconCoin col-3">
          <div class="" v-if="actionType === 'buy'">
            <img
              :src="
                fiatType.name === 'IRT'
                  ? cdnUrl + '/media/asset/logo/md/' + fiatType.imgIRT
                  : cdnUrl + '/media/asset/logo/md/' + fiatType.imgUSTD
              "
              class="iconFront"
            />
            <img
              :src="
                cdnUrl +
                '/media/asset/logo/md/' +
                formValues.coinInfo.base_asset_logo
              "
              class="iconBack"
            />
          </div>
          <div v-else>
            <img
              :src="
                cdnUrl +
                '/media/asset/logo/md/' +
                formValues.coinInfo.base_asset_logo
              "
              class="iconFront"
            />
            <img
              :src="
                fiatType.name === 'IRT'
                  ? cdnUrl + '/media/asset/logo/md/' + fiatType.imgIRT
                  : cdnUrl + '/media/asset/logo/md/' + fiatType.imgUSTD
              "
              class="iconBack"
            />
          </div>
        </div>
        <!-- icon-coin-fiat -->
      </div>
      <div class="Main">
        <!-- tab -->
        <div class="buuton-box">
          <div id="btnTab" :style="{ right: btntab }"></div>

          <button
            @click="actionTab('buy', '0')"
            class="toogle-btn toogle-btn-buy col-6 pr-4"
          >
            خرید
          </button>
          <button
            @click="actionTab('sell', '154px')"
            class="toogle-btn toogle-btn-sell col-6 pl-4"
          >
            فروش
          </button>
        </div>
        <!-- tab -->

        <!-- form -->

        <!-- coin-Input -->
        <div class="mt-2 mb-2 container-box">
          <span class="text-small">مقدار</span>
          <div class="numberBox">
            <div>
              <button class="plus w-100" @click="increaseCoin">
                <img src="@/assets/images/home/plus.svg" alt="" />
              </button>
            </div>

            <currency-input
              class="form-control"
              id="market-amount"
              autocomplete="off"
              v-model="formValues.coinNumber.value"
              locale="en"
              dir="ltr"
              :currency="null"
              :value-as-integer="false"
              :distraction-free="false"
              :auto-decimal-mode="false"
              :precision="{
                min: 0,
                max: formValues.coinInfo.amount_prec + 1,
              }"
              :allow-negative="false"
              @keyup="inputDirectChangeCoin"
              :placeholder="formValues.coinInfo.base_asset_ticker"
            ></currency-input>
            <div>
              <button class="minus w-100" @click="decreaseCoin">
                <img src="../../../assets/images/home/minus.svg" alt="" />
              </button>
            </div>
          </div>
          <div :class="[actionType === 'sell' && 'boxDetails']">
            <div
              v-if="actionType === 'sell' && loginUser"
              class="accurancy text-small"
            >
              <div>
                <span>موجودی {{ formValues.coinInfo.base_asset_name }} :</span>
                <span>{{
                  userSelectedCoin.balance.available === "0e-8"
                    ? "0"
                    : userSelectedCoin.balance.available
                      | prec(userSelectedCoin.prec_show)
                }}</span>
                <span>{{ formValues.coinInfo.base_asset_ticker }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- coin-Input -->

        <!-- fiat-Input -->
        <div class="mt-4 mb-3 container-box">
          <span class="text-small">{{
            actionType === "buy" ? "مبلغ حدودی پرداختی" : "مبلغ حدودی دریافتی"
          }}</span>
          <div class="numberBox">
            <div>
              <button class="plus w-100" @click="increasePrice">
                <img src="@/assets/images/home/plus.svg" alt="" />
              </button>
            </div>

            <currency-input
              class="form-control"
              autocomplete="off"
              v-model="formValues.fiatnumber.value"
              locale="en"
              dir="ltr"
              :currency="null"
              :value-as-integer="false"
              :distraction-free="false"
              :auto-decimal-mode="false"
              :precision="{
                min: 0,
                max: formValues.coinInfo.price_prec + 1,
              }"
              :allow-negative="false"
              @keyup="inputDirectChangePrice"
              :placeholder="fiatType.name"
            ></currency-input>
            <div>
              <button class="minus w-100" @click="decreasePrice">
                <img src="../../../assets/images/home/minus.svg" alt="" />
              </button>
            </div>
          </div>
          <div :class="[actionType === 'buy' && 'boxDetails']">
            <div
              v-if="actionType === 'buy' && loginUser"
              class="accurancy text-small"
            >
              <div v-if="fiatType.name === 'IRT'">
                <span>موجودی تومانی : </span>
                <span>{{ inventoryIRT.balance.available | prec(0) }}</span>
                <span>تومان</span>
              </div>
              <div v-else>
                <span>موجودی تتر : </span>
                <span>{{
                  inventoryUSDT.balance.available
                    | prec(inventoryUSDT.prec_show)
                }}</span>
                <span>USDT</span>
              </div>
              <router-link
                to="/user/deposit/IRT"
                v-if="fiatType.name === 'IRT'"
              >
                <img src="../../../assets/images/home/circle-add.svg" alt="" />
              </router-link>
              <div class="tooltip-btn" v-else>
                <img
                  src="../../../assets/images/home/circle-add.svg"
                  alt=""
                  ref="tooltip"
                  @click="tooltipFalg = !tooltipFalg"
                />
                <div class="tooltip-wrapper" v-show="tooltipFalg">
                  <div class="tooltip-custom right">
                    <router-link to="/user/deposit/USDT">واریز تتر</router-link>

                    <div>
                      <router-link to="/tradeview/USDT_IRT">
                        واریز تومان و خرید تتر</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="textInfo text-small">
            <span>قیمت:</span>
            <span>{{
              formValues.priceCoin | prec(formValues.coinInfo.price_prec)
            }}</span
            ><span>{{ fiatType.name }}</span>
          </div>
        </div>
        <!-- fiat-Input -->
        <div class="btn-submit mt-1 mb-2 btn" @click="SubmitBtn">
          {{ actionType === "buy" ? "خرید" : "فروش" }}
        </div>

        <!-- form -->
      </div>
    </div>
  </div>
</template>
<script>
// import "vue-toast-notification/dist/theme-sugar.css";
import axios from "axios";

export default {
  name: "CalculatorCoin",
  props: ["usdtMarkets", "irtMarkets", "formValues", "fiatType"],
  components: {},
  data() {
    return {
      actionType: "buy",

      btntab: "0",
      dropDownflag: false,

      userWallet: {},
      inventoryIRT: "0",
      inventoryUSDT: "0",
      userInfo: this.$session.get("user_info"),
      userSelectedCoin: "",
      bestAsk: "",
      bestBid: "",
      orderDepth: {},
      polling: null,
      pollingMinute: null,
      marketId: "",
      takerFee: 0,
      makerFee: 0,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      loginUser: this.$session.exists("user_info"),

      tooltipFalg: false,
    };
  },

  methods: {
    handleFiat(tab) {
      this.fiatType.name = tab;
      // resetCalc
      this.resetForm();
      const findCoin = (arr) => {
        const coin = arr.find((item) => {
          return (
            item.base_asset_ticker ===
            this.formValues.coinInfo.base_asset_ticker
          );
        });
        if (coin !== undefined) {
          return (this.formValues.coinInfo = coin);
        } else {
          return (this.formValues.coinInfo = arr[0]);
        }
      };
      if (this.formValues.coinInfo.base_asset_ticker !== "USDT") {
        tab === "IRT" ? findCoin(this.irtMarkets) : findCoin(this.usdtMarkets);
      } else {
        this.formValues.coinInfo =
          tab === "IRT" ? findCoin(this.irtMarkets) : this.usdtMarkets[0];
      }

      this.handlePriceCoin();
      //for orderbook/depth api
      this.marketId =
        this.formValues.coinInfo.base_asset_ticker + "_" + this.fiatType.name;
      this.updateOrderbook();
    },
    resetForm() {
      this.formValues.coinNumber.value = null;
      this.formValues.fiatnumber.value = null;
    },

    //convert best price ask and buy on switching between buy and sell
    handlePriceCoin() {
      const num =
        this.actionType === "buy"
          ? this.formValues.coinInfo.best_ask_raw
          : this.formValues.coinInfo.best_bid_raw;
      return (this.formValues.priceCoin = num);
    },
    //handle switch btn
    actionTab(action, leftPX) {
      this.actionType = action;
      this.btntab = leftPX;
      this.handlePriceCoin();
      this.resetForm();
    },

    //handle switch between fiat

    closeSelectBox(event) {
      const dropdownElement = this.$refs.dropdown;
      if (dropdownElement && !dropdownElement.contains(event.target)) {
        this.dropDownflag = false;
      }
    },
    closeTooltip(event) {
      const tooltipElement = this.$refs.tooltip;
      if (tooltipElement && !tooltipElement.contains(event.target)) {
        this.tooltipFalg = false;
      }
    },
    changeSelectedCoin() {
      // this.formValues.coinInfo = item;
      console.log("Function executed in the child component");
      this.resetForm();
      const findCoin = (arr) => {
        const coin = arr.find((item) => {
          return (
            item.base_asset_ticker ===
            this.formValues.coinInfo.base_asset_ticker
          );
        });
        if (coin !== undefined) {
          return (this.formValues.coinInfo = coin);
        } else {
          return (this.formValues.coinInfo = arr[0]);
        }
      };
      if (this.formValues.coinInfo.base_asset_ticker !== "USDT") {
        this.fiatType.name === "IRT"
          ? findCoin(this.irtMarkets)
          : findCoin(this.usdtMarkets);
      } else {
        this.formValues.coinInfo =
          this.fiatType.name === "IRT"
            ? findCoin(this.irtMarkets)
            : this.usdtMarkets[0];
      }

      if (this.loginUser) {
        this.updateUserWallet();
      }

      //for orderbook/depth api
      this.marketId =
        this.formValues.coinInfo.base_asset_ticker + "_" + this.fiatType.name;
      this.updateOrderbook();

      this.handlePriceCoin();
    },
    //for coin
    increaseCoin() {
      const num = +this.formValues.coinNumber.value + 0.1;
      this.formValues.coinNumber.value = num;
      this.inputDirectChangeCoin();
    },
    decreaseCoin() {
      const num = +this.formValues.coinNumber.value - 0.1;
      if (
        this.formValues.coinNumber.value === null ||
        this.formValues.coinNumber.value === 0
      ) {
        this.formValues.fiatnumber.value = null;
        this.formValues.coinNumber.value = null;
        // num
      } else if (this.formValues.coinNumber.value > 0) {
        if (this.formValues.coinNumber.value <= 0.1) {
          this.formValues.fiatnumber.value = null;
          this.formValues.coinNumber.value = null;
        } else {
          this.formValues.coinNumber.value = num;
          this.inputDirectChangeCoin();
        }
      }
    },
    inputDirectChangeCoin() {
      const val = this.formValues.coinNumber.value;
      if (this.actionType === "buy") {
        //change this
        if (val > 0) {
          // const totalCoinFee = val * (1 + this.takerFee);
          // const total = totalCoinFee * this.bestAsk;

          // const totalFee = val * this.takerFee;
          // const totalCoin = val + totalFee;
          // const totalPrice = totalCoin * this.bestAsk;

          const totalPrice = val * this.bestAsk;
          const totalFee = totalPrice * this.takerFee;
          // const totalCoin = val + totalFee;

          this.formValues.fiatnumber.value = totalPrice + totalFee;
          const maxTotalPrice = this.maxTotalAvailableForBid();
          if (this.formValues.fiatnumber.value > maxTotalPrice) {
            this.notice(
              "error",
              "مقدار درخواستی بیش از حجم بازار است",
              "مقدار به حداکثر حجم بازار اصلاح گردید",
              3
            );
            this.formValues.fiatnumber.value = maxTotalPrice;
            this.inputDirectChangePrice();
            return;
          }
        } else if (val === null) {
          this.formValues.fiatnumber.value = null;
        } else {
          // this.formValues.fiatnumber.value = null;
          // this.formValues.coinNumber.value = null;
        }
      } else {
        //main
        if (val > 0) {
          const maxTotalAmount = this.maxAmountAvailableForAsk();
          if (val > maxTotalAmount) {
            this.notice(
              "error",
              "مقدار درخواستی بیش از حجم بازار است",
              "مقدار به حداکثر حجم بازار اصلاح گردید",
              3
            );
            this.formValues.coinNumber.value = maxTotalAmount;
            this.inputDirectChangeCoin();
            return;
          }

          // this.askMarketTotalPrice = (val * this.bestAsk);
          const askMarketTotalPrice = val * this.bestBid;
          // this.askMarketFeeAmount = this.bestBid * val * this.takerFee;
          this.formValues.fiatnumber.value =
            askMarketTotalPrice - askMarketTotalPrice * this.takerFee;
        } else {
          // this.formValues.fiatnumber.value = null;
          // this.formValues.coinNumber.value = null;
        }
      }
    },
    //for IRT & USDT

    increasePrice() {
      const step = this.fiatType.name === "IRT" ? 10000 : 0.1;
      const num = +this.formValues.fiatnumber.value + step;
      this.formValues.fiatnumber.value = num;
      this.inputDirectChangePrice();
    },
    decreasePrice() {
      const step = this.fiatType.name === "IRT" ? 10000 : 0.1;

      const num = +this.formValues.fiatnumber.value - step;

      if (
        this.formValues.fiatnumber.value === null ||
        this.formValues.coinNumber.value === 0
      ) {
        this.formValues.coinNumber.value = null;
        this.formValues.fiatnumber.value = null;
      } else if (this.formValues.fiatnumber.value > 0) {
        //handle USDT
        // if (this.fiatType.name === "USDT") {
        //   if (Math.round(num) === num) {
        //     this.formValues.fiatnumber.value = num;
        //   } else {
        //     this.formValues.fiatnumber.value = num.toFixed(1);
        //   }
        // } else {
        //   this.formValues.fiatnumber.value = num;
        // }
        this.formValues.fiatnumber.value = num;
        this.inputDirectChangePrice();
      }
    },
    inputDirectChangePrice() {
      const val = this.formValues.fiatnumber.value;
      if (this.actionType === "buy") {
        //main
        if (val > 0) {
          const maxTotalPrice = this.maxTotalAvailableForBid();
          if (val > maxTotalPrice) {
            this.notice(
              "error",
              "مقدار درخواستی بیش از حجم بازار است",
              "مقدار به حداکثر حجم بازار اصلاح گردید",
              3
            );
            this.formValues.fiatnumber.value = maxTotalPrice;
            this.inputDirectChangePrice();
            return;
          }
          const fee = this.takerFee;
          const totalAmount = this.calculateBidTotal(val);
          const totalFee = totalAmount * fee;

          this.formValues.coinNumber.value = totalAmount - totalFee;
        } else {
          this.formValues.fiatnumber.value = null;
          this.formValues.coinNumber.value = null;
        }
      } else {
        if (val > 0) {
          const fee = this.takerFee;
          const totalCoin = val + this.takerFee;
          const totalAmount = this.calculateAskTotal(totalCoin);
          const totalFee = totalAmount * fee;
          // this.bidMarketTotalPrice = this.bidMarketTotal;
          this.formValues.coinNumber.value = totalAmount + totalFee;
          const maxTotalAmount = this.maxAmountAvailableForAsk();
          if (this.formValues.coinNumber.value > maxTotalAmount) {
            this.notice(
              "error",
              "مقدار درخواستی بیش از حجم بازار است",
              "مقدار به حداکثر حجم بازار اصلاح گردید",
              3
            );
            this.formValues.coinNumber.value = maxTotalAmount;
            this.inputDirectChangeCoin();
            return;
          }
          // کارمزد
          // this.bidMarketFeeAmount = totalFee;
        } else {
          this.formValues.fiatnumber.value = null;
          this.formValues.coinNumber.value = null;
          // this.bidMarketTotal = 0;
          // this.bidMarketTotalAmount = 0;
          // this.bidMarketTotalPrice = 0;
          // this.bidMarketReceive = 0;
          // this.bidMarketFeeAmount = 0;
        }
      }
    },
    //for toastify
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },
    //for submit calc btn
    SubmitBtn() {
      if (this.loginUser) {
        const marketParams = {
          amount:
            this.actionType === "buy"
              ? this.formValues.fiatnumber.value
              : this.formValues.coinNumber.value,
          side: this.actionType === "buy" ? 2 : 1,
          market: this.formValues.coinInfo.identifier,
        };
        if (this.formValues.coinNumber.value > 0) {
          this.notice("info", "در حال ثبت سفارش  حرید...");

          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/order/market",
              marketParams,
              { withCredentials: true }
            )
            .then(() => {
              this.notice("success", "سفارش با موفقیت ثبت شد");
              this.resetForm();
              return this.$router.push("/user/orders");
            })
            .catch((e) => {
              this.notice("error", "خطا", e.response.data.message, 5);
              this.resetForm();
            });
        }
      } else {
        this.$router.push("/login");
      }
    },

    //new
    maxTotalAvailableForBid() {
      let totalTotalPrice = 0;
      if (this.orderDepth !== undefined) {
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          const price = parseFloat(ask[0]);
          const amount = parseFloat(ask[1]);
          totalTotalPrice += price * amount;
        }
      }
      return totalTotalPrice;
    },
    maxAmountAvailableForAsk() {
      let totalAmount = 0;
      if (this.orderDepth !== undefined) {
        const bids = this.orderDepth.bids;
        for (let i = 0; i < bids.length; i++) {
          const bid = bids[i];
          const amount = parseFloat(bid[1]);
          totalAmount += amount;
        }
      }
      return totalAmount;
    },
    maxAmountAvailableForBid() {
      let totalAmount = 0;
      if (this.orderDepth !== undefined) {
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const bid = asks[i];
          const amount = parseFloat(bid[1]); // the number of coin
          totalAmount += amount;
        }
      }
      return totalAmount;
    },
    maxTotalAvailableForAsk() {
      let totalAmount = 0;
      if (this.orderDepth !== undefined) {
        const bids = this.orderDepth.bids;
        for (let i = 0; i < bids.length; i++) {
          const bid = bids[i];
          const amount = parseFloat(bid[1]); // the number of coin
          totalAmount += amount;
        }
      }
      return totalAmount;
    },
    handleVariablesMarket() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/market/stats/${this.marketId}`)
        .then((response) => {
          this.formValues.coinInfo = response.data;

          if (this.loginUser) {
            this.$nextTick(function () {
              if (
                this.formValues.coinInfo !== undefined &&
                this.formValues.coinInfo !== null &&
                this.formValues.coinInfo.quote_asset_ticker !== undefined &&
                this.formValues.coinInfo.base_asset_ticker !== undefined
              ) {
                this.takerFee = Math.min(
                  this.formValues.coinInfo.taker_fee,
                  this.userInfo.fee_group.taker_fee
                );
              }
            });
          }
        })
        .catch(() => {});
    },

    updateOrderbook() {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/orderbook/depth/${this.marketId}`
        )
        .then((response) => {
          this.orderDepth = response.data;
          this.totalAskAmount = this.orderDepth.asks.reduce(
            (a, b) => a + (parseFloat(b[1]) || 0),
            0
          );
          this.totalBidAmount = this.orderDepth.bids.reduce(
            (a, b) => a + (parseFloat(b[1]) || 0),
            0
          );
        })
        .catch(() => {});
    },
    //
    updateUserWallet() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/wallets", {
          withCredentials: true,
        })
        .then((res) => {
          this.userWallet = res.data;
          function FindFiat(fiat) {
            return res.data.wallets.find((item) => item.ticker === fiat);
          }
          this.inventoryIRT = FindFiat("IRT");
          this.inventoryUSDT = FindFiat("USDT");

          return this.handleUserCoin();
        });
    },
    handleUserCoin() {
      const findSelectedCoin = this.userWallet.wallets.find((data) => {
        return data.ticker === this.formValues.coinInfo.base_asset_ticker;
      });
      return (this.userSelectedCoin = findSelectedCoin);
    },
    //
    asksSum() {
      if (this.orderDepth.asks !== undefined && this.orderDepth.asks !== null) {
        return this.orderDepth.asks.reduce(
          (total, obj) => parseFloat(obj[1]) + total,
          0
        );
      }
      return 0;
    },

    bidsSum() {
      if (this.orderDepth.bids !== undefined && this.orderDepth.bids !== null) {
        return this.orderDepth.bids.reduce(
          (total, obj) => parseFloat(obj[1]) + total,
          0
        );
      }
      return 0;
    },
    updateSums() {
      this.sumOfAsksAmount = this.asksSum();
      this.sumOfBidsAmount = this.bidsSum();
    },

    pollData() {
      this.polling = setInterval(() => {
        if (this.formValues.coinInfo === "") {
          this.fetchData(false);
        }

        this.updateOrderbook();
        this.handlePriceCoin();
        return this.handleVariablesMarket();
      }, 3000);
    },

    calculateBidTotal(totalAmount) {
      let tap = 0; //  price * coin amount
      let ta = 0; //coin amount
      // let pta = 0;
      if (this.orderDepth !== undefined) {
        const asks = this.orderDepth.asks;
        for (let i = 0; i < asks.length; i++) {
          const ask = asks[i];
          const price = parseFloat(ask[0]);
          const amount = parseFloat(ask[1]);
          const rowTotal = price * amount;
          tap += rowTotal;
          ta += amount;
          if (totalAmount === tap && tap > 0) {
            return ta;
          } else if (totalAmount < tap) {
            return (totalAmount - tap) / price + ta;
          }
        }
      }
    },
    calculateAskTotal(totalAmount) {
      let tap = 0;
      let ta = 0;
      // let pta = 0;
      if (this.orderDepth !== undefined) {
        const bids = this.orderDepth.bids;
        for (let i = 0; i < bids.length; i++) {
          const bid = bids[i];
          const price = parseFloat(bid[0]);
          const amount = parseFloat(bid[1]);
          const rowTotal = price * amount;
          tap += rowTotal;
          ta += amount;
          if (totalAmount === tap && tap > 0) {
            return ta;
          } else if (totalAmount < tap) {
            return (totalAmount - tap) / price + ta;
          } else {
            return (totalAmount - tap) / price + ta;
          }
          // pta += amount;
        }
      }
    },
    updateInputValues() {
      this.pollingMinute = setInterval(() => {
        if (this.actionType === "buy") {
          this.inputDirectChangePrice();
        } else {
          this.inputDirectChangeCoin();
        }
      }, 60000);
    },

    dataArray(data) {
      const arr = Object.keys(data).map((key) => {
        return data[key];
      });

      return arr;
    },
    dataHandler(data) {
      //remove BTTC
      const filterCoins = data.filter(
        (item) => item.base_asset_ticker !== "BTTC"
      );
      const reverseData = filterCoins.reverse();
      const selectedCoins = reverseData.splice(0, 5);
      return selectedCoins;
    },
    fetchData(flagPollData) {
      let loader = this.$loading.show({
        container: this.$refs.calcMain,
        canCancel: false,
        bars: "dots",
      });
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=IRT")
        .then((response) => {
          const data = this.dataHandler(this.dataArray(response.data));
          this.formValues.coinInfo = data[0];
          this.formValues.priceCoin = data[0].best_bid_raw;
          this.marketId =
            this.formValues.coinInfo.base_asset_ticker +
            "_" +
            this.fiatType.name;
          if (this.loginUser) {
            this.updateUserWallet();
          }

          flagPollData && this.pollData();
          loader.hide();
        })
        .catch(() => {
          this.formValues.coinInfo = "";
          flagPollData && this.pollData();
          loader.hide();
        });
    },
  },
  watch: {
    "formValues.coinInfo": function (val) {
      this.bestAsk = val.best_ask_raw;
      this.bestBid = val.best_bid_raw;
    },
  },
  mounted() {
    this.resetForm();
    this.marketId =
      this.formValues.coinInfo.base_asset_ticker + "_" + this.fiatType.name;
    if (this.loginUser) {
      this.updateUserWallet();
    }

    this.pollData();
    document.addEventListener("click", this.closeSelectBox);
    document.addEventListener("click", this.closeTooltip);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
    clearInterval(this.polling);
    clearInterval(this.pollingMinute);
  },
};
</script>
<style scoped>
/* tooltip */
.tooltip-btn {
  position: relative;
}
.tooltip-btn img {
  cursor: pointer;
}
.tooltip-wrapper {
  width: 150px;
  position: absolute;
  left: 18px;
  z-index: 10;
}
.tooltip-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #555555;
  text-align: start;
  padding: 10px;
  border-radius: 2px;
}
.tooltip-custom a:hover {
  color: #bbbbbb;
}
.tooltip-custom a {
  text-align: start;
  width: 100%;
}
/**arrows**/
.tooltip-custom::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555555 transparent transparent transparent;
}

.right::after {
  top: 40%;
  left: 6%;
  rotate: 90deg;
}

/* tooltip */
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.flex-xycenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-small {
  font-size: 0.85rem;
}

.Card {
  background-color: #302d2c;
  border: 1px solid #ffff;
  border-radius: 30px;
  width: 350px;
  overflow: hidden;
}

.header {
  border-bottom: 1px solid #ffff;
  display: flex;
  padding: 10px 5px 0px 10px;
}

.headerTab span {
  cursor: pointer;
  padding: 5px 0;
}

.headerTab {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
}

.activeHeader {
  color: #f8aa0f;
  border-bottom: 3px solid #f8aa0f;
}

/* icon */
.iconCoin img {
  border-radius: 100%;
  width: 32px;
}

.iconFront {
  position: absolute;
  z-index: 15;
  right: 18px;
}

.iconBack {
  position: absolute;
  z-index: 5;
  left: 10px;
}

/* buttonTab */
.buuton-box {
  width: 100%;
  margin: 10px auto;
  position: relative;
  border-radius: 13px;
  border: 1px solid #ffff;
  background-color: #4a4a48;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  overflow: hidden;
}

.toogle-btn {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
  color: #ffff;
  font-weight: bold;
}

#btnTab {
  top: 0;
  /* left: 10px; */
  position: absolute;
  height: 100%;
  background: #1e1919;
  border-radius: 13px;
  transition: 1s;
  border: 1px solid #ffff;
}

/* buttonTab */
.Main {
  padding: 5px 20px;
}
@media screen and (min-width: 300px) {
  #btnTab {
    width: 152px;
  }
}


@media screen and (min-width: 768px) {
  #btnTab {
    width: 102px;
  }
  .toogle-btn-sell {
    padding-right: 50px !important ;
  }
  .toogle-btn-buy {
    padding-left: 50px !important;
  }
  .iconFront {
  position: absolute;
  z-index: 15;
  right:5px;
}

.iconBack {
  position: absolute;
  z-index: 5;
  left: 10px;
}
}
@media screen and (min-width: 1000px) {
  #btnTab {
    width: 122px;
  }
  .toogle-btn-sell {
    padding-right: 40px !important ;
  }
  .toogle-btn-buy {
    padding-left: 40px !important;
  }
  
}
@media screen and (min-width: 1200px) {
  #btnTab {
    width: 152px;
  }
  .toogle-btn-sell {
    padding-right: 1.5rem !important ;
  }
  .toogle-btn-buy {
    padding-left: 1.5rem !important;
  }
  .iconFront {
  position: absolute;
  z-index: 15;
  right: 18px;
}

.iconBack {
  position: absolute;
  z-index: 5;
  left: 10px;
}
}
.select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.select-box img {
  width: 32px;
}

.selected {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #f9f6f6;
  padding: 7px 10px;
  width: 100%;
}

.openDropDown {
  border: 1px solid #fff;
  border-radius: 13px;
}

.selected :first-child {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.4rem;
}

.listCoin {
  position: absolute;
  /* bottom: 0; */
  top: 48px;
  height: 200px;
  overflow: auto;
  background-color: #302d2c;
  width: 100%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  padding: 10px 5px 15px 5px;
}

/* input arrow number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* input arrow number */

/* scroll */
.listCoin {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.listCoin::-webkit-scrollbar {
  display: none;
}

/* scroll */

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  cursor: pointer;
}

.option:hover {
  background-color: #363332;
}

.option :first-child {
  display: flex;
  gap: 0.5rem;
}

.arrow-select {
  width: 17px !important;
  transition: all 0.3s ease;
}

.rotate-arrow {
  transform: rotate(-180deg);
  transition: all 0.3s ease;
}

/* input number */
input {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
  color: #ffff !important;
  text-align: start !important;
  box-shadow: none !important;
}

input::placeholder {
  color: #ffff;
}

.container-box {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
}

.numberBox {
  border: 1px solid #fff;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.numberBox div {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberBox button {
  background: transparent;
}

.numberBox img {
  width: 15px;
}

.minus {
  border: none;
  border-right: 1px solid #fff;
  padding: 10px;
  outline: none;
}

.plus {
  border: none;
  border-left: 1px solid #fff;
  padding: 10px;
  outline: none;
}

.plus:hover {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: #363332;
}

.minus:hover {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  background-color: #363332;
}

/* input number */

/* icon */

.textInfo {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.2rem;
}

.accurancy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accurancy div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
}

.accurancy button {
  background: transparent;
  outline: none;
  border: none;
}

.accurancy img {
  width: 17px;
}

.btn-submit {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  border: 1px solid #fff;
  background-color: #4a4a48;
  border-radius: 13px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  font-size: 0.98rem;
  font-weight: bold;
}

.btn-submit:hover {
  background-color: #363332;
}

#ask-limit-amount:focus,
#ask-limit-amount2:focus {
  background-color: inherit;
  outline: none;
  border: none;
  color: #ffff;
  box-shadow: none;
}

/* toast */

/* new */
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
  font-weight: bolder;
  font-size: 1.3rem;
}
.boxDetails {
  height: 20px;
}
</style>
