<template>
  <div >
    <div class="m-3">
      <h3>
        تغییر رمز عبور
      </h3>
    </div>

    <section id="tabs">
      <div class="container">
        <div class="row">
          <div class="login-wrapper">
            <nav>
              <div
                class="nav"
                id="nav-tab"
                role="tablist"
                style="position: relative"
              >
                <a
                  class="nav-item active"
                  id="nav-login-tab"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  aria-controls="nav-login"
                  aria-selected="true"
                >
                  تغییر رمز عبور
                </a>
              </div>
            </nav>
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-login"
                role="tabpanel"
                aria-labelledby="nav-login-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <form @submit="resetPassword">
                      <div class="input-group mt-3 mb-3 ltr">
                        {{ userInfo.email }}
                      </div>
                      <div class="input-group mt-3 mb-3">
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <font-awesome-icon
                              :icon="eyeIcon"
                              class="clickable"
                              @click="switchVisibility"
                            />
                          </span>
                        </div>
                        <input
                          :type="passwordFieldType"
                          class="form-control"
                          name="currentPassword"
                          id="currentPassword"
                          placeholder="رمز عبور فعلی"
                          dir="ltr"
                          v-model="currentPassword"
                        />

                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="key" />
                          </span>
                        </div>
                      </div>
                      <div class="input-group mt-3 mb-3">
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <font-awesome-icon
                              :icon="eyeIcon"
                              class="clickable"
                              @click="switchVisibility"
                            />
                          </span>
                        </div>
                        <input
                          :type="passwordFieldType"
                          class="form-control"
                          name="newPassword"
                          id="newPassword"
                          placeholder="رمز عبور جدید"
                          dir="ltr"
                          v-model="newPassword"
                        />

                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="key" />
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="userInfo.is_otp_active"
                        class="input-group mt-3 mb-3"
                      >
                        <div class="input-group-append"></div>
                        <input
                          type="tel"
                          class="form-control"
                          name="otp"
                          id="otp"
                          maxlength="6"
                          placeholder="رمز دوعاملی"
                          dir="ltr"
                          v-model="otpCode"
                        />

                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="key" />
                          </span>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-3">
                        <input
                          class="btn btn-warning login-btn"
                          type="submit"
                          value="تغییر رمز عبور"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangePassword",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      password: "",
      passwordFieldType: "password",
      eyeIcon: "eye-slash",

      markets: [],
      currentPassword: null,
      newPassword: null,
      otpCode: null,
      userInfo: this.$session.get("user_info"),
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.eyeIcon = this.eyeIcon === "eye" ? "eye-slash" : "eye";
    },

    logout() {
      this.$session.destroy();
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/logout", {
          withCredentials: true,
        })
        .then(() => {
          return this.$router.push("/login");
        })
        .catch(() => {
          // console.log(e)
        });
    },
    resetPassword(e) {
      e.preventDefault();

      if (this.currentPassword === null || this.currentPassword === "") {
        this.notice("error", "خطا", "لطفا رمز عبور فعلی را وارد نمایید", 5);
        return;
      }

      if (this.newPassword === null || this.newPassword === "") {
        this.notice("error", "خطا", "لطفا رمز عبور جدید را وارد نمایید", 5);
        return;
      }

      if (this.userInfo.is_otp_active && this.otpCode === null) {
        this.notice("error", "خطا", "لطفا رمز دو عاملی را وارد نمایید", 5);
        return;
      }
      if (this.newPassword === this.currentPassword) {
        this.notice("error", "خطا", "رمز عبور فعلی و جدید مشابه هستند", 5);
        return;
      }

      this.notice("info", "در حال بررسی اطلاعات ارسالی");

      const emailInfo = {
        current_password: this.currentPassword,
        new_password: this.newPassword,
        otp_code: this.otpCode,
      };

      axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}/user/change-password`,
          emailInfo,
          { withCredentials: true }
        )
        .then(() => {
          this.newPassword = null;
          this.notice(
            "success",
            "عملیات موفق",
            "رمز عبور شما با موفقیت تغییر یافت"
          );
          this.logout();
        })
        .catch((e) => {
          this.notice("error", "خطا", e.response.data.error, 10);
        });
    },
  },
  created() {
    this.checkSession();
    this.updateMarketList();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.login-btn {
  width: 200px;
  border-radius: 20px;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  width: 400px;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0.5);
}

.nav-item {
  border-bottom: 4px solid #f7a906;
  color: #f7a906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: #fff !important;
  color: #f7a906 !important;
  border: 0;
}

.input-group-append .input-group-text {
  background-color: #fff !important;
  color: #000 !important;
  border: 0;
}

.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
</style>
