<template>
    <div id="content">
                <div class="m-4">

                    <h2>
                        ثبت موفق اطلاعات
                    </h2>

                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-12 p-3 box">

                                <h4>
                                    با تشکر از شما
                                </h4>

                                <p>
                                    اطلاعات شما با موفقیت ثبت گردید، منتظر تماس تیم پشتیبان لیدیا در
                                    سریعترین زمان ممکن بین ساعت ۹ الی ۲۰ باشید
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
   
    import axios from 'axios'

    export default {
        name: 'IdentityVerifySubmitted',
        title: "اطلاعات کاربری",

        props: {
            msg: String
        },
        components: {
           
        },

        data() {
            return {
                markets: [],
                isDisabled: false,
                buttonsVisible: false,
                firstName: "",
                lastName: "",
                nationalCode: "",
                phone: "",
                mobile: "",
                birthDate: "",
                address: "",
                userInfo: null,

                formDisabled: false,

                kycStatus: {},


                cardNo: null,
                ibanNo: null,
                holderName: null,
                cards: [],
                hasImage: false,
                polling: null,



            }
        },
        computed: {
            irtMarkets: function () {
                return Object.values(this.markets).filter((m) => m.quote_asset_ticker === "IRT")
            },
            usdtMarkets: function () {
                return Object.values(this.markets).filter((m) => m.quote_asset_ticker === "USDT")
            },

        },
        methods: {
            checkSession() {

            if (!this.$session.exists('user_info')) {
                this.$session.destroy();
                return this.$router.push('/login').catch(() => {
                });
            }
        },

            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                });
            },

            loadUserInfo() {
                this.userInfo = this.$session.get('user_info');

                this.firstName = this.userInfo.first_name;
                this.lastName = this.userInfo.last_name;
                this.nationalCode = this.userInfo.national_code;
                this.phone = this.userInfo.phone;
                this.mobile = this.userInfo.mobile;
                this.birthDate = this.userInfo.birth_date;
                this.address = this.userInfo.address;
            },



            updateUserInfo() {
                if (this.$session.exists('user_info')) {
                    axios.get(process.env.VUE_APP_BACKEND_URL + '/user/info', {withCredentials: true})
                        .then(response => {
                            this.userInfo = response.data;
                            this.$session.set('user_info', this.userInfo);
                            this.userInfo = response.data;
                            this.firstName = this.userInfo.first_name;
                            this.lastName = this.userInfo.last_name;
                            this.nationalCode = this.userInfo.national_code;
                            this.phone = this.userInfo.phone;
                            this.mobile = this.userInfo.mobile;
                            this.birthDate = this.userInfo.birth_date;
                            this.address = this.userInfo.address;
                        })
                        .catch(() => {
                            // this.$session.destroy();
                        });
                }
            },

            uploadSuccess(file) {
                if (file.accepted === true && file.status === "success") {
                    this.notice('success', 'تصویر با موفقیت آپلود شد.');
                    this.kycStatus.selfie = true;

                }
            },
            updateMarketList() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/market/list')
                    .then(response => {
                        this.markets = response.data;
                    })
                    .catch(() => {
                        // console.log(e)
                    });

            },
            updateCardList() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/card-list', {withCredentials: true})
                    .then(response => {
                        this.cards = response.data;
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },

            getKycStatus() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/kyc-status', {withCredentials: true})
                    .then(response => {
                        this.kycStatus = response.data;
                        if (this.kycStatus.verify_status === 'verified') {
                            this.formDisabled = true;
                            this.$refs.selfieDropzone.disable();
                        }
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },

            clearCardForm() {
                this.ibanNo = "---";
                this.holderName = "---";
                this.cardNo = "";
                this.isDisabled = false;
                this.buttonsVisible = false;
            },




        },
        created() {
            this.checkSession();

            this.loadUserInfo();
            this.updateMarketList();
            this.updateCardList();

            this.getKycStatus();
            if (this.kycStatus.verify_status === 'verified') {
                this.formDisabled = true;
                this.$refs.selfieDropzone.disable();
            }
        },


        /*        mounted() {
                    if (this.kycStatus.verify_status === 'verified') {
                        console.log("disable");
                        this.formDisabled = true;
                        this.$refs.selfieDropzone.disable();
                    }else{
                        console.log(this.kycStatus);
                    }
                    this.p();
                },
                beforeDestroy() {
                    clearInterval(this.polling)
                },*/

    }
</script>
<style lang="scss" scoped>
    .app {
        text-align: center;

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            background-color: #222;
            color: #fff;
        }

        &__title {
            display: block;
            font-size: 1.5em;
        }
    }
</style>
<style scoped>

    .login-btn {
        width: 200px !important;
        border-radius: 20px;
    }


    .login-btn2 {
        border-radius: 20px;
    }

    .selfie img {
        border-radius: 10px;
        max-width: 90%;
    }

    .card-row {
        border-bottom: 1px solid #666;
    }

    .card-row:before {
        border-top: 1px solid #666;
    }


    .wrapper {
        width: 100%;
    }

    #content {
        width: 100%;
        display: block;
        padding: 0;
        min-height: 100vh;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
    }

    .box {
        color: #fff;
        background-color: #565656;
        border-radius: 10px;
    }

    /* ********************************/
    #customdropzone {
        background-color: #ABABAB;
        letter-spacing: 0.2px;
        color: #fff;
        transition: background-color .2s linear;
        height: 200px;
        padding: 40px;
        border-radius: 10px;
    }

    #customdropzone .dz-preview {
        width: 160px;
        display: inline-block
    }

    #customdropzone .dz-preview .dz-image {
        width: 80px;
        height: 80px;
        margin-left: 40px;
        margin-bottom: 10px;
    }

    #customdropzone .dz-preview .dz-image > div {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        background-size: contain;
    }

    #customdropzone .dz-preview .dz-image > img {
        width: 100%;
    }

    #customdropzone .dz-preview .dz-details {
        color: white;
        transition: opacity .2s linear;
        text-align: center;
    }

    #customdropzone .dz-success-mark, .dz-error-mark, .dz-remove {
        display: none;
    }

    /* ********************************/

    @media screen and (max-width: 600px) {
        .box {
            font-size: 12px;
        }

        .box h4 {
            font-size: 18px;
        }

    }

</style>
