<template>
  <div class="flex-xycenter main">
    <CalculatorCoin :irtMarkets="irtMarkets" :usdtMarkets="usdtMarkets" />
  </div>
</template>
<script>
import CalculatorCoin from "../components/CalculatorCoin.vue";
import axios from "axios";
export default {
  name: "fast-trade",
  metaInfo: {
    title: "معامله سریع و آسان ارزهای دیجیتال - لیدیا",

    meta: [
      {
        name: "og:title",
        vmid: "og:title",
        content: "معامله سریع و آسان ارزهای دیجیتال - لیدیا",
      },
      {
        name: "description",
        vmid: "description",
        content:
          "انجام معاملات و ترید ارز دیجیتال در سریع ترین زمان ممکن | خرید و فروش آسان ارز دیجیتال در صرافی لیدیا",
      },
      {
        name: "og:description",
        vmid: "og:description",
        content:
          "انجام معاملات و ترید ارز دیجیتال در سریع ترین زمان ممکن | خرید و فروش آسان ارز دیجیتال در صرافی لیدیا",
      },
    ],
  },
  components: {
    CalculatorCoin,
  },
  data() {
    return {
      irtMarkets: [],
      usdtMarkets: [],
    };
  },

  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.updateMarketList();
      }, 3000);
    },

    updateMarketList() {
      function dataArray(data) {
        const arr = Object.keys(data).map((key) => {
          return data[key];
        });

        return arr;
      }
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=IRT")
        .then((response) => {
          this.irtMarkets = dataArray(response.data);
        });
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=USDT")
        .then((response) => {
          this.usdtMarkets = dataArray(response.data);
        });
    },
  },

  created() {
    this.updateMarketList();
  },
  mounted() {
    this.pollData();
  },
  beforeDestroy() {
    this.$nextTick(() => {
      clearInterval(this.polling);
    });
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
}
#content {
  width: 100%;
  display: block;
  padding: 0;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.flex-xycenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main {
  margin: 60px 0 80px 0;
  max-height: 500px;
}

@media screen and (min-width: 1200px) {
  .main {
    margin: 20px 0 20px 0;
  }
}
@media screen and (min-width: 991px) {
  .main {
    margin: 60px 0 70px 0;
  }
}
</style>
