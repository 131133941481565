import { render, staticRenderFns } from "./DepositBoxCrypto.vue?vue&type=template&id=12afa824&scoped=true&"
import script from "./DepositBoxCrypto.vue?vue&type=script&lang=js&"
export * from "./DepositBoxCrypto.vue?vue&type=script&lang=js&"
import style0 from "./DepositBoxCrypto.vue?vue&type=style&index=0&id=12afa824&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12afa824",
  null
  
)

export default component.exports