<template>
    <div class="list-item-announcement">
        <div v-if="messages !== undefined && messages != null && messages.records.length > 0">

        <div v-for="message in messages.records" :key="message.id" @click="annClicked(message)">
            <div v-bind:class="{ unread: !message.is_read }">
                <router-link :to="`/user/message/${message.id}`">
                    <div class="bold">
                        {{message.title}}
                    </div>
                    <div class="small mt-2 gray ltr">
                        {{message.created_at | persianDateUnix}}
                    </div>
                    <div class="mt-3 small ">
                        <p v-html="message.message">

                        </p>
                        <span class="blue"> ادامه پیام ...</span>
                    </div>
                </router-link>

            </div>
        </div>
        </div>
        <div v-else>
        <div>
            <div class="text-center">
                پیامی وجود ندارد
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MessagesTable',
        props: ['messages'],
        created() {
            console.log(this.messages);
        },
        methods: {
            annClicked: function (message) {
                if (!message.is_read) {
                    console.log(message);
                    return this.$root.$emit('annClicked', message.id);
                }

            }
        },

    }
</script>

<style scoped>

    .open-messages  {
        font-size: 12px;
        border: 0;
        border-spacing: 0;
    }

    .unread {
      background-color: #161c24;
      border-radius: 12px;
    }

    .blue {
      color: #007bff;
    }

    .gray {
      color: #d2d2d2;
    }

</style>
