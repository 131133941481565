<template>
  <div class=" wrapper  ">
    <div class="m-4">
      <div class="container">
        <div class="login-wrapper">
          <nav>
            <div
              class="nav text-center"
              id="nav-tab"
              role="tablist"
              style="position: relative"
            >
              <a
                class="nav-item active text-center"
                id="nav-login-tab"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-controls="nav-login"
                aria-selected="true"
              >
                پیامک ورود
              </a>
            </div>
          </nav>
          <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-login"
              role="tabpanel"
              aria-labelledby="nav-login-tab"
            >
              <div class="row">
                <div class="col-12">
                  {{ mobile }}
                </div>
                <div class="col-12">
                  <form @submit="mobileVerify">
                    <div class="input-group mt-3 mb-3">
                      <div class="input-group-append">
                        <span class="input-group-text"> </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="کد دریافتی در تلفن همراه"
                        dir="ltr"
                        v-model="token"
                        :disabled="isDisabled"
                      />

                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <font-awesome-icon icon="key" />
                        </span>
                      </div>
                    </div>
                    <div class="col-12 text-center mt-2">
                      <input
                        class="btn btn-warning login-btn"
                        type="submit"
                        ref="submitBtn"
                        value="تایید تلفن همراه"
                        :disabled="isDisabled"
                      />
                    </div>
                  </form>
                  <!-- <div
                        v-if="session.exists('user_info')"
                        class="col-12 mt-4 hint"
                      >
                        کد تاییدی دریافت نکرده اید؟
                        <a href="#" class="p-1" @click="mobileResend">
                          ارسال مجدد کد تایید
                        </a>
                      </div> -->
                  <div class="timerBox">
                    <span>{{
                      time === "00"
                        ? "کد تایید ارسال نشد؟"
                        : "کد تایید ارسال شده"
                    }}</span>
                    <div>
                      <button v-if="time === '00'" @click="mobileResend()">
                        ارسال مجدد کد تایید
                      </button>
                      <div v-else>
                        <span v-if="time < 10">00:{{ "0" + time }}</span>
                        <span v-else>00:{{ time }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-right mt-4">
                    <button @click="outtologin" class="btn btn-danger">
                      بازگشت به صفحه ورود
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MobileVerifyNew",

  components: {},
  data() {
    return {
      markets: [],
      token: "",
      mobile: "",
      isDisabled: false,
      resent: false,
      session: this.$session,
      time: 60,
      userInfo2: this.$route.params.userInfo2,
    };
  },

  watch: {
    token: function() {
      if (this.token.length >= 6) {
        this.$refs.submitBtn.click();
      }
    },
  },
  methods: {
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    checkKycLevel() {
      if (this.userInfo2.kyc_step === 0) {
        return this.$router.push({ name: "EmailVerify" });
      } else if (this.userInfo2.kyc_step === 1) {
        return this.$router.push({ name: "MobileVerify" });
      } else if (this.userInfo2.kyc_step === 2) {
        return this.$router.push({ name: "Profile" });
      } else {
        return this.$router.push({ name: "Profile" });
      }
    },

    mobileVerify(e) {
      if (e !== undefined) {
        e.preventDefault();
      }

      this.notice(
        "info",
        "در حال اعتبارسنجی تلفن همراه...",
        "لطفا کمی صبر کنید",
        2
      );
      const mobileInfo = {
        email: localStorage.getItem("email"),
        token: this.token,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/user/mobile-verify-new",
          mobileInfo,
          { withCredentials: true }
        )
        .then((res) => {
          this.notice(
            "success",
            "تایید تلفن همراه",
            "کد وارد شده صحیح می باشد."
          );
          //const userInfo = this.session.get('user_info');
          this.session.set("smspagetrue", true);
          this.$session.set("user_info", res.data);
          this.$router.push({ name: "Profile" });
        })
        .catch((e) => {
          this.notice("error", "خطا", e.response.data.message, 3);
          this.isDisabled = false;
        });
    },
    outtologin() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/logout", {
          withCredentials: true,
        })
        .then(() => {
          this.$session.destroy();
          this.userInfo2 = "";
          return this.$router.push("/login");
        })
        .catch(() => {
          // console.log(e)
        });
    },
    mobileResend() {
      this.notice("info", "درخواست کد تایید مجدد...", "", 4);
      const resendInfo = {
        email: localStorage.getItem("email"),
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/user/checkSmsforLogin",
          resendInfo,
          { withCredentials: true }
        )
        .then(() => {
          this.notice(
            "success",
            "درخواست کد تایید مجدد",
            "کد تایید با موفقیت مجددا برایتان ارسال شد"
          );
          this.timerCounter();
        })
        .catch((e) => {
          this.notice("error", "خطا", e.response.data.message, 5);
        });
    },
    timerCounter() {
      this.time = 60;

      const decreaseTime = setInterval(() => {
        if (this.time === 1) {
          clearInterval(decreaseTime);
          return (this.time = "00");
        }
        return this.time--;
      }, 1000);
      return decreaseTime;
    },
    mobileGenerate() {
      const num = this.userInfo2.get("user_info").mobile;
      const sliceNum = `${num.slice(8, 11)}*****${num.slice(0, 3)}`;
      this.mobile = sliceNum;
    },
  },

  created() {
    if (this.userInfo2 === undefined || this.userInfo2 == null) {
      return this.$router.push({ name: "Login" });
    }
    this.mobileGenerate();
    this.session.set("smspagetrue", false);
  },
  mounted() {
    this.timerCounter();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.hint {
  font-size: 11px;
}

.hint a {
  color: #f7a906;
}

.wrapper {
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 500px;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.login-btn {
  width: 200px;
  border-radius: 20px;
}

.static {
  min-height: 500px;
}

.static .content {
  margin-top: 50px;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  width: 400px;
  height: 100%;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0.5);
}

.nav-item {
  border-bottom: 4px solid #f7a906;
  color: #f7a906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: #fff !important;
  color: #f7a906 !important;
  border: 0;
}

.input-group-append .input-group-text {
  background-color: #fff !important;
  color: #000 !important;
  border: 0;
}

.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}
/* new */
.timerBox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}
.timerBox button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #f7a906;
}
/* new */

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}
</style>
