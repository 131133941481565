<template>
  <table class="table table-bitemless">
    <thead>
      <tr>
        <th scope="col">زمان</th>
        <th scope="col">نوع</th>
        <th scope="col">ارز</th>
        <th scope="col">شبکه</th>
        <th scope="col">مقدار</th>
        <th scope="col">موجودی</th>
        <th scope="col">وضعیت</th>
        <th scope="col">رسید</th>
      </tr>
    </thead>
    <tbody v-if="history.records !== undefined && history.records.length">
      <tr v-for="item in history.records" :key="item.item_id">
        <td class="ltr">
          {{ item.time | persianDateUnix }}
        </td>
        <td>
          <span v-if="item.type === 'withdraw'" class="text-danger">
            برداشت
          </span>
          <span v-else-if="item.type === 'deposit'" class="text-success">
            واریز
          </span>
          <span v-else-if="item.type === 'trade'" class="text-warning">
            ترید
          </span>
          <span v-else-if="item.type === 'fee'" class="text-info">
            کارمزد
          </span>
          <span v-else-if="item.type === 'commission'" class="text-info">
            سود معرفی
          </span>
        </td>
        <td>{{ item.asset }}</td>
        <td>{{ item.network_name }}</td>
        <td dir="ltr">
          <span v-if="item.change > 0" class="text-success">
            +{{ item.change | prec(assetPrec[item.asset]) }}
          </span>
          <span v-else class="text-danger">
            {{ item.change | prec(assetPrec[item.asset]) }}
          </span>
        </td>
        <td dir="ltr">{{ item.balance | prec(assetPrec[item.asset]) }}</td>
        <td>
          <div v-if="item.detail.status !== undefined">
            <span v-if="item.detail.status === 0" class="badge badge-warning">
              در انتظار تایید
            </span>
            <span v-if="item.detail.status === 1" class="badge badge-info">
              تایید شده و در انتظار پردازش
            </span>
            <span v-if="item.detail.status === 2" class="badge badge-success">
              انجام شده
            </span>
          </div>
          <div v-else>
            <span class="badge badge-secondary"> تراکنش سیستمی </span>
          </div>
        </td>
        <td>
          <!--                <div v-if="item.detail.is_confirmed !== undefined">
                    <span v-if="item.detail.is_confirmed" class="text-success">
                        تایید شده
                    </span>
                    <span v-else class="text-warning">
                        منتظر تایید
                    </span>
                </div>-->
          <div
            v-if="
              item.detail.to !== undefined &&
              item.detail.to !== '' &&
              item.detail.to !== null
            "
            class="small latin"
          >
            به: {{ item.detail.to }}
          </div>
          <div
            v-if="
              item.detail.ref_num !== undefined &&
              item.detail.ref_num !== '' &&
              item.detail.ref_num !== null
            "
            class="small"
          >
            کد پیگیری: {{ item.detail.ref_num }}
          </div>
          <div
            v-if="
              item.detail.tx_hash !== undefined &&
              item.detail.tx_hash !== null &&
              item.detail.tx_hash !== ''
            "
            class="btn-group btn-group-sm"
            style="z-index: 10"
          >
            <a
              class="btn btn-sm btn-info mt-2"
              v-if="
                item.detail.explorer !== undefined &&
                item.detail.explorer !== ''
              "
              :href="`${item.detail.explorer}/${item.detail.tx_hash}`"
              target="_blank"
            >
              مشاهده در Explorer
            </a>
            <br />
            <button
              class="btn btn-sm btn-danger mt-2"
              v-clipboard:copy="item.detail.tx_hash"
              v-clipboard:success="onCopy"
            >
              کپی TX Hash
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="6" class="text-center">تاریخچه‌ای موجود نمی باشد</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "HistoryTable",
  props: ["history", "assets"],
  /*        data() {
            return {
                assetPrec : [],
            }
        },*/
  /*        created() {
            const explorers = {};
            this.assets.forEach(function (asset) {
                if (asset.explorer_transaction !== undefined) {
                    explorers[asset.ticker] = asset.explorer_transaction;
                }
            });
            this.explorers = explorers;
        },*/
  methods: {
    onCopy: function () {
      return this.notice(
        "success",
        "کپی TX Hash",
        "TX Hash با موفقیت کپی شد.",
        3
      );
    },
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },
  },
  computed: {
    explorers: function () {
      const explorers = {};
      this.assets.forEach(function (asset) {
        if (asset.explorer_transaction !== undefined) {
          explorers[asset.ticker] = asset.explorer_transaction;
        }
      });

      return explorers;
    },

    assetPrec: function () {
      const assetPrec = {};
      this.assets.forEach(function (asset) {
        if (asset.prec_show !== undefined) {
          assetPrec[asset.ticker] = asset.prec_show;
        }
      });
      return assetPrec;
    },
  },
};
</script>

<style scoped>
table thead th {
  bitem-bottom: 2px solid #999;
}

table tbody tr {
  bitem-bottom: 1px solid #999;
}

.open-history table {
  font-size: 12px;
  bitem: 0;
  bitem-spacing: 0;
}
</style>
