<template>
    <div class="TVChartContainer" :id="containerId"></div>
</template>

<script>
    import Datafeed from '../js/TradingViewUDF'

    import { widget } from '../../public/charting_library/charting_library.min';

    export default {
        name: 'TVChartContainer',
        props: {
            symbol: {
                default: 'BTC_USDT',
                type: String,
            },
            interval: {
                default: '1',
                type: String,
            },
            containerId: {
                default: 'tv_chart_container',
                type: String,
            },
            datafeedUrl: {
                default: 'https://demo_feed.tradingview.com',
                type: String,
            },
            libraryPath: {
                default: '/charting_library/',
                type: String,
            },
            chartsStorageUrl: {
                default: 'https://saveload.tradingview.com',
                type: String,
            },
            chartsStorageApiVersion: {
                default: '1.2',
                type: String,
            },
            clientId: {
                default: 'tradingview.com',
                type: String,
            },
            userId: {
                default: 'public_user_id',
                type: String,
            },
            fullscreen: {
                default: false,
                type: Boolean,
            },
            autosize: {
                default: true,
                type: Boolean,
            },
            studiesOverrides: {
                type: Object,
            }
        },
        watch: {
            symbol: function (newVal) { // watch it
                this.symbol = newVal;

                const widgetOptions = {
                    symbol: this.symbol,
                    // BEWARE: no trailing slash is expected in feed URL
                    datafeed: Datafeed, // new window.Datafeeds.UDFCompatibleDatafeed(this.datafeedUrl),
                    interval: this.interval,
                    container_id: this.containerId,
                    library_path: this.libraryPath,
                    theme: 'Dark',
                    locale: 'en',
                    disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
                    allow_symbol_change: false,
                    calendar: true,
                    debug: false,
                    details: true,
                    enable_publishing: false,
                    enabled_features: ['show_animated_logo'],
                    hotlist: true,
                    // enabled_features: ['study_templates'],
                    charts_storage_url: this.chartsStorageUrl,
                    charts_storage_api_version: this.chartsStorageApiVersion,
                    client_id: this.clientId,
                    user_id: this.userId,
                    fullscreen: this.fullscreen,
                    autosize: this.autosize,
                    studies_overrides: {},
                    timeframe: '3D',
                    withdateranges: false,
                    timezone: 'Tehran/Asia'
                };

                const tvWidget = new widget(widgetOptions);
                this.tvWidget = tvWidget;

            }
        },
        tvWidget: null,
        mounted() {

            const widgetOptions = {
                symbol: this.symbol,
                // BEWARE: no trailing slash is expected in feed URL
                datafeed: Datafeed, // new window.Datafeeds.UDFCompatibleDatafeed(this.datafeedUrl),
                interval: this.interval,
                container_id: this.containerId,
                library_path: this.libraryPath,
                theme: 'Dark',
                locale: 'en',
                disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
                allow_symbol_change: false,
                calendar: true,
                debug: false,
                details: true,
                enable_publishing: false,
                enabled_features: ['show_animated_logo'],
                hotlist: true,
                // enabled_features: ['study_templates'],
                charts_storage_url: this.chartsStorageUrl,
                charts_storage_api_version: this.chartsStorageApiVersion,
                client_id: this.clientId,
                user_id: this.userId,
                fullscreen: this.fullscreen,
                autosize: this.autosize,
                studies_overrides: {},
                timeframe: '3D',
                withdateranges: false,
                timezone: 'Tehran/Asia'
            };

            const tvWidget = new widget(widgetOptions);
            this.tvWidget = tvWidget;


        },
/*        destroyed() {
            if (this.tvWidget !== null) {
                this.tvWidget.remove();
                this.tvWidget = null;
            }
        },*/


    }
</script>

<style>
    .TVChartContainer {
        position: absolute;
        width: 100%;
        height: 100%;
    }
</style>
