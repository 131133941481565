<template>
  <div class="row">
    <div class="col-12 asset-item mb-3">
      <div class="row header">
        <div class="col-1 operation"></div>
        <div class="col-md-2 col-sm-2 col-2 text-center text-md-left">ارز</div>
        <div class="col-md-2 col-sm-3 col-3 text-right text-md-left">
          موجودی
        </div>
        <div class="col-md-2 col-sm-2 col-2 text-center text-md-left d-sm-none">
          سفارشات
        </div>
        <div
          class="col-md-2 col-sm-2 col-2 text-center text-md-left d-none d-sm-flex"
        >
          در سفارشات
        </div>
        <div class="col-md-2 col-2 text-left">معادل</div>
        <div class="col-md-3 col-3 text-center text-sm-left">عملیات</div>
      </div>
    </div>
    <div
      v-for="wallet in wallets"
      v-bind:key="wallet.index"
      class="col-12 asset-item mb-3"
    >
      <div class="row p-1">
        <div class="col-1 logo">
          <img :src="cdnUrl + '/media/asset/logo/md/' + wallet.logo_filename" />
        </div>
        <div class="col-2">
          <strong>
            {{ wallet.ticker }}
          </strong>
          <span class="small">
            {{ wallet.name }}
          </span>
        </div>
        <div class="col-2">
          {{ wallet.balance.available | prec(wallet.prec_show) }}
          <br />
        </div>
        <div class="col-2">
          {{ wallet.balance.freeze | prec(wallet.prec_show) }}
        </div>
        <div class="col-2">
          <span class="small-line-height">
            {{ wallet.balance.equal_values.irt | prec(0) }} تومان
          </span>
          <div class="mb-3 small small-line-height latin2">
            ≈ ${{ wallet.balance.equal_values.usdt | prec(2, true) }}
          </div>
        </div>
        <div v-if="userInfo.kyc_step !== 4" class="col-3">
          <router-link
            to="/user/not-verified"
            class="btn btn-success mr-1 custom-btn"
          >
            واریز
          </router-link>
          <router-link
            to="/user/not-verified"
            class="btn btn-danger mr-1 custom-btn"
          >
            برداشت
          </router-link>

          <router-link
            to="/user/not-verified"
            class="btn btn-secondary mr-1 custom-btn"
          >
            معامله
          </router-link>
        </div>

        <div v-else class="col-3">
          <button
            v-if="!wallet.is_deposit_active"
            class="btn btn-success mr-1 custom-btn"
            disabled="disabled"
          >
            واریز
          </button>

          <router-link
            v-else
            :to="`/user/deposit/${wallet.ticker}`"
            class="btn btn-success mr-1 custom-btn"
            disabled="disabled"
          >
            واریز
          </router-link>

          <router-link
            v-if="
              userInfo.is_otp_active &&
              userInfo.otp_changed == 1 &&
              wallet.is_withdraw_active
            "
            :to="`/user/withdraw/${wallet.ticker}`"
            class="btn btn-danger mr-1 custom-btn"
          >
            برداشت
          </router-link>

          <button
            @click="submit()"
            v-else-if="
              userInfo.is_otp_active &&
              userInfo.otp_changed == 0 &&
              wallet.is_withdraw_active
            "
            class="btn btn-danger mr-1 custom-btn"
          >
            برداشت
          </button>
          <span v-else>
            <button
              v-if="!wallet.is_withdraw_active"
              disabled="disabled"
              class="btn btn-danger mr-1 custom-btn"
            >
              برداشت
            </button>

            <router-link
              v-else
              to="/user/otp-not-active"
              class="btn btn-danger mr-1 custom-btn"
            >
              برداشت
            </router-link>
          </span>

          <span v-if="wallet.ticker !== 'IRT'">
            <span
              v-if="wallet.trade_market"
              @click="handleTickerRouter(wallet.trade_market)"
              class="btn btn-primary mr-1 custom-btn"
            >
              معامله
            </span>
            <button
              v-if="wallet.is_dust"
              @click="confirmDust(wallet.ticker)"
              class="btn btn-info"
            >
              تبدیل سریع به تومان
            </button>
          </span>
          <span v-else>
            <button
              disabled="disabled"
              class="btn btn-secondary mr-1 custom-btn"
            >
              معامله
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import emailMask from "email-mask";
import maskEmailsPhones from "mask-email-phone";

export default {
  name: "WalletAssets",
  props: ["wallets"],
  data() {
    return {
      backendRoot: process.env.VUE_APP_BACKEND_ROOT,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      userInfo: this.$session.get("user_info"),
    };
  },

  methods: {
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    confirmDust(ticker) {
      this.$confirm({
        message: `از تبدیل کل این دارایی به تومان مطمین هستید؟`,
        button: {
          no: "خیر",
          yes: "بله",
        },
        callback: (confirm) => {
          if (confirm) {
            this.notice(
              "info",
              "در حال تبدیل به تومان",
              `در حال تبدیل به تومان `
            );

            const data = { assets: [ticker] };

            axios
              .post(process.env.VUE_APP_BACKEND_URL + "/order/dust", data, {
                withCredentials: true,
              })
              .then(() => {
                this.notice(
                  "success",
                  "با موفقیت تبدیل شد",
                  `با موفقیت تبدیل شد`
                );
              })
              .catch((e) => {
                this.notice("error", "خطا", e.response.data.message, 5);
              });
          }
        },
      });
    },

    submit() {
      const _this = this;
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-warning w-25 btn-block form-control",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          width: "45rem",
          title: "بروز رسانی رمز دو عاملی",
          html: `<div class="col mt-3 text-left">

                    <div class="form-group row top-info">
                        <div class="col-5 col-md-5 text-primary fs-1">
                            <h6>${emailMask(
                              _this.userInfo.first_name
                            )}  ${emailMask(_this.userInfo.last_name)} عزیز</h6>
                        </div>
                    </div>

                    <div class="form-group row top-info">
                    <div class="col-12 col-md-12"><p>جهت ارتقاء سطح امنیت حساب کاربری خود لازم است رمز دو عاملی را بروز رسانی نمایید. در صورت نیاز به راهنمایی با پشتیبانی سایت در تماس باشید.</p></div>
                    <div class="col-10 col-md-10 ltr black latin ltr">
       
                    </div>
                    </div>


                    <div class="form-group">
                    <label for="emailChangeOtpInput">کد شناسایی ایمیل</label>
                    <div class="input-group">
                    <input type="tel" class="form-control latin" id="emailChangeOtpInput" maxlength="6">
                    <div class="input-group-append">
                    <button class="btn btn-outline-info" type="button" id="emailChangeOtpBtn">دریافت کد </button>
                    </div>
                </div>
                <small id="emailHelp" class="form-text text-muted">لطفا کد ۶ رقمی که به ایمیل <label class="latin ltr">${emailMask(
                  _this.userInfo.email
                )}</label> ارسال می‌گردد را وارد نمایید.</small>
                </div>


                <div class="form-group">
                    <label for="smsChangeOtpInput">کد شناسایی تلفن همراه</label>
                    <div class="input-group">
                        <input type="tel" class="form-control latin" maxlength="6" id="smsChangeOtpInput">
                        <div class="input-group-append">
                            <button class="btn btn-outline-info" type="button" id="smsChangeOtpBtn">دریافت کد </button>
                        </div>
                    </div>
                    <small id="smsHelp" class="form-text text-muted">لطفا کد ۶ رقمی که به تلفن همراه <label class="latin ltr">${maskEmailsPhones(
                      _this.userInfo.mobile
                    )}</label> ارسال می‌گردد را وارد نمایید.</small>
                </div>

                <div class="form-group">
                <label for="twoFaInput">رمز دو عاملی</label>
                <div class="input-group">
                    <input type="tel" class="form-control latin" maxlength="6" id="twoFaInput">
                </div>
                <small id="twoFaHelp" class="form-text text-muted">لطفا رمز ۶ رقمی دریافتی از اپلیکیشن Google Authenticator را وارد نمایید.</small>
                </div>
                </div>
                `,
          confirmButtonText: "تایید",
          showCloseButton: true,
          allowEnterKey: false,
          focusConfirm: true,
          preConfirm: () => {
            _this.$swal
              .getPopup()
              .querySelector("#emailChangeOtpInput")
              .classList.remove("is-invalid");
            _this.$swal
              .getPopup()
              .querySelector("#smsChangeOtpInput")
              .classList.remove("is-invalid");
            _this.$swal
              .getPopup()
              .querySelector("#twoFaInput")
              .classList.remove("is-invalid");

            const emailToken = this.$swal
              .getPopup()
              .querySelector("#emailChangeOtpInput").value;
            const smsToken = this.$swal
              .getPopup()
              .querySelector("#smsChangeOtpInput").value;
            const twoFa = this.$swal
              .getPopup()
              .querySelector("#twoFaInput").value;
            if (!emailToken) {
              this.$swal
                .getPopup()
                .querySelector("#emailChangeOtpInput")
                .classList.add("is-invalid");
              this.$swal.showValidationMessage(
                `لطفا تمامی موارد خواسته شده را پر کنید`
              );
            }
            if (!smsToken) {
              this.$swal
                .getPopup()
                .querySelector("#smsChangeOtpInput")
                .classList.add("is-invalid");
              this.$swal.showValidationMessage(
                `لطفا تمامی موارد خواسته شده را پر کنید`
              );
            }

            if (!twoFa) {
              this.$swal
                .getPopup()
                .querySelector("#twoFaInput")
                .classList.add("is-invalid");
              this.$swal.showValidationMessage(
                `لطفا تمامی موارد خواسته شده را پر کنید`
              );
            }

            /* return fetch(`//api.github.com/users/${login}`)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            }
                            return response.json()
                        })
                        .catch(error => {
                            Swal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        })
    */

            const params = {
              email_token: emailToken,
              sms_token: smsToken,
              otp_code: twoFa,
            };
            return axios
              .post(
                process.env.VUE_APP_BACKEND_URL + "/user/changeotpfinalize",
                params,
                { withCredentials: true }
              )
              .then((response) => {
                return response.data;
              })
              .catch((e) => {
                this.$swal.showValidationMessage(e.response.data.error);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              "عملیات موفق",
              "غیر فعال سازی رمز دو عاملی با موفقیت انجام گردید.",
              "success"
            );
            this.$router.push({ name: "OtpActivate" });
          }
        });

      const _emailChangeOtpBtn = this.$swal
        .getPopup()
        .querySelector("#emailChangeOtpBtn");
      _emailChangeOtpBtn.onclick = function () {
        _this.$swal
          .getPopup()
          .querySelector("#emailChangeOtpInput")
          .classList.remove("is-invalid");
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/user/changeotpemail",
            {},
            { withCredentials: true }
          )
          .then(() => {})
          .catch(() => {});
        let counter = 60;
        const mailCountdown = setInterval(() => {
          _emailChangeOtpBtn.setAttribute("disabled", "disabled");
          counter--;
          _emailChangeOtpBtn.textContent = `کد ارسال شد`;
          if (counter <= 0) {
            clearInterval(mailCountdown);
            _emailChangeOtpBtn.removeAttribute("disabled");
            _emailChangeOtpBtn.textContent = "ارسال مجدد کد";
          }
        }, 1000);
      };

      const _smsChangeOtpBtn = this.$swal
        .getPopup()
        .querySelector("#smsChangeOtpBtn");
      _smsChangeOtpBtn.onclick = function () {
        _this.$swal
          .getPopup()
          .querySelector("#smsChangeOtpInput")
          .classList.remove("is-invalid");

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/user/changeotpsms",
            {},
            { withCredentials: true }
          )
          .then(() => {})
          .catch(() => {});

        let counter = 60;
        const mailCountdown = setInterval(() => {
          _smsChangeOtpBtn.setAttribute("disabled", "disabled");
          counter--;
          _smsChangeOtpBtn.textContent = `کد ارسال شد`;
          if (counter <= 0) {
            clearInterval(mailCountdown);
            _smsChangeOtpBtn.removeAttribute("disabled");
            _smsChangeOtpBtn.textContent = "ارسال مجدد کد";
          }
        }, 1000);
      };
    },

    maxAmount(key) {
      this.forms[key].amount = this.balance.available;
    },

    clearForm() {
      for (const key of Object.keys(this.asset.networks)) {
        this.forms[key] = {
          address: "",
          amount: 0,
          tag: "",
        };
      }
    },
    handleTickerRouter(ticker) {
      console.log(ticker);
      const mainTickerName = ticker.split("_");
      return this.$router.push({
        name: "Trade",
        params: { marketId: `${mainTickerName[0]}_IRT` },
      });
    },
  },
};
</script>

<style scoped>
.asset-item {
  background-color: #212b36;
  color: #fff;
  border-radius: 10px;
  vertical-align: middle;
  font-size: 15px;
}

.small-line-height {
  line-height: 12px !important;
}

.asset-item div {
  line-height: 50px;
}

.asset-item .logo img {
  max-width: 50px;
}

.custom-btn {
  width: 75px;
}
@media screen and (max-width: 766px) {
  .operation {
    display: none;
  }
  .header {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .asset-item {
    font-size: 12px;
  }

  .asset-item .logo img {
    max-width: 30px;
  }
}

@media screen and (max-width: 576px) {
  .header {
    font-size: 9px;
  }
}
</style>
