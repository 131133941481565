<template>
  <div class="container">
    <h2 class="mainH2">ارزهای دیجیتال جدید لیدیا</h2>
    <div class="mainBox">
      <div class="coinInfoBox row">
        <!-- table start -->
        <div class="col-12 col-md-7 col-lg-7 flex-col p-0 px-md-3">
          <div class="w-100">
            <Table
              :irtMarkets="irtMarkets"
              :usdtMarkets="usdtMarkets"
              :loaderFlag="loaderFlag"
              :formValues="formValues"
              :fiatType="fiatType"
              :funcChangeSelectedCoin="funcChangeSelectedCoin"
            />
          </div>
          <div class="w-100 d-flex justify-content-md-end">
            <div class="col-12 textCoin">
              <h5>
                ارز دیجیتال {{ formValues.coinInfo.base_asset_name }} ({{
                  formValues.coinInfo.base_asset_ticker
                }})
              </h5>

              <p>
                {{ formValues.coinInfo.base_asset_name }}یکی از ارزهای دیجیتال
                جدید لیدیا است که با نماد
                {{ formValues.coinInfo.base_asset_ticker }} در بازار رمز ارزها
                معامله می‌شود. برای آشنایی بیشتر با این رمز ارز، مقاله
                <router-link
                  :to="'/coin/' + formValues.coinInfo.base_asset_ticker"
                  class="orangeText"
                >
                  خرید و فروش
                  {{ formValues.coinInfo.base_asset_name }}</router-link
                >
                را مطالعه کنید.
              </p>
            </div>
          </div>
        </div>
        <!-- table start -->

        <!-- calc start-->
        <div class="col-12 col-md-5 col-lg-4 p-0">
          <CalculatorCoin
            :usdtMarkets="usdtMarkets"
            :irtMarkets="irtMarkets"
            :formValues="formValues"
            :fiatType="fiatType"
            ref="childRef"
          />
        </div>
        <!-- calc end-->
      </div>

      <!-- another coins start-->
      <div class="row w-100 other-coin-main">
        <div class="other-coin col-12">
          <h3>سایر ارزها</h3>
          <div class="other-coin-parent">
            <div
              v-for="item in otherCoin"
              :key="item.name"
              class="other-coin-box"
            >
              <router-link :to="'coin/' + item.url">
                <img :src="item.img" alt="" class="avatar-size" />

                <span class="text-ellipsis">{{ item.name }}</span></router-link
              >
            </div>
          </div>
        </div>
      </div>

      <!-- another coins end-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CalculatorCoin from "./components/CalculatorCoin.vue";
import Table from "./components/Table/index.vue";
export default {
  name: "newestCoins",
  metaInfo: {
    title: "جدیدترین ارزهای دیجیتال | فبمت، خرید و فروش - لیدیا",

    meta: [
      {
        name: "og:title",
        vmid: "og:title",
        content: "جدیدترین ارزهای دیجیتال | فبمت، خرید و فروش - لیدیا",
      },
      {
        name: "description",
        vmid: "description",
        content:
          "جدیدترین ارزهای دیجیتال موجود در صرافی لیدیا | معرفی آخرین رمزارزها در صرافی | خرید و فروش سریع و آسان جدیدترین رمزارزها",
      },
      {
        name: "og:description",
        vmid: "og:description",
        content:
          "جدیدترین ارزهای دیجیتال موجود در صرافی لیدیا | معرفی آخرین رمزارزها در صرافی | خرید و فروش سریع و آسان جدیدترین رمزارزها",
      },
    ],
  },
  components: {
    Table,
    CalculatorCoin,
  },

  data() {
    return {
      formValues: {
        coinInfo: {},
        priceCoin: null,
        coinNumber: {
          value: null,
        },
        fiatnumber: {
          value: null,
          formattedNumber: "",
        },
      },
      fiatType: {
        name: "IRT",
        imgIRT: "57caf3ea_7105_201105152450.png",
        imgUSTD: "4a8a08f0_5085_201105152029.png",
      },
      irtMarkets: [],
      usdtMarkets: [],
      polling: null,
      loaderFlag: false,
      otherCoin: [
        {
          name: "دوج کوین",
          url: "DOGE",
          img: require("@/assets/images/coins/DOGE.png"),
        },
        {
          name: "بایننس کوین",
          url: "BNB",
          img: require("@/assets/images/coins/BNB.png"),
        },
        {
          name: "تتر",
          url: "USDT",
          img: require("@/assets/images/coins/USDT.png"),
        },
        {
          name: "شیبا",
          url: "SHIB",
          img: require("@/assets/images/coins/SHIB.png"),
        },
        {
          name: "اتریوم",
          url: "ETH",
          img: require("@/assets/images/coins/ETH2.png"),
        },
      ],
    };
  },
  methods: {
    dataArray(data) {
      const arr = Object.keys(data).map((key) => {
        return data[key];
      });

      return arr;
    },
    dataHandler(data) {
      //remove BTTC
      const filterCoins = data.filter(
        (item) => item.base_asset_ticker !== "BTTC"
      );
      const reverseData = filterCoins.reverse();
      const selectedCoins = reverseData.splice(0, 5);
      return selectedCoins;
    },
    updateMarkets(flagMounted) {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=IRT")
        .then((response) => {
          const data = this.dataHandler(this.dataArray(response.data));
          this.irtMarkets = data;
          if (flagMounted) {
            this.formValues.coinInfo = data[0];
            this.formValues.priceCoin = data[0].best_bid_raw;
          }
          this.loaderFlag = false;
        })
        .catch(() => {
          this.loaderFlag = false;
          if (flagMounted) {
            this.formValues.coinInfo = "";
          }
        });

      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=USDT")
        .then((response) => {
          const data = this.dataHandler(this.dataArray(response.data));
          this.usdtMarkets = data;
          this.loaderFlag = false;
        })
        .catch(() => (this.loaderFlag = false));
    },

    pollData() {
      this.polling = setInterval(() => {
        this.updateMarkets(false);
      }, 3000);
    },
    funcChangeSelectedCoin() {
      // Access the child component's function and run it
      return this.$refs.childRef.changeSelectedCoin();
    },
  },

  mounted() {
    this.updateMarkets(true);
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<style scoped>
.flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mainBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.textCoin {
  background-color: #302d2c;
  border: solid 1px #fff;
  border-radius: 13px;
  padding: 20px 10px;
  width: 100%;
  margin: 10px 0;
}
.orangeText {
  font-weight: bolder;
  color: #f8aa0f;
}
.orangeText:hover {
  font-weight: bolder;
  color: #a9802e;
}
.textCoin p {
  text-align: justify !important;
  font-size: 13px;
  margin: 0;
}
/* other-coin */
.other-coin h3 {
  font-weight: bolder;
  padding: 25px 0 0 0;
}
.other-coin {
  margin: 13px 0;
  text-align: center;
  background-color: #302d2c;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 5px;
}

.other-coin-parent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}
.other-coin img {
  width: 50px;
}
.other-coin-main {
  width: 350px !important;
}
.other-coin-box {
  /* padding: 1rem 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100px;
  margin: 10px 0;
}

.other-coin-box a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.other-coin-box span {
  font-size: 0.8rem;
}
.mainH2 {
  padding: 40px 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bolder;
}
@media screen and (min-width: 390px) {
  .textCoin p {
    font-size: 14px;
  }
  .other-coin-main {
    width: 100% !important;
  }
  .other-coin-box {
    width: 100px;
  }
}
@media screen and (min-width: 472px) {
  .other-coin-parent {
    justify-content: space-between;
  }
  .other-coin-box {
    width: 80px;
  }
}
@media screen and (min-width: 700px) {
  .textCoin p {
    font-size: 14px;
  }
  .other-coin-box span {
    font-size: 1rem;
  }
  .other-coin-box {
    width: 100%;
  }

  .other-coin-parent {
    display: grid;
    grid-template-columns: repeat(5, 2fr);
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .textCoin p {
    font-size: 13.5px;
  }
  .other-coin-main {
    width: 700px !important;
  }
  .other-coin-parent {
    grid-template-columns: repeat(5, 2fr);
  }
}
@media screen and (min-width: 992px) {
  .other-coin-main {
    width: 820px !important;
    margin-left: 10px;
  }

  .other-coin img {
    width: 70px;
  }
  .mainH2 {
    padding: 40px 0;
    text-align: center;
    font-weight: bolder;
    font-size: 2rem;
  }
  .textCoin {
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
  }
}
@media screen and (min-width: 1200px) {
  .other-coin-main {
    width: 950px !important;
    margin-left: 40px;
  }
}
</style>
