<template>
  <div class="main">
    version 1019
  </div>
</template>
<script>
export default {
  name: "version",
  data() {
    return {};
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
}
@media screen and (min-width: 990px) {
  .main {
    font-size: 2rem;
    font-weight: bold;
  }
}
</style>
