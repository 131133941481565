<template>
   <div class=" px-4 my-5 ">
          <h2>
            دعوت دوستان
          </h2>

          <div class="row no-padding no-margin">
            <div class="col-12 mt-3 mb-5">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="alert alert-info mt-4">
                    <strong>
                      طرح پاداش لیدیا:
                    </strong>

                    <br />
                    <p class="mt-2">
                      در صورت تمایل می توانید با ارسال یکی از لینک ها یا کدهای
                      دعوت زیر که مختص حساب کاربری شما می باشند از دوستان خود
                      دعوت به عمل آورید و از طرح پاداش لیدیا تا سقف 30% مطابق
                      جدول ذیل بهره مند شوید.
                    </p>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="p-5 text-center m-2 ref-box">
                    <h6>
                      مجموع سود دریافتی
                    </h6>

                    <h4 class="mt-5 text-warning">
                      {{ refCodes.total_commissions | numFormat("0,0") }}
                      تومان
                    </h4>
                  </div>

                  <div class="p-5 text-center m-2 ref-box">
                    <h6>
                      تعداد دعوت شدگان
                    </h6>

                    <h4 class="mt-5 text-warning">
                      {{ refCodes.reffered_users }}
                      نفر
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                v-for="(refCode, index) in refCodes.codes"
                v-bind:key="index"
                class="form-row"
              >
                <div class="form-group col-12 col-md-2">
                  <label for="code">کد دعوت</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      dir="ltr"
                      id="code"
                      disabled
                      readonly
                      :value="refCode.code"
                      class="form-control"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group col-12 col-md-4">
                  <label for="link">لینک دعوت</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      dir="ltr"
                      id="link"
                      disabled
                      readonly
                      :value="`${websiteUrl}/register?ref_code=${refCode.code}`"
                      class="form-control"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group col-12 col-md-4">
                  <label style="visibility: hidden" class="d-none d-md-block"
                    >&nbsp;A</label
                  >
                  <div class="input-group mb-3">
                    <span class="mt-1 ml-2">
                      ( سود شما:
                      {{ refCode.referrer_commission }}% ، سود دعوت شونده:
                      {{ refCode.referree_commission }}% )
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import axios from "axios";


export default {
  name: "Affiliate",
  title: "دعوت دوستان",

  props: {
    msg: String,
  },

  data() {
    return {
      markets: [],
      refCodes: {},
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
      userInfo: this.$session.get("user_info"),
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    getRefCodes() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/ref-codes", {
          withCredentials: true,
        })
        .then((response) => {
          this.refCodes = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
  },
  created() {
    this.checkSession();
    this.updateMarketList();
    this.getRefCodes();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.ref-box {
  width: 250px;
  height: 250px;
  border-radius: 20px;
  background-color: #565656;
  float: left;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}
</style>
