<template>
  <div class="table table-responsive table-hover">
    <table id="datatableusdt" class="table text-white">
      <thead>
        <tr>
          <th class="coinTh"><span> رمزارز </span></th>
          <th>تغییر</th>
          <th>قیمت فروش</th>
          <th class="trade">
            <span class="trade-short">معامله</span
            ><span class="trade-long">معامله حرفه ای</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="market in markets"
          :key="market.identifier"
          class="text-white eachTr"
          @click="changeSelectedCoin(market)"
        >
          <td class="coinTdMain">
            <div class="coinTd">
              <div class="">
                <router-link :to="'/coin/' + market.base_asset_ticker">
                  <img
                    @click="routerPushTr(market.base_asset_ticker)"
                    class="asset-logo"
                    :src="
                      cdnUrl + '/media/asset/logo/md/' + market.base_asset_logo
                    "
                  />
                </router-link>
              </div>
              <div class="coinName">
                <router-link
                  :to="'/coin/' + market.base_asset_ticker"
                  class="w-100"
                >
                  <div class="w-100">
                    <span class="bold text-white coinName">
                      {{ market.base_asset_name }}
                    </span>
                  </div>
                  <div class="bold">({{ market.base_asset_ticker }})</div>
                </router-link>
              </div>
            </div>
          </td>

          <td
            class="ltr bold text-left"
            :class="market.change <= 0 ? 'text-danger' : 'text-success'"
          >
            <div class="flex-xyCenter">
              <!-- <span v-if="market.change > 0">+</span
                  >{{ market.change_display }} % -->
              <img
                class="iconChange"
                src="@/assets/images/home/table/arrowUp.svg"
                alt=""
                v-if="market.change > 0"
              />
              <img
                class="iconChange"
                src="@/assets/images/home/table/arrowDown.svg"
                alt=""
                v-else
              />
              <span class="pr-1"
                >{{ Math.abs(market.change_display).toFixed(2) }} %
              </span>
            </div>
          </td>
          <td>
            <div class="med-size2 center">
              {{ market.best_ask_raw | prec(8) }}
            </div>
          </td>

          <td class="buySellBtn">
            <router-link :to="'/tradeview/' + market.identifier">
              <img
                src="@/assets/images/home/table/buy&sell.svg"
                alt=""
                class="w-50"
              />
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import numeral from "numeral";

export default {
  name: "HomeMarkets",
  props: {
    markets: Array,
    formValues: Object,
    funcChangeSelectedCoin: Function,
    fiatType: Object,
  },
  mounted() {
    this.polling = setInterval(() => {
      return $("#datatableusdt").DataTable({
        bLengthChange: false,
        info: false,
        retrieve: true,
        bPaginate: false,
        bDestroy: true,
        oLanguage: {
          sSearch: "",
          info: "نمایش _START_ از _TOTAL_ رکورد",
          lengthMenu: "نمایش _MENU_ ",
          searchPlaceholder: "جست و جو ارزها...",
        },

        language: {
          paginate: {
            previous: '<span class="prev-icon text-white">قبل</span>',
            next: '<span class="next-icon text-white">بعد</span>',
          },
        },
        columnDefs: [
          {
            type: "html-num-fmt",
            targets: 0,
          },
        ],
        // pageLength: 15,

        searching: false,
        paging: false,
        ordering: false,
        bFooter: false,
      });
    }, 1000);
  },
  data: function() {
    return {
      market: [],
      polling: null,
    };
  },
  methods: {
    goToMarket: function(market) {
      this.$router.push({ name: "Trade", params: { marketId: market } });
    },
    goToTickerInfo: function(market) {
      this.$router.push({ name: "TickerInfo", params: { ticker: market } });
    },
    prec(value, prec) {
      let format = "0,0";
      if (prec > 0) {
        format = `0,0.[${"0".repeat(prec)}]`;
      }
      return numeral(value)
        .format(format)
        .replace(".", "٫");
    },
    routerPushTr(id) {
      return this.$router.push({ name: "TickerInfo", params: { ticker: id } });
    },
    changeSelectedCoin(item) {
      this.fiatType.name = "USDT";

      this.formValues.coinInfo = item;
      this.funcChangeSelectedCoin();
    },
  },
  computed: {
    backendRoot: function() {
      return process.env.VUE_APP_BACKEND_ROOT;
    },
    cdnUrl: function() {
      return process.env.VUE_APP_CDN_URL;
    },
  },

  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  beforeDestroy() {
    $("#datatableirtCoin")
      .DataTable()
      .destroy();

    clearInterval(this.polling);
  },
};
</script>

<style scoped>
.dataTable > thead > tr > th[class*="sort"]:before,
.dataTable > thead > tr > th[class*="sort"]:after {
  content: "" !important;
}
table {
  background-color: inherit;
}
.price-hidden {
  display: none;
}

.dataTables_empty {
  display: none;
}

.coin-titles,
.coin-titles a,
.coin-titles a:visited {
  color: #ffa500;
}

#data-table {
  margin: auto;
}

#datatableusdt {
  background-color: inherit;
}

#datatable.input {
  display: hidden;
}

.card {
  background-color: #302d2c;
  padding: 20px 0px 0px 0px !important;
  border-radius: 20px !important;
  border-color: white;
  box-shadow: 0 0 20px;
  text-align: justify;
}

.trade-btn {
  color: white !important;
  border: 1px solid white;
  font-size: 16px;
  border-radius: 50px !important;
  background-color: #302d2c !important;
  padding: 5px 14px;
  width: 90% !important;
}

.trade-btn:hover {
  background: #202020 !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.markets {
  color: #666;
  background-color: #fff;
  min-height: 150px;
  border-radius: 10px 0 10px 10px;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: rgba(41, 41, 41, 0.3);
}

.markets-header {
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.markets-header div {
  margin: 4px;
}

.market-item {
  border-bottom: 1px solid #e6e8f6;
  line-height: 40px;
}

.markets .market-item:last-child {
  border-bottom: 0;
}

.markets .market-item:hover {
  background-color: #e6e8f6;
}

.asset-logo {
  max-width: 35px;
}

.market-cells {
  padding: 10px;
  margin: auto;
  text-align: center;
}

.coin-title,
.coin-title a,
.coin-title a:visited {
  color: #3a4aa9;
}

/* .mmm:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03) !important;
  } */

.dataTables_filter input {
  background: #4a4a48;
  border: 1px solid #aaa;
  border-radius: 50px !important;
  box-shadow: 0 0 0px !important;
  text-indent: 10px;
  color: white;
  margin: 10px 20px 0px 20px;
}

table.dataTable thead > tr > th.sorting {
  text-align: right;
  width: 60px;
}

.table td,
.table th {
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  vertical-align: unset;
}

::placeholder {
  color: white;
}

div.dataTables_length select {
  color: white !important;
  background-color: 4a4a48 !important;
  margin-left: 30px;
}

.buySellBtn img {
  width: 25px !important;
}
#datatableusdt {
  font-size: 13px;
}

@media screen and (max-width: 991px) {
  table.dataTable thead > tr > th.sorting {
    text-align: right;
    width: 40px;
  }

  .price-hidden {
    display: block;
  }

  .trade-btn {
    color: white !important;
    border: 1px solid white;
    font-size: 14px;
    border-radius: 10px;
    width: 80% !important;
  }

  .market-item {
    font-size: 12px;
  }

  .markets-header {
    font-size: 10px;
  }

  .market-cells {
    padding: 0px 13px;
    margin: auto;
    text-align: center;
  }

  .asset-logo {
    max-width: 30px;
  }

  #datatableusdt {
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    width: -moz-available !important;
  }

  .coinName {
    font-size: 0.7rem;
    flex-grow: 1 !important;
  }
}
@media screen and (max-width: 600px) {
  /* .big-size {
      width: 130px;
      text-align: center;
    }
  
    .med-size {
      width: 70px;
      text-align: center;
    } */
  .med-size2 {
    width: 80px;
    text-align: center;
  }

  .eachTr::after {
    height: 3px;
  }
}
/* new */
.gap-half {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}
.flex-xyCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-yend {
  display: flex;
  justify-content: end;
  align-items: center;
}

.coinTd {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-size: 0.7rem;
}

table.dataTable thead th {
  border: none;
}
table.dataTable thead th {
  border: none;
  padding: 15px 30px 5px 0;
  text-align: start;
}
table.dataTable.no-footer {
  border-bottom: 0 !important;
}
.iconChange {
  width: 6px;
}

table.dataTable thead th {
  text-align: center;
}
table.dataTable tbody tr td {
  border: none;
  text-align: center;
}
table {
  border-collapse: collapse;
}
.eachTr {
  position: relative;
  border-bottom: none;
}

.eachTr::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.2px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 40%,
    rgba(48, 45, 44, 0) 99%
  );
}
/* new */
.table {
  width: 100% !important;
  margin: 0 !important;
}
table.dataTable {
  width: 500px !important;
}
.eachTr {
  width: 100%;
  cursor: pointer;
}

.eachTr td {
  width: 25%;
  /* padding: 25px 10px !important; */
}
.iconChange {
  width: 6px;
}
.coinName {
  flex-grow: 1 !important;
  text-align: start;
}
.coinTdMain {
  width: 25% !important;
}
th {
  padding: 10px 30px !important;
  text-align: center !important;
  width: inherit;
}
.coinTh {
  text-align: start !important;
}
table.dataTable tbody tr td {
  border: none;
  text-align: center;
}
table.dataTable {
  width: 100% !important;
  margin: 0 !important;
}
@media screen and (max-width: 768px) {
  .eachTr td {
    width: 23%;
    padding: 10px 10px !important;
  }
  .buySellBtn {
    width: 20% !important;
  }
  .eachTr .coinTdMain {
    width: 30% !important;
  }

  .trade-short {
    display: block;
  }
  .trade-long {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  /* md */
  .eachTr td {
    width: 25%;
    padding: 10px 10px !important;
  }
  .buySellBtn {
    width: 20% !important;
  }
  .eachTr .coinTdMain {
    width: 30% !important;
  }

  .trade-short {
    display: block;
  }
  .trade-long {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  table.dataTable thead th {
    padding: 10px 0px !important;
    text-align: center !important;
    font-size: 0.7rem;
  }

  .eachTr td {
    width: 20% !important;
    padding: 10px 5px !important;
  }
  .eachTr .buySellBtn {
    width: 10% !important;
  }
  .eachTr .coinTdMain {
    width: 40% !important;
  }
  table.dataTable thead .coinTh {
    text-align: start !important;
    padding: 0 20px 0 0 !important;
  }

  /* .trade{
    display: none;
  } */
}
@media screen and (min-width: 600px) {
  .table {
    width: 100% !important;
    margin: 0 !important;
  }
  .eachTr::after {
    height: 3px;
  }
  table.dataTable {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .eachTr td {
    width: 25%;
  }

  .eachTr td {
    width: 20%;
    padding: 8px 10px !important;
  }
  .eachTr .buySellBtn {
    width: 20% !important;
  }
  .eachTr .coinTdMain {
    width: 40% !important;
  }
  .trade-short {
    display: block;
  }
  .trade-long {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  table.dataTable thead th {
    font-size: 0.9rem;
  }
  .iconChange {
    width: 10px;
  }
  .eachTr td {
    width: 20%;
    padding: 7px 10px !important;
  }
  .eachTr .buySellBtn {
    width: 30% !important;
  }
  .eachTr .coinTdMain {
    width: 35% !important;
  }
  .trade-short {
    display: none;
  }
  .trade-long {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .eachTr td {
    width: 22%;
    padding: 9px 10px !important;
  }
  .coinTdMain {
    width: 30% !important;
  }
}
th {
  font-weight: 200 !important;
  /* padding: 0px !important; */
  /* font-size: 5px; */
}
th {
  padding: 5px !important;
}
</style>
