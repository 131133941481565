<template>
  <div id="content">
        <div class="m-4">
          <h2>تاریخچه</h2>

          <div class="container">
            <div class="row">
              <div class="col-6 col-md-3 mb-2">
                <div class="dropdown position-relative">
                  <button
                    class="btn btn-warning dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ selectedAsset.name }}
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right dropdown-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      v-for="asset in assets"
                      v-bind:key="asset.ticker"
                      class="dropdown-item"
                      href="#"
                      @click="selectAsset(asset.ticker)"
                      >{{ asset.name }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3 mb-2">
                <div class="dropdown">
                  <button
                    class="btn btn-warning dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ selectedTransactionType.name }}
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      v-for="txType in transactionTypes"
                      v-bind:key="txType.value"
                      class="dropdown-item"
                      href="#"
                      @click="selectTxType(txType.value)"
                      >{{ txType.name }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3 mb-2">
                <date-picker
                  color="#4263eb"
                  v-model="startDate"
                  label="از تاریخ"
                  input-class="form-control form-control-sm"
                  @change="getOrders"
                  :max="this.today"
                ></date-picker>
              </div>
              <div class="col-6 col-md-3 mb-2">
                <date-picker
                  color="#4263eb"
                  v-model="endDate"
                  label="تا تاریخ"
                  input-class="form-control form-control-sm"
                  @change="getOrders"
                  :value="this.today"
                  :max="this.today"
                ></date-picker>
              </div>
            </div>

            <div class="box p-2 mt-3">
              <div
                class="vld-parent table-responsive"
                ref="tableContainer"
                style="min-height: 200px"
              >
                <HistoryTable
                  v-if="history != null"
                  :history="history"
                  :assets="assets"
                />
              </div>
              <div class="pagination">
                <paginate
                  :pageCount="pageCount"
                  :clickHandler="getOrders"
                  :prevText="'قبلی'"
                  :nextText="'بعدی'"
                  :containerClass="'pagination'"
                  :page-class="'page-item'"
                  :page-link-class="'page-link'"
                  :active-class="'page-item active'"
                  :next-class="'page-item'"
                  :next-link-class="'page-link'"
                  :prev-class="'page-item'"
                  :prev-link-class="'page-link'"
                  v-model="page"
                >
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import axios from "axios";
import HistoryTable from "./HistoryTable";
import moment from "moment-jalaali";

export default {
  name: "History",
  title: "تاریخچه",

  props: {
    msg: String,
  },
  components: {
    HistoryTable,
    
  },
  data() {
    return {
      markets: [],
      assets: [],
      selectedAsset: { name: "تمامی ارزها", ticker: "all" },
      transactionTypes: [
        { name: "تمامی تراکنش‌ها", value: "all" },
        { name: "واریز", value: "deposit" },
        { name: "برداشت", value: "withdraw" },
        { name: "ترید", value: "trade" },
        { name: "کارمزد", value: "fee" },
        { name: "سود معرفی", value: "commission" },
      ],
      selectedTransactionType: { name: "تمامی تراکنش‌ها", value: "all" },
      startDate: null,
      endDate: null,
      history: null,
      page: null,
      pageCount: 1,
      today: "",
    };
  },
  computed: {
    irtMarkets: function () {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function () {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    selectAsset(ticker) {
      this.selectedAsset = this.assets.find(
        (element) => element.ticker === ticker
      );
      this.getOrders();
    },
    selectTxType(value) {
      this.selectedTransactionType = this.transactionTypes.find(
        (element) => element.value === value
      );
      this.getOrders();
    },

    getOrders() {
      let loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
        bars: "dots",
      });

      let url = `${process.env.VUE_APP_BACKEND_URL}/user/balance-history`;
      let filters = "";
      if (this.selectedAsset.ticker !== "all") {
        filters += filters !== "" ? "&" : "";
        filters += `asset=${this.selectedAsset.ticker}`;
      }
      if (this.selectedTransactionType.value !== "all") {
        filters += filters !== "" ? "&" : "";
        filters += `type=${this.selectedTransactionType.value}`;
      }

      if (this.startDate !== null) {
        filters += filters !== "" ? "&" : "";
        const m = moment(this.startDate, "jYYYY/jMM/jDD");
        filters += `start=${m.unix()}`;
      }

      if (this.endDate !== null) {
        filters += filters !== "" ? "&" : "";
        const m = moment(this.endDate + "23:59:59", "jYYYY/jMM/jDD HH:mm:ss");
        filters += `end=${m.unix()}`;
      }

      const limit = 20;

      if (this.page !== null) {
        const offset = (this.page - 1) * limit;
        filters += filters !== "" ? "&" : "";
        filters += `offset=${offset}&limit=${limit}`;
      }

      if (filters !== "") {
        url = `${url}?${filters}`;
      }

      axios
        .get(url, { withCredentials: true })
        .then((response) => {
          this.history = response.data;
          const pageCount = Math.ceil(response.data.total / limit);
          this.pageCount = pageCount > 0 ? pageCount : 1;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          // console.log(e)
        });
    },

    updateAssetList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/asset/list")
        .then((response) => {
          this.assets = response.data;
          this.assets.unshift({ name: "تمامی ارزها", ticker: "all" });
        })
        .catch(() => {
          // console.log(e)
        });
    },
    handleDate() {
      const currentDate = new Date();
      //today
      const shamsiDate = moment(currentDate).format("jYYYY/jMM/jDD");
      this.today = shamsiDate;
      this.endDate = shamsiDate;
      //tenDays
      const tenDaysAgo = new Date(currentDate);
      tenDaysAgo.setDate(currentDate.getDate() - 10);
      // Convert the 10 days ago date to the Shamsi date
      const shamsiDateTenDaysAgo = moment(tenDaysAgo).format("jYYYY/jMM/jDD");
      this.startDate = shamsiDateTenDaysAgo;
    },
  },
  created() {
    this.checkSession();
    this.updateMarketList();
    this.updateAssetList();
  },

  mounted() {
    this.handleDate();
    this.getOrders();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
[dir="rtl"] .dropdown-menu {
  right: auto !important;
  left: inherit;
  text-align: right;
}

.dropdown-toggle {
  width: 150px;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

.dropdown-toggle:after {
  left: 4px !important;
}

.nav-item {
  margin-right: 0 !important;
}

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}

.dropdown-custom {
  position: absolute !important;
  height: 300px !important;
  overflow: scroll !important;
  position: absolute !important;
}
@media screen and (min-width: 600px) {
  .dropdown-custom {
    height: 400px !important;
  }
}

/* new */
</style>
