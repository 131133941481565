<template>
  <div id="content">
    <div class="" id="exampleModalCenter">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle">احراز هویت</h4>
            <router-link
              to="/user/profile"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </router-link>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-sm-12 col-md-6">
                <div class="row">
                  <p>
                    احراز هویت امری ضروری جهت تامین و ارتقای امنیت حساب کاربری
                    شما می باشد و برای انجام فعالیت های مالی در لیدیا لازم
                    است. با تکمیل مراحل احراز ،به سادگی احراز هویت کنید.
                  </p>
                </div>
                <div class="row">
                  <img
                    class="logo-site-identify"
                    src="../assets/images/identify.png"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12 col-md-6">
                <div class="card m-auto">
                  <div class="m-auto">
                    <button @click="quickly2" disabled class="btn card-btn ">
                      احراز هویت سریع
                    </button>
                    <button @click="normal2" class="btn card-btn2 active">
                      احراز هویت معمولی
                    </button>
                  </div>
                  <div id="identify-quickly2">
                    <div>
                      <ul>
                        <li>تایید حساب طی ۵ دقیقه</li>
                        <li>سریع و امن</li>
                        <li>بدون نیاز به سلفی با تعهدنامه</li>
                        <li>تنها مدرک مورد نیاز، کارت بانکی و کارت ملی</li>
                      </ul>
                    </div>
                    <div class="row m-auto">
                      <button @click="identify" disabled class="btn card-btn3">
                        شروع <small class="text-danger">(غیرفعال)</small>
                      </button>
                    </div>
                  </div>

                  <div id="identify-normal2">
                    <div>
                      <ul>
                        <li>ثبت اطلاعات فردی</li>
                        <li>بارگذاری کارت بانکی</li>
                        <li>بارگزاری سلفی و تعهد نامه</li>
                        <li>تایید حساب کاربری</li>
                      </ul>
                    </div>
                    <div class="row m-auto">
                      <router-link
                        class="btn card-btn3"
                        to="/user/identity-verify"
                        >شروع</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="row m-auto">
                  <router-link class="btn card-btn4" to="/user/profile"
                    >بعدا احراز هویت میکنم</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">بستن</button>
            <button @click="identify" type="button" class="btn btn-success">شروع</button>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MobileVerify",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      markets: [],
      token: "",
      mobile: "",
      isDisabled: false,
      resent: false,
      session: this.$session,
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    quickly2(e) {
      e.preventDefault();
      document.getElementById("identify-quickly2").style.display = "block";
      document.getElementById("identify-normal2").style.display = "none";
    },

    normal2(e) {
      e.preventDefault();
      document.getElementById("identify-quickly2").style.display = "none";
      document.getElementById("identify-normal2").style.display = "block";
    },

    identify(e) {
      e.preventDefault();

      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/authentication", {
          withCredentials: true,
        })
        .then((response) => {
          //console.log(response);
          window.location.replace(
            "https://cloud.uid.ir/crypto/?requestBusinessId=b28d10eb-f1683454246835&metaData=" +
              response.data
          );
          // this.stars = response.data.fee_group.stars;
          // this.clearForm();
        })
        .catch(() => {
          // console.log(e)
        });
      // this.userInfo = this.$session.get('user_info');
      // this.id = this.userInfo.id;
      // console.log(this.id);

      //window.location.replace("https://cloud.uid.ir/crypto/?requestBusinessId=b28d10eb-f1683454246835&metaData=1000");
      // axios.get(process.env.VUE_APP_BACKEND_URL + '/user/authentication', {withCredentials: true})
      //     .then(response => {
      //         console.log(response);
      //         // this.stars = response.data.fee_group.stars;
      //         // this.clearForm();
      //     })
      //     .catch(() => {
      //         // console.log(e)
      //     });
    },

    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    checkKycLevel(userInfo) {
      if (userInfo.kyc_step === 0) {
        return this.$router.push({ name: "EmailVerify" });
      } else if (userInfo.kyc_step === 2) {
        return this.$router.push({ name: "TypeVerify" });
      } else if (userInfo.kyc_step === 3) {
        return this.$router.push({ name: "Profile" });
      } else if (userInfo.kyc_step === 4) {
        return this.$router.push({ name: "Profile" });
      }
    },
  },
  created() {
    if (this.session.exists("user_info")) {
      this.mobile = this.session.get("user_info").mobile;
      this.checkKycLevel(this.session.get("user_info"));
    } else {
      return this.$router.push({ name: "Login" });
    }
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.hint {
  font-size: 11px;
}

.modal-content {
  background-color: #302d2c;
  border: 2px solid #f8aa0f;
  box-shadow: 0px 0px 20px #f8aa0f;
  border-radius: 16px;
  width: 100%;
}

.logo-img {
  width: 25px;
  background-color: transparent;
}

#identify-quickly2 {
  display: block;
  margin: auto;
}

#identify-normal2 {
  display: none;
  margin: auto;
}

.modal-header {
  border: unset;
}

.modal-body .card {
  background: #4a4a48;
  border: 1px solid #ffffff;
  border-radius: 16px;
  width: 340px;
  box-shadow: unset;
  height: 330px;
}

.modal-body p {
  font-size: 16px;
  text-align: justify;
  margin: auto;
  padding: 12px;
}

.modal-body {
  padding-bottom: 0rem;
}

.modal-dialog {
  max-width: 850px;
  margin: 0px auto;
}

.logo-site-identify {
  width: 460px;
  margin-right: 15px;
}

.card-row {
  border-bottom: 1px solid #666;
}

.card-row:before {
  border-top: 1px solid #666;
}

.card-btn {
  background: #1e1919;
  border: 1px solid #ffffff;
  border-radius: 0px 8px 8px 0px;
  color: white;
  font-size: 14px;
  margin: auto;
  margin: 15px 0px;
}

.card-btn2 {
  background: #1e1919;
  border: 1px solid #ffffff;
  border-radius: 8px 0px 0px 8px;
  color: white;
  font-size: 14px;
  margin: auto;
  margin: 15px 0px;
}

.card-btn3 {
  background: #302d2c;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  margin: auto;
  width: 250px;
  margin: 15px 0px;
}

.card-btn4 {
  background: #4a4a48;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  margin: auto;
  width: 340px;
  margin: 30px auto;
}

.card-btn.active,
.card-btn.selected {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn4:hover {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn2:hover {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn3:hover {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn:focus {
  background-color: transparent;
  color: #f8aa0f;
}

.card-btn2:focus {
  background-color: transparent;
  color: #f8aa0f;
}

.card ul {
  font-size: 15px;
  margin: 10px 0px;
  padding: 0px;
}

.card ul li {
  margin: 10px 0px;
}

.hint a {
  color: #f7a906;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.login-btn {
  width: 200px;
  border-radius: 20px;
}

.static {
  background: url("../assets/images/home-page-background.png") no-repeat center
    top;
  background-size: 100%;
  min-height: 500px;
}

.static .content {
  margin-top: 50px;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  width: 400px;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0.5);
}

#exampleModalCenter {
  display: block;
}
.nav-item {
  border-bottom: 4px solid #f7a906;
  color: #f7a906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: #fff !important;
  color: #f7a906 !important;
  border: 0;
}

.input-group-append .input-group-text {
  background-color: #fff !important;
  color: #000 !important;
  border: 0;
}

.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

@media screen and (max-width: 768px) {
  .logo-site-identify {
    width: 400px;
    margin: auto;
    margin-right: 104px;
  }

  .modal-header {
    border: unset;
    margin: unset;
    padding: 8px 10px 0px 10px;
  }

  .modal-header h4 {
    font-size: 20px;
  }

  .modal-body .card {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 16px;
    width: 400px;
    margin: 10px 0px;
  }

  .modal-body p {
    font-size: 16px;
    text-align: justify;
    margin: auto;
    padding: 12px;
  }

  .modal-dialog {
    max-width: 550px;
    margin: 2rem auto;
  }

  .card-btn {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 0px 8px 8px 0px;
    color: white;
    font-size: 13px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn2 {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 8px 0px 0px 8px;
    color: white;
    font-size: 13px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn3 {
    background: #302d2c;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    margin: auto;
    width: 250px;
    margin: 15px 0px;
  }

  .card-btn4 {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    margin: auto;
    width: 290px;
    margin: 30px auto;
  }

  .card-btn:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn2:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn3:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }

  .card-btn:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card-btn2:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card ul {
    font-size: 14px;
    margin: 10px 0px;
    padding: 0px;
  }

  .modal-content {
    padding: 0px 0px 20px 0px;
  }
}

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }

  .dropdown-menu.profile {
    margin-right: 0px !important;
  }

  .avatar {
    margin-left: 0 !important;
  }

  .logo-site-identify {
    width: 270px;
    margin: auto;
    margin-right: 53px;
  }

  .modal-header {
    border: unset;
    margin: unset;
    padding: 8px 10px 0px 10px;
  }

  .modal-header h4 {
    font-size: 16px;
  }

  .modal-body .card {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 16px;
    width: 300px;
    margin: 10px 0px;
  }

  .modal-body p {
    font-size: 13px;
    text-align: justify;
    margin: auto;
    padding: 12px;
  }

  .modal-dialog {
    max-width: 350px;
    margin: 2rem auto;
  }

  .card-btn {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 0px 8px 8px 0px;
    color: white;
    font-size: 11px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn2 {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 8px 0px 0px 8px;
    color: white;
    font-size: 11px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn3 {
    background: #302d2c;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    margin: auto;
    width: 250px;
    margin: 15px 0px;
  }

  .card-btn4 {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    margin: auto;
    width: 290px;
    margin: 30px auto;
  }

  .card-btn:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn2:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn3:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }

  .card-btn:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card-btn2:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card ul {
    font-size: 13px;
    margin: 10px 0px;
    padding: 0px;
  }

  .modal-content {
    padding: 0px 0px 20px 0px;
  }
}
</style>
