<template>
    <div>
        <div class="wrapper d-flex">

            <!-- Page Content  -->
            <div id="content">
                <div class="m-4">
                    <h2>
                        فعال سازی رمز دو عاملی
                    </h2>
                    <div class="container">
                        <!-- Button trigger modal -->


                        <div class="box p-5 mt-3 box">
                            جهت فعال‌سازی رمز دو عاملی برای حساب خود، ابتدا اپلیکیشن موبایل Google Authenticator را روی
                            گوشی خود نصب نمایید.
                            <br/>
                            می‌توانید این اپلیکیشن را از
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                               target="_blank">
                                گوگل پلی
                            </a>
                            یا
                            <a href="https://cafebazaar.ir/app/com.google.android.apps.authenticator2/" target="_blank">
                                کافه بازار
                            </a>
                            یا
                            <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                                اپل استور
                            </a>
                            نصب نمایید.


                            <div v-if="otpInfo !== null && otpInfo.is_otp_active" class="pt-5 text-center">
                                <div class="alert alert-success">
                                    رمز دو عاملی برای حساب کاربری شما فعال شده است
                                </div>


                                <button type="button" class="btn btn-danger" data-toggle="modal"
                                        data-target="#modalCenter" @click="clearOtp()">
                                    غیرفعال سازی رمز دوعاملی
                                </button>

                                <!-- Modal -->

                            </div>
                            <div v-else-if="otpInfo !== null && !otpInfo.is_otp_active" class="mt-4">
                                لطفا QR کد زیر را توسط اپلیکیشن Google Authenticator گوشی خود اسکن نمایید.

                                <div class="alert alert-warning mt-2">
                                    دقت کنید که پس از تایید کد، ورود به حساب شما علاوه بر گذرواژه نیاز به وارد کردن رمز
                                    دو عاملی نیز خواهد داشت. در نتیجه حتما از QR کد زیر تصویری تهیه کنید و به صورت امن
                                    ذخیره‌سازی کنید، یا عبارت زیر را روی یک کاغذ نوشته و به صورت امن نگهداری نمایید.
                                    <pre class="otp-token mt-3 text-center">{{otpInfo.otp_token}}</pre>

                                </div>

                                <div class="text-center pt-3">
                                    <qrcode-vue :value="otpInfo.otp_qr" size="200" level="H"></qrcode-vue>
                                    <p style="font-size: 11px">
                                        پس از اسکن QR کد، مورد مربوط به لیدیا در Google Authenticator ایجاد خواهد شد.
                                        <br>
                                        برای تایید صحت
                                        فرآیند،
                                         کدی که در 
                                         پیامک و
                                        Google Authenticator 
                                        مشاهده می‌کنید را در کادرهای زیر وارد
                                        نمایید.
                                    </p>
                                    <form @submit.prevent="submitForm" ref="form">
                                        <div class="form-group">
                                            <label for="smsChangeOtpInput">کد شناسایی تلفن همراه</label>
                                            <div class="input-group otp-form m-auto">
                                                <input autofocus type="tel" class="form-control latin" maxlength="6"  v-model="smsCode" id="smsChangeOtpInput">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-info" type="button" @click="smsChangeOtpBtn" id="smsChangeOtpBtn">دریافت کد</button>
                                                </div>
                                            </div>
                                            <small id="smsHelp" class="form-text text-muted"> لطفا کد ۶ رقمی که به تلفن همراه شما <label class="latin ltr"></label> ارسال می‌گردد را وارد نمایید.</small>
                                        </div>
                                        <label for="smsChangeOtpInput">کد شناسایی رمز دو عاملی</label>

                                        <div class="input-group otp-form">
                                            <input class="form-control" dir="ltr" maxlength="6" id="qr-code"
                                                   v-model="otpCode" autocomplete="off">
                                            <div class="input-group-append">
                                                <span class="input-group-text">کد ۶ رقمی</span>
                                            </div>
                                            <small id="smsHelp" class="form-text text-muted"> لطفا رمز ۶ رقمی دریافتی از اپلیکیشن Google Authenticator را وارد نمایید.</small>
                                        </div>

                                        <div class="input-group mt-5">
                                            <input type="submit" class="btn btn-success m-auto" value="تایید">
                                        </div>
                                  
                                    </form>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modalCenter" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle"
             aria-hidden="true" data-backdrop="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalLongTitle">غیر فعالسازی رمز دو عاملی</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit="disableOtp">
                        <div class="modal-body">
                            <div class="input-group otp-form-disable">
                                <input class="form-control" dir="ltr" maxlength="6" id="qr-code0" v-model="otpCode"
                                       autocomplete="off">
                                <div class="input-group-append">
                                    <span class="input-group-text">کد ۶ رقمی</span>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">انصراف</button>
                            <input type="submit" class="btn btn-danger" @click="disableOtp"
                                   value="غیر فعالسازی رمز دو عاملی">
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    import QrcodeVue from 'qrcode.vue'
    import $ from 'jquery'


    export default {
        name: 'OtpActivate',
        title: 'فعال سازی رمز دو عاملی',
        props: {
            msg: String
        },
        components: {
          
            QrcodeVue
        },
        data() {
            return {
                markets: [],
                otpInfo: null,
                otpCode: null,
            }
        },
        /*        watch: {
                    'otpCode' : function (val) {
                        if(val.length === 6){
                            this.$refs.form.submit();
                        }
                    }
                },*/
        computed: {
            irtMarkets: function () {
                return Object.values(this.markets).filter((m) => m.quote_asset_ticker === "IRT")
            },
            usdtMarkets: function () {
                return Object.values(this.markets).filter((m) => m.quote_asset_ticker === "USDT")
            },

        },
        methods: {
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },
            smsChangeOtpBtn(){
                let counter = 60;
                const mailCountdown = setInterval(() => {
                     const _smsChangeOtpBtn = document.getElementById("smsChangeOtpBtn");
                    _smsChangeOtpBtn.setAttribute("disabled", "disabled");
                    counter--;
                    _smsChangeOtpBtn.textContent = `کد ارسال شد`;
                    if (counter <= 0) {
                        clearInterval(mailCountdown);
                        _smsChangeOtpBtn.removeAttribute("disabled");
                        _smsChangeOtpBtn.textContent = 'ارسال مجدد کد'
                    }

                }, 1000);

                axios.post(process.env.VUE_APP_BACKEND_URL + '/user/createotpsms', {}, {withCredentials: true})
                    .then((response) => {
                        console.log(response);
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },
            checkSession() {

                if (!this.$session.exists('user_info')) {
                    this.$session.destroy();
                    return this.$router.push('/login').catch(() => {
                    });
                }
            },
            updateUserInfo() {
                if (this.$session.exists('user_info')) {
                    axios.get(process.env.VUE_APP_BACKEND_URL + '/user/info', {withCredentials: true})
                        .then(response => {
                            this.userInfo = response.data;
                            this.$session.set('user_info', this.userInfo);
                        })
                        .catch(() => {
                            this.$session.destroy();
                        });
                }
            },
            updateMarketList() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/market/list')
                    .then(response => {
                        this.markets = response.data;
                    })
                    .catch(() => {
                        // console.log(e)
                    });

            },

            updateOtpInfo() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/otp', {withCredentials: true})
                    .then(response => {
                        console.log(response.data);
                        this.otpInfo = response.data;
                    })
                    .catch(() => {
                        // console.log(e)
                    });

            },

            submitForm(e) {
                e.preventDefault();

                if (this.otpCode === null || this.otpCode.length < 6) {
                    this.notice('error', 'خطا', 'لطفا کد دریافتی از اپلیکیشن Google Authenticator را به درستی وارد نمایید', 5);
                    return;
                }

                if (this.smsCode === null || this.smsCode.length < 6) {
                    this.notice('error', 'خطا', 'لطفا کد دریافتی از طریق پیامک را به درستی وارد نمایید.', 5);
                    return;
                }


                this.notice('info', 'در حال فعال سازی رمز دو عاملی ...');

                const otpInfo = {
                    activate: true,
                    otp_code: this.otpCode,
                    sms_code: this.smsCode
                };

                axios.post(process.env.VUE_APP_BACKEND_URL + '/user/otp', otpInfo, {withCredentials: true})
                    .then((response) => {
                        this.notice('success', 'رمز دو عاملی با موفقیت فعال شد');
                        this.otpInfo = response.data;
                        this.updateUserInfo();
                        this.otpCode = '';
                        this.smsCode = '';
                    })
                    .catch(e => {
                        this.notice('error', 'خطا', e.response.data.error, 5);
                    });


            },

            clearOtp() {
                this.otpCode = '';
                this.smsCode = '';
            },

            disableOtp(e) {
                e.preventDefault();

                if (this.otpCode === null || this.otpCode.length < 6) {
                    this.notice('error', 'خطا', 'لطفا کد دریافتی از اپلیکیشن Google Authenticator را به درستی وارد نمایید', 5);
                    return;
                }


                this.notice('info', 'در حال غیر فعال سازی رمز دو عاملی ...');


                const otpInfo = {
                    activate: false,
                    otp_code: this.otpCode,
                };

                axios.post(process.env.VUE_APP_BACKEND_URL + '/user/otp', otpInfo, {withCredentials: true})
                    .then((response) => {
                        this.notice('success', 'رمز دو عاملی با موفقیت غیر فعال شد');
                        this.otpInfo = response.data;
                        this.otpCode = '';
                        this.updateUserInfo();
                        $('#modalCenter').modal('hide');
                    })
                    .catch(e => {
                        this.notice('error', 'خطا', e.response.data.error, 5);
                    });


            },


        },
        created() {
            this.updateMarketList();
            this.updateOtpInfo();
            this.checkSession();

        },
        mounted() {


        }

    }
</script>
<style lang="scss" scoped>
    .app {
        text-align: center;

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            background-color: #222;
            color: #fff;
        }

        &__title {
            display: block;
            font-size: 1.5em;
        }
    }
</style>
<style scoped>

    .wrapper {
        width: 100%;
    }

    #content {
        width: 100%;
        display: block;
        padding: 0;
        min-height: 100vh;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
    }

    .otp-form {
        margin: 10px auto;
        max-width: 400px;
    }

    .otp-form-disable {
        margin: 10px auto;
        max-width: 450px;
    }

    .box {
        color: #000;
        background-color: #fff;
        border-radius: 10px;
    }


    .box a {
        color: #3A4AA9;
    }

    .btn-warning, .btn-danger {
        min-width: 250px;
        color: #000 !important;
    }

    .btn-danger {
        color: #fff !important;
    }

    .otp-token {
        color: #000;
        font-size: 18px;
        font-weight: bold;
    }
</style>
