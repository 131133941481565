import Vue from "vue";
import VueRouter from "vue-router";
import MobileVeify from '../components/MobileVerify'
import Home from "../components/HomePage";
// import Terms from "../components/Terms";
// import About from "../components/About";
// import HowToUse from "../components/HowToUse";
// import Fees from "../components/Fees";
import Login from "../components/Login";
import VideoRecord from "../components//VideoRecorder.vue";
import ForgotPassword from "../components/ForgotPassword";
import Profile from "../components/Profile";
import EmailVerify from "../components/EmailVerify";
import Trade from "../components/Trade";
import NotFound from "../components/NotFound";
import OtpVerify from "../components/OtpVerify";
import Wallet from "../components/Wallet.vue";
import BankInfo from "../components/bankInfo.vue";
import WalletDeposit from "../components/WalletDeposit";
import Affiliate from "../components/Affiliate";
import Orders from "../components/Orders";
import History from "../components/History";
import FiatVerify from "../components/FiatVerify";
import IdentityVerify from "../components/IdentityVerify";
import LoginLog from "../components/LoginLog";
import WalletWithdraw from "../components/WalletWithdraw";
import NotVerified from "../components/NotVerified";
import OtpNotActive from "../components/OtpNotActive";
import OtpActivate from "../components/OtpActivate";
import Settings from "../components/Settings";
import ResetPassword from "../components/ResetPassword";
import ChangePassword from "../components/ChangePassword";
import IdentityVerifySubmitted from "../components/IdentityVerifySubmitted";
import Announcements from "../components/Announcements";
import Announcement from "../components/Announcement";
import Messages from "../components/Messages";
import Message from "../components/Message";
// import Contact from "../components/Contact";
// import Faq from "../components/Faq";
import Withdraw from "../components/Withdraw";
import TickerInfo from "../components/TickerInfo";
// import CoinPrice from "../components/CoinPrice";
// import FriendsInvite from "../components/FriendsInvite";
// import CareerOpportunities from "../components/CareerOpportunities";
// import ApplicationDownload from "../components/ApplicationDownload";
import ForgetTfa from "../components/ForgetTfa";
import ForgetTfaVerify from "../components/ForgetTfaVerify";
import WebCallback from "../components/WebCallback";
import TypeVerify from "../components/TypeVerify";
import MobileVerifyNew from "../components/MobileVerifyNew";

import FastTradeUser from "../components/user/FastTrade.vue";
import FastTrade from "../components/FastTrade.vue";
import ContactManager from "../components/ContactManager.vue";
import NewestCoins from "../components/newestCoins";
import Version from "../components/Version.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //
  // {
  //   path: "/coin-price",
  //   name: "CoinPrice",
  //   component: CoinPrice,
  // },

  {
    path: "/forget-tfa",
    name: "ForgetTfa",
    component: ForgetTfa,
  },

  // {
  //   path: "/application-download",
  //   name: "ApplicationDownload",
  //   component: ApplicationDownload,
  // },
  //
  // {
  //   path: "/career-opportunities",
  //   name: "CareerOpportunities",
  //   component: CareerOpportunities,
  // },
  //
  // {
  //   path: "/friends-invite",
  //   name: "FriendsInvite",
  //   component: FriendsInvite,
  // },

  // {
  //   path: "/how-to-use",
  //   name: "HowToUse",
  //   component: HowToUse,
  // },
  // {
  //   path: "/terms",
  //   name: "Terms",
  //   component: Terms,
  // },
  // {
  //   path: "/fees",
  //   name: "Fees",
  //   component: Fees,
  // },

  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: Contact,
  // },

  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  // },

  // {
  //   path: "/faq",
  //   name: "Faq",
  //   component: Faq,
  // },

  {
    path: "/withdraw",
    name: "Withdraw",
    component: Withdraw,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/otp-verify",
    name: "OtpVerify",
    component: OtpVerify,
    props: true,
  },
  {
    path: "/register",
    name: "Register",
    component: Login,
  },
  {
    path: "/user/videoRecord",
    name: "videoRecord",
    component: VideoRecord,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/forget-tfa-verify",
    name: "ForgetTfaVerify",
    component: ForgetTfaVerify,
  },
  {
    path: "/fast-trade",
    name: "FastTrade",
    component: FastTrade,
  },

  {
    path: "/tradeview/:marketId",
    name: "Trade",
    component: Trade,
    props: true,
  },
  {
    path: "/coin/:ticker",
    name: "TickerInfo",
    component: TickerInfo,
    props: true,
  },

  {
    path: "/contactManager",
    name: "contact-manager",
    component: ContactManager,
  },
  {
    path: "/latest-coins",
    name: "NewestCoins",
    component: NewestCoins,
  },
  //user
  {
    path: "/user/web-callback",
    name: "WebCallback",
    component: WebCallback,
  },
  {
    path: "/user/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/user/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/user/wallet",
    name: "Wallet",
    component: Wallet,
  },
  {
    path: "/user/bank",
    name: "BankInfo",
    component: BankInfo,
  },

  {
    path: "/user/deposit/:ticker",
    name: "WalletDeposit",
    component: WalletDeposit,
    props: true,
  },
  {
    path: "/user/withdraw/:ticker",
    name: "WalletWithdraw",
    component: WalletWithdraw,
    props: true,
  },
  {
    path: "/user/fiat-verify/:moduleId",
    name: "FiatVerify",
    component: FiatVerify,
    props: true,
  },
  {
    path: "/user/not-verified",
    name: "NotVerified",
    component: NotVerified,
  },
  {
    path: "/user/otp-not-active",
    name: "OtpNotActive",
    component: OtpNotActive,
  },

  {
    path: "/user/otp-activate",
    name: "OtpActivate",
    component: OtpActivate,
  },

  {
    path: "/user/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/user/announcements",
    name: "Announcements",
    component: Announcements,
  },

  {
    path: "/user/announcement/:announcement_id",
    name: "Announcement",
    component: Announcement,
    props: true,
  },

  {
    path: "/user/messages",
    name: "Messages",
    component: Messages,
  },

  {
    path: "/user/message/:message_id",
    name: "Message",
    component: Message,
    props: true,
  },

  {
    path: "/user/affiliate",
    name: "Affiliate",
    component: Affiliate,
  },

  {
    path: "/user/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/user/history",
    name: "History",
    component: History,
  },
  {
    path: "/user/login-log",
    name: "LoginLog",
    component: LoginLog,
  },
  {
    path: "/user/email-verify",
    name: "EmailVerify",
    component: EmailVerify,
  },
  {
    path: '/user/mobile-verify',
    name: 'MobileVerify',
    component: MobileVeify
},
  {
    path: "/user/mobile-verify-new",
    name: "MobileVerifyNew",
    component: MobileVerifyNew,
  },
  {
    path: "/user/type-verify",
    name: "TypeVerify",
    component: TypeVerify,
  },

  {
    path: "/user/identity-verify",
    name: "IdentityVerify",
    component: IdentityVerify,
  },
  {
    path: "/user/identity-verify-submitted",
    name: "IdentityVerifySubmitted",
    component: IdentityVerifySubmitted,
  },
  {
    path: "/user/fast-trade",
    name: "FastTradeUser",
    component: FastTradeUser,
  },

  //Exception
  {
    path: "/version",
    name: "version",
    component: Version,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const _to = to.fullPath.replace(/\/$/, "");
  //const _from = from.fullPath.replace(/\/$/, "");
  //const _current = window.location.pathname.replace(/\/$/, "");
  const flag = _to !== "" && to.fullPath && to.fullPath.match(/\/$/);
  if (flag) {
    let _to2 = "";
    for (let i = 1; i < to.fullPath.split(/\//).length - 1; i++) {
      _to2 += "/" + to.fullPath.split(/\//)[i];
    }
    next(_to2);
  } else next();
});

export default router;
