var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row static"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('img',{staticStyle:{"max-width":"75px"},attrs:{"src":`${_vm.cdnUrl}/media/asset/logo/md/${_vm.asset.logo_filename}`}}),_c('h1',{staticClass:"mt-1"},[_vm._v("خرید و فروش "+_vm._s(_vm.asset.name))]),_c('span',[_vm._v(" "+_vm._s(_vm.asset.ticker)+" ")])])]),_c('div',{staticClass:"m-5 text-center"},[_vm._v("  "),_c('router-link',{staticClass:"btn btn-lg btn-warning home-btn2",attrs:{"to":'/tradeview/' + _vm.asset.ticker + '_IRT'}},[_vm._v(" ورود به بازار حرفه‌ای ")])],1),_c('div',{staticClass:"m-5"},[_vm._v(" ")]),_c('div',{staticClass:"m-5"},[_vm._v(" ")]),(_vm.ticker !== 'USDT')?_c('div',[_c('VueTradingView',{attrs:{"options":{
            symbol: 'BINANCE:' + this.ticker + 'USDT',
            interval: 'D',
            height: '300px',
            width: '100%',
            style: '3',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            hide_top_toolbar: true,
            save_image: false,
          }}})],1):_vm._e(),_c('div',{staticClass:"col-12 mt-4 mb-4 p-3 box"},[(_vm.asset.description !== '')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.asset.description)}}):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }