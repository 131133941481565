<template>
  <div class="px-lg-4 my-5">
    <div class="row no-padding no-margin market-view">
      <div class="col-12"></div>
    </div>
    <div class="row no-padding no-margin">
      <div class="container mt-4">
        <h2>
          کیف پول
        </h2>
        <div class="row mt-4">
          <div class="col">
            <div class="row totals vld-parent">
              <div class="col-12 pl-3 pt-3">
                ‌مجموع تقریبی دارایی‌ها
              </div>
              <div class="col-12 p-3">
                <span class="irt-value">
                  {{ totals.irt | prec(0) }}
                  <span class="small">تومان</span>
                </span>
                <span >
                  ≈
                </span>
                <label class="latin2 ltr">
                  ${{ totals.usdt | prec(2, true) }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <div class="row asset-item mb-3">
              <div class="mb-3 mt-3 col-12 col-md-6">
                <input
                  v-model="filterKeyword"
                  class="form-control"
                  type="text"
                  placeholder="جستجو در نام یا نماد ارز"
                />
              </div>
              <div class="mb-3 mt-4 col-12 col-md-6">
                <input
                  type="checkbox"
                  value="0"
                  id="chkHideZeroBalances"
                  v-model="hideZeroBalances"
                  @change="hideZero"
                />
                <label for="chkHideZeroBalances" class="ml-1"
                  >عدم نمایش ارزهای بدون موجودی</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <WalletAssets :wallets="wallets" />
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <router-link to="/user/history" class="btn btn-warning history-btn">
              آخرین تراکنش‌ها
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import WalletAssets from "./WalletAssets.vue";
// import UserLayout from "../layout/user/index.vue";
export default {
  name: "Wallet",
  title: "کیف پول",

  props: {
    msg: String,
  },
  components: {
    WalletAssets,
    // UserLayout,
  },
  data() {
    return {
      markets: [],
      allWallets: [],
      filteredWallets: [],
      wallets: {},
      polling: null,
      marketStats: {},
      hideZeroBalances: 0,
      backendRoot: process.env.VUE_APP_BACKEND_ROOT,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      filterKeyword: "",
      userInfo: this.$session.get("user_info"),
      totals: {},
      init: true,
      loading: false,
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.updateAllData();
      }, 3000);
    },
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateWallets() {
      let loader = null;
      if (!this.loading) {
        loader = this.$loading.show({
          container: this.$refs.tableContainer,
          canCancel: false,
          bars: "dots",
        });
        this.loading = true;
      }

      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/wallets", {
          withCredentials: true,
        })
        .then((response) => {
          if (this.init === true) {
            this.wallets = response.data.wallets;
            this.allWallets = response.data.wallets;
            this.filteredWallets = response.data.wallets;
            this.init = false;
          } else {
            this.allWallets = response.data.wallets;
            if (this.filterKeyword === "") {
              if (!this.hideZeroBalances) {
                this.wallets = response.data.wallets;
              }
            } else {
              const filteredWallets = [];
              for (const [i, filteredWallet] of Object.entries(
                this.filteredWallets
              )) {
                filteredWallets[i] = response.data.wallets.find(
                  (x) => x.ticker === filteredWallet.ticker
                );
              }
              this.wallets = filteredWallets;
              this.filteredWallets = filteredWallets;
            }
          }
          if (loader !== undefined && loader !== null) {
            loader.hide();
          }
          this.totals = response.data.totals;
        })
        .catch(() => {
          if (loader !== undefined && loader !== null) {
            loader.hide();
          }
          // console.log(e)
        });
    },

    hideZero() {
      if (this.hideZeroBalances) {
        this.wallets = this.filteredWallets.filter(
          (x) => x.balance.available + x.balance.freeze > 0
        );
      } else {
        this.wallets = this.filteredWallets;
      }
    },
    updateAllData() {
      this.updateWallets();
    },
  },
  watch: {
    filterKeyword: function(newValue) {
      if (newValue !== undefined && newValue !== "") {
        this.wallets = this.allWallets.filter(
          (x) =>
            x.ticker.toLowerCase().includes(newValue.toLowerCase()) ||
            x.name.includes(newValue)
        );
      } else {
        this.wallets = this.allWallets;
      }

      this.filteredWallets = this.wallets;
      this.hideZero();
    },
  },
  created() {
    this.init = true;

    this.$nextTick(function() {
      this.updateWallets();
    });

    this.$nextTick(function() {
      this.updateMarketList();
    });
  },

  mounted() {
    this.init = true;
    this.updateWallets();
    this.pollData();
  },
  beforeDestroy() {
    this.$nextTick(()=> {
      clearInterval(this.polling);
    });
  },
};
</script>

<style scoped>
.totals {
  background-color: #212b36;
  color: #fff;
  border-radius: 10px;
  vertical-align: middle;
  font-size: 15px;
}

.totals .irt-value {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.asset-item {
  background-color: #212b36;
  color: #fff;
  border-radius: 10px;
  vertical-align: middle;
  font-size: 15px;
}

.history-btn {
  width: 200px;
  border-radius: 20px;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
</style>

<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
/* new */
.wrapperMain {
  width: 100% !important;
  height: 100% !important;
}
.wrapperSide {
  width: 100% !important;
  height: fit-content !important;
  display: flex !important;
  justify-content: space-between !important;
}
.mainContent {
  flex-grow: 1 !important;
}
.main {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
</style>
