<template>
  <div class="my-5 main">
    <div class="container">
      <div class="login-wrapper box">
      <section id="tabs">
        <div class="container">
          <div class="row">
            <div class="login-wrapper">
              <nav>
                <div
                  class="nav"
                  id="nav-tab"
                  role="tablist"
                  style="position: relative"
                >
                  <a
                    class="nav-item active"
                    id="nav-login-tab"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-controls="nav-login"
                    aria-selected="true"
                  >
                    ورود با رمز دو عاملی
                    <span class="latin">(2FA)</span>
                  </a>
                </div>
              </nav>
              <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-login"
                  role="tabpanel"
                  aria-labelledby="nav-login-tab"
                >
                  <div class="row">
                    <div class="col-12">
                      <form ref="form" @submit.prevent="submitForm">
                        <div class="input-group mt-3 mb-3">
                          <input
                            autofocus
                            type="text"
                            class="form-control form-control-lg latin"
                            :disabled="disabled"
                            placeholder="کد Google Authenticator"
                            dir="ltr"
                            v-model="otpCode"
                          />
                        </div>
                        <div class="col-12 text-center mt-2">
                          <input
                            class="btn btn-primary login-btn"
                            type="submit"
                            @click="submitForm"
                            ref="submitBtn"
                            :disabled="disabled"
                            value="ورود با رمز 2FA"
                          />
                        </div>
                      </form>
                      <div class="col-12 text-right mt-3">
                        <router-link to="/login">
                          بازگشت به صفحه ورود
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OtpVerify",
  title: "ورود با رمز دو عاملی (2FA)",
  props: ["userInfo"],

  data() {
    return {
      otpCode: "",
      disabled: false,
    };
  },

  watch: {
    otpCode: function() {
      if (this.otpCode.length >= 6) {
        this.$refs.submitBtn.click();
      }
    },
  },
  methods: {
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    checkKycLevel(userInfo) {
      if (userInfo.kyc_step === 0) {
        return this.$router.push({ name: "MobileVerify" });
      } else if (userInfo.kyc_step === 1) {
        return this.$router.push({ name: "MobileVerify" });
      } else if (userInfo.kyc_step === 2) {
        return this.$router.push({ name: "Profile" });
      } else {
        return this.$router.push({ name: "Profile" });
      }
    },

    setUserInfoToSession(userInfo) {
      this.$session.clear();
      try {
        this.$session.set("user_info", userInfo);
      } catch (e) {
        // console.log("catch session login page", e);
      }
    },

    submitForm(e) {
      this.disabled = true;
      e.preventDefault();

      if (this.otpCode === "") {
        return this.notice(
          "error",
          "خطا",
          "کد دریافتی از Google Authenticator را وارد نمایید",
          3
        );
      }
      if (this.userInfo === undefined || this.userInfo == null) {
        this.notice(
          "error",
          "خطا",
          "مهلت ورود شما به پایان رسیده است. لطفا مجددا وارد شوید",
          5
        );
        return this.$router.push({ name: "Login" });
      }

      this.notice(
        "info",
        "در حال اعتبار سنجی ...",
        "در حال اعتبار سنجی کد 2FA. لطفا کمی صبر کنید ..."
      );
      const loginInfo = {
        mobile: this.userInfo.mobile,
        login_code: this.userInfo.login_code,
        otp_code: this.otpCode,
      };

      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/user/otp-verify", loginInfo, {
          withCredentials: true,
        })
        .then((response) => {
          this.userInfo2 = response.data;
          if (response.data.sms == 1) {
            axios
              .post(
                process.env.VUE_APP_BACKEND_URL + "/user/checkSmsforLogin",
                loginInfo,
                { withCredentials: true }
              )
              .then(() => {
                localStorage.setItem("email", this.userInfo2.email);
                // this.$session.set("user_info", this.userInfo2);
                return this.$router.push({
                  name: "MobileVerifyNew",
                  params: { userInfo2: this.userInfo2 },
                });
              })
              .catch(() => {
                console.log(e);
              });
          } else {
            this.setUserInfoToSession(this.userInfo2);
            return this.checkKycLevel(this.userInfo2);
          }
        })
        .catch((e) => {
          if (e.response.data !== undefined) {
            const data = e.response.data;
            const errors =
              "<ul>" +
              data.errors
                .map(function(err) {
                  return "<li>" + err + "</li>";
                })
                .join("") +
              "</ul>";
            this.notice("error", "خطا", errors, 10);

            setTimeout(() => {
              this.disabled = false;
              this.otpCode = "";
            }, 2000);

            return;
          }

          setTimeout(() => {
            this.disabled = false;
            this.otpCode = "";
          }, 2000);
          return this.notice(
            "error",
            "خطا",
            "رمز دو عاملی وارد شده صحیح نمی باشد.",
            5
          );
        });
    },
  },

  created() {
    if (this.userInfo === undefined || this.userInfo == null) {
      return this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped>

.static .content {
  margin-top: 50px;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 400px;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0.5);
}


.nav-item {
  border-bottom: 4px solid #f7a906;
  color: #f7a906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: #fff !important;
  color: #f7a906 !important;
  border: 0;
}

.input-group-append .input-group-text {
  background-color: #fff !important;
  color: #000 !important;
  border: 0;
}

.form-control {
  background-color: transparent;
  border: 1px solid #4c5157;
  letter-spacing: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
}
.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
  font-family: IRANSans, serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  font-family: IRANSans, serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  font-family: IRANSans, serif;
}

:-ms-input-placeholder {
  text-align: center;
  font-family: IRANSans, serif;
}
.main {
  max-height: 500px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .login-wrapper {
    width:100%;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}
</style>
