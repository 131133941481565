<template>
  <div class="flex-xycenter home justify-content-md-end">
    <div class="vld-parent w-100 col-12 col-lg-11 p-0">
      <div class="tab-content card w-100" id="myTabContent" ref="tableCoins">
        <div class="row pb-3 pt-3">
          <ul class="nav nav-tabs" id="marketTabs" role="tablist">
            <li class="nav-item text-center" @click="handleActiveTab('IRT')">
              <a
                class="nav-link active"
                id="irt-tab"
                data-toggle="tab"
                href="#irt"
                role="tab"
              >
                <img
                  class="coin-img"
                  src="https://cdn.dafinex.com/media/asset/logo/md/57caf3ea_7105_201105152450.png"
                  alt="IRT"
                />
                تومان
              </a>
            </li>
            <li class="nav-item text-center" @click="handleActiveTab('USDT')">
              <a
                class="nav-link"
                id="usdt-tab"
                data-toggle="tab"
                href="#usdt"
                role="tab"
              >
                <img
                  class="coin-img"
                  src="https://cdn.dafinex.com/media/asset/logo/md/4a8a08f0_5085_201105152029.png"
                  alt="USDT"
                />
                تتر</a
              >
            </li>
          </ul>
        </div>

        <div class="tab-pane fade show active" id="irt" role="tabpanel">
          <TableIRT
            :markets="
              activeFilter === 'main'
                ? handleNumberOfCoins(irtMarkets)
                : filterData
            "
            :formValues="formValues"
            :funcChangeSelectedCoin="funcChangeSelectedCoin"
            :fiatType="fiatType"
          />
        </div>
        <div class="tab-pane fade" id="usdt" role="tabpanel">
          <TableUSDT
            :markets="
              activeFilter === 'main'
                ? handleNumberOfCoins(usdtMarkets)
                : filterData
            "
            :formValues="formValues"
            :funcChangeSelectedCoin="funcChangeSelectedCoin"
            :fiatType="fiatType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableIRT from "./TableIRT.vue";
import TableUSDT from "./TableUSDT.vue";

// import axios from "axios";

export default {
  // title: "دفینکس پلتفرم تبادل ارزهای دیجیتال",
  name: "Table",
  props: {
    irtMarkets: Array,
    usdtMarkets: Array,
    loaderFlag: Boolean,
    formValues: Object,
    fiatType: Object,
    funcChangeSelectedCoin: Function,
  },
  components: {
    TableIRT,
    TableUSDT,
  },
  data() {
    return {
      filterData: null,

      readMore: false,
      tabItems: [
        { name: "صفحه اصلی", id: "main" },
        { name: "پر سود ترین ها", id: "profitable" },
        { name: "جدید ترین ها", id: "new" },
        { name: "برترین ها ", id: "best" },
      ],
      activeFilter: "main",
      activeTab: "IRT",
      arrayCoins: {
        IRT: {
          best: ["BTC", "ETH", "SHIB", "USDT", "DOGE"],
        },
        USDT: {
          best: ["BTC", "AAVE", "DOGE", "ETH", "BNB"],
        },
      },
    };
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    setActiveFilterTab(tab) {
      this.activeFilter = tab;
      this.FilterData(tab);
    },
    handleActiveTab(tab) {
      this.activeTab = tab;
      this.activeFilter = "main";
      this.FilterData(tab);
    },
    //for filter Tab
    FilterData(FilterItem) {
      const data =
        this.activeTab === "IRT" ? this.irtMarkets : this.usdtMarkets;
      let selectedCoins = [];
      if (FilterItem === "profitable") {
        selectedCoins = [...data]
          .sort((itemA, itemB) => itemB.change - itemA.change)
          .splice(0, 5);
      } else if (FilterItem === "new") {
        //remove BTTC
        const filterCoins = [...data].filter(
          (item) => item.base_asset_ticker !== "BTTC"
        );
        const reverseData = filterCoins.reverse();
        selectedCoins = reverseData.splice(0, 5);
      } else if (FilterItem === "best") {
        selectedCoins = this.getSelectedCoins(
          [...data],
          this.activeTab === "IRT"
            ? this.arrayCoins.IRT.best
            : this.arrayCoins.USDT.best
        );
      }

      return (this.filterData = selectedCoins);
    },

    getSelectedCoins(data, coinList) {
      const selectedCoins = [];
      for (const coin of coinList) {
        const findCoin = data.find((item) => item.base_asset_ticker === coin);
        if (findCoin) {
          selectedCoins.push(findCoin);
        }
      }
      return selectedCoins;
    },
    handleNumberOfCoins(data) {
      const dataArray = Object.keys(data)
        .map((key) => {
          return data[key];
        })
        .slice(0, 5);
      return [...dataArray];
    },
  },
  mounted() {
    const loader = this.$loading.show({
      container: this.$refs.tableCoins,
      canCancel: false,
      bars: "dots",
    });
    const intervalId = setInterval(() => {
      if (!this.loaderFlag) {
        loader.hide();
        clearInterval(intervalId); // Clear the interval when loaderFlag is false
      }
    }, 1000);
  },
};
</script>

<style scoped>
.morelessbtn {
  background-color: transparent;
  color: #f8aa0f;
  border-color: transparent;
}
.nav-tabs {
  border-bottom: 0 !important;
  margin: auto;
}

.bolder {
  font-weight: bolder !important;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #f8aa0f;
}

.callapse-btn {
  background-color: #dc8b71;
  color: #f8aa0f !important;
}

#myTabContent {
  background: #302d2c;
  border: 1px solid #ffffff;
  border-radius: 40px;
  widows: 100%;
}

.nav-item {
  width: 100px;
}

.nav-link:not(.active) {
  background-color: #efefef;
  color: #000;
}

.coin-img {
  width: 25px;
}

#irt-tab {
  border-radius: 0px 39px 39px 0px;
  background-color: #4a4a48;
  color: white;
  border-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 4px 6px;
  font-weight: bold;
}

#irt-tab:hover {
  background-color: #616161;
  border: 1px solid #ffffff;
  /* box-shadow: inset 0px 0px 15px #000000; */
}

.nav-item .active {
  background-color: #1e1919 !important;
  border: 1px solid #ffffff;
  /* box-shadow: inset 0px 0px 15px #000000; */
}

#irt-tab:focus {
  background-color: transparent;
}

#usdt-tab:hover {
  background-color: #616161;
  border: 1px solid #ffffff;
  /* box-shadow: inset 0px 0px 15px #000000; */
}

#usdt-tab {
  border-radius: 39px 0px 0px 39px;
  background-color: #4a4a48;
  color: white;
  border-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 4px 6px;
  font-weight: bold;
}

#usdt-tab:focus {
  background-color: transparent;
}

.home {
  background-size: 100%;
}

.moreBtn {
  text-align: center;
  color: #f8aa0f;
}

.moreFont {
  font-size: 20px;
}

.logo-wrapper {
  text-align: center;
}
.logo-wrapper .slogan {
  font-size: 22px;
  text-shadow: 1px 1px #ccc;
}

.home-btn {
  border-radius: 20px;
  background-color: #504f4d;
  border: 0;
  font-size: 17px;
  width: 220px;
  margin: 30px 10px;
}

.home-btn2 {
  border-radius: 20px;
  border: 0;
  font-size: 17px;
  width: 220px;
  margin: 30px 10px;
}

.card {
  background-color: #302d2c;
  padding: 0 0 0 0 !important;
  border-radius: 50px;
  box-shadow: none;
  border-color: #f8aa0f;
  text-align: justify;
  overflow: hidden;
}

.home-logo {
  width: 50%;
  margin: 30px 30px 10px 30px;
  max-width: 220px;
}

.home-icon {
  background-color: #1e1919;
  margin-top: 30px;
}

.home-icon img {
  max-width: 90px;
}

.span-font {
  font-size: 18px;
}

.download-apps a img {
  max-width: 180px;
  margin-right: 5px;
  margin-top: 5px;
}

p {
  text-align: justify;
}

h3 {
  color: #f8aa0f;
  margin: 20px 0px;
  text-align: right;
}

.hr-line {
  height: 1px;
  background: white;
  box-sizing: 2px;
  margin: 0px;
}
/* new */
.bg-table {
  background-color: rgb(48, 45, 44, 0.9) !important;
  padding-bottom: 0 !important ;
  position: relative;
  overflow: hidden !important;
}

.coinbg {
  position: absolute;
  z-index: -1;
}

.coinbg2 {
  top: 0;
  left: 0;
}
.coinbg2 img {
  width: 220px;
  margin: 10px 0px 0px 0px;
}
.coinbg1 {
  bottom: 0;
  right: 0;
}
.coinbg1 img {
  width: 130px;
  margin: 0 10px 10px 0;
}

@media screen and (min-width: 480px) {
  .coinbg2 img {
    width: 240px;
  }
  .coinbg1 img {
    width: 180px;
  }
}
@media screen and (min-width: 769px) {
  .coinbg2 img {
    width: 300px;
  }
  .coinbg1 img {
    width: 220px;
  }
}
@media screen and (max-width: 800px) {
  .btn.home {
    width: 160px;
    font-size: 12px;
    margin: 30px 5px;
  }

  .home-icon img {
    max-width: 40px;
  }

  h2 {
    font-size: 23px;
  }

  p {
    text-align: justify;
    font-size: 14px;
  }

  h3 {
    color: #f8aa0f;
    margin: 20px 0px;
    text-align: right;
    font-size: 20px;
  }

  .moreBtn {
    text-align: center;
    color: #f8aa0f;
    font-size: 16px;
  }
}
/* new */

/* scroll  for tabFilter*/

/* mozila */
.tabFilter-box {
  scrollbar-color: rgba(90, 90, 90, 0.724) #434343;
  scrollbar-width: thin;
}
/* another browser */
.tabFilter-box::-webkit-scrollbar {
  height: 5px;
}

.tabFilter-box::-webkit-scrollbar-track {
  background-color: rgba(90, 90, 90, 0.724);
  height: 1px;
}
.tabFilter-box::-webkit-scrollbar-thumb {
  background-color: #434343;
  border-radius: 2px;
  height: 1px;
}
/* // */

.tab-pane {
  height: fit-content !important;
}
.flex-xycenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabFilter-box {
  background-color: #4a4a48;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 0 0;
  padding: 8px 14px;
  font-size: 0.8rem;
  overflow: auto;
  white-space: nowrap;
}
.tabFilter-box ul {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
.tabFilter-box li {
  background-color: #241f1f;
  border: 1px solid #ffff;
  border-radius: 10px;
  cursor: pointer;
  padding: 4px 10px 1.5px 10px;
  border-radius: 20px;

  /* width:100px; */
}
.tabFilter-box li:not(.active-tab):hover {
  background-color: #322f2f;
}
.allCoins {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  background-color: #241f1f;
  border: 1px solid #ffff;
  border-radius: 15px;
  cursor: pointer;
  padding: 4px 10px 1.5px 10px;
  margin: 0 30px 0 0;
}
.allCoins img {
  width: 13px;
}
.allCoins:hover {
  color: #ffff;
  background-color: #322f2f;
}

.active-tab {
  background-color: #241f1f;
  border: 1px solid #f8aa0f !important;
  color: #f8aa0f;
}

@media screen and (min-width: 990px) {
  .tabFilter-box {
    font-size: 0.98rem;
  }
  .allCoins {
    font-size: 0.98rem;
  }
  .allCoins img {
    width: 16px;
  }
}
@media screen and (min-width: 780px) {
  .tabFilter-box {
    width: 100%;
  }
}
</style>
