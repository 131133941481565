<template>
  <div class="d-md-none d-lg-none">
    <CurvedBottomNavigation :background-color="backgroundColor"
                            :foreground-color="foregroundColor"
                            :options="options" v-model="selected">
      <template #icon="{ props }">
        <img style="width: 55%" :src="props.icon" :alt="props.title" />
      </template>
      <template #title="{ props }">
        <p style="color: #fff">{{ props.title }}</p>
      </template>

      <template #child-icon="{ props }">
        <img style="width: 55%" :src="props.icon" :alt="props.title" />
      </template>
      <template #child-title="{ props }">
        <p style="color: #fff">{{ props.title }}</p>
      </template>
    </CurvedBottomNavigation>
  </div>
</template>

<script>
import { CurvedBottomNavigation } from "bottom-navigation-vue";

export default {
  components: { CurvedBottomNavigation },
  data: () => ({
    selected: 1,
    backgroundColor: "#161c24",
    foregroundColor: "#212b36",
    options: [
      {
        id: 1,
        icon: require("@/assets/images/sidebar/history.png"),
        title: "تاریخچه",
        path: "/user/history",
      },
      {
        id: 2,
        icon: require("@/assets/images/sidebar/orders.png"),
        title: "سفارشات",
        path: "/user/orders",
      },
      {
        id: 3,
        icon: require("@/assets/images/sidebar/trade.png"),
        title: "معاملات",
        childs: [
          { id: 301, icon: require("@/assets/images/header/tradeview.png"), title: "حرفه‌ای",  path: "/tradeview/BTC_IRT"},
          { id: 302, icon: require("@/assets/images/header/fastTrade.png"), title: "آسان" , path: "/user/fast-trade"}
        ],
      },
      { id: 4,
        icon: require("@/assets/images/sidebar/wallet.png"),
        title: "کیف پول",
        path: "/user/wallet",
      },
      {
        id: 5,
        icon: require("@/assets/images/sidebar/dashboard.png"),
        title: "داشبورد",
        path: "/user/profile"
      },
    ],
  }),
};
</script>