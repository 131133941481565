<template>
    <div class="list-item-announcement">
        <div v-if="announcements !== undefined && announcements != null && announcements.records.length > 0">

        <div v-for="announcement in announcements.records" :key="announcement.id" @click="annClicked(announcement)">
            <div v-bind:class="{ unread: !announcement.is_read }">
                <router-link :to="`/user/announcement/${announcement.id}`">
                    <div class="bold">
                        {{announcement.title}}
                    </div>
                    <div class="small mt-2 gray ltr">
                        {{announcement.created_at | persianDateUnix}}
                    </div>
                    <div class="mt-3 small">
                        <p v-html="announcement.message">

                        </p>
                        <span class="blue"> ادامه پیام ...</span>
                    </div>
                </router-link>

            </div>
        </div>
        </div>
        <div v-else>
        <div>
            <div class="text-center">
                اعلانی وجود ندارد
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AnnouncementsTable',
        props: ['announcements'],
        created() {
            console.log(this.announcements);
        },
        methods: {
            annClicked: function (announcement) {
                if (!announcement.is_read) {
                    console.log(announcement);
                    return this.$root.$emit('annClicked', announcement.id);
                }

            }
        },

    }
</script>

<style scoped>

      .list-item-announcement{
        width: 100%;
        border-bottom: 1px solid #666;
        margin-bottom: 15px;
      }

    .open-announcements {
        font-size: 12px;
        border: 0;
        border-spacing: 0;
    }

    .unread {
      background-color: #161c24;
      border-radius: 12px;
    }

    .blue {
        color: #007bff;
    }

    .gray {
        color: #d2d2d2;
    }

</style>
