<template>
  <div>
    <div class="desc">
      <h5>
        این صفحه برای ارتباط مستقیم و بدون واسطه شما کاربران عزیز با مدیریت
        ایجاد شده است
      </h5>
      <ul>
        <li>
          <p>
            از این فرم صرفا برای ارائه " <span>انتقادات</span> و
            <span>پیشنهادات</span> و <span>شکایات</span> " خود استفاده کنید و
            پیگیری سفارشاتتان را از طریق پشتیبانی آنلاین و یا تماس با شماره
            <span>02191302939</span> انجام دهید
          </p>
        </li>
        <li>
          <p>
            برای بررسی هرچه‌سریع‌تر درخواستتان، لطفا تمامی موارد خواسته‌شده در
            فرم را تکمیل کنید.
          </p>
        </li>
        <li>
          <p>
            پیام‌های ارسالی شما به ترتیب در ساعات اداری بررسی و پیگیری می‌شود
          </p>
        </li>
      </ul>
    </div>
    <div class="container">
      <form @submit="sendEmail()" class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <label for="">نام و نام خانوادگی</label>
          <input type="text" name="FirstName" v-model="name.value" />
          <div class="error">
            <p v-show="name.error">{{ name.error }}</p>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <label for="">شماره تلفن همراه</label>
          <input type="number" name="Phone" v-model="phone.value" />
          <div class="error">
            <p v-show="phone.error">{{ phone.error }}</p>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <label for="">ایمیل</label>
          <input type="text" name="Email" v-model="email.value" />
          <div class="error">
            <p v-show="email.error">{{ email.error }}</p>
          </div>
        </div>
        <div class="col-12">
          <label for="">نظر شما</label>
          <textarea
            id="story"
            name="story"
            rows="5"
            cols="33"
            v-model="message.value"
          />
          <div class="error">
            <p v-show="message.error">{{ message.error }}</p>
          </div>
        </div>
        <span><button type="submit" name="submit">ارسال</button></span>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-manager",
  components: {},
  data() {
    return {
      name: { value: "", error: "" },
      phone: {
        value: "",
        error: "",
      },
      email: {
        value: "",
        error: "",
      },

      message: {
        value: "",
        error: "",
      },
    };
  },
  methods: {
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },
    checkValidation() {
      const regexEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
      const regexPhoneLength = /^\d{11}$/; //just accept 11 number
      const regexPhoneMain = /^09\d{9}$/; //just accept number  and 09 at the first and 11 number

      //email start
      if (this.email.value === "") {
        this.email.error = "لطفا ایمیل خود را وارد کنید";
      } else if (!regexEmail.test(this.email.value)) {
        this.email.error = "ایمیل خود را تصحیح کنید";
      } else {
        this.email.error = "";
      }
      //email end
      //phone start
      if (this.phone.value === "") {
        this.phone.error = "لطفا شماره موبایل خود را وارد کنید";
      } else if (!regexPhoneLength.test(this.phone.value)) {
        this.phone.error = "شماره موبایل باید شامل ۱۱ رقم باشد";
      } else if (!regexPhoneMain.test(this.phone.value)) {
        this.phone.error = "۰۹ را در اول شماره موبایل قرار دهید";
      } else {
        this.phone.error = "";
      }
      //phone end

      // name start
      if (this.name.value.trim() === "") {
        this.name.error = "لطفا نام و نام خانوادگی خود را وارد کنید";
      } else {
        this.name.error = "";
      }
      // name end

      //message start
      if (this.message.value.trim() === "") {
        this.message.error = "لطفا نظر خود را وارد کنید";
      } else {
        this.message.error = "";
      }

      //message end
    },
    sendEmail() {
      event.preventDefault();
      this.checkValidation();
      if (
        this.name.error === "" &&
        this.phone.error === "" &&
        this.email.error === "" &&
        this.message.error === ""
      ) {
        this.name.value = "";
        this.phone.value = "";
        this.email.value = "";
        this.message = "";
        this.notice("success", "با تشکر از ثبت نظر شما");
      }
    },
  },
};
</script>
<style scoped>
form {
  background-color: #302d2c;
  box-shadow: -2px 10px 30px 0px #000 inset;
  border: 1px solid #f8aa0f;
  border-radius: 15px;
  padding: 10px;
}
input,
textarea {
  border: 1px solid #ffff;
  border-radius: 8px;
  box-shadow: none;
  color: #ffff;
  outline: none;
  background-color: transparent;
  width: 100%;
  padding: 5px 10px;
}
form div {
  margin-top: 20px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
form button {
  border: 1px solid #ffff;
  border-radius: 10px;
  color: #ffff;
  background-color: #4a4a48;
  margin: 15px 0;
  padding: 5px 30px;
}
form span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
form button:hover {
  background-color: #323232;
}
label {
  color: #ffff;
  text-align: end;
}

h5 {
  margin-bottom: 30px;
}
ul {
  padding-right: 10px;
}
li {
  line-height: 1.6;
}

.desc {
  margin: 20px 20px;
}
ul span {
  color: #f8aa0f;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.error {
  height: 10px;
  color: #f8aa0f;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (min-width: 991px) {
  .desc {
    margin: 50px 40px;
  }
  li {
    font-size: 17px;
  }
  ul {
    padding-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .desc {
    margin: 50px 100px;
  }
}
</style>
