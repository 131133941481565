const ws_url = process.env.VUE_APP_WEBSOCKET_URL;
let call_id = 0;
let sock = null;

function start_ws(onready, onmessage) {
    let created_sock = false;
    if (sock == null || sock.readyState != 1) {
        sock = new WebSocket(ws_url);
        created_sock = true;
    }
    // eslint-disable-next-line no-unused-vars
    sock.onopen = function (event) {
        // console.log("opened ws to '" + ws_url + "'", event);
        onready(sock);
    };
    sock.onmessage = function (event) {
        onmessage(sock, event.data);
    };
    /*    sock.onerror = function (event) {
            console.log("ws error (" + event + ")");
        };
        sock.onclose = function (event) {
            console.log("ws closed (" + event.code + ")");
        };*/
    if (!created_sock)
        onready(sock);
}


let callbacks = {};

function call_ws_server(method, params, msgType, callback) {
    call_id++;
    start_ws(
        function (sock) {
            const msg = JSON.stringify({id: call_id, method: method, params: params});
            // console.log("start_ws", msg);
            sock.send(msg);
        },
        function (sock, data) {
            const obj = JSON.parse(data);
            if (msgType !== undefined) {
                callbacks[msgType] = callback;
            }

            // const pretty = JSON.stringify(obj, null, 4);
            /*            if (callback !== undefined) {
                            handleOnMessage(msgType, obj, callback)
                        } else {
                            console.log("no callback is defined for ws");
                        }*/

            handleOnMessage(obj);
        }
    );
}

/*
function mycallback(data) {
    console.log("from my callback", data.params);
    const chart = data.params;

    if (chart !== undefined) {
        let objs = chart.map(function (x) {
            return {
                time: x[0] * 1000,
                open: x[1],
                high: x[2],
                low: x[3],
                close: x[4],
                volume: x[5],
            };
        });

        console.log(objs)
    }
}*/


function handleOnMessage(data) {
    Object.keys(callbacks).forEach(function (c) {
        if (c === data.method) {
            callbacks[c](data);
        }
    });
}


// eslint-disable-next-line no-unused-vars
function depth_update(data) {
    console.log("from depth_update", data);
}

// eslint-disable-next-line no-unused-vars
function kline_update(data) {
    console.log("from kline_update", data);
}


/*function handleKline(data) {
    const chart = data.params;
    if (chart !== undefined) {
        let objs = chart.map(function (x) {
            return {
                time: x[0] * 1000,
                open: x[1],
                high: x[3],
                low: x[4],
                close: x[2],
                volume: x[5],
            };
        });
        onRealtimeCallback(objs[(objs.length) - 1])
    }
}*/


function subscribeDepth(symbolName) {
    const params = [symbolName, 50, "0"];
    call_ws_server('depth.subscribe', params, 'depth.update', function (data) {
        if(data.params[0] === false){
            call_ws_server('depth.query', params);
        }/*else{
            console.log("full bids");
        }*/
    });
}


// eslint-disable-next-line no-unused-vars
function subscribeKline(symbolName, interval, onRealtimeCallback) {
    call_ws_server("kline.subscribe", [symbolName, parseInt(interval) * 60], 'kline.update', function (data) {
        const chart = data.params;
        if (chart !== undefined) {
            let objs = chart.map(function (x) {
                return {
                    time: x[0] * 1000,
                    open: x[1],
                    high: x[3],
                    low: x[4],
                    close: x[2],
                    volume: x[5],
                };
            });
            for (let i = 0; i < objs.length; i++) {
                const obj = objs[i];
                onRealtimeCallback(obj);
            }
        }
    });
}

function unsubscribeKline(subscribeID) {
    const marketInfo = subscribeID.split('_');
    const symbolName = marketInfo[0] + '_' + marketInfo[1];
    call_ws_server("kline.unsubscribe", [symbolName], function () {
    });
}

export default {
    subscribeKline,
    unsubscribeKline,
    subscribeDepth,
}