<template>
  <nav class="navbar navbar-expand-lg navbar-light navbar-custom">
    <div class="navbar-top">
      <div class="rightMenu">
        <div
          @click="closeNavbar('menuebtn')"
          id="menuToggle"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <input type="checkbox" id="myCheckbox" />
          <span></span>
          <span></span>
          <span></span>
        </div>

        <!-- until md start -->
        <router-link to="/" class="d-lg-none d-flex align-items-center justify-content-center">
          <img class="logo-site" src="@/assets/images/lydiaLogo.png" />
          <span class="mr-1 ml-1">لیدیا</span>
        </router-link>
      </div>
      <div class="leftMenu">
        <div class="leftNavBar-sm" v-if="session.get('user_info')">
          <div>
            <div class="notifElement">
              <router-link to="/user/messages"
                ><img
                  src="@/assets/images/header/header2/mail.png"
                  alt=""
                /><span
                  v-if="
                    messageCount !== undefined &&
                      messageCount !== null &&
                      messageCount.message_count > 0 &&
                      messageCount.message_count < 100
                  "
                  class="badge"
                  >{{ messageCount.message_count }}</span
                >
                <span
                  v-if="
                    messageCount !== undefined &&
                      messageCount !== null &&
                      messageCount.message_count > 0 &&
                      messageCount.message_count >= 100
                  "
                  class="badge"
                  >99</span
                ></router-link
              >
            </div>
          </div>
          <div>
            <div class="notifElement">
              <router-link to="/user/announcements">
                <img src="@/assets/images/header/header2/bell.png" alt="" />
                <span
                  v-if="
                    messageCount !== undefined &&
                      messageCount !== null &&
                      messageCount.announcement_count > 0 &&
                      messageCount.announcement_count < 100
                  "
                  class="badge"
                  >{{ messageCount.announcement_count }}</span
                >
                <span
                  v-if="
                    messageCount !== undefined &&
                      messageCount !== null &&
                      messageCount.announcement_count > 0 &&
                      messageCount.announcement_count > 100
                  "
                  class="badge"
                  >99</span
                ></router-link
              >
            </div>
          </div>
          <div class="userElement">
            <router-link to="/user/identity-verify">
              <div @click="changeTab('داشبورد')">
                <img
                  v-if="session.get('user_info').kyc_step == 4"
                  src="@/assets/images/header/header2/profileVerify.png"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/images/header/header2/profileNotVerify.png"
                  alt=""
                />
              </div>
            </router-link>

            <button
              v-if="session.get('user_info').kyc_step == 2"
              type="button"
              class="btn btn-warning mx-2 text-center btn-sm hide-btn"
              data-toggle="modal"
              data-target="#exampleModalCenter"
            >
              احراز هویت نشده
            </button>

            <button
              v-if="session.get('user_info').kyc_step == 3"
              type="button"
              class="btn btn-warning mx-2 text-center btn-sm hide-btn"
            >
              در حال احراز هویت
            </button>
            <span v-if="session.get('user_info').kyc_step == 4">
              {{ session.get("user_info").first_name }}
              {{ session.get("user_info").last_name }}</span
            >
          </div>
        </div>
        <div class="leftNavBar-sm" v-else>
          <router-link to="/login" class="login-btn"
            >ورود / ثبت نام</router-link
          >
        </div>
      </div>
      <div
        class="col-12 d-flex justify-content-center show-btn"
        v-if="session.exists('user_info')"
      >
        <button
          v-if="session.get('user_info').kyc_step == 2"
          type="button"
          class="btn btn-warning mx-2 text-center btn-sm mt-3 "
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          احراز هویت نشده
        </button>

        <button
          v-else-if="session.get('user_info').kyc_step == 3"
          type="button"
          class="btn btn-warning mx-2 text-center btn-sm "
        >
          در حال احراز هویت
        </button>
      </div>
    </div>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav " v-if="session.get('user_info')">
        <li class="navbar-li" v-for="item in listTabsUser" :key="item.name">
          <router-link :to="item.link" class="nav-link ">
            <div @click="changeTab(item.name)" class="nav-link-sm">
              <img :src="item.img" :alt="item.name" />
              <span>{{ item.name }}</span>
            </div>
          </router-link>
        </li>
        <li class="navbar-exist ">
          <span @click="changeTab('خروج')" class="nav-link exitButton"
            >خروج از حساب کاربری</span
          >
        </li>
      </ul>
      <ul v-else>
        <li class="navbar-li" v-for="item in listTabs" :key="item.name">
          <!-- trade accardion start-->
          <div
            v-if="item.name === 'معاملات'"
            class="nav-link "
            data-toggle="collapse"
            data-target="#collapse2"
            aria-expanded="false"
            :aria-controls="`collapse2`"
          >
            <div class="header-accordion " id="heading12">
              <div class="header-accordion-right">
                <img :src="item.img" :alt="item.name" />
                <span class="">{{ item.name }}</span>
              </div>

              <button class="btn-accordion">
                <img
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="false"
                  class="btn-nav-accordion"
                  src="@/assets/images/footer/arrowDown.svg"
                  alt=""
                />
              </button>
            </div>
            <div id="collapse2" class="collapse" aria-labelledby="heading12">
              <div class="accordionBoxTransactionBody">
                <div class="eachBox" @click="closeNavbar()">
                  <router-link to="/tradeview/BTC_IRT"
                    ><img src="@/assets/images/header/tradeview.png" alt=""
                  /></router-link>

                  <router-link to="/tradeview/BTC_IRT" class="name"
                    >معامله حرفه ای</router-link
                  >
                </div>
                <div class="eachBox" @click="closeNavbar()">
                  <router-link to="/fast-trade">
                    <img src="@/assets/images/header/fastTrade.png" alt=""
                  /></router-link>

                  <router-link to="/fast-trade" class="name"
                    >معامله آسان</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- trade accardion start-->

          <router-link
            :to="item.link"
            class="nav-link "
            v-else
            @click="item.name !== 'معاملات' && changeTab(item.name)"
          >
            <img :src="item.img" :alt="item.name" />
            <span>{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- until md end-->
    <!-- market list start -->
    <div
      v-if="this.$route.name === 'Trade'"
      class="d-flex mt-md-1  mt-3 col-8 offset-sm-3 offset-md-4    d-lg-none"
    >
      <span> بازارها: </span>
      <div class="dropdown show margin-right-10 position-relative">
        <a
          class="btn btn-sm btn-secondary dropdown-toggle market-selector"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          تومان
        </a>

        <div class="dropdown-menu dropdown-menu-custom">
          <router-link
            class="dropdown-item"
            :to="{ name: 'Trade', params: { marketId: market.identifier } }"
            v-for="market in irtMarkets"
            :key="market.identifier"
          >
            {{ market.name }}
          </router-link>
        </div>
      </div>
      <div class="dropdown show margin-right-10 position-relative">
        <a
          class="btn btn-sm btn-secondary dropdown-toggle market-selector"
          href="#"
          role="button"
          id="dropdownMenuLink2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          USDT
        </a>

        <div class="dropdown-menu custom2 dropdown-menu-custom">
          <router-link
            class="dropdown-item"
            :to="{ name: 'Trade', params: { marketId: market.identifier } }"
            v-for="market in usdtMarkets"
            :key="market.identifier"
          >
            {{ market.name }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- market list end -->
    <!-- md and bigger start -->
    <div class="mainNavbar-sm">
      <div class="rightNavBar-sm">

          <router-link to="/" class="d-flex align-items-center justify-content-center">
            <img class="logo-site" src="@/assets/images/lydiaLogo.png" />
            <span class="mr-1 ml-1">لیدیا</span>
          </router-link>

        <!-- market list start -->
        <div class="d-flex " v-if="this.$route.name === 'Trade'">
          <span> بازارها: </span>
          <div class="dropdown show margin-right-10 position-relative">
            <a
              class="btn btn-sm btn-secondary dropdown-toggle market-selector"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              تومان
            </a>

            <div class="dropdown-menu dropdown-menu-custom">
              <router-link
                class="dropdown-item"
                :to="{
                  name: 'Trade',
                  params: { marketId: market.identifier },
                }"
                v-for="market in irtMarkets"
                :key="market.identifier"
              >
                {{ market.name }}
              </router-link>
            </div>
          </div>
          <div class="dropdown show margin-right-10 position-relative">
            <a
              class="btn btn-sm btn-secondary dropdown-toggle market-selector"
              href="#"
              role="button"
              id="dropdownMenuLink2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              USDT
            </a>

            <div class="dropdown-menu custom2 dropdown-menu-custom">
              <router-link
                class="dropdown-item"
                :to="{
                  name: 'Trade',
                  params: { marketId: market.identifier },
                }"
                v-for="market in usdtMarkets"
                :key="market.identifier"
              >
                {{ market.name }}
              </router-link>
            </div>
          </div>
        </div>
        <!-- market list end -->
        <ul v-if="this.$route.name !== 'Trade'">
          <li @click="changeTab('معاملات')">
            <router-link to="/tradeview/BTC_IRT">معامله حرفه ای</router-link>
          </li>
          <li @click="changeTab('معاملات')">
            <router-link to="/user/fast-trade" v-if="session.get('user_info')"
              >معامله آسان</router-link
            >
            <router-link to="/fast-trade" v-else>معامله آسان</router-link>
          </li>
          <li><a href="https://lydiaex.com/userGuide" target="_blank">راهنمای استفاده</a></li>
          <li><a href="https://lydiaex.com/faq" target="_blank">سوالات متداول</a></li>
        </ul>
      </div>
      <div class="leftNavBar-sm" v-if="session.get('user_info')">
        <div>
          <div class="notifElement">
            <router-link to="/user/messages"
              ><img src="@/assets/images/header/header2/mail.png" alt="" /><span
                v-if="
                  messageCount !== undefined &&
                    messageCount !== null &&
                    messageCount.message_count > 0
                "
                class="badge"
                >{{ messageCount.message_count }}</span
              ></router-link
            >
          </div>
        </div>
        <div>
          <div class="notifElement">
            <router-link to="/user/announcements">
              <img src="@/assets/images/header/header2/bell.png" alt="" /><span
                v-if="
                  messageCount !== undefined &&
                    messageCount !== null &&
                    messageCount.announcement_count > 0
                "
                class="badge"
                >{{ messageCount.announcement_count }}</span
              ></router-link
            >
          </div>
        </div>

        <div class="userElement">
          <router-link to="/user/identity-verify">
            <div @click="changeTab('داشبورد')">
              <img
                v-if="session.get('user_info').kyc_step != 4"
                src="@/assets/images/header/header2/profileNotVerify.png"
                alt=""
              />
              <img
                v-if="session.get('user_info').kyc_step == 4"
                src="@/assets/images/header/header2/profileVerify.png"
                alt=""
              />
            </div>
          </router-link>
          <router-link
              v-if="session.get('user_info').kyc_step == 2"
              class="btn btn-primary mx-2 text-center btn-sm"
              to="/user/identity-verify">
            احراز هویت نشده
          </router-link>

          <button
            v-if="session.get('user_info').kyc_step == 3"
            type="button"
            class="btn btn-warning mx-2 text-center btn-sm"
          >
            هویت شما در دست بررسی است
          </button>
          <span v-if="session.get('user_info').kyc_step == 4">
            {{ session.get("user_info").first_name }}
            {{ session.get("user_info").last_name }}</span
          >
        </div>
      </div>
      <div v-else class="leftNavBar-sm">
        <router-link to="/login" class="login-btn">ورود / ثبت نام</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "Header2Defualt",
  props: ["activeTab"],
  data() {
    return {
      polling: null,
      session: this.$session,
      messageCount: {},
      listTabsUser: [
        {
          img: require("@/assets/images/sidebar/dashboard.png"),
          name: "داشبورد",
          link: "/user/profile",
        },
        {
          img: require("@/assets/images/sidebar/wallet.png"),
          name: "کیف پول",
          link: "/user/wallet",
        },
        {
          img: require("@/assets/images/sidebar/orders.png"),
          name: "سفارشات",
          link: "/user/orders",
        },
        {
          img: require("@/assets/images/sidebar/tradeview.png"),
          name: "معامله حرفه ای",
          link: "/tradeview/BTC_IRT",
        },
        {
          img: require("@/assets/images/sidebar/fastTrade.png"),
          name: "معامله آسان",
          link: "/user/fast-trade",
        },
        {
          img: require("@/assets/images/sidebar/user.png"),
          name: "اطلاعات کاربری",
          link: "/user/identity-verify",
        },
        {
          img: require("@/assets/images/sidebar/user.png"),
          name: "اطلاعات بانکی",
          link: "/user/bank",
        },
        // {
        //   img: require("@/assets/images/sidebar/list.png"),
        //   name: "لیست من",
        //   link: "",
        // },
        {
          img: require("@/assets/images/sidebar/history.png"),
          name: "تاریخچه",
          link: "/user/history",
        },
        {
          img: require("@/assets/images/sidebar/settings.png"),
          name: "تنظیمات",
          link: "/user/settings",
        },

        {
          img: require("@/assets/images/sidebar/report.png"),
          name: "گزارش ورود",
          link: "/user/login-log",
        },
        {
          img: require("@/assets/images/sidebar/invitation.png"),
          name: "دعوت دوستان",
          link: "/user/affiliate",
        },
      ],
      listTabs: [
        {
          img: require("@/assets/images/sidebar/login.png"),
          name: "ورود و ثبت نام",
          link: "/login",
        },
        {
          img: require("@/assets/images/sidebar/trade.png"),
          name: "معاملات",
          link: "",
        },
      ],
      irtMarkets: "",
      usdtMarkets: "",
    };
  },

  methods: {
    dataArray(data) {
      const arr = Object.keys(data).map((key) => {
        return data[key];
      });
      return arr;
    },
    quickly(e) {
      e.preventDefault();
      document.getElementById("identify-quickly").style.display = "block";
      document.getElementById("identify-normal").style.display = "none";
    },

    normal(e) {
      e.preventDefault();
      document.getElementById("identify-quickly").style.display = "none";
      document.getElementById("identify-normal").style.display = "block";
    },
    identify(e) {
      e.preventDefault();

      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/authentication", {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response);
          window.location.replace(
            "https://cloud.uid.ir/crypto/?requestBusinessId=b28d10eb-f1683454246835&metaData=" +
              response.data
          );
        })
        .catch(() => {
          // console.log(e)
        });
    },
    updateMessageCount() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/message-count", {
          withCredentials: true,
        })
        .then((response) => {
          this.messageCount = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    updateUserInfo() {
      if (this.$session.exists("user_info")) {
        axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
            withCredentials: true,
          })
          .then((response) => {
            this.userInfo = response.data;
            this.$session.set("user_info", this.userInfo);
          })
          .catch(() => {
            this.$session.destroy();
          });
      }
    },
    changeTab(item) {
      this.$emit("handleActiveTab", item);
      this.closeNavbar();
    },
    closeNavbar(item) {
      // close the navbar by clicking a menu item
      if (item !== "menuebtn") {
        $("#myCheckbox").prop("checked", false);
      }

      $("#navbarNav").collapse("hide");
      $("#collapse2").collapse("hide");
    },
    updateMarkets() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=IRT")
        .then((response) => {
          const data = this.dataArray(response.data);
          this.irtMarkets = data;
        });

      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats?quote_asset=USDT")
        .then((response) => {
          const data = this.dataArray(response.data);
          this.usdtMarkets = data;
        });
    },
    pollData() {
      this.polling = setInterval(() => {
        this.updateMessageCount();
      }, 3000);
    },
  },

  created() {
    if (this.session.exists("user_info")) {
      this.updateMessageCount();
    }
  },

  mounted() {
    this.$root.$on("annClicked", () => {
      this.updateMessageCount();
    });
    this.updateUserInfo();
    this.updateMarkets();
  },
  watch: {
    $route: function() {
      if (
        this.$route.name === "Messages" ||
        this.$route.name === "Message" ||
        this.$route.name === "Announcements" ||
        this.$route.name === "Announcement"
      ) {
        this.pollData();
      } else {
        return this.$nextTick(() => {
          clearInterval(this.polling);
        });
      }
    },
  },
  beforeDestroy() {
    this.$nextTick(() => {
      clearInterval(this.polling);
    });
  },
};
</script>

<style scoped>
ul {
  list-style: none !important;
}
nav {
  background-color: #1e1919;
  border-bottom: 2px solid #4263eb;
  width: 100%;
}
.nav-item,
.nav-link,
.navbar-brand {
  color: #fff !important;
}
.navbar-toggler {
  padding: 0;
}

/* hambergur menu */

#menuToggle {
  position: relative;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -6px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    /* background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), */ opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(-45deg) translate(-1px, 2px);
  background: #d1d1d1;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(45deg) translate(-5px, -10px);
}
/* hambergur menu */

/* mainNavbar-sm */
.navbar-custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.navbar-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.mainNavbar-sm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
}
.rightMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.rightNavBar-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.rightNavBar-sm ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 0;
}
.logo {
  width: 140px;
}
.leftNavBar-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.notifElement {
  position: relative;
  width: 30px;
  cursor: pointer;
}
.notifElement span {
  position: absolute;
  bottom: 20px;
  left: 17px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: red;
  border-radius: 100%;
  color: #fff;
}
.leftNavBar-sm img {
  width: 30px;
}
.userElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
}
.userElement span {
  font-size: 15px;
}
.navbar-li a {
  width: fit-content !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.navbar-li a img {
  width: 25px !important;
}
.navbar-li a:hover {
  color: #4263eb !important;
}
/* mainNavbar-sm */

/* existButton */
.navbar-exist {
  display: flex;
  justify-content: center;
  align-items: center;
}
.exitButton {
  border: solid 1px #ffff;
  border-radius: 12px;
  padding: 10px;
  background-color: #ea3943;
  width: fit-content;
  font-size: 13px;
  cursor: pointer;
}
.exitButton:hover {
  background-color: #ea394295;
}
/* existButton */
.login-btn {
  border: solid 1px #fff;
  padding: 5px 20px;
  background-color: #302d2c;
  border-radius: 10px;
  font-size: 14px;
}
.login-btn:hover {
  background-color: #4263eb;
  color: #fff;
}
/* accordion */
.card-accordion {
  margin: 1rem;
  border: 1px solid #c78830;
}
.header-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  cursor: pointer;
}
.header-accordion-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.header-accordion-right img {
  width: 25px;
}
.btn-accordion {
  width: 40px;
  border: none;
  background-color: inherit;
}
.body-accordion {
  text-align: right;
  padding: 1rem;
}
.accordion-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordionBoxTransactionBody {
  padding: 5px 0 0 0;
}
.accordionBoxTransactionBody .eachBox {
  padding: 10px 0 0 0;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}
/* modal */
.modal-content {
  background-color: #302d2c;
  border: 2px solid #f8aa0f;
  box-shadow: 0px 0px 20px #f8aa0f;
  border-radius: 16px;
  width: 100%;
}
.modal-content {
  background-color: #302d2c;
  border: 2px solid #f8aa0f;
  box-shadow: 0px 0px 20px #f8aa0f;
  border-radius: 16px;
  width: 100%;
}

.logo-img {
  width: 25px;
  background-color: transparent;
}

#identify-quickly {
  display: block;
  margin: auto;
}

#identify-normal {
  display: none;
  margin: auto;
}

.modal-header {
  border: unset;
}

.modal-body .card {
  background: #4a4a48;
  border: 1px solid #ffffff;
  border-radius: 16px;
  width: 340px;
  box-shadow: unset;
  height: 330px;
}

.modal-body p {
  font-size: 16px;
  text-align: justify;
  margin: auto;
  padding: 12px;
}

.modal-body {
  padding-bottom: 0rem;
}

.modal-dialog {
  max-width: 850px;
  margin: 2rem auto;
}

.logo-site-identify {
  width: 460px;
  margin-right: 15px;
}

.card-row {
  border-bottom: 1px solid #666;
}

.card-row:before {
  border-top: 1px solid #666;
}

.card-btn {
  background: #1e1919;
  border: 1px solid #ffffff;
  border-radius: 0px 8px 8px 0px;
  color: white;
  font-size: 14px;
  margin: auto;
  margin: 15px 0px;
}

.card-btn2 {
  background: #1e1919;
  border: 1px solid #ffffff;
  border-radius: 8px 0px 0px 8px;
  color: white;
  font-size: 14px;
  margin: auto;
  margin: 15px 0px;
}

.card-btn3 {
  background: #302d2c;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  margin: auto;
  width: 250px;
  margin: 15px 0px;
}

.card-btn.active,
.card-btn.selected {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn2:hover {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn3:hover {
  background: #4a4a48 !important;
  border: 1px solid #ffffff !important;
  box-shadow: inset 0px 0px 15px #000000 !important;
}

.card-btn:focus {
  background-color: transparent;
  color: #f8aa0f;
}

.card-btn2:focus {
  background-color: transparent;
  color: #f8aa0f;
}

.card ul {
  font-size: 15px;
  margin: 10px 0px;
  padding: 0px;
}

.card ul li {
  margin: 10px 0px;
}
.global-stats {
  background-color: #4263eb;
  height: 2px;
}

.dropdown-menu {
  font-size: 13px;
  padding: 5px;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu.custom {
  margin-right: -20px;
}

.dropdown-menu .custom2 {
  margin-right: -55%;
}

.avatar {
  background-color: #333;
  border-radius: 50%;
  padding: 10px 15px;
  margin-left: 10px;
  border: 0;
  color: #fff;
}

.avatar2 {
  background-color: transparent;
  margin-left: 10px;
  border: 0;
  color: #fff;
}

.login .btn {
  border-radius: 20px;
  width: 150px;
  background-color: #393535;
  border: 0;
  font-size: 13px;
}

.market-selector {
  background-color: #504f4d !important;
  border: 0;
  min-width: 100px;
}

.market-selector:active,
.market-selector:focus {
  border: 0 !important;
}

.dropdown-toggle:after {
  left: 4px !important;
}

.navbar-brand img {
  max-width: 150px;
  margin-top: 8px;
}

.navbar-toggler {
  color: #fff;
  border: 0;
}

.nav-item {
  margin-right: 0 !important;
}

.market-selector {
  background-color: #504f4d !important;
  border: 0;
  min-width: 100px;
}

.market-selector:active,
.market-selector:focus {
  border: 0 !important;
}

.dropdown-toggle:after {
  left: 4px !important;
}

.notif-bnt {
  color: #e0a800;
  background-color: transparent;
  border-radius: 50%;
  padding: 10px 10px;
  margin-left: 5px;
  border: 0;
  font-size: 16px;
  position: relative;
}

.notif-bnt .badge {
  position: absolute;
  top: 0;
  right: 0 !important;
  padding: 5px 7px !important;
  border-radius: 50% !important;
  background: red;
  color: white;
  font-size: 10px;
  font-weight: normal;
}
.nav-link-sm {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.btn-nav-accordion {
  width: 20px;
}
.btn-nav-accordion[aria-expanded="true"] {
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}
.btn-nav-accordion[aria-expanded="false"] {
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

/* menu */
.dropdown-menu-custom {
  width: 160px !important;
  height: 400px !important ;
  overflow-y: scroll !important ;
  position: absolute !important ;
  right: 0 !important;
  left: 50% !important;
  transform: translate(40%, 0px) !important;
  font-size: 12px;
}
.dropdown-menu-custom {
  overflow-x: hidden;
  right: 40px !important;
  top: 30px !important;
}

.dropdown-menu-custom a {
  padding: 5px 5px;
}

/* scroll for mainTable */

/* mozila */
.dropdown-menu-custom {
  scrollbar-color: rgba(255, 255, 255, 0.724) #434343 !important;
}
/* another browser */
.dropdown-menu-custom::-webkit-scrollbar {
  height: 5px !important;
  width: 6px !important;
}

.dropdown-menu-custom::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.724) !important;
  height: 1px !important;
}
.dropdown-menu-custom::-webkit-scrollbar-thumb {
  background-color: #434343 !important;
  border-radius: 10px !important;
  height: 1px !important;
  margin: 5px 10px !important;
}
/* identy-btn */
.hide-btn {
}
.show-btn {
  display: none !important;
}
/* // */
@media screen and (max-width: 992px) {
  .modal-content {
    background-color: #302d2c;
    border: 2px solid #f8aa0f;
    box-shadow: 0px 0px 20px #f8aa0f;
    border-radius: 16px;
    width: 100%;
  }

  #identify-quickly {
    display: block;
    margin: auto;
  }

  #identify-normal {
    display: none;
    margin: auto;
  }

  .modal-header {
    border: unset;
  }

  .modal-body .card {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 16px;
    width: 340px;
  }

  .modal-body p {
    font-size: 16px;
    text-align: justify;
    margin: auto;
    padding: 12px;
  }

  .modal-body {
    padding-bottom: 0rem;
  }

  .modal-dialog {
    max-width: 750px;
    margin: 2rem auto;
  }

  .logo-site-identify {
    width: 400px;
    margin-right: 12px;
  }

  .card-row {
    border-bottom: 1px solid #666;
  }

  .card-row:before {
    border-top: 1px solid #666;
  }

  .card-btn {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 0px 8px 8px 0px;
    color: white;
    font-size: 14px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn2 {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 8px 0px 0px 8px;
    color: white;
    font-size: 14px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn3 {
    background: #302d2c;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    margin: auto;
    width: 250px;
    margin: 15px 0px;
  }

  .card-btn:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn2:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn3:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }

  .card-btn:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card-btn2:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card ul {
    font-size: 15px;
    margin: 10px 0px;
    padding: 0px;
  }

  .card ul li {
    margin: 10px 0px;
  }
}

@media screen and (max-width: 768px) {
  .logo-site-identify {
    width: 400px;
    margin: auto;
    margin-right: 104px;
  }

  .modal-header {
    border: unset;
    margin: unset;
    padding: 8px 10px 0px 10px;
  }

  .modal-header h4 {
    font-size: 20px;
  }

  .modal-body .card {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 16px;
    width: 400px;
    margin: 10px 0px;
  }

  .modal-body p {
    font-size: 16px;
    text-align: justify;
    margin: auto;
    padding: 12px;
  }

  .modal-dialog {
    max-width: 550px;
    margin: 2rem auto;
  }

  .card-btn {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 0px 8px 8px 0px;
    color: white;
    font-size: 14px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn2 {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 8px 0px 0px 8px;
    color: white;
    font-size: 14px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn3 {
    background: #302d2c;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    margin: auto;
    width: 250px;
    margin: 15px 0px;
  }

  .card-btn:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn2:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn3:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }

  .card-btn:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card-btn2:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card ul {
    font-size: 14px;
    margin: 10px 0px;
    padding: 0px;
  }

  .modal-content {
    padding: 0px 0px 20px 0px;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.profile {
    margin-right: 0px !important;
  }

  .avatar {
    margin-left: 0 !important;
  }

  .logo-site-identify {
    width: 270px;
    margin: auto;
    margin-right: 53px;
  }

  .modal-header {
    border: unset;
    margin: unset;
    padding: 8px 10px 0px 10px;
  }

  .modal-header h4 {
    font-size: 16px;
  }

  .modal-body .card {
    background: #4a4a48;
    border: 1px solid #ffffff;
    border-radius: 16px;
    width: 300px;
    margin: 10px 0px;
  }

  .modal-body p {
    font-size: 13px;
    text-align: justify;
    margin: auto;
    padding: 12px;
  }

  .modal-dialog {
    max-width: 350px;
    margin: 2rem auto;
  }

  .card-btn {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 0px 8px 8px 0px;
    color: white;
    font-size: 12px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn2 {
    background: #1e1919;
    border: 1px solid #ffffff;
    border-radius: 8px 0px 0px 8px;
    color: white;
    font-size: 12px;
    margin: auto;
    margin: 15px 0px;
  }

  .card-btn3 {
    background: #302d2c;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    margin: auto;
    width: 250px;
    margin: 15px 0px;
  }

  .card-btn:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn2:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }
  .card-btn3:hover {
    background: #4a4a48 !important;
    border: 1px solid #ffffff !important;
    box-shadow: inset 0px 0px 15px #000000 !important;
  }

  .card-btn:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card-btn2:focus {
    background-color: transparent;
    color: #f8aa0f;
  }

  .card ul {
    font-size: 13px;
    margin: 10px 0px;
    padding: 0px;
  }

  .modal-content {
    padding: 0px 0px 20px 0px;
  }
}
.rightNavBar-sm li {
  font-size: 15px;
}
.rightNavBar-sm li a:hover {
  color: #f8aa0f;
}
.navbar-collapse {
  padding: 20px 0 0 0;
}
/* modal end */
@media screen and (max-width: 576px) {
  .hide-btn {
    display: none;
  }
  .show-btn {
    display: flex !important;
  }
  .leftNavBar-sm img {
    width: 27px;
  }
  .notifElement span {
    position: absolute;
    bottom: 17px;
    left: 21px;
    width: 11px;
    height: 11px;
    font-size: 0px;
  }
}
@media screen and (max-width: 992px) {
  .mainNavbar-sm {
    display: none !important;
  }
  .logo {
    width: 135px;
  }

  nav {
    padding: 15px 15px;
  }
  .userElement span {
    display: none;
  }
  .rightNavBar-sm li {
    font-size: 14px;
  }
  .navbar-li {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    display: none !important;
  }
  .leftMenu {
    display: none;
  }
}
</style>
