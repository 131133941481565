<template>
   <div class="row static">
            <div class="container">
                <section id="tabs">
                    <div class="container">
                        <div class="row gradient">
                            <div class="login-wrapper mb-4">

                                    <div>

                                        <div class="row mb-5">
                                            <h4 class=" m-auto">غیرفعال سازی کد دوعاملی</h4>
                                        </div>
                                        <div>
                                            <p class="alert alert-info small m-auto text-justify">کاربر گرامی، در صورت عدم دسترسی به شناسه دوعاملی، می توانید از طریق این صفحه اقدام به حذف شناسه دوعاملی از حساب کاربری خود نمایید. همچنین در صورت تکمیل این پروسه و حذف شناسه دوعاملی برداشت رمزارز حساب شما به مدت 24 ساعت محدود خواهد شد. </p>
                                        </div>
                                        
                                    </div>

                                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                    <div class="row">
                                        <div class="col-12">
                                            <form @submit="submitLogin">
                                                <div class="input-group mt-3 mb-3">
                                
                                                    <input type="text" class="form-control latin" placeholder="ایمیل" name="loginEmail" id="loginEmail"
                                                            v-model="loginEmail"
                                                            dir="ltr">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <!-- <font-awesome-icon :icon="['far', 'envelope']" /> -->
                                                            <img class="login-logo" src="../assets/images/mail2.png" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="input-group mt-3 mb-3">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">
                                                            <font-awesome-icon :icon="eyeIcon" class="clickable text-white"
                                                                            @click="switchVisibility()"/>
                                                        </span>
                                                    </div>
                                                    <input :type="passwordFieldType" class="form-control latin" name="loginPassword" id="loginPassword"
                                                            placeholder="رمز عبور" v-model="loginPassword"
                                                            dir="ltr">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <img class="login-logo" src="../assets/images/key2.png" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="input-group mt-3 mb-3">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" style="padding: 2px">
                                                            <img :src="backendRoot + 'assets/captcha.png?v=' + loadTime" style="border-radius: 5px !important; margin-left: 2px" />
                                                            <a class="btn btn-sm btn-light" @click="updateLoadTime"> <font-awesome-icon class="round-arrow" icon="redo"/></a>
                                                        </span>
                                                    </div>
                                                    <input type="tel" class="form-control latin" name="captcha" id="captcha"
                                                            placeholder="کد امنیتی" v-model="captchaCode" autocomplete="off"
                                                            dir="ltr">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <img class="login-logo" src="../assets/images/puzzle-piece2.png" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center mt-2">
                                                    <input class="btn btn-warning login-btn" type="submit"
                                                            value="مرحله بعد">
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
</template>

<script>
    
    import axios from 'axios';
    import { Auth } from 'aws-amplify';

    export default {
        name: 'ForgetTfa',
        title: 'غیر فعالسازی رمز دوعاملی',
        props: {},
        components: {
            
        },
        metaInfo () {
            return {
                title: 'غیر فعالسازی رمز دوعاملی',
                titleTemplate: '%s - لیدیا پلتفرم تبادل ارزهای دیجیتال',
                meta: [
                    { name: 'og:title', vmid: 'og:title', content: 'غیر فعالسازی رمز دوعاملی' },
                    { name: 'description',  vmid: 'description', content: 'غیر فعالسازی رمز دوعاملی' },
                    { name: 'og:description', vmid: 'og:description', content: 'غیر فعالسازی رمز دوعاملی' },
                ]
            }
        },
        data() {
            return {
                password: '',
                passwordFieldType: 'password',
                loginEmail: '',
                loginPassword: '',
                eyeIcon: 'eye-slash',
                registerEmail: '',
                registerPassword: '',
                registerConfirmPassword: '',
                registerMobile: '',
                registerRefCode: '',
                registerCheck: '',
                isRegister: this.$route.name === 'Register',
                isLogin: this.$route.name === 'Login',
                captchaCode: '',
                loadTime : 0,
                backendRoot: process.env.VUE_APP_BACKEND_ROOT

            }
        },
        created() {

            this.registerRefCode = this.$route.query.ref_code;
            this.loadTime = Date.now()

        },
        methods: {
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },
            auth() {
                try {
                    Auth.federatedSignIn({ provider: 'Google' });
                } catch (error) {
                    console.log('error:', error);
                }
            },


            updateLoadTime(e){
                e.preventDefault();
                this.loadTime = Date.now();
                this.captchaCode = '';
            },

            resetVisibility() {
                this.eyeIcon = 'eye-slash';
                this.passwordFieldType = 'password';
            },
            switchVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
                this.eyeIcon = this.eyeIcon === 'eye' ? 'eye-slash' : 'eye'
            },
            submitLogin(e) {
                e.preventDefault();
                const loginInfo = {
                    email: this.loginEmail,
                    password: this.loginPassword,
                    captcha : this.captchaCode
                };

                if (this.loginEmail !== '' && this.loginPassword !== '' && this.captchaCode !== '') {
                    this.notice('info', 'در حال ورود ...');

                    axios.post(process.env.VUE_APP_BACKEND_URL + '/user/login', loginInfo, {withCredentials: true})
                        .then((response) => {
                            const userInfo = response.data;              

                            if (userInfo.is_otp_active) {
                                this.$session.set('user_info_email', userInfo);
                                return this.$router.push({name: 'ForgetTfaVerify'});
                            }else{
                                return this.notice('error', 'خطا', 'در حال حاضر کد دوعاملی شما غیر فعال می‌باشد.', 5);
                            }

                        })
                        .catch((e) => {
                            if (e.response.data !== undefined) {
                                const data = e.response.data;
                                const errors = '<ul>' + data.errors.map(function (err) {
                                    return '<li>' + err + '</li>';
                                }).join('') + '</ul>';
                                this.notice('error', 'خطا', errors, 10);
                                return;
                            }

                            return this.notice('error', 'خطا', 'ایمیل یا رمز عبور نادرست است', 5);

                        });
                } else {
                    this.notice('error', 'خطا', 'ایمیل و رمز عبور و کد امنیتی را وارد نمایید', 3);
                }

            },
        },



    }
</script>

<style scoped>

    .login-btn {
        width: 315px;
        border-radius: 20px;
    }

    .static {
        background: url("../assets/images/map_black.png") no-repeat center top;
        background-size: 100%;
        min-height: 900px;
        width: unset;
        height: 100%;
        
    }

    .latin{
        color: white;
    }

    .static .content {
        margin-top: 50px;
    }


    .login-wrapper {
        margin-right: auto;
        margin-left: auto;
        margin-top: 5%;
        width: 400px;
        padding: 30px;
        border: 2px solid #FFFFFF;
        box-sizing: 10px;
        background: rgba(48, 45, 44, 0.5);
        box-shadow: 0px 0px 30px #FFFFFF;
        border-radius: 50px;
    }

    .btn-light{
        background-color: transparent;
        color: white !important;
    }

    .input-group-prepend {
        border: 0;
    }

    input {
        border: 0;
    }
    

    input:focus {
        box-shadow: none;
    }

    .input-group-prepend .input-group-text {
        background-color: transparent !important;
        color: white !important;
        border: 1px solid #F8AA0F;
        border-radius: 15px;
    }

    .input-group-append .input-group-text {
        background-color: transparent !important;
        color: #000 !important;
        border: 1px solid #F8AA0F;
        border-radius: 15px;
    }

    .form-control{
        background-color: transparent;
        border: 1px solid #F8AA0F;
        border-radius: 15px;
    }

    ::placeholder{
        color: white;
    }

    .login-logo{
        width: 24px;
        height: 24px;
    }

    .btn-warning{
        background-color: #4A4A48;
        color: white;
        border: 1px solid #F8AA0F;
        margin-top: 10px;
    }

    .btn-warning:hover {  
        background: #4A4A48;
        border: 1px solid #FFFFFF;
        box-shadow: inset 0px 0px 15px #000000;
        border-radius: 31px;
    }

    ::-webkit-input-placeholder {
        text-align: center;
        font-family: IRANSans,serif;
    }

    :-moz-placeholder { /* Firefox 18- */
        text-align: center;
        font-family: IRANSans,serif;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        text-align: center;
        font-family: IRANSans,serif;
    }

    :-ms-input-placeholder {
        text-align: center;
        font-family: IRANSans,serif;
    }

    
    @media screen and (max-width: 700px) {

        .static {
            background: url("../assets/images/map_black.png") no-repeat center top;
            background-size: 100%;
            min-height: 100px;
            width: unset;
            
        }
    
        .login-wrapper {
            margin-top: 5%;
            width: 350px;
            border: 2px solid #FFFFFF;
            box-sizing: 10px;
            background: rgba(48, 45, 44, 0.5);
            box-shadow: 0px 0px 30px #FFFFFF;
            border-radius: 50px;
        }
        .login-btn {
            width: 200px;
            border-radius: 20px;
        }

        .login-logo{
            width: 18px;
            height: 18px;
            color: white;
        }

        ::placeholder{
            color: white;
            font-size: 13px;
        }

    }
</style>
