<template>
  <div class="my-5 main">
    <div class="container">
      <div class="login-wrapper box">
        <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-login"
            role="tabpanel"
            aria-labelledby="nav-login-tab"
          >
            <div class="row mt-2">
              <div v-if="session.exists('user_info')" class="col-12 latin">
                {{ session.get("user_info").email }}
              </div>
              <div class="col-12">
                <form @submit="emailVerify">
                  <div class="input-group mt-3 mb-3">
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="کد دریافتی در ایمیل"
                      dir="ltr"
                      v-model="token"
                      :disabled="isDisabled"
                    />
                  </div>
                  <div class="col-12 text-center mt-2">
                    <input
                      class="btn btn-primary btn-lg btn-block"
                      type="submit"
                      value="تایید ایمیل"
                      :disabled="isDisabled"
                    />
                  </div>
                </form>
                <div
                  v-if="session.exists('user_info')"
                  class="col-12 mt-4 hint"
                >
                  ایمیلی دریافت نکرده اید؟
                  <a href="#" class="p-1" @click="emailResend">
                    ارسال مجدد ایمیل
                  </a>
                </div>
                <div class="col-12 text-center mt-4">
                  <span class="cursor" @click="logout"> بازگشت به صفحه ورود </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EmailVerify",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      markets: [],
      token: "",
      isDisabled: false,
      resent: false,
      session: this.$session,
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    checkKycLevel(userInfo) {
      if (userInfo.kyc_step === 1) {
        return this.$router.push({ name: "MobileVerify" });
      } else if (userInfo.kyc_step === 2) {
        return this.$router.push({ name: "IdentityVerify" });
      } else if (userInfo.kyc_step === 3) {
        return this.$router.push({ name: "Profile" });
      }
    },

    emailVerify(e) {
      if (e !== undefined) {
        e.preventDefault();
      }

      this.notice("info", "در حال اعتبارسنجی ایمیل...", "", 4);
      const emailInfo = {
        token: this.token,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/user/email-verify",
          emailInfo,
          { withCredentials: true }
        )
        .then(() => {
          this.notice("success", "تایید ایمیل", "ایمیل با موفقیت تایید شد");
          const userInfo = this.session.get("user_info");
          userInfo.kyc_step = 1;
          this.$session.remove("user_info");
          this.$session.set("user_info", userInfo);
          this.$router.push({ name: "MobileVerify" });
          return;
        })
        .catch((e) => {
          if (e.response !== undefined) {
            this.notice("error", "خطا", e.response.data.message, 5);
          }
          this.isDisabled = false;
        });
    },
    emailResend(e) {
      if (this.isDisabled) {
        return;
      }

      if (this.resent) {
        this.notice(
          "warning",
          "درخواست تکراری",
          "درخواست ارسال مجدد ایمیل ثبت شده است.",
          4
        );
        return;
      }

      if (e !== undefined) {
        e.preventDefault();
      }

      this.resent = true;

      this.notice("info", "درخواست ایمیل مجدد...", "", 4);
      const resendInfo = {
        email: this.session.get("user_info").email,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/user/email-resend",
          resendInfo,
          { withCredentials: true }
        )
        .then(() => {
          this.notice(
            "success",
            "درخواست ایمیل مجدد",
            "ایمیل با موفقیت مجددا برایتان ارسال شد"
          );
        })
        .catch((e) => {
          this.notice("error", "خطا", e.response.data.message, 5);
          this.isDisabled = false;
        });
    },
    logout() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/logout", {
          withCredentials: true,
        })
        .then(() => {
          this.$session.destroy();
          return this.$router.push("/login");
        })
        .catch(() => {
          // console.log(e)
        });
    },
  },
  created() {
    if (this.session.exists("user_info")) {
      // console.log(this.session.get('user_info'));
      this.checkKycLevel(this.session.get("user_info"));
    }
    if (
      this.$route.query.token !== undefined &&
      this.$route.query.token !== ""
    ) {
      this.token = this.$route.query.token;
      this.isDisabled = true;
      this.emailVerify();
    }
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.hint {
  font-size: 11px;
}

.hint a {
  color: #f7a906;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.login-btn {
  width: 200px;
  border-radius: 20px;
}

.static {
  background: url("../assets/images/home-page-background.png") no-repeat center
    top;
  background-size: 100%;
  min-height: 500px;
}

.static .content {
  margin-top: 50px;
}

.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 400px;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0.5);
}

.nav-item {
  border-bottom: 4px solid #f7a906;
  color: #f7a906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}

.input-group-prepend .input-group-text {
  background-color: #fff !important;
  color: #f7a906 !important;
  border: 0;
}

.input-group-append .input-group-text {
  background-color: #fff !important;
  color: #000 !important;
  border: 0;
}

.form-control {
  background-color: transparent;
  border: 1px solid #4c5157;
  letter-spacing: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
}

::placeholder {
  color: #8a8e91;
  text-align: right !important;
}

.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
  font-family: IRANSans, serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  font-family: IRANSans, serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  font-family: IRANSans, serif;
}

:-ms-input-placeholder {
  text-align: center;
  font-family: IRANSans, serif;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}
.main {
  max-height: 500px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor{
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
  .login-wrapper {
    width: 320px;
  }
}
</style>
