<template>
    <div class="row mt-4 mr-3 ml-3 mb-2 p-3 box">
        <div class="col-2 logo">
            <img v-if="asset.logo_filename"
                 :src="`${cdnUrl}/media/asset/logo/md/${asset.logo_filename}`"
                 :alt="asset.ticker">
        </div>
        <div class="col-10 col-md-6 pt-2">
            <h4 class="bold">
                واریز {{asset.name}}
                <span class="text-dark small">{{asset.ticker}}</span>
            </h4>
            <p class="text-secondary small">
                لطفا
                جهت واریز
                {{asset.name}}
                ‌از آدرس اختصاصی تولید شده، که در کادر زیر نمایش داده شده است استفاده نمایید
            </p>
        </div>
        <div class="col-12 col-md-4">
            <div class="row">
                <div class="col-12 mb-2">
                                            <span class="text-secondary">
                                                موجودی:
                                            </span>
                    {{balance.available | prec(asset.prec)}}
                </div>
                <div class="col-12">
                                            <span class="text-secondary">
                                               در سفارشات:
                                            </span>
                    {{balance.freeze | prec(asset.prec)}}
                </div>
            </div>

        </div>
        <div class="col-12 mt-2">
            <div v-if="asset.description !==''" class="alert alert-secondary small p-2" v-html="asset.description">
                {{asset.description}}
            </div>
            <div class="row mt-4">
                <div class="col-12">


                <div class="vld-parent" ref="tableContainer">
                    <ul class="nav nav-tabs nav-pills nav-justified" id="marketTabs" role="tablist">
                        <li v-for="(network, n) in asset.networks" :key="n"  class="nav-item text-center">
                            <a class="nav-link latin2" :id="n + 'tab'" data-toggle="tab" :href="'#'+n" role="tab" v-bind:class="{ active: network.first_item }">{{network.name}}</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-3 mb-3" id="myTabContent">
                        <div v-for="(network, n) in asset.networks" :key="n" class="tab-pane fade show" :id="n" role="tabpanel" v-bind:class="{ active: network.first_item }">
                            <div class="row">
                                <div class="col-md-9">
                                    <div v-if="network.min_deposit_amount > 0" class="alert alert-info small">
                                        حداقل میزان واریز قابل قبول:
                                        {{network.min_deposit_amount | prec(asset.prec)}}
                                        {{asset.ticker}}
                                    </div>
                                    <div v-if="network.description !==''" class="alert alert-secondary small" v-html="network.description">
                                        {{network.description}}
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 qrcode text-center">
                                    <qrcode-vue :value="network.address" size="200" level="H"></qrcode-vue>
                                </div>

                                <div class="col-12 mt-3">
                                    <div class="input-group input-group-lg">
                                        <div class="input-group-prepend">
                                                        <span class="input-group-text clickable"
                                                              v-clipboard:copy="network.address"
                                                              v-clipboard:success="onCopy">
                                                            <font-awesome-icon icon="copy"/>
                                                        </span>
                                        </div>
                                        <input type="text" class="form-control latin"
                                               dir="ltr" v-model="network.address" readonly>


                                    </div>
                                </div>
                                <div class="col-12 mt-3" v-if="!!network.comment">
                                    <div class="input-group input-group-lg">
                                        <div class="input-group-prepend">
                                                        <span class="input-group-text clickable"
                                                              v-clipboard:copy="network.comment"
                                                              v-clipboard:success="onCopy">
                                                            <font-awesome-icon icon="copy"/>
                                                        </span>
                                        </div>
                                        <input type="text" class="form-control latin"
                                               dir="ltr" v-model="network.comment" readonly>

                                      <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            Memo
                                                        </span>
                                      </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>



                </div>


            </div>
        </div>
    </div>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'

    export default {
        name: 'DepositBoxCrypto',
        props: ['asset', 'balance'],
        components: {
            QrcodeVue,
        },
        data() {
            return {
                backendRoot: process.env.VUE_APP_BACKEND_ROOT,
                cdnUrl: process.env.VUE_APP_CDN_URL,
            }
        },
        methods:{
            onCopy: function () {
                 this.notice('success', 'کپی آدرس', 'آدرس با موفقیت کپی شد.', 3);
            },
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },
        }
    }
</script>

<style scoped>
    h4 .small{
        font-size: 14px;
    }

    .nav-pills .nav-link:not(.active) {
        background-color: #FCE6A3 !important;
        border: 1px solid #FFC107 !important;
    }

    .nav-pills .nav-link {
        background-color: #FFC107 !important;
        color: #333;
    }

    .box .logo img {
        max-width: 100px;
    }

    .nav-link {
        color: #000 !important;
    }
    @media screen and (max-width: 600px) {
        .box {
            font-size: 12px;
        }

        .box h4 {
            font-size: 18px;
        }

        .box .logo img {
            max-width: 50px;
        }
    }
</style>
