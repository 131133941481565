<template>
    <table class="table table-borderless">
        <thead>
        <tr>
            <th scope="col">زمان</th>
            <th scope="col">IP</th>
            <th scope="col">دستگاه</th>
            <th scope="col">وضعیت</th>
        </tr>
        </thead>
        <tbody v-if="logs !== undefined && logs.total > 0">
        <tr v-for="log in logs.records" :key="log.time">

            <td class="ltr">{{log.time | persianDateUnix}}</td>
            <td class="latin">{{log.ip}}</td>
            <td class="latin">{{log.user_agent}}</td>
            <td v-if="log.is_success" class="text-success">موفق</td>
            <td v-else class="text-danger">ناموفق</td>
        </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="6" class="text-center">
                        گزارشی موجود نمی باشد
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
  name: 'LoginLogTable',
  props: ['logs']
}
</script>

<style scoped>
    table thead th {
        border-bottom: 2px solid #999;
    }

    table tbody tr {
        border-bottom: 1px solid #999;
    }
    .open-orders table {
        font-size: 12px;
        border: 0;
        border-spacing: 0;
    }

</style>
