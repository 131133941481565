<template>
  <div class=" px-4 my-5 ">
    <div class="container">
      <div class="box p-5 mt-3 mb-2">
        <div
          v-if="announcement !== undefined && announcement != null"
          class="vld-parent table-responsive"
          ref="tableContainer"
        >
          <h3>{{ announcement.title }}</h3>
          <div class="small mt-2 gray">
            منتشر شده در:
            <span class="ltr">
              {{ announcement.created_at | persianDateUnix }}
            </span>
          </div>
          <div class="mt-3 small">
            <p v-html="announcement.message"></p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <router-link
            to="/user/announcements"
            class="btn btn-primary history-btn"
          >
            بازگشت
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Announcement",
  title: "اعلان‌ها",

  props: ["announcement_id"],
  components: {},
  data() {
    return {
      announcement: [],
      pageCount: 0,
      assets: [],
      markets: [],
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    pagination(page) {
      this.getAnnouncements(page);
    },
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    getAnnouncements() {
      let loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
        bars: "dots",
      });

      let url = `${process.env.VUE_APP_BACKEND_URL}/user/announcement/${this.announcement_id}`;
      axios
        .get(url, { withCredentials: true })
        .then((response) => {
          this.announcement = response.data;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.checkSession();
    this.updateMarketList();
  },
  mounted() {
    this.getAnnouncements();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

.history-btn {
  width: 200px;
}

.blue {
  color: #3a4aa9;
}

.gray {
  color: #d2d2d2;
}


@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}
</style>
