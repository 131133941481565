<template>
  <div class="my-5 main">
    <div class="container">
      <div class="login-wrapper box">
        <section id="tabs">
          <div class="container">
            <div class="row">
              <div class="login-wrapper">
                <nav>
                  <div class="nav" id="nav-tab" role="tablist" style="position: relative">
                    <a class="nav-item active" id="nav-login-tab" data-toggle="tab"
                       href="#" role="tab" aria-controls="nav-login" aria-selected="true">
                      بازیابی رمز عبور
                    </a>

                  </div>
                </nav>
                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-login" role="tabpanel"
                       aria-labelledby="nav-login-tab">
                    <div class="row">
                      <div class="col-12">
                        <form @submit="resetPassword">
                          <div class="input-group mt-3 mb-3 ltr">
                            {{ mobile }}
                          </div>
                          <div class="input-group mt-3 mb-3">
                            <div class="input-group-append">
                                                        <span class="input-group-text">
                                                            <font-awesome-icon :icon="eyeIcon" class="clickable"
                                                                               @click="switchVisibility"/>
                                                        </span>
                            </div>
                            <input :type="passwordFieldType" class="form-control" name="registerPassword"
                                   id="registerPassword"
                                   placeholder="رمز عبور جدید"
                                   dir="ltr" v-model="newPassword">

                          </div>
                          <div class="input-group mt-3 mb-3">
                            <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="کد دریافتی در تلفن همراه"
                                dir="ltr"
                                v-model="token"
                            />
                          </div>
                          <div class="input-group mt-3 mb-3">
                            <div class="input-group-append">
                                                        <span class="input-group-text" style="padding: 2px">
                                                            <img :src="backendRoot + 'assets/captcha.png?v=' + loadTime"
                                                                 style="border-radius: 5px !important; margin-left: 2px"/>
                                                            <a class="btn btn-sm btn-light" @click="updateLoadTime"> <font-awesome-icon
                                                                icon="redo"/></a>
                                                        </span>
                            </div>
                            <input type="tel" class="form-control latin" name="captcha"
                                   id="captcha2"
                                   placeholder="کد امنیتی" v-model="captchaCode" autocomplete="off"
                                   dir="ltr">
                          </div>
                          <div class="col-12 text-center mt-3">
                            <input class="btn btn-warning login-btn" type="submit"
                                   value="تغییر رمز عبور">
                          </div>

                        </form>
                        <div class="col-12 text-right mt-3">
                          <router-link to="/login">
                            ورود / ثبت نام
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'ResetPassword',
  title: 'بازیابی رمز عبور',
  components: {},
  data() {
    return {
      password: '',
      token: '',
      passwordFieldType: 'password',
      eyeIcon: 'eye-slash',
      newPassword: null,
      mobile: null,
      captchaCode: '',
      loadTime: 0,
      backendRoot: process.env.VUE_APP_BACKEND_ROOT
    }
  },

  methods: {
    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      })
    },
    updateLoadTime(e) {
      e.preventDefault();
      this.loadTime = Date.now();
      this.captchaCode = '';
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.eyeIcon = this.eyeIcon === 'eye' ? 'eye-slash' : 'eye'
    },
    checkKycLevel(userInfo) {
      if (userInfo.kyc_step === 0) {
        return this.$router.push({name: 'MobileVerify'});
      } else if (userInfo.kyc_step === 1) {
        return this.$router.push({name: 'MobileVerify'});
      } else if (userInfo.kyc_step === 2) {
        return this.$router.push({name: 'IdentityVerify'});
      } else {
        return this.$router.push({name: 'Profile'});
      }
    },
    resetPassword(e) {
      e.preventDefault();
      if (this.newPassword === null) {
        this.notice('error', 'خطا', 'لطفا رمز عبور جدید را به درستی وارد نمایید', 5);
        return;
      }


      this.notice('info', 'در حال بررسی اطلاعات ارسالی');

      const emailInfo = {
        mobile: this.mobile,
        new_password: this.newPassword,
        token: this.token,
        captcha: this.captchaCode
      };

      axios.post(process.env.VUE_APP_BACKEND_URL + '/reset-password', emailInfo , {
        withCredentials: true
      })
          .then(() => {
            this.newPassword = null;
            this.notice('success', 'عملیات موفق', 'رمز عبور شما با موفقیت تغییر یافت');
            return this.$router.push('/login');

          })
          .catch(e => {
            this.notice('error', 'خطا', e.response.data.error, 10);
          });


    }
  },

  created() {
    this.loadTime = Date.now();
    if (this.$session.exists('user_info')) {
      return this.checkKycLevel(this.$session.get('user_info'));
    }
    if (this.$route.query.mobile === undefined) {
      return this.$router.push('/');
    }

    if (this.$route.query.mobile !== null) {
      this.mobile = decodeURI(this.$route.query.mobile.toString());
    }
  }


}
</script>

<style scoped>

.login-btn {
  width: 200px;
}

.static .content {
  margin-top: 50px;
}


.login-wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  width: 70%;
  padding: 30px;
}


.nav-item {
  border-bottom: 4px solid #F7A906;
  color: #F7A906;
  width: 200px;
  text-align: center;
  padding: 5px;
}

.input-group-prepend {
  border: 0;
}

input {
  border: 0;
}

input:focus {
  box-shadow: none;
}


.input-group-prepend .input-group-text {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid #4c5157;
  border-radius: 15px;
}

.input-group-append .input-group-text {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #4c5157;
  border-radius: 15px;
}


.form-control {
  background-color: transparent;
  border: 1px solid #4c5157;
  letter-spacing: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
}

::placeholder {
  color: #8a8e91;
  text-align: right !important;
}

.register-tab {
  position: absolute;
  left: 0;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder { /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

@media (max-width: 600px) {
  .login-wrapper {
    width: 100%;
    padding: 0;
  }
}
</style>
