import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false;
import {library} from '@fortawesome/fontawesome-svg-core'


import {
    faUserSecret,
    faBars,
    faTimes,
    faUser,
    faKey,
    faEye,
    faEyeSlash,
    faPhone,
    faGift,
    faClipboard,
    faCopy,
    faPlus,
    faClock,
    faCheck, faChevronUp, faChevronDown, faExchangeAlt, faPuzzlePiece, faRedo
} from '@fortawesome/free-solid-svg-icons'

import { faBell, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueRouter from 'vue-router'
import router from './router'
import VueCurrencyInput from 'vue-currency-input'
import CripNotice from 'crip-vue-notice'
import percent from 'percent'
import VueTheMask from 'vue-the-mask'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import persianDate from 'persian-date'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueClipboard from 'vue-clipboard2'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import Paginate from 'vuejs-paginate'
import {VueStars} from "vue-stars"
import VueSession from 'vue-session'
import titleMixin from './mixins/titleMixin'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueApexCharts from 'vue-apexcharts'
import ToggleButton from 'vue-js-toggle-button'
// import VueGoogleMap from 'vuejs-google-maps'
import VueMeta from 'vue-meta'

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

//toast
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import bottomNavigationVue from "bottom-navigation-vue";


Vue.use(bottomNavigationVue);

Vue.use(ToastPlugin); // Use the Toast component globally




Vue.use(VueSweetalert2);

/*Vue.use(VueGoogleMap, {
    load: {
        apiKey: 'AIzaSyDNeJK9hVEFtSAbTx-wVWGlFrfK-zRHzGQ',
        libraries: []
    }
});*/


Vue.component('date-picker', VuePersianDatetimePicker);



Vue.component("vue-stars", VueStars);



library.add(faBell);
library.add(faUserSecret);
library.add(faExchangeAlt);
library.add(faBars);
library.add(faTimes);
library.add(faUser);
library.add(faKey);
library.add(faEnvelope);
library.add(faEye);
library.add(faEyeSlash);
library.add(faPhone);
library.add(faGift);
library.add(faClipboard);
library.add(faCopy);
library.add(faPlus);
library.add(faCheck);
library.add(faClock);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faPuzzlePiece);
library.add(faRedo);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueCurrencyInput);

Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(CripNotice);
Vue.use(VueTheMask);

Vue.mixin(titleMixin);


Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

Vue.use(VueSession,
    {
        persist: true
    }
);

Vue.component('paginate', Paginate);



Vue.use(Loading, {
        color: '#4263eb',
        loader: 'dots',
        backgroundColor: '#161c24',
    }
);



Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);



Vue.use(ToggleButton)


VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.filter('numFormat', numFormat(numeral));

Vue.filter('percent', function (value, total) {
    return percent.calc(value, total, 2)
});

Vue.filter('underscoreToSlash', function (value) {
    return value.replace("_", "/");
});


Vue.filter('persianDateUnix', function (value) {
    if(value === undefined || value == null){
        return;
    }
    const p = new persianDate.unix(value);
    return p.format('YY/MM/DD HH:mm');
});


Vue.filter('prec', function (value, prec, isLatin) {
    let format = '0,0';
    if (prec > 0) {
        format = `0,0.[${'0'.repeat(prec)}]`;
    }
    if(isLatin === undefined || isLatin === false){
        return numeral(value).format(format).replace('.', '٫')
    }
    return numeral(value).format(format);
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app1');
// Install BootstrapVue
// Optionally install the BootstrapVue icon components plugin
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueRouter);