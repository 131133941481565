<template>
  <div class=" px-4 my-3 ">
    <div class="row">
      <div class="col-md-12 ">

        <div
            v-if="kycStatus.verify_status === 'rejected'"
            class="col-12 alert alert-warning text-center"
        >
          <p v-if="kycStatus.reject_reason !== ''">
            <b>
              {{ kycStatus.reject_reason }}
            </b>
          </p>
          <br/>
          <router-link to="/user/identity-verify" class="btn btn-primary block">
            ادامه احراز هویت
          </router-link>
        </div>
        <div class="imageContainer d-md-none mb-3">
          <router-link v-if="session.get('user_info').kyc_step != 4" to="/user/identity-verify">
            <img src="@/assets/images/banner/banner2.jpg" alt="banner2" />
          </router-link>
          <router-link v-else to="/user/affiliate">
            <img src="@/assets/images/banner/banner2.jpg" alt="banner2" />
          </router-link>
        </div>


        <div class="row">
          <div class="col-sm-12 col-md-6 text-center">
            <div class="card cardBg">
              <div class="card-body">
<!--                <div class="d-flex justify-content-lg-between">-->
<!--                  <h6>سطح کاربری شما:</h6>-->
<!--                  <p>{{ userInfo.fee_group.name }}</p>-->
<!--                </div>-->
                <div class="d-flex justify-content-lg-between">
                  <p style="font-size: 14px">مجموع معاملات 30 روز اخیر شما:</p>
                  <p style="font-size: 15px"> {{ userInfo.fee_group.total_orders | numFormat("0,0") }} تومان</p>
                </div>
                <div class="d-flex justify-content-lg-between">

                  <h6 style="font-size: 14px">مجموع دارایی لحظه‌ای شما معادل:</h6>
                  <p style="font-size: 15px">{{ balanceTotal | numFormat("0,0") }} تومان</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="row">
              <div class="col text-center">
                <div class="card cardBg">
                  <div v-if="filteredAssets.length > 0" class="card-body">
                    <div  class="mx-auto">
                  <h6>
                    موجودی کیف پول شما
                  </h6>
                  <div style="width: 200px" class="mx-auto">
                    <PieChart :balances="filteredAssets" />
                  </div>

                  <div class="col-6 mt-1 mb-4 mx-auto">
                    <div class="row asset-list">
                      <div
                          v-for="(asset, index) in filteredAssets"
                          v-bind:key="index"
                      >
                          <div
                              :style="`background-color: ${colors[index % 13]}`"
                              class="asset-bullet"
                          ></div>
                          <div class="asset-title">{{ asset.name }}</div>
                          <div class="asset-balance">
                            <div class="ltr mt-1">
                              <strong>{{
                                  asset.balance_orig | prec(asset.prec)
                                }}</strong>
                              {{ asset.ticker }}
                            </div>
                            <div v-if="asset.ticker !== 'IRT'">
                              معادل
                              {{ asset.balance | numFormat("0,0") }} تومان
                            </div>
                          </div>

                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <!--                            <div class="row">
                                <div class="col-12">

                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="ml-5">
                                                دید کلی بازار
                                            </h5>
                                            <div class="m-5" style="margin-top: 20px !important;">
                                                <MarketView :backend-root="backendRoot" :cdn-url="cdnUrl" :market-stats="marketStats"
                                                            :balances="balances"/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>-->
        <div class="row">
          <div class="col-12 pt-4">
            <h6>سفارشات باز</h6>
            <div
              class="box mt-3 table-responsive vld-parent"
              ref="tableContainer2"
            >
              <OpenOrders2
                v-if="openOrders != null"
                :open-orders="openOrders"
              />
            </div>
          </div>
          <div class="col-12 ">
            <!-- middle start --->
            <h6>آخرین معاملات</h6>
            <div
              class="box mt-3 table-responsive vld-parent"
              ref="tableContainer"
            >
              <OrdersTable :orders="orders" />
            </div>
            <!-- middle end --->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OrdersTable from "./OrdersTable";
import PieChart from "./PieChart";
import OpenOrders2 from "./OpenOrders2";

export default {
  name: "Profile",
  title: "داشبورد",

  props: {
    msg: String,
  },
  components: {
    OpenOrders2,
    PieChart,
    OrdersTable,
  },
  data() {
    return {
      polling: null,
      markets: [],
      marketStats: {},
      session: this.$session,
      kycStatus: {},
      backendRoot: process.env.VUE_APP_BACKEND_ROOT,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      orders: {},
      openOrders: null,
      balances: [],
      balanceTotal: 0,
      balanceBrief: [],
      assets: [],
      colors: [
        "#89D60B",
        "#FFC70D",
        "#3590AB",
        "#64CA8C",
        "#55c9c2",
        "#FF33A6",
        "#FF0000",
        "#8A085A",
        "#FFDE00",
        "#6C7988",
        "#FF5454",
        "#5C54FF",
        "#FF7C54",
      ],
      userInfo: this.$session.get("user_info"),
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
    filteredAssets() {
      return this.balanceBrief.filter(asset => asset.balance !== 0);
    }
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.updateAllData();
      }, 3000);
    },

    updateAllData() {
      this.updateUserInfo();
      this.getMarketStats();
      this.updateBalances();
      this.updateOrders();
      this.getAssetsBrief();
      this.getOpenOrders();
      this.getMarketStats();
    },

    updateUserInfo() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
          withCredentials: true,
        })
        .then((response) => {
          this.userInfo = response.data;
          this.$session.set("user_info", this.userInfo);
        })
        .catch(() => {
          this.$session.destroy();
        });
    },
    updateOrders() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/orders?limit=10", {
          withCredentials: true,
        })
        .then((response) => {
          this.orders = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    getOrders() {
      let loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
        bars: "dots",
      });

      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/orders?limit=6", {
          withCredentials: true,
        })
        .then((response) => {
          this.orders = response.data;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          // console.log(e)
        });
    },

    getAssetList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/asset/list")
        .then((response) => {
          this.assets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateBalances() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/balances", {
          withCredentials: true,
        })
        .then((response) => {
          this.balances = response.data.balances;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    getAssetsBrief() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL + "/user/balance-brief?convert=IRT",
          { withCredentials: true }
        )
        .then((response) => {
          this.balanceBrief = response.data.balances;
          this.balanceTotal = response.data.total;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    getOpenOrders() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/openorders-all", {
          withCredentials: true,
        })
        .then((response) => {
          this.openOrders = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    getMarketStats() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/stats")
        .then((response) => {
          this.marketStats = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
    getKycStatus() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/kyc-status", {
            withCredentials: true,
          })
          .then((response) => {
            this.kycStatus = response.data;
          })
          .catch(() => {
            // console.log(e)
          });
    },
  },
  created() {
    this.updateMarketList();
    this.getMarketStats();
    this.getAssetList();
    this.updateBalances();
    this.getKycStatus();
  },
  mounted() {
    this.getOrders();
    this.getAssetsBrief();
    this.getOpenOrders();

    this.pollData();
  },
  beforeDestroy() {
    this.$nextTick(()=> {
      clearInterval(this.polling);
    });
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.imageContainer{
  background-color: #212b36;
  padding: 10px;
  border-radius: 12px;
}

.imageContainer img{
  width: 100%;
}

.cardBg{
  background-color: #212b36 !important;
  border-radius: 12px !important;
}
.asset-bullet {
  width: 10px;
  height: 10px;
  float: right;
}
.asset-title {
  margin-top: -4px;
  padding-right: 14px;
  clear: left;
}
.asset-list {
  font-size: 13px;
}
.asset-list > div {
  text-align: right;
  padding: 10px;
  margin-bottom: -2px;
}

.asset-balance {
  font-size: 11px;
}

.stars {
  font-size: 40px;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }

  .dropdown-menu.profile {
    margin-right: 0px !important;
  }
}
</style>
