<template>
  <div>

  </div>
</template>
<script>
export default {
  name: "Home",

  mounted() {
    this.$router.push('/login');
  },
  beforeMount() {
    this.$router.push('/new-page');
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>