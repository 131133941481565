<template>
  <div class="main container-fluid"><slot></slot></div>
</template>
<script>
import axios from "axios";
export default {
  methods: {
    updateUserInfo() {
      if (this.$session.exists("user_info")) {
        axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
            withCredentials: true,
          })
          .then((response) => {
           
            this.$session.set("user_info", response.data);
          })
          .catch(() => {
            this.$session.destroy();
          });
      }
    },
  },
  mounted() {
    this.updateUserInfo();
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: inherit;
}
</style>
