<template>
    <div role="table" class="table-container">
        <div role="rowgroup" class="flex-table header">
            <div role="columnheader" class="flex-row">
                قیمت واحد <sub>({{market.quote_asset_ticker}})
            </sub></div>
            <div role="columnheader" class="flex-row">
                مقدار <sub>({{market.base_asset_ticker}})
            </sub></div>
            <div role="columnheader" class="flex-row">مبلغ کل <sub>({{market.quote_asset_ticker}})</sub></div>
        </div>
        <div class="outer" style="height: 364px; position: relative">
            <div class="inner-container" id="asks-wrapper">
                <div v-for="d in asks" :key="d.id" class="flex-table clickable" @click="rowClick(d)">
                    <span class="after bgRed" :style="'width: '+ d.percent+'%;'">&nbsp;</span>
                    <div class="flex-row">
                        {{d.price | prec(market.price_prec)}}
                    </div>
                    <div class="range-selector flex-row">
                        {{d.volume | prec(market.amount_prec)}}
                    </div>
                    <div class="flex-row text-danger">
                        {{d.price * d.volume | prec(market.quote_asset_prec)}}
                        <span class="dot" v-if="hasOpen(d.price)" style="float: left"></span>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import percent from 'percent'
    export default {
        name: 'OrderbookAsk',
        props: ['market','depth' ,'total', 'opens'],
        methods: {
            rowClick: function (row) {
                this.$emit('rowClick', row);
            },
            hasOpen(price){
                return this.opens.includes(price);
            }
        },
        computed: {
            asks: function () {
                const b = [];
                let p = 0;
                let id = 0;
                const to = this.total;
                if (this.depth.asks !== undefined) {
                    const asksReverced = this.depth.asks;
                    asksReverced.forEach(function (element) {
                        const c = {};
                        c.id = id;
                        c.price = parseFloat(element[0]);
                        c.volume = parseFloat(element[1]);
                        p = p + parseFloat(element[1]);
                        c.total = p;
                        c.percent = percent.calc(p, to, 2);
                        b.push(c);
                        id++;
                    });
                }
                return b
            },
        },

        mounted() {
            const element = document.getElementById("asks-wrapper");
            element.scrollTop = element.scrollHeight;
        }
    }
</script>

<style scoped>
    .dot {
        margin-top: 5px;
        height: 5px;
        width: 5px;
        background-color: #F8A91B;
        border-radius: 50%;
        display: inline-block;
    }

    .outer{
        display: flex;
        flex-flow: column;
    }


    .inner-container::-webkit-scrollbar {
        width: 8px;
    }
    .inner-container::-webkit-scrollbar-track {
        background: transparent;
    }
    .inner-container::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.3);
        border-radius: 20px;
    }

    .inner-container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: rgba(0,0,0,0.3) transparent;
    }

    .text-success {
        color: #0EBF00 !important;
    }

    .text-danger {
        color: #FF4C2F !important;
    }


    .scrollable {
        overflow-y: scroll;
        overflow-x: hidden;

    }


    .table-container {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }

    .flex-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        border-left: 1px solid #444140;
        position: relative;
    }

    .bgRed {
        background: rgba(179, 26, 28, .09);
    }

    .after {
        color: rgba(28, 29, 38, 0);
        position: absolute;
        height: 100%;
    }

    .flex-row {
        width: 33.33333%;
        text-align: right;
        display: inline-block;
        border-top: 1px solid #444140;
        padding: 6px;
        color: #fff;
        font-size: 11px;
    }

    .range-selector {
        cursor: pointer;
        display: inline-block;
    }
</style>
