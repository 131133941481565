<template>
  <div>
    <div>
      <div class="row no-padding no-margin market-view">
        <div class="col-12"></div>
      </div>
      <div class="row no-padding no-margin">
        <div class="container vld-parent">
          <WithdrawBoxCrypto
            v-if="asset.is_fiat == false"
            :balance="balance"
            :asset="asset"
          />
          <WithdrawBoxFiat
            v-if="asset.is_fiat == true"
            :balance="balance"
            :asset="asset"
          />

          <div class="row">
            <div class="col-12 text-center">
              <router-link
                to="/user/wallet"
                class="btn btn-primary history-btn"
              >
                بازگشت
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import WithdrawBoxCrypto from "./WithdrawBoxCrypto";
import WithdrawBoxFiat from "./WithdrawBoxFiat";

export default {
  name: "WalletWithdraw",
  props: ["ticker"],
  components: {
    WithdrawBoxCrypto,
    WithdrawBoxFiat,
  },
  data() {
    return {
      asset: {},
      markets: [],
      assets: [],
      polling: null,
      backendRoot: process.env.VUE_APP_BACKEND_ROOT,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      balance: {},
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.updateAllData();
      }, 3000);
    },
    checkSession() {
      if (!this.$session.exists("user_info")) {
        return this.$router.push("/login");
      }
    },
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    updateBalance() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/user/balance/${this.ticker}`, {
          withCredentials: true,
        })
        .then((response) => {
          this.balance = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },

    assetDetails() {
      let loader = this.$loading.show({
        container: this.$refs.tableContainer,
        canCancel: false,
        bars: "dots",
      });

      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/user/withdraw/${this.ticker}`,
          { withCredentials: true }
        )
        .then((response) => {
          this.asset = response.data;
          this.balance = response.data.balance;
          loader.hide();
        })
        .catch(() => {
          // console.log(e)
          loader.hide();
        });
    },
    updateAllData() {
      this.checkSession();
      this.updateBalance();
    },
  },
  created() {
    this.checkSession();
    this.updateMarketList();
  },
  mounted() {
    this.assetDetails();
    this.updateAllData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  overflow-x: hidden;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.history-btn {
  width: 200px;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.box .logo img {
  max-width: 100px;
}

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }

  .box .logo img {
    max-width: 50px;
  }
}
</style>

<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
