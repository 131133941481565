<template>
    <div class="row static">
            <div class="container">
                <section id="tabs">
                    <div class="container">
                        <div class="row gradient">
                            <div class="login-wrapper mb-4">

                                    <div>

                                        <div class="row mb-5">
                                            <h4 class=" m-auto">غیرفعال سازی کد دوعاملی</h4>
                                        </div>
                                        <div>
                                            <p class="alert alert-info small m-auto">
                                                کاربر گرامی لطفا کد ارسال شده به شماره همراه و ایمیل خود را به منظور هویت سنجی وارد نمایید.
                                            </p>
                                        </div>
                                        
                                    </div>

                                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                    <div class="row">
                                        <div class="col-12">
                                            <form @submit="submitForget">
                                                <div>
                                                    <div>
                                                        <label for="">کد ارسال شده به شماره همراه</label>
                                                    </div>
                                                    <div id="divInner" class="input-group mt-3 mb-3">
                                                        <input type="text" maxlength="6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==6) return false;" class="form-control latin" name="SMSCode" id="SMSCode"
                                                            v-model="SMSCode"
                                                            dir="ltr">
                                                    </div>
                                                </div>

                                                <div >
                                                    <div>
                                                        <label for="">کد ارسال شده به ایمیل حساب کاربری</label>
                                                    </div>
                                                    <div lass="input-group mt-3 mb-3">
                                                        <input type="text" maxlength="6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==6) return false;" class="form-control latin" name="EmailCode" id="EmailCode"
                                                            v-model="EmailCode"
                                                            dir="ltr">
                                                    </div>
                                                    
                                                </div>
<!-- 
                                                <div class="input-group mt-3 mb-3">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" style="padding: 2px">
                                                            <img :src="backendRoot + 'assets/captcha.png?v=' + loadTime" style="border-radius: 5px !important; margin-left: 2px" />
                                                            <a class="btn btn-sm btn-light" @click="updateLoadTime"> <font-awesome-icon class="round-arrow" icon="redo"/></a>
                                                        </span>
                                                    </div>
                                                    <input type="tel" class="form-control latin" name="captcha" id="captcha"
                                                            placeholder="کد امنیتی" v-model="captchaCode" autocomplete="off"
                                                            dir="ltr">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <img class="login-logo" src="../assets/images/puzzle-piece2.png" />
                                                        </span>
                                                    </div>
                                                </div> -->
                                                <div class="col-12 text-center mt-2">
                                                    <input class="btn btn-warning login-btn" type="submit"
                                                            value="ارسال">
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
</template>

<script>
    
    import axios from 'axios';
    import { Auth } from 'aws-amplify';

    export default {
        name: 'ForgetTfa',
        title: 'غیر فعالسازی رمز دوعاملی',
        props: {},
        components: {
          
        },
        metaInfo () {
            return {
                title: 'غیر فعالسازی رمز دوعاملی',
                titleTemplate: '%s - لیدیا پلتفرم تبادل ارزهای دیجیتال',
                meta: [
                    { name: 'og:title', vmid: 'og:title', content: 'غیر فعالسازی رمز دوعاملی' },
                    { name: 'description',  vmid: 'description', content: 'غیر فعالسازی رمز دوعاملی' },
                    { name: 'og:description', vmid: 'og:description', content: 'غیر فعالسازی رمز دوعاملی' },
                ]
            }
        },
        data() {
            return {
                SMSCode: '',
                EmailCode: '',
                captchaCode: '',
                loadTime : 0,
                backendRoot: process.env.VUE_APP_BACKEND_ROOT,
                session: this.$session,
                
            }
        },
        created() {

         

            if (this.$session.exists('user_info_email')) {
                // return this.checkKycLevel(this.$session.get('user_info'));
                localStorage.setItem('email', this.session.get('user_info_email').email);
            }else{
                return this.$router.push({name: 'ForgetTfa'});
            }
            this.SendSMSEmail();
            // this.registerRefCode = this.$route.query.ref_code;
            // this.loadTime = Date.now()

        },
        methods: {
            SendSMSEmail() {
 
                const loginInfo = {
                    email: localStorage.getItem('email')
                };
                axios.post(process.env.VUE_APP_BACKEND_URL + '/user/forgettfa', loginInfo, {withCredentials: true})
                    .then(response => {
                        console.log(response);
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },
            auth() {
                try {
                    Auth.federatedSignIn({ provider: 'Google' });
                } catch (error) {
                    console.log('error:', error);
                }
            },
            checkKycLevel(userInfo) {
                if (userInfo.kyc_step === 0) {
                    return this.$router.push({name: 'EmailVerify'});
                } else if (userInfo.kyc_step === 1) {
                    return this.$router.push({name: 'MobileVerify'});
                } else if (userInfo.kyc_step === 2) {
                    return this.$router.push({name: 'IdentityVerify'});
                } else {
                    return this.$router.push({name: 'Profile'});
                }
            },

            updateLoadTime(e){
                e.preventDefault();
                this.loadTime = Date.now();
                this.captchaCode = '';
            },
            resetVisibility() {
                this.eyeIcon = 'eye-slash';
                this.passwordFieldType = 'password';
            },
            switchVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
                this.eyeIcon = this.eyeIcon === 'eye' ? 'eye-slash' : 'eye'
            },
            submitForget(e) {
                e.preventDefault();
                const forgetInfo = {
                    smscode: this.SMSCode,
                    mailcode: this.EmailCode,
                    email: localStorage.getItem('email'),
                    //captcha : this.captchaCode
                    
                };
              
                if (this.SMSCode !== '' && this.EmailCode !== '') {
                    this.notice('info', 'در حال ورود ...');

                    axios.post(process.env.VUE_APP_BACKEND_URL + '/user/forgetInfo', forgetInfo, {withCredentials: true})
                        .then((response) => {
                            //console.log(response);
                            if(response.data == true){
                                this.notice('info', 'موفق', ' غیر فعالسازی شناسه دو عاملی حساب کاربری شما با موفقیت انجام شد. در نظر داشته باشید برداشت رمزارز شما به مدت 24 ساعت محدود خواهد شد.', 10);
                                return this.$router.push({name: 'Login'});
                            }else if(response.data == false){
                                return this.notice('error', 'خطا', 'مقادیر وارد شده صحیح نمی‌باشد.', 3);
                            }else if(response.data == 'time'){
                                return this.notice('error', 'خطا', 'کد اعتبار سنجی منقضی شده است.', 3);
                            }
                        })
                        .catch((e) => {
                            if (e.response.data !== undefined) {
                                const data = e.response.data;
                                const errors = '<ul>' + data.errors.map(function (err) {
                                    return '<li>' + err + '</li>';
                                }).join('') + '</ul>';
                                this.notice('error', 'خطا', errors, 10);
                                return;
                            }

                            return this.notice('error', 'خطا', 'ایمیل یا رمز عبور نادرست است', 5);

                        });
                } else {
                    this.notice('error', 'خطا', 'تمامی مقادیر را وارد کنید.', 3);
                }

            },
        },



    }
</script>

<style scoped>

    .login-btn {
        width: 315px;
        border-radius: 20px;
    }

    #SMSCode {
        letter-spacing: 42px;
        background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 50px 1px;
        background-repeat: repeat-x;
        background-position-x: 35px;
    }
    
    #EmailCode {
        letter-spacing: 42px;
        background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 50px 1px;
        background-repeat: repeat-x;
        background-position-x: 35px;
    }

    #divInner{
        left: 0;
        position: sticky;
    }

    .static {
        background: url("../assets/images/map_black.png") no-repeat center top;
        background-size: 100%;
        min-height: 900px;
        width: unset;
        height: 100%;
        
    }

    .latin{
        color: white;
    }

    .static .content {
        margin-top: 50px;
    }


    .login-wrapper {
        margin-right: auto;
        margin-left: auto;
        margin-top: 5%;
        width: 400px;
        padding: 30px;
        border: 2px solid #FFFFFF;
        box-sizing: 10px;
        background: rgba(48, 45, 44, 0.5);
        box-shadow: 0px 0px 30px #FFFFFF;
        border-radius: 50px;
    }

    .nav-item {
        border-bottom: 4px solid #BA8310;
        color: #BA8310;
        width: 100px;
        text-align: center;
        padding: 5px;
    }

    input {
        border: 0;
    }
    

    input:focus {
        box-shadow: none;
    }

    .input-group-prepend .input-group-text {
        background-color: transparent !important;
        color: white !important;
        border: 1px solid #F8AA0F;
        border-radius: 15px;
    }

    .input-group-append .input-group-text {
        background-color: transparent !important;
        color: #000 !important;
        border: 1px solid #F8AA0F;
        border-radius: 15px;
    }

    .form-control{
        background-color: transparent;
        border: 1px solid #F8AA0F;
        border-radius: 15px;
    }

    ::placeholder{
        color: white;
    }

    .login-logo{
        width: 24px;
        height: 24px;
    }

    
    .login-tab {
        background: #4A4A48;
        border: 1px solid #F8AA0F;
        border-radius: 39px;
        color: white;
        width: 150px;
        height: 45px;
        padding-top: 10px;
    }

    .btn-warning{
        background-color: #4A4A48;
        color: white;
        border: 1px solid #F8AA0F;
        margin-top: 10px;
    }

    .btn-warning:hover {  
        background: #4A4A48;
        border: 1px solid #FFFFFF;
        box-shadow: inset 0px 0px 15px #000000;
        border-radius: 31px;
    }

    .login-tab:hover{
        background: #4A4A48;
        border: 1px solid #FFFFFF;
        box-shadow: inset 0px 0px 15px #000000;
    }


    ::-webkit-input-placeholder {
        text-align: center;
        font-family: IRANSans,serif;
    }

    :-moz-placeholder { /* Firefox 18- */
        text-align: center;
        font-family: IRANSans,serif;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        text-align: center;
        font-family: IRANSans,serif;
    }

    :-ms-input-placeholder {
        text-align: center;
        font-family: IRANSans,serif;
    }

    
    @media screen and (max-width: 700px) {

        .static {
            background: url("../assets/images/map_black.png") no-repeat center top;
            background-size: 100%;
            min-height: 100px;
            width: unset;
            
        }

        #SMSCode {
            letter-spacing: 25px;
            background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 50px 1px;
            background-repeat: repeat-x;
            background-position-x: 35px;
        }
        
        #EmailCode {
            letter-spacing: 25px;
            background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 50px 1px;
            background-repeat: repeat-x;
            background-position-x: 35px;

        }
    
        .login-tab {
            background: #4A4A48;
            border: 1px solid #F8AA0F;
            border-radius: 39px;
            color: white;
            width: 100px;
            height: 45px;
            padding-top: 10px;
            margin: auto;
        }

        .login-wrapper {
            margin-top: 5%;
            width: 350px;
            border: 2px solid #FFFFFF;
            box-sizing: 10px;
            background: rgba(48, 45, 44, 0.5);
            box-shadow: 0px 0px 30px #FFFFFF;
            border-radius: 50px;
        }
        .login-btn {
            width: 200px;
            border-radius: 20px;
        }

        .login-logo{
            width: 18px;
            height: 18px;
            color: white;
        }

        ::placeholder{
            color: white;
            font-size: 13px;
        }

        .nav{
            margin-right: 0px;
        }
        

    }
</style>
