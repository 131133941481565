<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>

    <MainLayout v-if="layout === 'mainLayout'">
      <router-view :userInfo="userInfo"
    /></MainLayout>
    <UserLayout v-else> <router-view :userInfo="userInfo"/></UserLayout>
  </div>
</template>

<script>
import axios from "axios";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./style/custom.css";
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import UserLayout from "./layout/user/index.vue";
import MainLayout from "./layout/main/index.vue";
export default {
  name: "App",
  components: { UserLayout, MainLayout },

  //  this is commit becuase of seo

  // metaInfo() {
  //   return {
  //     // if no subcomponents specify a metaInfo.title, this title will be used
  //     title: "دفینکس | خرید و فروش ارز دیجیتال | صرافی آنلاین ارز دیجیتال",
  //     link: [{ rel: "canonical", href: this.canonicalUrl }],

  //     meta: [
  //       {
  //         name: "og:title",
  //         vmid: "og:title",
  //         content:
  //           "دفینکس | خرید و فروش ارز دیجیتال | صرافی آنلاین ارز دیجیتال",
  //       },
  //       {
  //         name: "description",
  //         vmid: "description",
  //         content:
  //           "صرافی آنلاین دفینکس، بهترین مرجع خرید و فروش ارز دیجیتال در ایران، به شما امکان معامله ارز دیجیتال با کمترین کارمزد را میدهد.",
  //       },
  //       {
  //         name: "og:description",
  //         vmid: "og:description",
  //         content:
  //           "صرافی آنلاین دفینکس، بهترین مرجع خرید و فروش ارز دیجیتال در ایران، به شما امکان معامله ارز دیجیتال با کمترین کارمزد را میدهد.",
  //       },
  //     ],
  //   };
  // },
  data() {
    return {
      userInfo: {},
      layout: "mainLayout",
      userPages: [
        "Wallet",
        "BankInfo",
        "Profile",
        "Orders",
        "IdentityVerify",
        "IdentityVerifySubmitted",
        "Settings",
        "Affiliate",
        "LoginLog",
        "Announcements",
        "Announcement",
        "Messages",
        "Message",
        "WalletDeposit",
        "WalletWithdraw",
        "FastTradeUser",
        "Trade",
        "ChangePassword",
        "WebCallback",
        "FiatVerify",
        "NotVerified",
        "OtpNotActive",
        "OtpActivate",
        "History",
        "TypeVerify",
      ],
      polling: null,
      canonicalUrl: window.location.href,
    };
  },
  methods: {
    updateUserInfo() {
      if (this.$session.exists("user_info")) {
        axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
            withCredentials: true,
          })
          .then((response) => {
            this.userInfo = response.data;
            this.$session.set("user_info", this.userInfo);
          })
          .catch(() => {
            this.$session.destroy();
          });
      }
    },
    updateAllData() {
      this.updateUserInfo();
    },

    pollData() {
      this.polling = setInterval(() => {
        this.updateAllData();
      }, 30000);
    },
    handleUserLayout() {
      const checkUserPage = this.userPages.find(
        (item) => this.$route.name === item
      );
      if (checkUserPage) {
        this.layout = "userLayout";
      } else {
        this.layout = "mainLayout";
      }
    },
  },

  created() {
    this.updateUserInfo();
    this.pollData();
    this.handleUserLayout();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  computed: {},
  watch: {
    $route: function() {
      this.handleUserLayout();
      this.canonicalUrl = window.location.href;
    },
  },
};
</script>

<style>
#app {
  direction: rtl;
}

:root {
  --primary: #4263eb;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("assets/fonts/iransans/eot/IRANSansWeb_Bold.eot");
  src: url("assets/fonts/iransans/eot/IRANSansWeb_Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/iransans/woff2/IRANSansWeb_Bold.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/iransans/woff/IRANSansWeb_Bold.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("assets/fonts/iransans/ttf/IRANSansWeb_Bold.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/iransans/eot/IRANSansWeb_Medium.eot");
  src: url("assets/fonts/iransans/eot/IRANSansWeb_Medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/iransans/woff2/IRANSansWeb_Medium.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/iransans/woff/IRANSansWeb_Medium.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("assets/fonts/iransans/ttf/IRANSansWeb_Medium.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/iransans/eot/IRANSansWeb_Light.eot");
  src: url("assets/fonts/iransans/eot/IRANSansWeb_Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/iransans/woff2/IRANSansWeb_Light.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/iransans/woff/IRANSansWeb_Light.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("assets/fonts/iransans/ttf/IRANSansWeb_Light.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/iransans/eot/IRANSansWeb_UltraLight.eot");
  src: url("assets/fonts/iransans/eot/IRANSansWeb_UltraLight.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/iransans/woff2/IRANSansWeb_UltraLight.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/iransans/woff/IRANSansWeb_UltraLight.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("assets/fonts/iransans/ttf/IRANSansWeb_UltraLight.ttf")
      format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/iransans/eot/IRANSansWeb.eot");
  src: url("assets/fonts/iransans/eot/IRANSansWeb.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/iransans/woff2/IRANSansWeb.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/iransans/woff/IRANSansWeb.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("assets/fonts/iransans/ttf/IRANSansWeb.ttf") format("truetype");
}
*,
*::before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  background-color: #161c24;
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: IRANSans, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overflow-x: hidden;
}
html,
body,
#app,
#app1 {
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.ltr {
  direction: ltr !important;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

a:hover,
a:active {
  text-decoration: none;
  color: #999;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-5 {
  padding: 5px;
}

.clickable {
  cursor: pointer;
}

.no-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.bg-success,
.btn-success {
  background-color: #22c55d !important;
}

.bg-danger,
.btn-danger {
  background-color: #ff5631 !important;
}

.text-success {
  color: #22c55d !important;
}

.text-danger {
  color: #ff5631 !important;
}

.latin {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.latin2 {
  font-family: sans-serif, Helvetica, Arial;
}

.swal2-popup {
  font-size: 12px !important;
}
.swal2-html-container label {
  margin-bottom: 0 !important;
}

.black {
  color: #000 !important;
}
.dropzone {
  font-family: IRANSans !important;
  border-radius: 10px;
  min-height: 290px !important;
  background-color: #ababab !important;
  color: #fff !important;
}

.dropzone * {
  border-radius: 10px;
}

.vpd-container,
.vpd-input-group label {
  color: #000 !important;
}

.apexcharts-legend-marker {
  margin-left: 10px;
}

/* .accordion {
          border: 0 !important;
          border-radius: 0 !important;
      }

      .accordion__item {
          border-bottom: 1px solid #555 !important;
      }

      .accordion__title-text {
          color: #fff;
          font-weight: bold;
      }

      .accordion__toggle-button::after, .accordion__toggle-button::before {
         content: "asd";

      }

      .accordion__value {
          color: #fff !important;
      }

      .accordion__value > div{
          direction: rtl !important;
          text-align: right !important;
          font-size: 13px;
          font-weight: lighter;
      } */

.video-js .vjs-big-play-button {
  right: 45% !important;
  top: 45% !important;
  width: 20%;
  height: 20%;
}

.video-js .vjs-play-control:before {
  top: 20% !important;
  content: "\f101";
  font-size: 48px;
}

.bold {
  font-weight: bold;
}

.ltr {
  direction: ltr !important;
}

.swal2-actions {
  margin: 0.2rem 1rem !important;
}
/* slider */
.dotsClass {
  background-color: red;
}
.slick-dots button::before,
.slick-dots button::after {
  content: "" !important;
}
.slick-dots button {
  background-color: rgb(255, 255, 255) !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
}
/* Custom active dot styles */
.slick-active button::before,
.slick-active button::after {
  content: "" !important;
}
.slick-active button {
  background-color: #f8aa0f !important;
  width: 22px !important;
  height: 3px !important;
  border-radius: 20px !important;
}
.slick-dots li {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 4px !important;
}
/* toast */
.v-toast .v-toast__text {
  padding: 0 !important;
}
.v-toast .v-toast__item {
  padding: 0 10px;
  margin: 1em 1em 0.1em 0 !important;
}
.v-toast .v-toast__icon {
  width: 5px !important;
  height: 20px !important;
}
/* toast */
@media screen and (min-width: 780px) {
  .v-toast .v-toast__item {
    margin: 0.2em 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .swal2-html-container .top-info {
    font-size: 11px;
  }

  .dropzone {
    min-height: auto !important;
  }

  .btn,
  .vpd-icon-btn,
  .dropdown-menu {
    font-size: 12px;
  }

  .video-js .vjs-big-play-button {
    right: 40% !important;
    top: 40% !important;
    width: 20%;
    height: 20%;
  }
}
</style>
