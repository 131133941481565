<template>
  <div class="px-4 my-5 ">
    <h2>اطلاعات بانکی</h2>

    <div class="container">
      <div class="row mt-3">
        <form enctype="multipart/form-data" >
          <div class="row">
            <div class="col mt-4">
              <h6>اطلاعات بانکی</h6>
            </div>

            <div class="col-12 p-3 box">
              <div class="form-row">
                <div class="form-group col-12 col-md-4">
                  <label for="cardNo">شماره کارت</label>
                  <div class="input-group mb-3">
                    <the-mask
                        :disabled="isDisabled"
                        type="text"
                        dir="ltr"
                        class="form-control"
                        id="cardNo"
                        v-model="cardNo"
                        :mask="'####-####-####-####'"
                        autocomplete="off"
                    ></the-mask>
                  </div>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label style="visibility: hidden">&nbsp;A</label>
                  <div class="input-group mb-3">
                    <button
                        class="btn btn-success"
                        :disabled="isDisabled"
                        @click="confirmCard"
                    >
                      افزودن
                    </button>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-12 text-danger">
                    دقت شود شماره کارت وارده حتما باید متعلق به شما و
                    متصل به یک حساب بانکی یکسان باشد.
                  </div>
                </div>
              </div>
              <div v-for="info in cards" v-bind:key="info.id" class="form-row">
                <div class="form-group col-12 col-md-4">
                  <label for="cardNo"></label>
                  <div class="input-group mb-3">
                    <input
                        type="text"
                        dir="ltr"
                        disabled
                        readonly
                        :value="info.card_no"
                        class="form-control"
                        autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group col-12 col-md-4">
                  <label for="i_last_name"></label>
                  <div class="input-group mb-3">
                    <input
                        type="text"
                        dir="ltr"
                        disabled
                        readonly
                        :value="info.iban_no"
                        class="form-control"
                        autocomplete="off"
                    />

                    <div class="input-group-append">
                      <span class="input-group-text"> IR </span>
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 col-md-4">
                  <label></label>

                  <div class="row input-group mb-3">
                    <div class="col-5">
                      <p class="">بانک {{ info.bank_name }}</p>
                    </div>

                    <div class="col-7">
                      <a
                          class="btn btn-danger"
                          :disabled="isDisabled || formDisabled"
                          @click="deleteCard(info.card_no)"
                      >حذف
                      </a>

                      <span
                          v-if="info.is_confirmed"
                          class="mt-2 ml-3 text-success"
                      >
                      <font-awesome-icon icon="check"></font-awesome-icon>
                      تایید شده
                    </span>

                      <span v-else class="mt-2 ml-3 text-warning">
                      <font-awesome-icon icon="clock"></font-awesome-icon>
                      در انتظار تایید
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BankInfo",
  title: "اطلاعات بانکی",

  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      session: this.$session.get("user_info"),
      markets: [],
      isDisabled: false,
      buttonsVisible: false,
      userInfo: null,
      formDisabled: false,
      formNameDisabled: false,
      kycStatus: {},
      cardNo: null,
      ibanNo: null,
      holderName: null,
      cards: [],
      hasImage: false,
      polling: null,
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
          (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
          (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    just_persian(e) {
      const clickedElement = e.target;
      const p = /^[\u0600-\u06FF\s]+$/;
      if (!p.test(e.key)) {
        console.log("not match");
        clickedElement.value = "";
        return false;
      }
      return true;
    },

    loadUserInfo() {
      this.userInfo = this.$session.get("user_info");
    },
    updateUserInfo() {
      if (this.$session.exists("user_info")) {
        axios
            .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
              withCredentials: true,
            })
            .then((response) => {
              this.userInfo = response.data;
              this.$session.set("user_info", this.userInfo);
              this.userInfo = response.data;
            })
            .catch(() => {
              // this.$session.destroy();
            });
      }
    },
    updateMarketList() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
          .then((response) => {
            this.markets = response.data;
          })
          .catch(() => {
            // console.log(e)
          });
    },
    updateCardList() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/card-list", {
            withCredentials: true,
          })
          .then((response) => {
            this.cards = response.data;
          })
          .catch(() => {
            // console.log(e)
          });
    },

    getKycStatus() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/kyc-status", {
            withCredentials: true,
          })
          .then((response) => {
            this.kycStatus = response.data;
            if (this.kycStatus.verify_status === "verified") {
              this.formDisabled = true;
              this.formNameDisabled = true;
              this.$refs.selfieDropzone.disable();
            }
          })
          .catch(() => {
            // console.log(e)
          });
    },

    clearCardForm() {
      this.ibanNo = "---";
      this.holderName = "---";
      this.cardNo = "";
      this.isDisabled = false;
      this.buttonsVisible = false;
    },

    deleteCard(cardNo) {
      this.$confirm({
        message: `آیا از حذف این کارت اطمینان دارید`,
        button: {
          no: "خیر",
          yes: "بله، حذف شود",
        },
        callback: (confirm) => {
          if (confirm) {
            this.notice("info", "در حذف کارت...");

            axios
                .delete(
                    `${process.env.VUE_APP_BACKEND_URL}/user/card-delete/${cardNo}`,
                    { withCredentials: true }
                )
                .then(() => {
                  this.notice("success", "کارت با موفقیت حذف شد");
                  this.updateCardList();
                })
                .catch((e) => {
                  this.notice("error", "خطا", e.response.data.message);
                });
          }
        },
      });
    },

    confirmCard(e) {
      e.preventDefault();
      if (this.cardNo.length !== 16) {
        return this.notice(
            "error",
            "شماره کارت",
            "شماره کارت وارد شده معتبر نمی‌باشد",
            5
        );
      }


      this.$confirm({
        message: `آیا تایید می‌کنید این حساب متعلق به شخص شماست؟`,
        button: {
          no: "خیر",
          yes: "بله، تایید می‌کنم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.notice(
                "info",
                "افزودن کارت",
                "در حال افزودن کارت. لطفا کمی صبر کنید",
                3
            );
            this.isDisabled = true;
            axios
                .post(
                    `${process.env.VUE_APP_BACKEND_URL}/user/card-add`,
                    {
                      card_no: this.cardNo
                    },
                    { withCredentials: true }
                )
                .then((response) => {
                  this.cards = response.data;
                  this.clearCardForm();
                  return;
                })
                .catch((e) => {
                  this.notice("error", "خطا", e.response.data.error, 10);
                  // this.clearCardForm();
                  this.isDisabled = false;
                });
          }
        },
      });
    },
  },
  created() {
    this.checkSession();

    this.loadUserInfo();
    this.updateMarketList();
    this.updateCardList();

    this.getKycStatus();

    if (this.kycStatus.verify_status === "verified") {
      this.formDisabled = true;
      this.formNameDisabled = true;
    }
  },

  /*        mounted() {
                    if (this.kycStatus.verify_status === 'verified') {
                        console.log("disable");
                        this.formDisabled = true;
                        this.$refs.selfieDropzone.disable();
                    }else{
                        console.log(this.kycStatus);
                    }
                    this.p();
                },
                beforeDestroy() {
                    clearInterval(this.polling)
                },*/
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.login-btn {
  width: 200px !important;
  border-radius: 20px;
}

.selfie img {
  border-radius: 10px;
  max-width: 90%;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  color: #fff;
  background-color: #565656;
  border-radius: 10px;
}

/* ********************************/
#customdropzone {
  background-color: #ababab;
  letter-spacing: 0.2px;
  color: #fff;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
  border-radius: 10px;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}

/* ********************************/

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }
}
</style>
