<template>
    <table class="table table-borderless">
        <thead>
        <tr>
            <th scope="col">لغو</th>
            <th scope="col">شناسه</th>
            <th scope="col">بازار</th>
            <th scope="col">مقدار</th>
            <th scope="col">قیمت واحد</th>
            <th scope="col">قیمت کل</th>
            <th scope="col">پر شده</th>
            <th scope="col">زمان</th>
        </tr> 
        </thead>
        <tbody v-if="openOrders.records !== undefined && openOrders.records.length">
        <tr v-for="order in openOrders.records" :key="order.order_id">
            <td>
                <font-awesome-icon icon="times" size="2x" class="text-danger clickable"
                                   @click="cancelOrder(order.market,order.order_id)"/>
            </td>
            <td>{{order.order_id}}</td>
            <td>{{order.market | underscoreToSlash}}</td>
            <td>{{order.amount | prec(order.amount_prec)}}</td>
            <td v-if="order.side === 'ask'" class="text-danger">{{order.price | prec(order.price_prec)}}</td>
            <td v-else class="text-success">{{order.price | prec(order.price_prec)}}</td>
            <td v-if="order.side === 'ask'" class="text-danger">{{order.amount * order.price |
                prec(order.price_prec)}}
            </td>
            <td v-else class="text-success">{{order.amount * order.price | prec(order.price_prec)}}</td>
            <td>{{order.amount - order.left | prec(order.amount_prec)}}</td>
            <td class="ltr">{{order.create_time | persianDateUnix}}</td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
            <td colspan="8" class="text-center">
                سفارش باز موجود نمی باشد
            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
    import axios from 'axios'

    export default {
        name: 'OpenOrders2',
        props: ['openOrders'],
        methods: {
            cancelOrder(market, order_id) {

                this.$confirm(
                    {
                        message: `آیا از لغو این سفارش اطمینان دارید`,
                        button: {
                            no: 'خیر',
                            yes: 'بله، لغو شود'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.notice('info', 'در حال لغو سفارش...');

                                axios.delete(`${process.env.VUE_APP_BACKEND_URL}/order/cancel/${market}/${order_id}`, {withCredentials: true})
                                    .then(() => {
                                        this.notice('success', 'سفارش با موفقیت لغو شد');
                                    })
                                    .catch(e => {
                                        this.notice('error', 'خطا', e.response.data.message);
                                    });
                            }
                        }
                    }
                );

            },
            notice(type, title, description) {
                this.$notice[type]({
                    title: title,
                    description: description || ""
                })
            },
        }
    }
</script>

<style scoped>
    table thead th {
        border-bottom: 2px solid #999;
        font-size: 14px;
    }

    table tbody tr {
        border-bottom: 1px solid #999;
        font-size: 14px;
    }


    .open-orders table {
        font-size: 12px;
        border: 0;
        border-spacing: 0;
    }

</style>
