<template>
    <div class="row mt-4 mr-3 ml-3 mb-2 p-3 box">
        <div v-if="asset.can_withdraw" class="row col-12">
            <div class="col-2 logo">
                <img v-if="asset.logo_filename"
                     :src="`${cdnUrl}/media/asset/logo/md/${asset.logo_filename}`"
                     :alt="asset.ticker">
            </div>
            <div class="col-10 col-md-6 pt-2">
                <h4 class="bold">
                    برداشت {{asset.name}}
                    <span class="text-dark small">{{asset.ticker}}</span>
                </h4>
                <p class="text-secondary small">
                    لطفا
                    جهت برداشت
                    {{asset.name}}
                    آدرس مقصد و میزان برداشت مورد نظر را وارد نموده و تایید نمایید.
                </p>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">

                    <div class="col-12 mb-2">
                                            <span class="text-secondary">
                                                موجودی:
                                            </span>
                        {{balance.available | prec(asset.prec) }}
                    </div>
                    <div class="col-12">
                                            <span class="text-secondary">
                                               در سفارشات:
                                            </span>
                        {{balance.freeze | prec(asset.prec) }}
                    </div>
                </div>

            </div>
            <div class="col-12 mt-1">
                <div v-if="asset.description !==''" class="alert alert-secondary small" v-html="asset.description">
                    {{asset.description}}
                </div>

                <div class="row mt-4">
                    <div class="col-12">
                        <div class="vld-parent" ref="tableContainer">
                            <ul class="nav nav-tabs nav-pills nav-justified" id="marketTabs" role="tablist">
                                <li v-for="(network, n) in asset.networks" :key="n" class="nav-item text-center">
                                    <a class="nav-link latin2" :id="n + 'tab'" data-toggle="tab" :href="'#'+n"
                                       role="tab"
                                       v-bind:class="{ active: network.first_item }">{{network.name}}</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3 mb-3" id="myTabContent">
                                <div v-for="(network, n) in asset.networks" :key="n" class="tab-pane fade show" :id="n"
                                     role="tabpanel" v-bind:class="{ active: network.first_item }">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="alert alert-info small">
                                                حداقل میزان برداشت قابل قبول:
                                                {{network.min_withdraw_amount | prec(8)}}
                                                {{asset.ticker}}
                                                <br/>
                                                میزان کارمزد برداشت:
                                                <span>
                                            {{network.withdraw_fee |  prec(8)}}
                                                {{asset.ticker}}
                                            </span>
                                            </div>
                                            <div v-if="network.description !==''" class="alert alert-secondary small"
                                                 v-html="network.description">
                                                {{network.description}}
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <form @submit="submit(n, $event)">
                                                <div class="form-group row">
                                                    <label for="i_amount" class="col-form-label">میزان
                                                        برداشت
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <button class="btn btn-outline-info" @click="maxAmount(n)"
                                                                    type="button">حداکثر موجودی
                                                            </button>
                                                        </div>
                                                        <currency-input class="form-control" id="i_amount" dir="ltr"
                                                                        autocomplete="off"
                                                                        v-model="forms[n].amount"
                                                                        locale="fa"
                                                                        :currency="null"
                                                                        :value-as-integer="false"
                                                                        :distraction-free="false"
                                                                        :auto-decimal-mode="false"
                                                                        :precision="{min: 0, max: asset.prec}"
                                                                        :value-range="{max: balance.available}"
                                                                        :allow-negative="false"></currency-input>
                                                        <div class="input-group-append w-auto">
                                                            <span class="input-group-text w-100">{{asset.ticker}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="i_address" class="col-form-label">آدرس <label
                                                            class="latin ltr">{{network.name}}</label> مقصد

                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" dir="ltr" autocomplete="off"
                                                               class="form-control latin" id="i_address"
                                                               v-model="forms[n].address">
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">
                                                                آدرس
                                                                &nbsp;
                                                            <small class="latin">
                                                                {{network.name}}
                                                            </small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="network.has_tag === true" class="form-group row">
                                                    <label for="i_address" class="col-sm-2 col-form-label">tag یا memo
                                                        یا paymentID</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" dir="ltr" autocomplete="off"
                                                               class="form-control latin" id="i_tag"
                                                               v-model="forms[n].tag">
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <div class="col">
                                                        <div class="mt-1">
                                                            میزان درخواستی جهت برداشت:
                                                            <span dir="ltr">
                                    {{forms[n].amount |  prec(asset.prec)}} {{asset.ticker}}
                                </span>
                                                        </div>
                                                        <div class="mt-1">

                                                        </div>
                                                        <div class="mt-1 large">
                                                            دریافتی شما:
                                                            <span dir="ltr"
                                                                  v-if="forms[n].amount - network.withdraw_fee > 0">
                                    {{forms[n].amount - network.withdraw_fee |  prec(8) }} {{asset.ticker}}
                                    </span>
                                                            <span v-else dir="ltr">
                                        0 {{asset.ticker}}
                                    </span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col">
                                                        <button type="submit" class="btn btn-success m-1">
                                                            برداشت
                                                        </button>
                                                        <router-link to="/user/wallet" class="btn btn-danger m-1">
                                                            انصراف
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row row col-12">
            <div class="col m-5">
                کاربر گرامی،
                <br/>
                با عرض پوزش به دستور پلیس فتا، امکان برداشت موجودی قبل از سپری شدن 72 ساعت پس از اولین سفارش مقدور
                نمی‌باشد.
                <br/>
                <br/>
                با تشکر از شما
            </div>
        </div>
    </div>

</template>

<script>


    import axios from 'axios';
    import numeral from 'numeral'
    import WAValidator from 'multicoin-address-validator'
    import maskEmailsPhones from "mask-email-phone"

    export default {
        name: 'WithdrawBoxCrypto',
        props: ['asset', 'balance'],
        components: {},
        data() {
            return {
                forms: [],
                backendRoot: process.env.VUE_APP_BACKEND_ROOT,
                cdnUrl: process.env.VUE_APP_CDN_URL,
                userInfo: this.$session.get('user_info'),
                showModal: false,
                countDown: 0,
                withdrawSummary: {}
            }
        },
        methods: {
            prec2(value, prec) {
                let format = '0,0';
                if (prec > 0) {
                    format = `0,0.[${'0'.repeat(prec)}]`;
                }
                return numeral(value).format(format)
            },
            onCopy: function () {
                return this.notice('success', 'کپی آدرس', 'آدرس با موفقیت کپی شد.', 3);
            },
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },


            showAlert(withdrawId) {
                const _this = this;
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/withdrawsummary/' + withdrawId, {withCredentials: true})
                    .then((result) => {
                        this.$swal.hideLoading();
                        _this.withdrawSummary = result.data;
                        const swalWithBootstrapButtons = this.$swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success w-100 btn-block form-control',
                                cancelButton: 'btn btn-danger',
                              popup: "box"
                            },
                            buttonsStyling: false
                        });
                        swalWithBootstrapButtons.fire({
                            width: '45rem',
                            title: 'اعتبار سنجی امنیتی جهت برداشت',
                            html: `<div class="col mt-3 text-left">
                             <div class="form-group row top-info">
                                <div class="col-3 col-md-2">میزان</div>
                                <div class="col-9 col-md-10">
                                دریافتی
                                  <label class="ltr latin">
                                  ${_this.prec2(_this.withdrawSummary.amount, _this.withdrawSummary.prec)}
                                  ${_this.withdrawSummary.asset}
                                  </label>
                                    &nbsp;
                                  (کارمزد شبکه
                                  <label class="ltr latin">
                                  ${_this.prec2(_this.withdrawSummary.fee, _this.withdrawSummary.prec)}
                                  ${_this.withdrawSummary.asset}
                                  </label>)
                                </div>
                              </div>

                             <div class="form-group row top-info">
                                <div class="col-3 col-md-2">آدرس</div>
                                <div class="col-9 col-md-10 ltr latin ltr">
                                  ${_this.withdrawSummary.to}
                                </div>
                              </div>

                              <div class="form-group row top-info">
                                <div class="col-3 col-md-2">شبکه</div>
                                <div class="col-9 col-md-10 ltr latin ltr">
                                  ${_this.withdrawSummary.network}
                                </div>
                              </div>
                            </div>

<div class="col-12">

                             <div class="form-group">
                             <label for="smsWithdrawCodeInput">کد شناسایی تلفن همراه</label>
                            <div class="input-group">
                              <input type="tel" class="form-control latin" maxlength="6" id="smsWithdrawCodeInput">
                              <div class="input-group-append">
                                <button class="btn btn-outline-info" type="button" id="smsWithdrawCodeBtn">دریافت کد </button>
                              </div>
                            </div>
                            <small id="smsHelp" class="form-text text-muted">لطفا کد ۶ رقمی که به تلفن همراه <label class="latin ltr">${maskEmailsPhones(_this.userInfo.mobile)}</label> ارسال می‌گردد را وارد نمایید.</small>
                            </div>

                            <div class="form-group">
                            <label for="twoFaInput">رمز دو عاملی</label>
                            <div class="input-group">
                              <input type="tel" class="form-control latin" maxlength="6" id="twoFaInput">
                            </div>
                            <small id="twoFaHelp" class="form-text text-muted">لطفا رمز ۶ رقمی دریافتی از اپلیکیشن Google Authenticator را وارد نمایید.</small>
                            </div>
</div>
                            </div>
                            `,
                            confirmButtonText: 'تایید',
                            showCloseButton: true,
                            allowEnterKey: false,
                            focusConfirm: true,
                            preConfirm: () => {
                                _this.$swal.getPopup().querySelector('#smsWithdrawCodeInput').classList.remove("is-invalid");
                                _this.$swal.getPopup().querySelector('#twoFaInput').classList.remove("is-invalid");

                               const smsToken = this.$swal.getPopup().querySelector('#smsWithdrawCodeInput').value;
                                const twoFa = this.$swal.getPopup().querySelector('#twoFaInput').value;
                                if (!smsToken) {
                                    this.$swal.getPopup().querySelector('#smsWithdrawCodeInput').classList.add("is-invalid");
                                    this.$swal.showValidationMessage(`لطفا تمامی موارد خواسته شده را پر کنید`)
                                }

                                if (!twoFa) {
                                    this.$swal.getPopup().querySelector('#twoFaInput').classList.add("is-invalid");
                                    this.$swal.showValidationMessage(`لطفا تمامی موارد خواسته شده را پر کنید`)
                                }

                               /* return fetch(`//api.github.com/users/${login}`)
                                    .then(response => {
                                        if (!response.ok) {
                                            throw new Error(response.statusText)
                                        }
                                        return response.json()
                                    })
                                    .catch(error => {
                                        Swal.showValidationMessage(
                                            `Request failed: ${error}`
                                        )
                                    })
*/

                               const params =  {
                                   "sms_token" : smsToken,
                                   "otp_code" : twoFa,
                                }
                                return axios.post(process.env.VUE_APP_BACKEND_URL + '/user/withdrawfinalize/' + withdrawId, params, {withCredentials: true})
                                    .then((response) => {
                                        return response.data;
                                    })
                                    .catch((e) => {
                                        this.$swal.showValidationMessage(e.response.data.error);
                                    });

                           }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$swal.fire(
                                    'برداشت موفق!',
                                    'درخواست برداشت شما با موفقیت ثبت شد!',
                                    'success'
                                )
                            }
                        })

                        const _smsWithdrawCodeBtn = this.$swal.getPopup().querySelector('#smsWithdrawCodeBtn');
                        _smsWithdrawCodeBtn.onclick = function () {
                            _this.$swal.getPopup().querySelector('#smsWithdrawCodeInput').classList.remove("is-invalid");

                            axios.post(process.env.VUE_APP_BACKEND_URL + '/user/withdrawsms/' + withdrawId, {}, {withCredentials: true})
                                .then(() => {
                                })
                                .catch(() => {
                                });

                            let counter = 60;
                            const mailCountdown = setInterval(() => {

                                _smsWithdrawCodeBtn.setAttribute("disabled", "disabled");
                                counter--;
                                _smsWithdrawCodeBtn.textContent = `کد ارسال شد`;
                                if (counter <= 0) {
                                    clearInterval(mailCountdown);
                                    _smsWithdrawCodeBtn.removeAttribute("disabled");
                                    _smsWithdrawCodeBtn.textContent = 'ارسال مجدد کد'
                                }

                            }, 1000);
                        }

                    })
                    .catch(() => {
                    });


            },
            maxAmount(key) {
                this.forms[key].amount = this.balance.available
            },
            clearForm() {
                for (const key of Object.keys(this.asset.networks)) {
                    this.forms[key] = {
                        address: '',
                        amount: 0,
                        tag: ''
                    }
                }
            },


            submit(networkId, e) {
                e.preventDefault();

                if (this.forms[networkId].amount <= 0 || this.forms[networkId].amount ===  undefined || this.forms[networkId].amount ==  null || this.forms[networkId].address === "") {
                    this.notice('error', 'ورودی های نادرست', 'لطفا اطلاعات خواسته شده را به درستی وارد نمایید', 5);
                    return;
                }

                if (this.forms[networkId].amount > this.balance.available) {
                    this.notice('error', 'ورودی های نادرست', 'مبلغ درخواستی بیش از موجودی شما است', 5);
                    return;
                }


                /*                if (this.forms[networkId].amount <= 0 || this.forms[networkId].address === "" || this.forms[networkId].otpCode === "" || this.forms[networkId].otpCode === null || this.forms[networkId].otpCode === undefined) {
                                    this.notice('error', 'ورودی های نادرست', 'لطفا اطلاعات خواسته شده را به درستی وارد نمایید', 5);
                                    return;
                                }

                                if (this.forms[networkId].otpCode.length !== 6) {
                                    this.notice('error', 'رمز دوعاملی نادرست', 'لطفا رمز دو عاملی را مجددا بررسی نموده و تصحیح فرمایید.', 5);
                                    return;

                                }*/

                if (this.forms[networkId].amount < this.asset.networks[networkId].min_withdraw_amount) {
                    this.notice('error', 'مبلغ نادرست', 'میزان درخواستی برداشت شما کمتر از حداقل مجاز برداشت می باشد.', 5);
                    return;
                }

                const validationTicker = this.asset.networks[networkId].validation_ticker;

                if (validationTicker !== '') {
                    if (WAValidator.findCurrency(validationTicker.toLowerCase()) && !WAValidator.validate(this.forms[networkId].address.trim(), validationTicker.toLowerCase())) {
                        this.notice('error', 'آدرس نامعتبر', 'آدرس مقصد وارد شده صحیح نمی‌باشد', 5);
                        return;
                    }
                }

                const swalWithBootstrapButtons = this.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success w-100',
                        cancelButton: 'btn btn-danger mr-1 ml-1',
                      popup: "box"
                    },
                    buttonsStyling: false
                });

                // const _this = this;
                swalWithBootstrapButtons.fire({
                    title: 'بررسی اطلاعات برداشت',
                    width: '40rem',
                    showCloseButton: true,
                    allowEnterKey: false,
                    focusConfirm: true,
                    showCancelButton: false,
                    html: `<div class="col mt-3 text-left">
                             <div class="form-group row top-info">
                                <div class="col-3 col-md-2">میزان</div>
                                <div class="col-9 col-md-10">
                                دریافتی
                                  <label class="ltr latin">
                                  ${this.prec2(this.forms[networkId].amount - this.asset.networks[networkId].withdraw_fee, this.asset.prec)}
                                  ${this.asset.ticker}
                                  </label>
                                    &nbsp;
                                  (کارمزد شبکه
                                  <label class="ltr latin">
                                  ${this.prec2(this.asset.networks[networkId].withdraw_fee, this.asset.prec)}
                                  ${this.asset.ticker}
                                  </label>)
                                </div>
                              </div>

                             <div class="form-group row top-info">
                                <div class="col-3 col-md-2">آدرس</div>
                                <div class="col-9 col-md-10 latin ltr">
                                  ${this.forms[networkId].address.trim()}
                                </div>
                              </div>

                              <div class="form-group row top-info">
                                <div class="col-3 col-md-2">شبکه</div>
                                <div class="col-9 col-md-10 latin ltr">
                                  ${this.asset.networks[networkId].name}
                                </div>
                              </div>
                            </div>

                            <div class="form-group text-left top-info">
                                  <ul>
                                   <li>از صحیح بودن آدرس وارد شده و شبکه انتخابی اطمینان حاصل نمایید</li>
                                   <li>تراکنش پس از تایید و ارسال قابل لغو نمی باشد</li>
                                  </ul>
                            </div>
`,
                    confirmButtonText: 'تایید و ادامه',
                    cancelButtonText: 'انصراف'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.notice('info', 'در حال ثبت درخواست برداشت...');
                        const withdrawInfo = {
                            asset: this.asset.ticker,
                            to: this.forms[networkId].address.trim(),
                            amount: this.forms[networkId].amount,
                            tag: this.forms[networkId].tag.trim(),
                            network_id: this.asset.networks[networkId].network_id,
                        };

                        axios.post(process.env.VUE_APP_BACKEND_URL + '/user/withdraw', withdrawInfo, {withCredentials: true})
                            .then((result) => {
                                this.clearForm();
                                this.showAlert(result.data.withdraw_id);
                            })
                            .catch((e) => {
                                if (e.response.data !== undefined) {
                                    this.notice('error', 'خطا', e.response.data.error, 10);
                                    return;
                                }

                                return this.notice('error', 'خطا', 'خطا در ثبت درخواست برداشت.', 5);

                            });

                    }
                });


            }
        },

        created() {
            this.clearForm();
        }
    }
</script>

<style scoped>

    .nav-pills .nav-link:not(.active) {
        background-color: #FCE6A3 !important;
        border: 1px solid #FFC107 !important;
    }

    .nav-pills .nav-link {
        background-color: #FFC107 !important;
        color: #333;
    }

    h4 .small {
        font-size: 14px;
    }


    .box .logo img {
        max-width: 100px;
    }

    .large {
        font-size: 20px;
        font-weight: bold;
    }

    .btn-danger {
        color: #fff !important;
    }

    @media screen and (max-width: 600px) {
        .box {
            font-size: 12px;
        }

        .box h4 {
            font-size: 18px;
        }

        .box .logo img {
            max-width: 50px;
        }
    }
</style>
