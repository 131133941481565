<template>
  <nav v-if="session.get('user_info')">
    <div
      @mouseover="openSidebar()"
      @mouseleave="closeSidebar()"
      class="sidebar"
    >
      <ul class="listTab flex-column" v-if="session.get('user_info')">
        <li
          v-for="item in listTabsUser"
          :key="item.name"
          @click="item.name !== 'معاملات' && changeTab(item.name)"
          @mouseleave="item.name === 'معاملات' && closeAccardion"
          :class="[
            'nav-item',
            activeTab === item.name && 'activeTab',
            isSideBarOpen ? 'liTabOpen' : 'liTabClose',
          ]"
        >
          <span
            v-if="item.name === 'معاملات'"
            id="accordion"
            class="title w-100 "
          >
            <div
              class="card-accordion w-100"
              data-toggle="collapse"
              :data-target="`#collapse1`"
              :aria-controls="`collapse1`"
            >
              <div class="header-accordion" :id="`heading1`">
                <img :src="item.img" />
                <div class="header-accordionText">
                  <span class="mb-0">معاملات</span>
                  <button class="btn-accordion">
                    <img
                      data-toggle="collapse"
                      :data-target="`#collapse1`"
                      class="btn-nav-accordion w-50"
                      src="@/assets/images/sidebar/down-arrow.png"
                      alt=""
                    />
                  </button>
                </div>
              </div>

              <div
                :id="`collapse1`"
                :class="['collapse']"
                :aria-labelledby="`heading1`"
                @mouseleave="item.name === 'معاملات' && closeAccardion"
                data-parent="#accordion"
              >
                <div class="body-accordion">
                  <div class="eachBox" @click="changeTab(item.name)">
                    <router-link to="/tradeview/BTC_IRT" class="w-100">
                      <div>
                        <img
                          src="@/assets/images/header/tradeview.png"
                          alt=""
                        />
                      </div>
                      <span class="text">معامله حرفه ای</span>
                    </router-link>
                  </div>
                  <div class="eachBox" @click="changeTab(item.name)">
                    <router-link to="/user/fast-trade" class="w-100">
                      <div>
                        <img
                          src="@/assets/images/header/fastTrade.png"
                          alt=""
                        />
                      </div>
                      <span class="text">معامله آسان</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <router-link v-else :to="item.link" @click="changeTab(item.name)">
            <img :src="item.img" :alt="item.name" />
            <span>{{ item.name }}</span></router-link
          >
        </li>
      </ul>

    </div>
  </nav>
</template>

<script>
import $ from "jquery";
export default {
  name: "SideBar",
  props: ["activeTab"],
  data() {
    return {
      isSideBarOpen: false,
      session: this.$session,
      listTabsUser: [
        {
          img: require("@/assets/images/sidebar/dashboard.png"),
          name: "داشبورد",
          link: "/user/profile",
        },
        {
          img: require("@/assets/images/sidebar/wallet.png"),
          name: "کیف پول",
          link: "/user/wallet",
        },
        {
          img: require("@/assets/images/sidebar/orders.png"),
          name: "سفارشات",
          link: "/user/orders",
        },
        {
          img: require("@/assets/images/sidebar/history.png"),
          name: "تاریخچه",
          link: "/user/history",
        },
        {
          img: require("@/assets/images/sidebar/trade.png"),
          name: "معاملات",
          link: "/tradeview/BTC_IRT",
        },
        {
          img: require("@/assets/images/sidebar/user.png"),
          name: "اطلاعات کاربری",
          link: "/user/identity-verify",
        },
        {
          img: require("@/assets/images/sidebar/user.png"),
          name: "اطلاعات بانکی",
          link: "/user/bank",
        },
        // {
        //   img: require("@/assets/images/sidebar/list.png"),
        //   name: "لیست من",
        //   link: "",
        // },
        {
          img: require("@/assets/images/sidebar/settings.png"),
          name: "تنظیمات",
          link: "/user/settings",
        },
        {
          img: require("@/assets/images/sidebar/invitation.png"),
          name: "دعوت دوستان",
          link: "/user/affiliate",
        },

        {
          img: require("@/assets/images/sidebar/report.png"),
          name: "گزارش ورود",
          link: "/user/login-log",
        },

        {
          img: require("@/assets/images/sidebar/logout.png"),
          name: "خروج",
          link: "",
        },
      ],
      listTabs: [
        {
          img: require("@/assets/images/sidebar/login.png"),
          name: "ورود و ثبت نام",
          link: "/login",
        },
        {
          img: require("@/assets/images/sidebar/info.png"),
          name: "راهنما",
          link: "/how-to-use",
        },
        {
          img: require("@/assets/images/sidebar/trade.png"),
          name: "معاملات",
          link: "",
        },
      ],
    };
  },
  methods: {
    openSidebar() {
      this.isSideBarOpen = true;
    },
    closeSidebar() {
      this.isSideBarOpen = false;
      this.closeAccardion();
    },
    closeAccardion() {
      $("#collapse1").collapse("hide");
    },

    changeTab(item) {
      // Emit an event to notify the parent about the change
      this.$emit("handleActiveTab", item);
    },
  },

  watch: {
    isSideBarOpen: function(newValue) {
      if (newValue === false) {
        setTimeout(() => {
          return this.closeAccardion();
        }, 500);
      }
      // You can add more logic here based on the new value
    },
  },
  mounted() {
    const findTab = this.listTabsUser.find((item) => {
      return item.link === this.$route.path;
    });
    this.changeTab(findTab.name);
  },
};
</script>

<style scoped>
.sidebar {
  width: 70px;
  height: 80%;
  transition: width .5s;
  background-color: #212b36;
  z-index: 100;
  border-left: solid transparent;
  border-width: .06px !important;
  position: fixed;
  border-radius: 12px;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
}

.sidebar:hover {
  width: 200px;
}
.openSideBar {
  position: absolute;
}
.closeSideBar {
  position: inherit;
}
nav {
  position: relative;
  width: 100px !important;
}
.activeTab {
  background-color: #454f5b;
}
.listTab {
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 10px 0;
  overflow: hidden;
}
.listTab li {
  display: flex;
  width: 200px;
  align-items: center;
  cursor: pointer;
}
.listTab li a {
  width: 100%;
  gap: 20px;

  padding: 13px 20px 13px 0;
  display: flex;
  justify-content: start;
}
.listTab li a:hover {
  color: white;
}
.liTabClose {
  justify-content: start;
}

.liTabOpen {
  justify-content: start;
}
.liTabOpen a {
  display: flex;
  justify-content: center;
  opacity: 2;
  transition: opacity 2s;
}
.liTabOpen span {
  display: inline;
}
li img {
  width: 30px;
}

.header-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 21px 15px 0;
}

.btn-accordion {
  width: 40px;
  border: none;
  background-color: inherit;
}
.body-accordion {
  background-color: inherit;
}
.body-accordion a {
  font-size: 0.8rem;
  /* width: 100%; */
}
.body-accordion .eachBox {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.body-accordion .eachBox a {
  padding: 10px 40px 10px 0 !important;
  width: 100% !important;
}
.body-accordion .eachBox img {
  width: 20px;
  padding: 0px !important;
}

.header-accordionText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 15px;
  width: 100%;
}
#collapse1 {
  background-color: #302d2c; /* Change the background color as needed */
}
@media screen and (max-width: 768px) {
  nav {
    display: none;
  }
}
.btn-nav-accordion[aria-expanded="true"] {
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}
.btn-nav-accordion[aria-expanded="false"] {
  transform: rotate(0deg);
  transition: all 0.3s ease;
}
</style>
