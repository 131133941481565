<template>
    <apexchart type="donut" :options="options" :series="series"></apexchart>
</template>


<script>
    import numeral from 'numeral'
    export default {
        name: 'PieChart',
        props: ['balances'],
        data() {
            return {
                labels: null,
                series: this.balances.map(a => a.balance),
                series342: this.balances.map(a => a.balance),
                options: {
                    colors: ['#89D60B', '#FFC70D', '#3590AB', '#64CA8C', '#55c9c2', '#FF33A6', '#FF0000', '#8A085A', '#FFDE00', '#6C7988', '#FF5454', '#5C54FF', '#FF7C54'],
                    labels: this.balances.map(a => a.name),
                    chart: {
                        fontFamily: 'IRANSans'
                    },
                    legend: {
                        show: false
                    },
                    responsive: [{
                        breakpoint: undefined,
                        options: {},
                    }],
                    stroke: {
                        show: false,
                    },
                    tooltip: {
                        y: {
                            formatter: function(value) {
                                return numeral(value).format('0,0');
                            }
                        }
                    },
                    plotOptions: {

                        pie: {
                            startAngle: 0,
                            expandOnClick: true,
                            donut: {
                                size: '25%',
                                background: 'transparent',

                            },
                        },
                    },
                },
            }
        },

    }
</script>

<style>
</style>