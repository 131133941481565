<template>
    <div role="table" class="table-container">
        <div class="scrollable" style="height: 452px">
            <div v-for="d in bids" :key="d.id" class="flex-table clickable" @click="rowClick(d)">
                <span class="after bgGreen" :style="'width: '+ d.percent+'%;'">&nbsp;</span>
                <div class="flex-row">

                    {{d.price | prec(market.price_prec) }}
                </div>
                <div class="range-selector flex-row">{{d.volume | prec(market.amount_prec)}}
                </div>
                <div class="flex-row text-success">
                    {{d.price * d.volume | prec(market.quote_asset_prec)}}
                    <span class="dot" v-if="hasOpen(d.price)" style="float: left"></span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import percent from 'percent';

    export default {
        name: 'OrderbookBid',
        props: ['market', 'depth', 'total', 'opens'],
        methods: {
            rowClick: function (row) {
                this.$emit('rowClick', row);
            },
            hasOpen(price){
                return this.opens.includes(price);

            }
        },


        computed: {
            bids: function () {
                const b = [];
                let p = 0;
                let id = 0;
                const to = this.total;
                if (this.depth.bids !== undefined) {
                    this.depth.bids.forEach(function (element) {
                        const c = {};
                        c.id = id;
                        c.price = parseFloat(element[0]);
                        c.volume = parseFloat(element[1]);
                        p = p + parseFloat(element[1]);
                        c.total = p;
                        c.percent = percent.calc(p, to, 2);
                        b.push(c);
                        id++;
                    });
                }
                return b;
            },
        },
    }
</script>

<style scoped>

    .dot {
        margin-top: 5px;
        height: 5px;
        width: 5px;
        background-color: #F8A91B;
        border-radius: 50%;
        display: inline-block;
    }


    .clickable{
        cursor: pointer;
    }
    .text-success {
        color: #0EBF00 !important;
    }

    .text-danger {
        color: #FF4C2F !important;
    }

    .scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        scrollbar-color: rgba(0,0,0,0.3) transparent;

    }



    .scrollable::-webkit-scrollbar {
        width: 8px;
    }
    .scrollable::-webkit-scrollbar-track {
        background: transparent;
    }
    .scrollable::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.3);
        border-radius: 20px;
    }


    .table-container {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }

    .flex-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        border-left: 1px solid #444140;
        position: relative;
    }

    .bgGreen {
        background: rgba(88, 255, 60, .09);
    }

    .after {
        color: rgba(28, 29, 38, 0);
        position: absolute;
        height: 100%;
    }

    .flex-row {
        width: 33.33333%;
        text-align: right;
        display: inline-block;
        border-top: 1px solid #444140;
        padding: 6px;
        /*
                background-color: #fff;
        */
        color: #fff;
        font-size: 11px;
    }

    .range-selector {
        display: inline-block;
    }
</style>
