<template>

    <section>
        <div v-if="stars == 2 || stars == 3 || stars == 4" class="row">

            <div class="col-6  text-right">
                <button @click="cardDeposite" class="btn btn-warning btn-outline-danger btn-rounded text-dark new-btn button">واریز شتابی</button>
            </div>

            <div class="col-6 text-left">
                <button @click="identifyDeposite" class="btn btn-warning btn-outline-danger btn-rounded text-dark new-btn button">واریز شناسه دار <small class="text-success font-weight-bold">(جدید)</small></button>
            </div>

        </div>

        <div id="cardDepositeDiv" class="row mt-4 mr-3 ml-3 mb-2 p-3 box bg-white" style="display: block;">
            <div class="row" style="color: #6c757d; margin: auto;">

                <div class="col-2 logo">
                <img v-if="asset.logo_filename"
                    :src="`${cdnUrl}/media/asset/logo/md/${asset.logo_filename}`"
                    :alt="asset.ticker">
                </div>
                <div class="col-10 col-md-6 pt-2">
                    <h4 class="bold">
                        واریز {{asset.name}}
                        <span class="text-dark small">{{asset.ticker}}</span>
                    </h4>
                    <p class="text-secondary small">
                        لطفا
                        جهت واریز
                        {{asset.name}}،
                        مبلغ مورد نظر جهت پرداخت را وارد نموده و پرداخت را کلیک نمایید.
                        <br/>
                        به درگاه پرداخت اینترنتی هدایت خواهید شد و پس از پرداخت و بازگشت به سایت، اعتبار {{asset.name}} شما به
                        روزرسانی خواهد شد.

                    </p>
                </div>
                <div class="col-12 col-md-4">

                    <div class="row">
                        <div class="col-12 mb-2">
                            <span class="text-secondary">
                                موجودی:
                            </span>
                            {{balance.available | prec(asset.prec)}}
                        </div>
                        <div class="col-12">
                            <span class="text-secondary">
                            در سفارشات:
                            </span>
                            {{balance.freeze | prec(asset.prec)}}
                        </div>
                    </div>

                </div>

            </div>


            <div class="col-12 mt-1">
<!--                <div v-if="asset.description !==''" class="alert alert-secondary small" v-html="asset.description">-->
<!--                    {{asset.description}}-->
<!--                </div>-->

                <div class="row mt-4">
                    <div class="col-12">

                        <div class="vld-parent" ref="tableContainer">
                        <span v-if="Object.keys(asset.networks).length > 1">
                        <ul class="nav nav-tabs nav-pills nav-justified" id="marketTabs" role="tablist">
                            <li v-for="(network, n) in asset.networks" :key="n" class="nav-item text-center">
                                <a class="nav-link" :id="n + 'tab'" data-toggle="tab" :href="'#'+n" role="tab"
                                v-bind:class="{ active: network.first_item }">{{network.name}}</a>
                            </li>
                        </ul>
                        </span>

                            <div class="tab-content mt-3 mb-3" id="myTabContent">
                                <div v-for="(network, n) in asset.networks" :key="n" class="tab-pane fade show" :id="n"
                                    role="tabpanel" v-bind:class="{ active: network.first_item }">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div v-if="network.min_deposit_amount > 0 || network.deposit_fee > 0" class="alert alert-info small">
                                                <span v-if="network.min_deposit_amount > 0">
                                                    حداقل میزان واریز قابل قبول:
                                                    {{network.min_deposit_amount | prec(asset.prec)}}
                                                    {{asset.ticker}}
                                                    <br/>
                                                </span>
                                                <span v-if="network.deposit_fee > 0">
                                                    میزان کارمزد واریز:
                                                    <span>
                                                    {{network.deposit_fee |  prec(asset.prec)}}
                                                        {{asset.ticker}}
                                                    </span>
                                                </span>
                                            </div>
                                            <div v-if="network.description !==''" class="alert alert-secondary small"
                                                v-html="network.description">
                                                {{network.description}}
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <form @submit="submitForm(n, $event)">
                                                <div class="form-group row">
                                                    <label for="i_address"
                                                           style="display:flex; justify-content: space-between; width: 100%;"
                                                           class="col-12 flex-col justify-space-between">
                                                      <span>انتخاب کارت</span>
                                                      <router-link class="text-right" style="color: #0c82df" to="/user/bank" href="#">افزودن کارت بانکی</router-link>
                                                    </label>
                                                    <div class="col-sm-12 mb-3">
                                                        <select v-if="cards !== undefined && cards.length > 0" dir="ltr" autocomplete="off"
                                                                class="form-control latin" id="i_address"
                                                                v-model="forms[n].cardNo">
                                                            <option v-for="card in cards" v-bind:key="card.id" :value="card.card_no">
                                                                {{card.card_no}}
                                                                - بانک
                                                                {{card.bank_name}}
                                                            </option>
                                                        </select>
                                                        <span v-else>
                                                        <select class="form-control" disabled>
                                                            <option>
                                                                شما هیچ کارت بانکی تایید شده ای ندارید
                                                            </option>
                                                        </select>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="i_amount" class="col-12 col-form-label">مبلغ مورد نظر</label>
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                            <currency-input type="text" class="form-control" id="i_amount"
                                                                            autocomplete="off"
                                                                            v-model="forms[n].amount"
                                                                            locale="fa"
                                                                            :currency="null"
                                                                            :value-as-integer="false"
                                                                            :distraction-free="false"
                                                                            :auto-decimal-mode="false"
                                                                            :precision="0"
                                                                            :allow-negative="false"
                                                                            dir="ltr">
                                                            </currency-input>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text">{{asset.name}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-2">

                                                    </div>
                                                    <div class="col-sm-10">
                                                        <button type="submit" class="btn btn-success m-1">تایید و پرداخت</button>
                                                        <router-link to="/user/wallet" class="btn btn-danger m-1" href="#">انصراف</router-link>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div id="identifyDepositeDiv" class="row mt-4 mr-3 ml-3 mb-2 p-3 box bg-white" style="display: none;">
            <div class="row" style="color: #6c757d; margin: auto;">
            <div class="col-2 logo">
                <img v-if="asset.logo_filename"
                    :src="`${cdnUrl}/media/asset/logo/md/${asset.logo_filename}`"
                    :alt="asset.ticker">
            </div>
            <div class="col-10 col-md-6 pt-2">
                <h4 class="bold">
                    واریز {{asset.name}}
                    <span class="text-dark small">{{asset.ticker}}</span>
                </h4>
                <p class="text-secondary small">
                    لطفا
                     جهت واریز شناسه دار
                   ،
                    ،شناسه ایجاد شده را در قسمت شناسه پرداخت در موبایل بانک
                    اینترنت بانک یا فیش پرداخت بانکی وارد کنید و مطمئن باشید که شناسه مربوط به
                    شماره شبای مبدا را وارد کرده اید.

                </p>
            </div>
            <div class="col-12 col-md-4">

                <div class="row">
                    <div class="col-12 mb-2">
                                                <span class="text-secondary">
                                                    موجودی:
                                                </span>
                        {{balance.available | prec(asset.prec)}}
                    </div>
                    <div class="col-12">
                                                <span class="text-secondary">
                                                در سفارشات:
                                                </span>
                        {{balance.freeze | prec(asset.prec)}}
                    </div>
                </div>

            </div>
        </div>
            <div class="col-12 mt-1">
                <div v-if="asset.description !==''" class="alert alert-secondary">
                    <p class="text-danger fs-1">توجه:</p>

                    <p class="text-center text-danger ">
                        تاکید می شود که از دریافت وجه ریالی از افراد ناشناس و انتقال رمزارز به آن ها
                        خودداری نمایید
                        چرا که در صورت بروز هر گونه مشکل احتمالی
                        مسئولیت قضایی ناشی از این امر به عهده کاربر است و دفینکس مسئولیتی در این زمینه ندارد.
                    </p>
                </div>

                <div class="row mt-4">
                    <div class="col-12">

                        <div class="vld-parent" ref="tableContainer">
                        <span v-if="Object.keys(asset.networks).length > 1">
                        <ul class="nav nav-tabs nav-pills nav-justified" id="marketTabs" role="tablist">
                            <li v-for="(network, n) in asset.networks" :key="n" class="nav-item text-center">
                                <a class="nav-link" :id="n + 'tab'" data-toggle="tab" :href="'#'+n" role="tab"
                                v-bind:class="{ active: network.first_item }">{{network.name}}</a>
                            </li>
                        </ul>
                        </span>

                            <div class="tab-content mt-3 mb-3" id="myTabContent">
                                <div v-for="(network, n) in asset.networks" :key="n" class="tab-pane fade show" :id="n"
                                    role="tabpanel" v-bind:class="{ active: network.first_item }">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="alert alert-info small">

                                                <span>
                                                    میزان کارمزد واریز:
                                                    <span>
                                                  <strong> ٪0.01</strong>

                                                         مبلغ واریزی
                                                          ، برای مثال اگر مبلغ واریزی
                                                          صد میلیون تومان باشد ،
                                                          کارمزد 0.01 آن معادل 10 هزار تومان خواهد بود.
                                                    </span>
                                                </span>

                                            </div>
                                            <div class="alert alert-secondary small">
                                                <p class="h6"><strong>در هنگام پرداخت لطفا به نکات زیر دقت نمایید:</strong></p>

                                                <ol>
                                                    <li>واریز شناسه دار بانکی، صرفا از حساب مبدا انتخاب شده امکان‌پذیر است.</li>
                                                    <li>هنگام واریز حتما توجه کنید که به اطلاعات حساب مقصد نمایش داده شده مختص به حساب مبدا انتخاب شده توسط شما واریز انجام شود.</li>
                                                    <li>هنگام واریز حتما توجه کنید که شناسه واریز نمایش داده شده مختص به حساب مبدا منتخب، حتما در فرم واریز ثبت شود.</li>
                                                    <li>هنگام واریز حتما توجه کنید که نام به صورت کامل و مطابق با کارت ملی ثبت شود.</li>
                                                    <li>با توجه به اینکه هر کدام از شماره شباهای ثبت شده در پنل کاربری شما دارای شناسه واریز متفاوت است، بنابراین در فرآیند واریز به صحیح وارد نمودن شناسه مقصد با توجه به حساب مبدا خود دقت فرمایید.</li>
                                                    <li>در صورتی که شناسه مقصد اشتباه وارد شود، مبلغ پس از یک تا سه روز کاری با کسر کارمزد به حساب مبدا عودت خواهد شد.</li>
                                                    <li>از شماره شبا متعلق به حساب های بانکی مشترک جهت واریز بانکی استفاده نکنید.</li>
                                                    <li>سقف واریز شناسه دار در روز ۱۰۰ میلیون تومان است، در صورت واریز بیش از این مقدار در روز، مسئولیت عدم تایید یا تایید دیرهنگام بر عهده کاربر است.</li>
                                                </ol>
                                            </div>

                                            <div class="alert alert-warning small">
                                                <p class="h6"><strong>فرآیند تایید واریز شناسه دار و افزایش موجودی در دفینکس</strong></p>
                                                <ul>
                                                    <li>
                                                        <p>
                                                            پس از انجام واریز شناسه دار نیازی به انجام کار دیگری ندارید. موجودی حساب کاربری شما پس از گرفتن تایید نهایی از
                                                            حساب مقصد به صورت اتوماتیک افزایش خواهد یافت.
                                                            به خاطر داشته باشید که فرآیند واریز شناسه دار نسبت به واریز شتابی طولانی تر است; در نتیجه حداکثر یک ساعت پس از نشستن مبلغ واریزی به حساب مقصد، موجودی حساب شما افزایش خواهد یافت.
                                                        </p>
                                                    </li>
                                                </ul>


                                            </div>

                                        </div>
                                        <div class="col-12 mt-3">
                                            <form @submit="submitForms(n, $event)">
                                                <div class="form-group row">
                                                    <label for="iban_address" class="col-sm-2 col-form-label">شماره شبا </label>
                                                    <div class="col-sm-10">

                                                        <select v-if="cards !== undefined && cards.length > 0" dir="ltr" autocomplete="off"
                                                                class="form-control latin" id="iban_address"
                                                                v-model="forms[n].iban">
                                                            <option v-for="card in cards" v-bind:key="card.id" :value="card.iban_no">
                                                                {{card.iban_no}}
                                                                - بانک
                                                                {{card.bank_name}}
                                                            </option>
                                                        </select>
                                                        <span v-else>

                                                        <select class="form-control" disabled>
                                                            <option>
                                                                شما هیچ کارت بانکی تایید شده ای ندارید
                                                            </option>
                                                        </select>

                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-2">

                                                    </div>
                                                    <div class="col-sm-10 text-center">
                                                        <button type="submit" class="btn btn-success m-2">ایجاد شناسه واریز</button>
                                                        <router-link class="btn btn-warning m-2" to="/user/identity-verify">افزودن شماره شبا</router-link>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                    <div v-if="paymentsid !== undefined && paymentsid.length > 0"  class="bg-info table-responsive my-5">

                                        <table id="datatables" style="background-color: azure; box-shadow: 10px;" class="table small">
                                            <thead>
                                                <tr>
                                                    <th>شماره شبا مبدا</th>
                                                    <th>شناسه واریز</th>
                                                    <th>شماره شبا مقصد</th>
                                                    <th>شماره حساب مقصد</th>
                                                    <th>بانک مقصد</th>
                                                    <th>نام صاحب حساب مقصد</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="paymentid in paymentsid" :key="paymentid.id" class="mmm">
                                                <td> {{paymentid.from_ibank}}</td>
                                                <td> {{paymentid.pay_id}}</td>
                                                <td> {{paymentid.to_ibank}}</td>
                                                <td> {{paymentid.destinationDepositNumber}}</td>
                                                <td> {{paymentid.bank_name}}</td>
                                                <td> {{paymentid.service_provider}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

    import axios from 'axios';

    export default {
        name: 'DepositBoxFiat',
        props: ['asset', 'balance'],
        components: {},
        data() {
            return {
                backendRoot: process.env.VUE_APP_BACKEND_ROOT,
                cdnUrl: process.env.VUE_APP_CDN_URL,
                cards: [],
                forms: [],
                stars: '',
                cardNo: '',
                iban: '',
                paymentsid:[],

            }
        },
        methods: {
            notice(type, title, description, duration) {
                if (duration === undefined) {
                    duration = 1;
                }
                this.$notice[type]({
                    title: title,
                    description: description || "",
                    duration: duration,
                })
            },

            updateCardList() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/card-list?confirmed=true', {withCredentials: true})
                    .then(response => {
                        this.cards = response.data;
                        this.cardNo = this.cards[0].card_no;
                        this.clearForm();
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },

            updatePaymentsList() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/paymentsid/1', {withCredentials: true})
                    .then(response => {
                        this.paymentsid = response.data;
                        this.clearForm();
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },

            updateInfo() {
                axios.get(process.env.VUE_APP_BACKEND_URL + '/user/info', {withCredentials: true})
                    .then(response => {
                        this.stars = response.data.fee_group.stars;
                        this.clearForm();
                    })
                    .catch(() => {
                        // console.log(e)
                    });
            },

            clearForm() {
                for (const key of Object.keys(this.asset.networks)) {
                    this.forms[key] = {
                        amount: 0,
                        cardNo: this.cardNo
                    }
                }
            },

            cardDeposite(e){
                e.preventDefault();
                document.getElementById("cardDepositeDiv").style.display = "block";
                document.getElementById("identifyDepositeDiv").style.display = "none";
            },

            identifyDeposite(e){
                e.preventDefault();
                document.getElementById("cardDepositeDiv").style.display = "none";
                document.getElementById("identifyDepositeDiv").style.display = "block";
            },

            submitForms(networkId, e) {
                e.preventDefault();

                if(this.forms[networkId].iban <= 0 || this.forms[networkId].iban === undefined || this.forms[networkId].iban === null ){
                    this.notice('error', 'ورودی های نادرست', 'شما کارتی جهت پرداخت انتخاب نکرده اید و یا کارت تایید شده ای ندارید', 5);
                    return;
                }


                 this.notice('info', 'ثبت درخواست واریز', 'در حال ایجاد شناسه واریز، لطفا لحظه‌ای صبر کنید.', 2);

                const params2 = {
                    iban: this.forms[networkId].iban
                };

                axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/paymentsid/${this.forms[networkId].iban}`, params2, {withCredentials: true})
                    .then((response) => {

                        if(response.data == false){
                            this.notice('error', 'ورودی های نادرست', 'شماره شبا انتخاب شده تکراری می باشد.', 7);
                        }else{
                            this.paymentsid = response.data;
                        }



                        // this.notice('success', 'پرداخت موفق', "با تشکر پرداخت موفق بود", 3);
                        //     this.isSuccess = true;
                        //     this.refnum = response.data.ref_num;
                        //     this.amount = response.data.amount;
                        //     this.isPending = false;

                        return;
                    })

            },

            submitForm(networkId, e) {
                e.preventDefault();

                if(this.forms[networkId].amount <= 0){
                    this.notice('error', 'ورودی های نادرست', 'لطفا مبلغ مورد نظر جهت واریز را به درستی وارد نمایید', 5);
                    return;
                }

                if(this.forms[networkId].cardNo <= 0 || this.forms[networkId].cardNo === undefined || this.forms[networkId].cardNo === null ){
                    this.notice('error', 'ورودی های نادرست', 'شما کارتی جهت پرداخت انتخاب نکرده اید و یا کارت تایید شده ای ندارید', 5);
                    return;
                }

                if(this.forms[networkId].amount < this.asset.networks[networkId].min_deposit_amount){
                    this.notice('error', 'مبلغ نادرست', 'مبلغ وارد شده جهت واریز، کمتر از حداقل مبلغ مجاز جهت واریز می باشد.', 5);
                    return;

                }

                this.notice('info', 'ثبت درخواست واریز', 'در حال ثبت واریز آنلاین، لطفا لحظه‌ای صبر کنید.', 10);

                const params = {
                    amount: this.forms[networkId].amount,
                    card_no : this.forms[networkId].cardNo.trim(),
                    network_id: this.asset.networks[networkId].network_id,
                };

                axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/fiat-deposit/${this.asset.ticker}`, params, {withCredentials: true})
                    .then((response) => {
                        this.notice('success', 'در حال هدایت به درگاه', "در حال هدایت به درگاه هستید. لطفا کمی صبر کنید", 3);
                        window.location.href = response.data.redirect_url;
                        // console.log(response.data);
                        return;

                    })
                    .catch(e => {
                        this.notice('error', 'خطا', e.response.data.error, 10);
                    });
            }
        },
        created() {
            this.clearForm();

            this.updateCardList();

            this.updatePaymentsList();

            this.updateInfo();
        },



    }
</script>

<style scoped>

    .nav-pills .nav-link:not(.active) {
        background-color: #FCE6A3 !important;
        border: 1px solid #FFC107 !important;
    }

    .nav-pills .nav-link {
        background-color: #FFC107 !important;
        color: #333;
    }
    h4 .small{
        font-size: 14px;
    }


    .box .logo img {
        max-width: 100px;
    }

    .new-btn{
        width: 170px;
        height: 50px;
        margin-top: 15px;
    }

    #datatables thead tr th{
        text-align: center;
    }

    #datatables tbody tr td{
        text-align: center;
    }

    html, body {
        height: 100%;
    }

    .wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button {
        color: #313133;
        background: #ff20da;
        background: linear-gradient(90deg, rgb(250, 164, 6) 0%, rgb(245, 188, 82) 100%);
        border: none;
        border-radius: 1000px;
        box-shadow: 12px 12px 24px rgba(79,209,197,.64);
        transition: all 0.3s ease-in-out 0s;
        cursor: pointer;
        outline: none;
        position: relative;
        padding: 10px;
    }

    .button:hover, .button:focus {
        color: #313133;
        transform: translateY(-6px);
    }

    button:hover::before, button:focus::before {
        opacity: 1;
    }

    button::after {
        content: '';
        width: 30px; height: 30px;
        border-radius: 100%;
        border: 6px solid #ffc400;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: ring 1.5s infinite;
    }

    button:hover::after, button:focus::after {
        animation: none;
        display: none;
    }


    @media screen and (max-width: 600px) {
        .box {
            font-size: 12px;
        }

        .box h4 {
            font-size: 18px;
        }

        .box .logo img {
            max-width: 50px;
        }

        .new-btn{
            width: 100px;
            height: 50px;
        }
    }
</style>
