<template>
    <div >
                <div class="m-4">

                    <div class="container">
                        <div class="card my-5">
                            <div class="row m-auto">
                                <img class="login-logo" src="../assets/images/logo4.png" />
                            </div>
                            <div class="row m-auto">
                                <img class="tick-logo" src="../assets/images/Tick.png" />
                            </div>
                            <div class="row m-auto">
                                <h5 class="my-5">کاربر گرامی مراحل احراز هویت شما با موفقیت تکمیل شده است.</h5>
                            </div>
                            <div class="row m-auto">
                                <h5 class="my-5">نتیجه احراز هویت تا دقایقی دیگر از طریق پیامک به اطلاع شما خواهد رسید.</h5>
                            </div>
                            <div class="row m-auto">
                                <router-link class="nav-link btn btn-secondary" to="/user/profile">مشاهده پروفایل</router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
</template>

<script>

    export default {
        name: 'web-callback',
        title: "برگشت uid",

        props: {
            msg: String
        },
        data() {
            return {
                announcements: null,
                pageCount : 0,
                assets: [],
                markets: []
            }
        },
        methods: {
            checkSession() {

            if (!this.$session.exists('user_info')) {
                this.$session.destroy();
                return this.$router.push('/login').catch(() => {
                });
            }
        },

        },
        created() {
            this.checkSession();
            this.updateMarketList();
        },
        mounted() {
            this.getAnnouncements()

        }

    }
</script>
<style lang="scss" scoped>
    .app {
        text-align: center;

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            background-color: #222;
            color: #fff;
        }

        &__title {
            display: block;
            font-size: 1.5em;
        }
    }
</style>
<style scoped>

    .wrapper {
        width: 100%;
    }

    .card{
        width: 800px;
        margin: auto;
    }

    #content {
        width: 100%;
        display: block;
        padding: 0;
        min-height: 100vh;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
    }

    .login-logo{
        width: 170px;
        margin-bottom: 20px;
    }

    .tick-logo{
        width: 50px;
    }

    @media screen and (max-width: 992px) {
        .card{
            width: unset;
            margin: auto;
        }
    }
    @media screen and (max-width: 770px) {

        .login-logo{
            width: 140px;
        }

        .card{
            width: unset;
            margin: auto;
        }

        .tick-logo{
            width: 35px;
        }

        h5{
            font-size: 13px;
            padding: 10px;
        }
    }

</style>
