<template>
  
    <div class="m-4">
      <h2>
        عدم تکمیل اطلاعات کاربری
      </h2>

      <div class="container">
        <div class="box p-5 mt-3 box">
          کاربر گرامی، جهت ادامه برای واریز، برداشت و معامله در سایت، لطفا
          اطلاعات حساب کاربری خود را تکمیل نمایید

          <div class="pt-5 text-center">
            <router-link class="btn btn-warning" to="/user/identity-verify">
              تکمیل اطلاعات حساب کاربری
            </router-link>
          </div>
        </div>
      </div>
    </div>
 
</template>

<script>
import axios from "axios";

export default {
  name: "NotVerified",
  title: "عدم ثبت اطلاعات کاربری",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      markets: [],
    };
  },
  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
        (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  methods: {
    updateMarketList() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
        .then((response) => {
          this.markets = response.data;
        })
        .catch(() => {
          // console.log(e)
        });
    },
  },
  created() {
    this.updateMarketList();
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: block;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.btn-warning {
  border-radius: 20px;
  min-width: 250px;
}
</style>
