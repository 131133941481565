<template>
  <div class="px-4 my-5 ">
    <h2>اطلاعات حساب کاربری</h2>

    <div class="container">
      <div class="row mt-3">
        <div
            v-if="kycStatus.verify_status === 'pending'"
            class="col-12 alert alert-warning"
        >
          اطلاعات شما ثبت شده و منتظر تایید است.
        </div>
        <div
            v-if="kycStatus.verify_status === 'rejected'"
            class="col-12 alert alert-warning text-center"
        >
          <p v-if="kycStatus.reject_reason !== ''">
            <b>
              {{ kycStatus.reject_reason }}
            </b>
          </p>
        </div>

        <div
            v-if="kycStatus.verify_status === 'verified'"
            class="col-12 alert alert-success"
        >
          اطلاعات شما بررسی و تایید شده است.
          <br />
          در صورت مطابقت نام و نام خانوادگی شما با مشخصات حساب،‌ تایید به صورت
          خودکار انجام می گیرد و نیازی به تایید دستی نخواهد بود. در غیر این صورت
          می‌بایست به صورت دستی توسط تیم پشتیبانی سایت، مورد بررسی و تایید قرار
          گیرد.
          <br />
          توجه داشته باشید هر گونه تغییر سایر اطلاعات به جز شماره حساب ها و
          کارتها، مستلزم سپری شدن مجدد مراحل احراز هویت می‌باشد.
        </div>
      </div>

      <form enctype="multipart/form-data">

        <div class="row">
          <div class="col mt-4">
            <h6>اطلاعات شخصی</h6>
          </div>

          <div class="col-12 p-3 box">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="i_first_name">نام</label>
                <input
                    type="text"
                    class="form-control"
                    id="i_first_name"
                    v-model="firstName"
                    :disabled="kycStatus.verify_status === 'pending' || kycStatus.verify_status === 'verified'"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="i_last_name">نام خانوادگی</label>
                <input
                    type="text"
                    class="form-control"
                    id="i_last_name"
                    v-model="lastName"
                    :disabled="kycStatus.verify_status === 'pending' || kycStatus.verify_status === 'verified'"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="i_national_code">کد ملی</label>
                <input
                    dir="ltr"
                    type="tel"
                    class="form-control"
                    id="i_national_code"
                    v-mask="'##########'"
                    v-model="nationalCode"
                    :disabled="kycStatus.verify_status === 'pending' || kycStatus.verify_status === 'verified'"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="i_mobile">شماره همراه</label>
                <input
                    dir="ltr"
                    type="tel"
                    class="form-control"
                    id="i_mobile"
                    v-mask="'###########'"
                    v-model="mobile"
                    disabled="disabled"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="i_birth_date">تاریخ تولد</label>
                <date-picker
                    color="#4263eb"
                    v-model="birthDate"
                    label="تقویم"
                    input-class="form-control date-picker-form"
                    id="i_birth_date"
                    :disabled="kycStatus.verify_status === 'pending' || kycStatus.verify_status === 'verified'"
                ></date-picker>
              </div>
            </div>
          </div>

        </div>
        <div class="row" v-if="kycStatus.verify_status !== 'pending' && kycStatus.verify_status !== 'verified'">
          <div class="col mt-4" v-if="!formNameDisabled">
            <h6>احراز هویت</h6>
          </div>

          <div class="col-12 p-3 box"  v-if="!formNameDisabled">
            <div class="row">
              <div class="col-12 col-md-12 pt-2">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-info">
                      <p>شرایط ضبط ویدئو</p>
                      <ul style="padding-right: 15px; font-size: 14px">
                        <li>رعایت پوشش مناسب</li>
                        <li>محیط با نور مناسب و بدون صدای اضافه</li>
                        <li>نگاه مستقیم به دوربین و صدای واضح</li>
                        <li>حداکثر زمان 20 ثانیه</li>
                      </ul>

                      <p style="text-align: justify; font-size: 14px"> لطفا دقیقا متن زیر را (بدون کوچکترین تغییر) بخوانید.</p>
                      <p style="font-weight: bold; text-align: justify; font-size: 15px">اینجانب   {{ firstName }} {{lastName}}، با شماره ملی
                        {{ nationalCode }}، قوانین و مقررات صرافی ارز دیجیتال لیدیا را خوانده‌ام و آن را میپذیرم.</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text">
                    <div>
                      <div class="container py-4">
                        <div class="row justify-content-center">
                          <div class="col-12 col-md-6 ">
                            <div class="mt-4">
                              <div :class="['card', isRecording ? 'border-animation' : '']">
                                <div  :class="['ratio ratio-16x9']">
                                  <video
                                      style="width: 100%;"
                                      ref="videoPlayer"
                                      id="my-video"
                                      class="video-js vjs-default-skin"
                                      playsinline
                                      controls
                                      preload="auto"
                                      data-setup="{}"
                                  ></video>
                                </div>
                              </div>

                              <div class="d-flex justify-content-center align-items-center my-3">
                                <div v-if="!isRecording && !videoSrc" @click="startRecordingProcess" class="btn btn-primary">
                                  شروع ضبط
                                </div>
                                <div
                                    :class="['d-grid gap-2 mt-3', videoSrc ? '' : 'd-none']">
<!--                                  <button-->
<!--                                      @click="handleSubmitVideo"-->
<!--                                      class="btn btn-primary"-->
<!--                                      :disabled="kycStatus.verify_status === 'pending'"-->
<!--                                      type="button"-->
<!--                                  >-->
<!--                                    ارسال ویدیو-->
<!--                                  </button>-->
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 text-center mt-5"  v-if="kycStatus.verify_status !== 'pending' && kycStatus.verify_status !== 'verified'">
          <button
              style="margin-bottom: 100px"
              class="btn btn-primary login-btn"
              @click="handleSubmitVideo"
              type="button"
              :disabled="kycStatus.verify_status === 'pending' || kycStatus.verify_status === 'verified' || loadingBtn"
          >
            ارسال اطلاعات
          </button>
        </div>

      </form>
    </div>
    <div v-if="showModal" id="browserModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <p>کاربر گرامی، برای احراز هویت لطفا فقط از مرورگر سافاری (safari browser) استفاده کنید.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted, onBeforeUnmount, getCurrentInstance } from "vue";
import videojs from "video.js";
import "videojs-record/dist/videojs.record.js";

export default {

  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      session: this.$session.get("user_info"),
      markets: [],
      isDisabled: false,
      loadingBtn: false,
      buttonsVisible: false,
      firstName: "",
      lastName: "",
      nationalCode: "",
      mobile: "",
      birthDate: "",
      userInfo: null,

      formDisabled: false,
      formNameDisabled: false,

      kycStatus: {},

      cardNo: null,
      ibanNo: null,
      holderName: null,
      cards: [],
      hasImage: false,
      polling: null,
      showModal: false,
    };
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const videoPlayer = ref(null);
    const videoSrc = ref(null);
    const isRecording = ref(false); // Added state to track recording
    let player = null;


    const submitForm = () =>  {
      if (
          proxy.firstName === "" ||
          proxy.lastName === "" ||
          proxy.nationalCode === "" ||
          proxy.mobile === "" ||
          proxy.birthDate === "" ||
          proxy.firstName === null ||
          proxy.lastName === null ||
          proxy.nationalCode === null ||
          proxy.mobile === null ||
          proxy.birthDate === null
      ) {
        return proxy.notice(
            "error",
            "خطا",
            "لطفا تمامی فیلدهای فرم را پر کنید",
            10
        );
      }

      const date = new Date(proxy.birthDate);
      const formattedBirthDate = `${date.getFullYear()}/${String(
          date.getMonth() + 1
      ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;

      const formData = {
        first_name: proxy.firstName,
        last_name: proxy.lastName,
        national_code: proxy.nationalCode,
        mobile: proxy.mobile,
        birth_date: formattedBirthDate
      };

      proxy.notice(
          "info",
          "ارسال اطلاعات",
          "در حال ارسال اطلاعات لطفا کمی صبر کنید",
          2
      );

      axios
          .post(
              `${process.env.VUE_APP_BACKEND_URL}/user/identity-verify`,
              formData,
              { withCredentials: true }
          )
          .then(() => {
            proxy.notice(
                "success",
                "اطلاعات با موفقیت ارسال شد",
                "با تشکر از شما اطلاعات با موفقیت ارسال شد. منتظر تماس همکاران ما جهت تکمیل مراحل احراز هویت باشید",
                10
            );
            proxy.updateUserInfo();
            proxy.$router.push("/user/identity-verify-submitted");
            proxy.loadingBtn = false;
          })
          .catch((e) => {
            proxy.loadingBtn = false;
            proxy.notice("error", "خطا", e.response.data.error, 10);
          });
    };


    const startRecordingProcess = async () => {
      if (player) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });

          player.record().getDevice();
          player.srcObject = stream;

          setTimeout(() => {
            player.record().start();
            isRecording.value = true;
          }, 500)

          console.log("Recording started");
        } catch (error) {
          console.error("Error accessing the camera and microphone:", error);
        }
      }
    };

    const stopRecordingProcess = () => {
      if (player) {
        player.record().stop();
        isRecording.value = false;
      }
    };

    const handleRecordAgain = () => {
      if (player) {
        player.record().reset();
        videoSrc.value = null;
      }
    };

    const handleSubmitVideo = async () => {
      if (player && player.recordedData) {
        try {
          proxy.loadingBtn = true;
          const formData = new FormData();
          formData.append("File", player.recordedData);
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/selfie`, formData,
              {
                withCredentials: true,
                headers:{
                  'Content-Type': 'multipart/form-data',
                }
              }
          );
          console.log("Upload successful:", response.data);
          return submitForm();
        } catch (e) {
          // Handle error response
          console.error("Upload failed:", e.response.data);
          proxy.$toast.error(e.response.data.error, {
            duration: 10000,
            position: 'top-left',
          });
          proxy.loadingBtn = false;
        }
      }else {
        proxy.$toast.error("ضبط ویدیو اجباری است", {
          duration: 10000,
          position: 'top-left',
        });
      }
    };

    onMounted(() => {
      player = videojs(videoPlayer.value, {
        controls: true,
        controlBar: {
          deviceButton: false,
          fullscreenToggle: false,
          volumePanel: false
        },
        width: "100%",
        height: 250,
        plugins: {
          record: {
            audio: true,
            video: true,
            maxLength: 20,
            debug: true,
            videoMimeType: "video/mp4",
          }
        }
      });

      player.on("startRecord", () => {
        console.log("Recording started");
      });

      player.on("finishRecord", () => {
        videoSrc.value = URL.createObjectURL(player.recordedData);
      });

      player.on("error", (error) => {
        console.error("Error occurred:", error);
      });
    });

    onBeforeUnmount(() => {
      if (player) {
        player.dispose(); // Clean up Video.js instance
      }
    });

    return {
      videoPlayer,
      videoSrc,
      isRecording, // Return the recording state
      startRecordingProcess,
      stopRecordingProcess,
      handleRecordAgain,
      handleSubmitVideo,
    };
  },

  computed: {
    irtMarkets: function() {
      return Object.values(this.markets).filter(
          (m) => m.quote_asset_ticker === "IRT"
      );
    },
    usdtMarkets: function() {
      return Object.values(this.markets).filter(
          (m) => m.quote_asset_ticker === "USDT"
      );
    },
  },
  mounted() {
    this.checkBrowser();
  },
  methods: {
    isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    isChrome() {
      return /CriOS/i.test(navigator.userAgent);
    },
    checkBrowser() {
      if (this.isIOS() && this.isChrome()) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false; // Close modal when the user clicks close
    },

    checkSession() {
      if (!this.$session.exists("user_info")) {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },

    notice(type, title, description, duration) {
      if (duration === undefined) {
        duration = 1;
      }
      this.$notice[type]({
        title: title,
        description: description || "",
        duration: duration,
      });
    },

    just_persian(e) {
      const clickedElement = e.target;
      const p = /^[\u0600-\u06FF\s]+$/;
      if (!p.test(e.key)) {
        console.log("not match");
        clickedElement.value = "";
        return false;
      }
      return true;
    },

    loadUserInfo() {
      this.userInfo = this.$session.get("user_info");

      this.firstName = this.userInfo.first_name;
      this.lastName = this.userInfo.last_name;
      this.nationalCode = this.userInfo.national_code;
      this.mobile = this.userInfo.mobile;
      this.birthDate = this.userInfo.birth_date;
    },

    editAnyway() {
      this.$confirm({
        message: `اطلاعات هویتی شما تایید شده است،‌ در صورت ویرایش می‌بایست مجددا فرایند تایید را سپری کنند، آیا مطمئن هستید؟`,
        button: {
          no: "خیر",
          yes: "بله، ویرایش شود",
        },
        callback: (confirm) => {
          if (confirm) {
            this.formDisabled = false;
            this.$refs.selfieDropzone.enable();
          }
        },
      });
    },

    updateUserInfo() {
      if (this.$session.exists("user_info")) {
        axios
            .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
              withCredentials: true,
            })
            .then((response) => {
              this.userInfo = response.data;
              this.$session.set("user_info", this.userInfo);
              this.userInfo = response.data;
              this.firstName = this.userInfo.first_name;
              this.lastName = this.userInfo.last_name;
              this.nationalCode = this.userInfo.national_code;
              this.mobile = this.userInfo.mobile;
              this.birthDate = this.userInfo.birth_date;
            })
            .catch(() => {
              // this.$session.destroy();
            });
      }
    },

    uploadSuccess(file) {
      if (file.accepted === true && file.status === "success") {
        this.notice("success", "تصویر با موفقیت آپلود شد.");
        this.kycStatus.selfie = true;
      }
    },
    updateMarketList() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + "/market/list")
          .then((response) => {
            this.markets = response.data;
          })
          .catch(() => {
            // console.log(e)
          });
    },

    getKycStatus() {
      axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/kyc-status", {
            withCredentials: true,
          })
          .then((response) => {
            this.kycStatus = response.data;
            if (this.kycStatus.verify_status === "verified") {
              this.formDisabled = true;
              this.formNameDisabled = true;
              this.$refs.selfieDropzone.disable();
            }
          })
          .catch(() => {
            // console.log(e)
          });
    },

    checkKycLevel(userInfo) {
      if (userInfo.kyc_step < 1) {
        return this.$router.push({ name: "EmailVerify" });
      } else if (userInfo.kyc_step < 2) {
        return this.$router.push({ name: "MobileVerify" });
      }
    }
  },
  created() {
    this.checkSession();

    this.loadUserInfo();
    this.updateMarketList();

    this.getKycStatus();

    if (this.kycStatus.verify_status === "verified") {
      this.formDisabled = true;
      this.formNameDisabled = true;
      this.$refs.selfieDropzone.disable();
    }
    this.checkKycLevel(this.userInfo);
  },
};
</script>
<style lang="scss" scoped>
.app {
  text-align: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
  }

  &__title {
    display: block;
    font-size: 1.5em;
  }
}
</style>
<style scoped>
.modal {
  display: block; /* Make sure modal can be shown */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.login-btn {
  width: 200px !important;
}



.selfie img {
  border-radius: 10px;
  max-width: 90%;
}

.wrapper {
  width: 100%;
}

#content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.box {
  color: #fff;
  background-color: #565656;
  border-radius: 10px;
}

/* ********************************/
#customdropzone {
  background-color: #ababab;
  letter-spacing: 0.2px;
  color: #fff;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
  border-radius: 10px;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}

/* ********************************/

@media screen and (max-width: 600px) {
  .box {
    font-size: 12px;
  }

  .box h4 {
    font-size: 18px;
  }
}
</style>
