<template>
    <table class="table table-borderless">
        <thead>
        <tr>
            <th scope="col">زمان</th>
            <th scope="col">بازار</th>
            <th scope="col">نوع</th>
            <th scope="col" class="d-none d-md-table-cell">سمت</th>
            <th scope="col">مقدار</th>
            <th scope="col">قیمت واحد</th>
            <th scope="col">قیمت کل</th>
            <th scope="col">کارمزد</th>
            <th scope="col">دریافتی شما</th>
            <th scope="col">شناسه</th>
        </tr>
        </thead>
        <tbody v-if="orders.records !== undefined && orders.records.length">

        <tr v-for="order in orders.records" :key="order.order_id">
            <td class="ltr">{{order.create_time | persianDateUnix}}</td>
            <td>{{order.market | underscoreToSlash}}</td>
            <td>
                <span v-if="order.type === 'limit'">
                    عادی
                </span>
                <span v-else>
                    فوری
                </span>

            </td>

            <td class="d-none d-md-table-cell">
                                        <span v-if="order.side === 'ask'" class="text-danger">
                                            فروش
                                        </span>
                <span v-else class="text-success">
                                            خرید
                                        </span>

            </td>
            <td>{{order.deal_base | prec(order.amount_prec)}}</td>
            <td v-if="order.side === 'ask'" class="text-danger">{{order.price | prec(order.price_prec)}}</td>
            <td v-else class="text-success">{{order.price | prec(order.price_prec)}}</td>
            <td v-if="order.side === 'ask'" class="text-danger">
                {{order.deal_quote | prec(order.price_prec)}}
            </td>
            <td v-else class="text-success">
                {{order.deal_quote | prec(order.price_prec)}}
            </td>
            <td>{{order.deal_fee | prec(order.amount_prec) }}</td>
            <td>{{order.rec_amount | prec(order.amount_prec) }}</td>
            <td>{{order.order_id }}</td>

        </tr>
        </tbody>
        <tbody v-else>
        <tr>
            <td colspan="8" class="text-center">
                سفارشی موجود نمی باشد
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: 'OrdersTable',
        props: ['orders'],

    }
</script>

<style scoped>
    table thead th {
        border-bottom: 2px solid #999;
        font-size: 14px;
    }

    table tbody tr {
        border-bottom: 1px solid #999;
        font-size: 14px;
    }

    .open-orders table {
        font-size: 12px;
        border: 0;
        border-spacing: 0;
    }

</style>
