<template>
  <div class=" mb-2 main container-fluid">
    <div class="wrapperMain">
      <Header :activeTab="activeTab" @handleActiveTab="handleActiveTab" />

      <!-- Page Content  -->
      <div class="wrapperSide">
        <SideBar :activeTab="activeTab" @handleActiveTab="handleActiveTab" />
        <div class="mainContent mb-2">
          <slot></slot>
          <div style="margin-bottom: 20px"></div>
        </div>
      </div>

      <bottomNavigation></bottomNavigation>
    </div>
  </div>
</template>

<script>
import SideBar from "./components/SideBar.vue";
import Header from "./components/Header.vue";
import axios from "axios";
import bottomNavigation from "@/layout/user/components/bottomNavigation.vue";
export default {
  name: "userLayout",
  components: {
    Header,
    SideBar,
    bottomNavigation
  },
  data() {
    return {
      polling: null,
      userInfo: this.$session.get("user_info"),
      activeTab: "داشبورد",
      listTabs: [{ name: "داشبورد", link: "" }],
    };
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.checkSession();
      }, 3000);
    },
    checkSession() {
      if (!this.$session.exists("user_info") && this.$route.name !== "Trade") {
        this.$session.destroy();
        return this.$router.push("/login").catch(() => {});
      }
    },
    handleActiveTab(item) {
      this.activeTab = item;
      if (item === "خروج") {
        this.logout();
      }
    },
    logout() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/user/logout", {
          withCredentials: true,
        })
        .then(() => {
          this.$session.destroy();
          return this.$router.push("/");
        })
        .catch(() => {
          // console.log(e)
        });
    },
    updateUserInfo() {
      if (this.$session.exists("user_info")) {
        axios
          .get(process.env.VUE_APP_BACKEND_URL + "/user/info", {
            withCredentials: true,
          })
          .then((response) => {
            this.$session.set("user_info", response.data);
          })
          .catch(() => {
            this.$session.destroy();
          });
      }
    },
    checkKycLevel() {
      const userInfo = this.$session.get("user_info");
      if (userInfo) {
        if (userInfo.kyc_step == 0) {
          return this.$router.push({ name: "EmailVerify" });
        } else if (userInfo.kyc_step == 1) {
          return this.$router.push({ name: "MobileVerify" });
        } else if (userInfo.kyc_step == 2) {
          return this.$router.push({ name: "IdentityVerify" });
        } else if (userInfo.kyc_step == 3) {
          return this.$router.push({ name: "Profile" });
        }
      }
    },
  },
  // created() {
  //   this.checkKycLevel(this.session.get("user_info"));
  // },
  mounted() { 
    this.checkSession();
    this.checkKycLevel();
    this.pollData();
    this.updateUserInfo();
  },
  beforeDestroy() {
    this.$nextTick(() => {
      clearInterval(this.polling);
    });
  },
};
</script>

<style scoped>
/* new */
.wrapperMain {
  width: 100% !important;
  height: 100% !important;
}
.wrapperSide {
  width: 100% !important;
  min-height: 100vh;
  display: flex !important;
  justify-content: space-between !important;
}
.mainContent {
  flex-grow: 1 !important;
  margin-right: 3.5rem !important;
  margin-left: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.main {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
@media screen and (max-width: 992px) {
  .mainContent {
    margin: 10px 0 !important;
  }
  .wrapperSide {
    display: block !important;
  }
}
</style>
